import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import ILanguages from '../interfaces/ILanguages';

const initializeI18N = (lang: ILanguages = 'en', resources?: Resource) => {
    i18n.use(initReactI18next).init({
        fallbackLng: lang,
        debug: false,
        interpolation: {
            escapeValue: false
        },
        resources: resources,
        lng: localStorage.getItem('lang') as ILanguages
    });
};

export default initializeI18N;
