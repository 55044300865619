import { Theme, createTheme } from '@mui/material/styles';

export const createPartnerPortalTheme: (theme: Theme) => Theme = (theme) => {
    return createTheme({
        ...theme,
        palette: {
            ...theme.palette
        }
    });
};
