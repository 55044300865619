import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { SearchInput } from 'nulia-ui';
// import { Badge } from '@material-ui/core';

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'applyPadding' && prop !== 'isOpen'
})<{ applyPadding: boolean; isOpen: boolean }>(({ theme, applyPadding, isOpen }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    paddingBottom: applyPadding ? '20px' : 'unset',
    [theme.breakpoints.down('lg')]: {
        width: !isOpen ? '100%' : '100%'
    }
}));

export const HeaderBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isSearchVisible'
})<{ isSearchVisible: boolean }>(({ theme, isSearchVisible }) => ({
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '8px 0px 8px 10px',
    [theme.breakpoints.down('md')]: {
        padding: '8px 0px',
        paddingBottom: isSearchVisible ? '4px' : 0
    }
}));

export const RootInnerBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isSidemenuOpen'
})<{ isSidemenuOpen: boolean }>(({ theme, isSidemenuOpen }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
        paddingLeft: isSidemenuOpen ? 10 : theme.breakpoints.down('md') ? 0 : 14
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: '100vh'
    }
}));

export const RootInnerContent = styled('div')<{ isPartnerPortalMode: boolean }>(({ theme, isPartnerPortalMode }) => ({
    display: 'grid',
    gridTemplateColumns: isPartnerPortalMode ? 'auto 1fr auto' : 'auto 3fr 1fr',
    width: '1060px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginLeft: 'unset'
    }
}));

export const RootInnerContentMdDown = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isThirdRowVisible' && prop !== 'isTabsVisible'
})<{ isThirdRowVisible: boolean; isTabsVisible: boolean }>(({ isThirdRowVisible, isTabsVisible }) => ({
    display: 'grid',
    gridTemplateRows:
        isThirdRowVisible && isTabsVisible ? '1fr 54px 50px' : isTabsVisible || isThirdRowVisible ? '1fr 46px' : '1fr',
    width: '100%',
    marginLeft: 'unset'
}));

export const BackNavigationBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isSidemenuVisible'
})<{ isSidemenuVisible: boolean }>(({ isSidemenuVisible = false, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flex: 2,
    gap: '20px',
    paddingLeft: isSidemenuVisible ? '10px' : '50px',
    [theme.breakpoints.down('lg')]: {
        flex: 1
    }
}));

export const BackNavigationControlBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
});

export const BackNavigationPageNameSpan = styled(Typography)(() => ({
    paddingLeft: 12,
    color: 'rgba(0,0,0)'
}));

export const ActionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '6px',
    [theme.breakpoints.down('lg')]: {
        paddingRight: '20px',
        flex: 1
    }
}));

export const BackIconBox = styled('div')({
    cursor: 'pointer',
    position: 'relative',
    top: 3
});

export const StyledQuestionIconButton = styled(IconButton)({});

export const DateLabel = styled(Typography)(({ theme }) => ({
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 11,
    color: 'rgba(0, 0, 0, 0.8)',
    width: '100%',
    fontSize: 11,
    [theme.breakpoints.down('lg')]: {
        textTransform: 'unset',
        fontSize: 11
    },
    [theme.breakpoints.down(800)]: {
        fontSize: 10,
        lineHeight: '15px'
    }
}));

// export const StyledBellIconButton = styled(IconButton)(({ theme }) => ({
//     [theme.breakpoints.down('sm')]: {
//         marginLeft: 4,
//     },
// }));

// export const StyledBadge = styled(Badge)(({ theme }) => ({
//     '.MuiBadge-colorPrimary': {
//         backgroundColor: theme.palette.primary.main,
//         border: `2px solid ${theme.palette.common.white}`,
//     },
//     '.MuiBadge-dot': {
//         minWidth: 12,
//         height: 12,
//         borderRadius: 6,
//     },
// }));

export const PageName = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
    fontWeight: 500,
    letterSpacing: '0.15px',
    lineHeight: '24px'
}));

export const LastUpdatedBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '1080px',
    marginTop: 21,
    marginBottom: 30,
    paddingLeft: 20,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
        width: 'unset'
    }
}));

export const SidemenuControlBox = styled('div')({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 56,
    height: 40,
    cursor: 'pointer'
});

export const TabsBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    flex: 4
});

export const TabLinkItemBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ theme, isActive }) => ({
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: '1px',
    color: isActive ? theme.palette.primary.main : 'rgba(97, 96, 99, 1)',
    textTransform: 'uppercase',
    cursor: 'pointer',
    textDecoration: isActive ? 'underline' : 'none',
    textDecorationThickness: isActive ? '4px' : 'inherit',
    textUnderlineOffset: isActive ? '20px' : 'inherit',
    padding: '14px 4px',

    '&:hover': {
        background: isActive ? 'inherit' : '#E9E9E9',
        color: isActive ? theme.palette.primary.main : 'rgba(97, 96, 99, 1)'
    },
    [theme.breakpoints.down('lg')]: {
        textWrap: 'nowrap'
    }
}));

export const StyledFeedbackIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ isActive }) => ({
    padding: '8px 10px',
    cursor: isActive ? 'default' : 'isActive'
}));

export const EngagementPointsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '10px',
    [theme.breakpoints.down(660)]: {
        gap: '2px',
        alignItems: 'center'
    }
}));

export const EngagementPointsTypography = styled(Typography)(({ theme }) => ({
    display: 'flex',
    textWrap: 'nowrap',
    alignItems: 'center',
    marginRight: '35px',
    color: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('lg')]: {
        marginRight: '4px'
    }
}));

export const EngagementPointValueTypography = styled(Typography)(({ theme }) => ({
    marginLeft: '8px',
    color: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('md')]: {
        marginRight: '4px',
        marginLeft: '2px'
    }
}));

export const EngagementPointLabelTypography = styled(Typography)(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down(660)]: {
        fontSize: '14px'
    }
}));

export const UserRow = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end'
});

export const TabsRow = styled('div')({});

export const SearchRow = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

export const SearchInputWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.down('md')]: {
        width: '80%',
        display: 'flex',
        justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
        width: 133
    }
}));

export const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
    background: 'linear-gradient(0deg, #F3F1F8, #F3F1F8), #FFFFFF;',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    marginRight: 12,
    '& input': {
        background: 'linear-gradient(0deg, #F3F1F8, #F3F1F8), #FFFFFF;'
    },
    [theme.breakpoints.down('md')]: {
        width: '80%',
        display: 'flex',
        justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
        width: 120
    }
}));
