import { styled } from '@mui/system';
import { Button } from '@mui/material';

export const ButtonRoot = styled(Button)<{ disabled: boolean }>(({ theme, disabled }) => ({
    minWidth: 108,
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    fontFamily: 'Ubuntu !important',
    lineHeight: 1.75,
    backgroundColor: disabled ? '#ccc !important' : `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
        backgroundColor: `${theme.palette.primary.dark} !important`
    }
}));
