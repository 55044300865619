import React, { ChangeEvent, FC, PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useTheme, useMediaQuery } from '@mui/system';
import GraphCard from '../../../components/graph/graph-card/GraphCard';
import { EGraphCardSelect } from '../../../../../interfaces/enums/EGraphCardSelect';
import Table from '../../../../../ui/general/table/Table';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import { useInsightsPersonalOutcomesStateValue } from '../../../../../contexts/InsightsPersonalOutcomesContext';
import { IInsightsPersonalOutcomeVM } from '../../../../../interfaces/views/IInsightsPersonalOutcomeVM';
import ClipboardCheck from '../../../../../assets/icons/ClipboardCheck';
import DottedCircle from '../../../../../ui/general/dotted-circle/DottedCircle';
import { createUrl } from '../../../../../utils/createUrl';
import {
    RootBox,
    SearchInputWrapper,
    StyledSearchInput,
    TableItemCountBox,
    TableItemCountTypography,
    TableLink
} from '../../../Style';
import useInsightsTimelineOption from '../../../../../hooks/useInsightsTimelineOption';
import BlueWarningIcon from '../../../../../assets/icons/BlueWarningIcon';
import { ISkillToWorkOn } from '../../../../../interfaces/ISkillToWorkOn';
import { Order } from '../../../../../hooks/useTable';
import { sortNumber } from '../../../../../utils/sortNumber';
import { formatDate } from '../../../../../utils/dateUtil';
import { ESetMasterLevelAction } from '../../../../../services/OutcomeQueryService';
import { WarningIconSpan } from './Style';
import { useInsightsStateValue } from '../../../../../contexts/InsightsContext';
import { EInsightsMode } from '../../../../../interfaces/enums/EInsightsMode';
import { useCrumbsStateValue } from '../../../../../contexts/CrumbsContext';
import { makeOutcomeRoute, makeSkillRoute, routes } from '../../../../../pages/routes';
import sortPossibleUndefinedStrings from '../../../../../utils/sortPossibleUndefinedStrings';
import fetchImageErrorCallback from '../../../../../utils/fetchImageErrorCallback';
import { isInTeams } from '../../../../../utils/isInTeams';
import { accessibilityEnterKeyCallback } from '../../../../../utils/accessibilityUtils';
import { Skeleton } from '@mui/material';

interface ITableHeader {
    outcomeBadge: string;
    title: string;
    isCompanyFocus: boolean;
    assignedDate: string;
    masterLevelRequired: boolean;
    totalBadgesReadyToClaim: number;
    skillToWorkOn: string;
}

const PersonalOutcomesTab: FC<PropsWithChildren> = () => {
    const { t } = useTranslation();
    const {
        data,
        scoreLines,
        changeScoreLinesInterval,
        isLoading,
        isError,
        searchText,
        setSearchText,
        toggleMasterLevelToUserClickHandler,
        isPostSetMasterLevelFocusLoading
    } = useInsightsPersonalOutcomesStateValue();
    const { activeTimelineOption, handleTimelineChange } = useInsightsTimelineOption({
        changedTimelineOptionCallback: changeScoreLinesInterval,
        initialTimelineOptionValue: EGraphCardSelect.MONTH_4
    });
    const theme = useTheme();
    const { mode } = useInsightsStateValue();
    const navigate = useNavigate();
    const { appendCrumb, setCrumbs } = useCrumbsStateValue();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'outcomeBadge',
            label: '',
            customRender: (value: any, obj: IInsightsPersonalOutcomeVM) => {
                return (
                    <DottedCircle
                        numberOfAchievedDots={obj.totalSkillsAttained}
                        numberOfDots={obj.totalSkills}
                        numberOfInProgressDots={obj.totalSkillsNotAttained}
                        numberOfNeedAttentionDots={obj.totalSkillsNeedsAttention}
                        isMaster={obj.isMasterFocus}
                        isAssigned={!!obj.assignedDate}
                    >
                        {obj.image ? (
                            <img
                                alt='outcome'
                                src={createUrl(obj.image)?.generatedUrl}
                                onError={(e) => fetchImageErrorCallback(e, '70px', '70px')}
                            />
                        ) : (
                            <Skeleton variant='circular' height={70} width={70} />
                        )}
                    </DottedCircle>
                );
            },
            customSort: (a: IInsightsPersonalOutcomeVM, b: IInsightsPersonalOutcomeVM, order: Order) => {
                return sortNumber(a.totalSkillsAttained, b.totalSkillsAttained, order);
            }
        },
        {
            id: 'title',
            label: 'Outcome Name',
            minWidth: '130px'
        },
        {
            id: 'isCompanyFocus',
            align: 'center',
            label: <ClipboardCheck />,
            customRender: (value) => {
                if (value) return <ClipboardCheck />;
                return <></>;
            },
            customSort: (a: IInsightsPersonalOutcomeVM, b: IInsightsPersonalOutcomeVM, order: Order) => {
                return order === 'asc'
                    ? Number(a.isCompanyFocus) - Number(b.isCompanyFocus)
                    : Number(b.isCompanyFocus) - Number(a.isCompanyFocus);
            }
        },
        {
            id: 'masterLevelRequired',
            label: <BlueWarningIcon />,
            align: 'center',
            customRender: (value, obj: IInsightsPersonalOutcomeVM) => {
                const isClickAvailable = mode !== EInsightsMode.STANDARD;
                if (value)
                    return (
                        <WarningIconSpan
                            id='master-level-required-span'
                            isAvailable={isClickAvailable}
                            isDisabled={isPostSetMasterLevelFocusLoading}
                            onClick={(e: React.MouseEvent) => {
                                if (!isClickAvailable) return;
                                e.stopPropagation();
                                if (!isPostSetMasterLevelFocusLoading)
                                    toggleMasterLevelToUserClickHandler(obj.outcomeId, ESetMasterLevelAction.DISABLE);
                            }}
                        >
                            <BlueWarningIcon />
                        </WarningIconSpan>
                    );
                return (
                    <WarningIconSpan
                        id='master-level-required-span'
                        isAvailable={isClickAvailable}
                        isDisabled={isPostSetMasterLevelFocusLoading}
                        onClick={(e: React.MouseEvent) => {
                            if (!isClickAvailable) return;
                            e.stopPropagation();
                            if (!isPostSetMasterLevelFocusLoading)
                                toggleMasterLevelToUserClickHandler(obj.outcomeId, ESetMasterLevelAction.ENABLE);
                        }}
                    >
                        <BlueWarningIcon color='rgba(0, 0, 0, 0.16)' />
                    </WarningIconSpan>
                );
            },
            customSort: (a: IInsightsPersonalOutcomeVM, b: IInsightsPersonalOutcomeVM, order: Order) => {
                return order === 'asc'
                    ? Number(a.masterLevelRequired) - Number(b.masterLevelRequired)
                    : Number(b.masterLevelRequired) - Number(a.masterLevelRequired);
            }
        },
        {
            id: 'assignedDate',
            label: 'Assigned',
            minWidth: '120px',
            customRender: (value) => {
                return formatDate(value);
            }
        },
        {
            id: 'totalBadgesReadyToClaim',
            numeric: true,
            label: 'Badges You Can Claim'
        },
        {
            id: 'skillToWorkOn',
            label: 'Work on This Skill',
            minWidth: '180px',
            customSort: (a: IInsightsPersonalOutcomeVM, b: IInsightsPersonalOutcomeVM, order: Order) => {
                return sortPossibleUndefinedStrings(a.skillToWorkOn?.text, b.skillToWorkOn?.text, order);
            },
            customRender: (value: ISkillToWorkOn, obj: IInsightsPersonalOutcomeVM) => {
                if (value && mode === EInsightsMode.STANDARD) {
                    const skillRoute = makeSkillRoute(value.id);
                    const onSkillLinkClick = (e: React.MouseEvent | React.KeyboardEvent) => {
                        e.stopPropagation();
                        appendCrumb({
                            name: value.text || 'Skill',
                            pathname: skillRoute
                        });
                        navigate(skillRoute);
                    };
                    return (
                        <TableLink
                            tabIndex={0}
                            onKeyDown={(e: React.KeyboardEvent) =>
                                accessibilityEnterKeyCallback(e, () => onSkillLinkClick(e))
                            }
                            variant={isMdDown ? 'caption' : 'subtitle2'}
                            onClick={onSkillLinkClick}
                        >
                            {value.text}
                        </TableLink>
                    );
                }
                return value?.text || '';
            }
        }
    ];

    const scoreLinesValuesMemo = useMemo(() => {
        if (isLoading || !scoreLines) return undefined;
        switch (activeTimelineOption) {
            case EGraphCardSelect.MONTH_4:
                return scoreLines?.map((scoreLine) => {
                    return {
                        ...scoreLine,
                        scores: scoreLine.scores.slice(-15)
                    };
                });
            case EGraphCardSelect.YEAR_WITH_WEEKS:
                return scoreLines;
            default:
                return scoreLines;
        }
    }, [scoreLines, activeTimelineOption, isLoading]);

    const xAxisMemo = useMemo(() => {
        if (isLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            switch (activeTimelineOption) {
                case EGraphCardSelect.MONTH_4:
                    return scoreLines[0].scores.map((scoreItem) => format(scoreItem.date as Date, 'MM/d/yy'));
                case EGraphCardSelect.YEAR_WITH_WEEKS:
                    return scoreLines[0].scores.map((scoreItem) => format(scoreItem.date as Date, 'MM/d/yy'));
                default:
                    return [];
            }
        }
        return [];
    }, [scoreLines, activeTimelineOption, isLoading]);

    const customToolbar = useMemo(() => {
        return (
            <TableItemCountBox>
                {data && (
                    <TableItemCountTypography variant='caption'>{`${data.length} Outcomes`}</TableItemCountTypography>
                )}
            </TableItemCountBox>
        );
    }, [data]);

    const handleTableRowClick = (id: string, obj: IInsightsPersonalOutcomeVM) => {
        const pathname = makeOutcomeRoute(obj.outcomeId);
        if (isInTeams()) {
            setCrumbs([
                {
                    name: 'Insights',
                    pathname: routes.INSIGHTS_PERSONAL_OUTCOMES
                },
                {
                    pathname,
                    name: obj.title
                }
            ]);
        } else {
            appendCrumb({
                pathname,
                name: obj.title
            });
        }

        navigate(pathname);
    };

    return (
        <RootBox>
            <GraphCard
                scoreLines={scoreLinesValuesMemo}
                xAxis={xAxisMemo}
                handleTimelineChange={handleTimelineChange}
                translations={{ graphCardTitle: t('insights.personal.outcomes.graphCardTitle') }}
                isDataEmpty={scoreLinesValuesMemo && scoreLinesValuesMemo.length === 0}
                isLoading={isLoading || scoreLinesValuesMemo === undefined}
            />
            <SearchInputWrapper className='search-box'>
                <StyledSearchInput
                    placeholder='Search Outcomes'
                    value={searchText || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (setSearchText) setSearchText(e.target.value);
                    }}
                />
            </SearchInputWrapper>
            <Table<IInsightsPersonalOutcomeVM, ITableHeader>
                headCells={headCells}
                data={data || []}
                propertyKeys={headCells.map((headCell) => {
                    return headCell.id;
                })}
                tableTitlePlural=''
                isFilterControlVisible={false}
                isFilterDrawerOpen={false}
                isLoading={isLoading}
                isError={isError}
                customToolbar={customToolbar}
                initialOrderBy='title'
                onRowClick={mode === EInsightsMode.STANDARD ? handleTableRowClick : undefined}
                customMediaColumnName='outcomeBadge'
                customMediaSecondaryColumns={['isCompanyFocus', 'masterLevelRequired']}
            />
        </RootBox>
    );
};

export default PersonalOutcomesTab;
