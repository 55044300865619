import { createContext, FC, useContext, PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router';
import { IAchievementGroupVM } from '../interfaces/views/IAchievementGroupVM';
import { useGetAchievementsByGroupIdQuery } from '../services/AchievementQueryService';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

interface IProps {
    achievementGroupDetail?: IAchievementGroupVM;
    isDataLoading?: boolean;
}

const AchievementsDetailContext = createContext<IProps>({} as IProps);

export const AchievementsDetailProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const params = useParams();
    const {
        data: fetchedAchievementGroupDetail,
        refetch: refetchAchievementDetails,
        isLoading: isDataLoading
    } = useGetAchievementsByGroupIdQuery(params?.id);
    const { resetQuery } = useApiStateValue();

    useEffect(() => {
        return () => {
            resetQuery(EApiQueryKey.ACHIEVEMENTS_FETCH_BY_GROUP_ID);
        };
    }, []);

    useEffect(() => {
        if (params && params.id) {
            refetchAchievementDetails();
        }
    }, [params]);

    const achievementsDetailState: IProps = {
        achievementGroupDetail: fetchedAchievementGroupDetail,
        isDataLoading
    };

    return (
        <AchievementsDetailContext.Provider value={achievementsDetailState}>
            {children}
        </AchievementsDetailContext.Provider>
    );
};

export const useAchievementsDetailStateValue: () => IProps = () => useContext(AchievementsDetailContext);

export default AchievementsDetailContext;
