import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import {
    Login,
    msalConfig,
    themeConfig,
    initializeI18N,
    TranslationProvider,
    reactPlugin,
    NotificationProvider,
    Loading,
    CrumbsProvider,
    AppInsightsProvider,
    ApiProvider,
    useApiStateValue,
    enLangTranslations
} from 'nulia-ui';
import { enPartnerLangTranslations } from 'partner-portal';
import Token from './Token';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { CssBaseline } from '@material-ui/core';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React, { useEffect } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RovingTabIndexProvider } from 'react-roving-tabindex';

const queryClient = new QueryClient();

const MainContent = () => {
    const { instance, accounts, inProgress } = useMsal();
    const { setQueryClient } = useApiStateValue();
    initializeI18N('en', {
        en: {
            translation: { ...enLangTranslations, ...enPartnerLangTranslations }
        }
    });

    useEffect(() => {
        setQueryClient(queryClient);
    }, []);

    if (inProgress === 'login') {
        return (
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start'
                }}
            >
                Loading...
                <Loading style={{ marginLeft: 5 }} />
            </div>
        );
    }

    return (
        <div style={{ maxWidth: '100vw', width: '100%' }}>
            <RovingTabIndexProvider>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <AppInsightsProvider>
                        <TranslationProvider>
                            <CrumbsProvider>
                                <ReactQueryDevtools initialIsOpen={false} />
                                <AuthenticatedTemplate>
                                    <NotificationProvider>
                                        <Token instance={instance} accounts={accounts} />
                                    </NotificationProvider>
                                </AuthenticatedTemplate>

                                <UnauthenticatedTemplate>
                                    <Login />
                                </UnauthenticatedTemplate>
                            </CrumbsProvider>
                        </TranslationProvider>
                    </AppInsightsProvider>
                </AppInsightsContext.Provider>
            </RovingTabIndexProvider>
        </div>
    );
};

export default function App() {
    const msalInstance = new PublicClientApplication(msalConfig);

    const themeConfObj = themeConfig();
    const theme = createTheme({}, themeConfObj);

    // useEffect(() => {
    //     appInsightsInit();
    // }, []);

    return (
        <ApiProvider>
            <QueryClientProvider client={queryClient}>
                <MsalProvider instance={msalInstance}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <MainContent />
                    </ThemeProvider>
                </MsalProvider>
            </QueryClientProvider>
        </ApiProvider>
    );
}
