import { ChangeEvent, FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import isEmail from 'validator/lib/isEmail';
import Input from '../../../../Input';
import Dialog from '../../../../components/dialogs/dialog/Dialog';
import { EAccountType } from '../../../../interfaces/enums/EAccountType';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    sendInstructionsDialog: {},
    text: {
        color: 'rgba(0,0,0,0.64)',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: 1.43,
        marginBottom: 32
    },
    label: {
        color: 'rgba(0,0,0,0.64)',
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0.42,
        lineHeight: 2
    }
});

interface Props {
    accountType: EAccountType;
    open: boolean;
    onClose: () => void;
}

const SendConnectInstructionsDialog: FC<Props> = ({ accountType, open, onClose }) => {
    const classes = useStyles();
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const { t } = useTranslation();

    const sendInstructions = async () => {
        setEmailError(false);
        if (email === '' || !isEmail(email)) {
            setEmailError(true);
        }
        onClose();
        // await ApiService.sendConnectEmail(email, account.id);
        // await getAccountData((account as Customer)?.resellerId ?? '');
    };
    const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onAccept={sendInstructions}
            title={t('accountDetails.connection.sendInstructionsModal.headerTitle')}
            acceptText={t('accountDetails.connection.sendInstructionsModal.acceptButton')}
            cancelText={t('accountDetails.connection.sendInstructionsModal.cancelButton')}
            acceptButtonWidth={168}
            width={384}
            minHeight='270px'
            content={
                <div className={classes.sendInstructionsDialog}>
                    <div className={classes.text}>
                        {t('accountDetails.connection.sendInstructionsModal.description')}
                    </div>
                    <div className={classes.label}>
                        {t('accountDetails.connection.sendInstructionsModal.adminEmail')}
                    </div>
                    <Input value={email} onChange={onChangeEmail} style={{ width: '100%' }} error={emailError} />
                </div>
            }
        />
    );
};

export default SendConnectInstructionsDialog;
