import { createContext, FC, useContext, PropsWithChildren } from 'react';
import { IGetHowToCategoriesResponse, useGetHowToCategoriesQuery } from '../services/HowToService';
import { IHowToCategoryVM } from '../interfaces/views/IHowToCategoryVM';
import { usePostUserContentTrackingQuery } from '../services/UserQueryService';
import { EUserContentTrackingType } from '../interfaces/enums/EUserContentTrackingType';

interface IHowTosContextProps {
    categories?: IHowToCategoryVM[];
    isDataLoading?: boolean;
    data?: IGetHowToCategoriesResponse;
    sendContentUsageRequest: (relatedId?: number) => Promise<void>;
}

interface IProps {}

const HowTosContext = createContext<IHowTosContextProps>({} as IHowTosContextProps);

export const HowTosProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const { data: fetchedHowToCategoriesData, isLoading: isDataLoading } = useGetHowToCategoriesQuery();
    const { mutateAsync: mutatePostUserContentTrackingAsync } = usePostUserContentTrackingQuery();

    const sendContentUsageRequest: () => Promise<void> = async () => {
        try {
            await mutatePostUserContentTrackingAsync({
                contentType: EUserContentTrackingType.HOW_TO_VIDEO
            });
        } catch (e) {
            console.error(e);
        }
    };

    const howTosState: IHowTosContextProps = {
        categories: fetchedHowToCategoriesData?.categories,
        isDataLoading,
        data: fetchedHowToCategoriesData,
        sendContentUsageRequest
    };

    return <HowTosContext.Provider value={howTosState}>{children}</HowTosContext.Provider>;
};

export const useHowTosStateValue: () => IHowTosContextProps = () => useContext(HowTosContext);

export default HowTosContext;
