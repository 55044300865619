import { createContext, FC, useContext, PropsWithChildren, useState, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material';
import {
    useDownloadAssessmentReportByDateQuery,
    useGetAssessmentReportByDateQuery,
    useGetAssessmentReportDataQuery
} from '../services/AssessmentQueryService';
import { IAssessmentReportVM } from '../interfaces/views/IAssessmentReportVM';
import { IAssessmentReportItemVM } from '../interfaces/views/IAssessmentReportItemVM';
import useDropdown from '../hooks/useDropdown';

export interface IInsightsOrganizationAssessmentContext {
    data?: IAssessmentReportItemVM[];
    departments?: string[];
    isAssessmentDataLoading: boolean;
    isAssessmentReportLoading: boolean;
    assessmentReportData?: IAssessmentReportVM[];
    handleWeeksFilterDropdownChange: (event: SelectChangeEvent<unknown>) => void;
    handleDepartmentFilterDropdownChange: (event: SelectChangeEvent<unknown>) => void;
    handleUserFilterDropdownChange: (event: SelectChangeEvent<unknown>) => void;
    changeDepartmentFilterDropdownValue: (option: string) => void;
    changeWeekFilterDropdownValue: (option: string) => void;
    selectedWeeksFilterValue?: string;
    selectedDepartmentFilterValue?: string;
    selectedUserFilterValue?: string;
    downloadAssessmentReportAsCSV: () => void;
    isDownloadAssessmentLoading: boolean;
    isAssesmentDataFetchingError: boolean;
}

export enum EUserReportType {
    ALL = 'All',
    WITH_LICENSE = 'WithLicense',
    WITHOUT_LICENSE = 'WithoutLicense'
}

export const InsightsOrganizationAssessmentContext = createContext<IInsightsOrganizationAssessmentContext>(
    {} as IInsightsOrganizationAssessmentContext
);

export const InsightsOrganizationalAssessmentProvider: FC<PropsWithChildren> = ({ children }) => {
    const {
        handleFilterDropdownChange: handleWeeksFilterDropdownChange,
        value: selectedWeeksFilterValue,
        changeFilterDropdownValue: changeWeekFilterDropdownValue
    } = useDropdown({});
    const {
        handleFilterDropdownChange: handleDepartmentFilterDropdownChange,
        value: selectedDepartmentFilterValue,
        changeFilterDropdownValue: changeDepartmentFilterDropdownValue
    } = useDropdown({});
    const { handleFilterDropdownChange: handleUserFilterDropdownChange, value: selectedUserFilterValue } = useDropdown({
        initialValue: EUserReportType.ALL
    });

    const {
        data: fetchedAssessmentReportData,
        isFetching: isAssessmentReportLoading,
        isError: isReportDataError
    } = useGetAssessmentReportDataQuery();
    const {
        refetch: refetchGetAssessmentReportByDate,
        data: assessmentReportByDateData,
        isFetching: isAssessmentReportByDateLoading,
        isError: isAssessmentReportByDateError
    } = useGetAssessmentReportByDateQuery(selectedWeeksFilterValue);
    const [assessmentMapByDepartment, setAssessmentMapByDepartment] = useState<
        Map<string, IAssessmentReportItemVM[]> | undefined
    >();
    const [departments, setDepartments] = useState<string[] | undefined>();
    const [filteredTableData, setFilteredTableData] = useState<IAssessmentReportItemVM[] | undefined>([]);

    const { refetch: refetchDownloadAssessmentReport, isFetching: isDownloadAssessmentLoading } =
        useDownloadAssessmentReportByDateQuery(selectedWeeksFilterValue);

    const downloadAssessmentReportAsCSV = () => {
        refetchDownloadAssessmentReport();
    };

    useEffect(() => {
        const assessmentMap = new Map<string, IAssessmentReportItemVM[]>();
        if (assessmentReportByDateData) {
            assessmentReportByDateData.forEach((assessmentItem) => {
                const mapItem = assessmentMap.get(assessmentItem.department);
                if (mapItem) {
                    mapItem.push(assessmentItem);
                    assessmentMap.set(assessmentItem.department, mapItem);
                } else {
                    assessmentMap.set(assessmentItem.department, [assessmentItem]);
                }
            });
            setDepartments(Array.from(assessmentMap.keys()));
            setAssessmentMapByDepartment(assessmentMap);
        } else {
            setDepartments(undefined);
            setAssessmentMapByDepartment(undefined);
        }
    }, [assessmentReportByDateData]);

    useEffect(() => {
        if (selectedWeeksFilterValue) refetchGetAssessmentReportByDate();
    }, [selectedWeeksFilterValue]);

    useEffect(() => {
        if (selectedDepartmentFilterValue && selectedUserFilterValue) {
            filterData();
        }
    }, [selectedDepartmentFilterValue, selectedUserFilterValue]);

    const filterData = () => {
        if (selectedDepartmentFilterValue) {
            const departmentData = assessmentMapByDepartment?.get(selectedDepartmentFilterValue);
            setFilteredTableData(departmentData);
        }
    };

    const insightsOrganizationAssessmentContext: IInsightsOrganizationAssessmentContext = {
        departments,
        isAssessmentDataLoading: isAssessmentReportByDateLoading,
        isAssessmentReportLoading,
        assessmentReportData: fetchedAssessmentReportData,
        handleWeeksFilterDropdownChange,
        handleDepartmentFilterDropdownChange,
        handleUserFilterDropdownChange,
        selectedWeeksFilterValue,
        selectedDepartmentFilterValue,
        selectedUserFilterValue,
        changeDepartmentFilterDropdownValue,
        changeWeekFilterDropdownValue,
        data: filteredTableData,
        downloadAssessmentReportAsCSV,
        isDownloadAssessmentLoading,
        isAssesmentDataFetchingError: isReportDataError || isAssessmentReportByDateError
    };

    return (
        <InsightsOrganizationAssessmentContext.Provider value={insightsOrganizationAssessmentContext}>
            {children}
        </InsightsOrganizationAssessmentContext.Provider>
    );
};

export const useInsightsOrganizationAssessmentStateValue: () => IInsightsOrganizationAssessmentContext = () =>
    useContext(InsightsOrganizationAssessmentContext);
