import { IconButton, makeStyles, Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FC, useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from 'react-feather';
import { ControlsBox, DocumentBox, PagerBox, PaginationValueBox, ZoomBox } from './Style';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        border: 'none',
        padding: 0,
        margin: 0,
        textDecoration: 'none',
        background: 'transparent',
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: '1rem',
        cursor: 'pointer',
        textAlign: 'center',
        transition: 'background 250ms ease-in-out, transform 150ms ease',
        '&:focus': {
            outline: 0
        },
        '&:disabled': {
            color: 'rgba(0,0,0,0.16)',
            boxShadow: 'none',
            cursor: 'not-allowed'
        },
        height: 24,
        width: 24
    },
    overflow: {
        overflow: 'auto'
    }
}));

interface IProps {
    src: string;
}

const PdfViewer: FC<IProps> = ({ src }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'));
    const [scale, setScale] = useState<number>(isLargeUp ? 1.25 : 1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [currentNumPages, setNumPages] = useState<number>(1);
    const pdfRef = useRef<any>(null);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);

    const onDocumentComplete = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    const handleBack = () => setPageNumber(pageNumber === 1 ? 1 : pageNumber - 1);

    const handleNext = async () => {
        setPageNumber(pageNumber === currentNumPages ? pageNumber : pageNumber + 1);
    };

    const zoomIn = () => setScale(scale + 0.25);

    const zoomOut = () => setScale(scale - 0.25);

    return (
        <>
            <DocumentBox>
                <Document className={classes.overflow} file={src} onLoadSuccess={onDocumentComplete} ref={pdfRef}>
                    <Page
                        pageNumber={pageNumber}
                        scale={scale}
                        renderMode='canvas'
                        renderTextLayer={true}
                        width={970}
                    />
                </Document>
            </DocumentBox>
            <ControlsBox>
                <PagerBox>
                    <PaginationValueBox
                        style={{ marginRight: '8px !important' }}
                    >{`Page ${pageNumber} of ${currentNumPages}`}</PaginationValueBox>
                    <IconButton
                        style={{}}
                        className={classes.button}
                        aria-label='left arrow'
                        onClick={handleBack}
                        disabled={pageNumber === 1}
                    >
                        <ChevronLeft />
                    </IconButton>
                    <IconButton
                        className={classes.button}
                        aria-label='right arrow'
                        onClick={handleNext}
                        disabled={pageNumber === currentNumPages}
                    >
                        <ChevronRight />
                    </IconButton>
                </PagerBox>
                <ZoomBox>
                    <IconButton className={classes.button} aria-label='zoom out' onClick={zoomOut}>
                        <ZoomOut />
                    </IconButton>
                    <IconButton className={classes.button} aria-label='zoom in' onClick={zoomIn}>
                        <ZoomIn />
                    </IconButton>
                </ZoomBox>
            </ControlsBox>
        </>
    );
};

export default PdfViewer;
