import { FC, useMemo } from 'react';
import { useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigationStateValue } from '../../../contexts/NavigationContext';
import { VERSION } from '../../../utils/constants';
import NavigationLinks from '../navigation-links/NavigationLinks';
import { createUrl } from '../../../utils/createUrl';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import { Links, Logo, Navigation, NavigationLogoImg, StyledX, Version } from './Style';

interface IProps {
    isAlwaysOpen?: boolean;
}

const NavigationSidemenu: FC<IProps> = ({ isAlwaysOpen = false }) => {
    const { showNav, setShowNav } = useNavigationStateValue();
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const isOpen = useMemo(() => {
        return showNav || isAlwaysOpen;
    }, [showNav, isAlwaysOpen]);

    return (
        <>
            {isOpen && (
                <Navigation>
                    <Logo>
                        {!isLgUp && (
                            <StyledX
                                onClick={() => {
                                    setShowNav(!showNav);
                                }}
                            />
                        )}
                        <NavigationLogoImg
                            alt='logo'
                            src={createUrl(`nulia-cdn://nwcontent/images/logos/nulia_logo_small.svg`)?.generatedUrl}
                            onError={fetchImageErrorCallback}
                        />
                    </Logo>
                    <Links>
                        <NavigationLinks />
                    </Links>
                    <Version>{VERSION}</Version>
                </Navigation>
            )}
        </>
    );
};

export default NavigationSidemenu;
