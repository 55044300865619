import { FC, PropsWithChildren, useMemo } from 'react';
import { ExpandedCollapsedBox, InfoBox, PaperHeaderBox, RootPaper, StatusCircle, TitleTypography } from './Style';
import { Loading, SomethingWentWrongCard, ToCollapseIcon, ToExpandIcon, Tooltip, useExpand } from 'nulia-ui';

export enum EStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning'
}

interface IProps {
    title?: string;
    status?: EStatus;
    isLoading?: boolean;
    isError?: boolean;
    refetch?: () => void;
    containerId?: string;
    defaultExpanded?: boolean;
    tooltipTitle?: string;
}

const BasicStatusCard: FC<PropsWithChildren<IProps>> = ({
    children,
    title,
    status,
    isLoading = false,
    isError = false,
    refetch,
    containerId,
    defaultExpanded = false,
    tooltipTitle = ''
}) => {
    const { collapse, expand, isExpanded } = useExpand({
        defaultExpanded: defaultExpanded
    });

    const color = useMemo(() => {
        switch (status) {
            case EStatus.SUCCESS:
                return '#B0CB3E';
            case EStatus.ERROR:
                return '#D63535';
            case EStatus.WARNING:
                return '#FC5';
            default:
                return undefined;
        }
    }, [status]);

    const contentMemo = useMemo(() => {
        if (isLoading) return <Loading />;
        if (isError) return <SomethingWentWrongCard boxStyle={{ boxShadow: 'none' }} actionCallback={refetch} />;
        return isExpanded ? children : <></>;
    }, [isLoading, isError, refetch, children, isExpanded]);

    return (
        <RootPaper elevation={1} id={containerId}>
            <PaperHeaderBox>
                <InfoBox>
                    {status && (
                        <Tooltip title={tooltipTitle}>
                            <StatusCircle color={color} />
                        </Tooltip>
                    )}
                    <TitleTypography variant='h6'>{title}</TitleTypography>
                </InfoBox>
                <ExpandedCollapsedBox tabIndex={0} onClick={isExpanded ? collapse : expand}>
                    {isExpanded ? <ToExpandIcon /> : <ToCollapseIcon />}
                </ExpandedCollapsedBox>
            </PaperHeaderBox>
            {contentMemo}
        </RootPaper>
    );
};

export default BasicStatusCard;
