import { EToastSeverity, useToastContextStateValue } from '../contexts/ToastContext';
import { ERecommendationType } from '../interfaces/enums/ERecommendationType';
import { useCallback, useMemo } from 'react';
import { usePostAssignOutcome, usePostMasterOutcome } from '../services/OutcomeQueryService';
import { usePostAssignSkill, usePostMasterSkill } from '../services/SkillQueryService';
import { usePostUserRecommendationComplete } from '../services/RecommendationService';
import { EAssignmentType } from '../interfaces/enums/EAssignmentType';
import { EMasterAction } from '../interfaces/enums/EMasterAction';
import { useTranslation } from 'react-i18next';
import { IRecommendationVM } from '../interfaces/views/IRecommendationVM';

export interface IUseRecommendationProps {
    recommendationType?: ERecommendationType;
    targetId?: number;
    recommendationId?: number;
    successRecommendationCallback?: (recommendation: IRecommendationVM) => void;
}

export interface IUseRecommendation {
    recommendationActionCallback: (isRecommendation?: boolean) => void;
    isActionLoading: boolean;
    isActionSuccess: boolean;
    isAssignOutcomeLoading: boolean;
    isAssignOutcomeSuccess: boolean;
    isAssignOutcomeError: boolean;
    isAssignSkillLoading: boolean;
    isAssignSkillSuccess: boolean;
    isAssignSkillError: boolean;
    isMasterSkillLoading: boolean;
    isMasterSkillSuccess: boolean;
    isMasterSkillError: boolean;
    isMasterOutcomeLoading: boolean;
    isMasterOutcomeSuccess: boolean;
    isMasterOutcomeError: boolean;
    headerCardTypeTitle: string | null;
}

const useRecommendation: (recommendationProps: IUseRecommendationProps) => IUseRecommendation = ({
    recommendationType,
    recommendationId,
    targetId,
    successRecommendationCallback
}) => {
    const { setToastMessage } = useToastContextStateValue();
    const { t } = useTranslation();
    const {
        mutateAsync: mutateAssignOutcomeAsync,
        isPending: isAssignOutcomeLoading,
        isSuccess: isAssignOutcomeSuccess,
        isError: isAssignOutcomeError
    } = usePostAssignOutcome();
    const {
        mutateAsync: mutateAssignSkillAsync,
        isPending: isAssignSkillLoading,
        isSuccess: isAssignSkillSuccess,
        isError: isAssignSkillError
    } = usePostAssignSkill();
    const {
        mutateAsync: mutatePostMasterSkillAsync,
        isPending: isMasterSkillLoading,
        isSuccess: isMasterSkillSuccess,
        isError: isMasterSkillError
    } = usePostMasterSkill();
    const {
        mutateAsync: mutatePostMasterOutcomeAsync,
        isPending: isMasterOutcomeLoading,
        isSuccess: isMasterOutcomeSuccess,
        isError: isMasterOutcomeError
    } = usePostMasterOutcome();
    const { mutateAsync: mutateCompleteUserRecommendationAsync } = usePostUserRecommendationComplete();

    const isActionLoading = useMemo(() => {
        return isAssignOutcomeLoading || isAssignSkillLoading || isMasterOutcomeLoading || isMasterSkillLoading;
    }, [isAssignOutcomeLoading, isAssignSkillLoading, isMasterOutcomeLoading, isMasterSkillLoading]);

    const isActionSuccess = useMemo(() => {
        return isAssignOutcomeSuccess || isAssignSkillSuccess || isMasterOutcomeSuccess || isMasterSkillSuccess;
    }, [isAssignSkillSuccess, isAssignOutcomeSuccess, isMasterSkillSuccess, isMasterOutcomeSuccess]);

    const actionCallback = useCallback(
        async (isRecommendation?: boolean) => {
            switch (recommendationType) {
                case ERecommendationType.ADD_OUTCOME:
                    if (targetId) {
                        try {
                            await mutateAssignOutcomeAsync({
                                outcomeId: targetId.toString(),
                                assignmentType: EAssignmentType.ASSIGN,
                                isRecommendation
                            });
                            setToastMessage({
                                isOpen: true,
                                severity: EToastSeverity.SUCCESS,
                                message: t('recommendations.successAddOutcome')
                            });
                        } catch (e) {
                            setToastMessage({
                                isOpen: true,
                                severity: EToastSeverity.ERROR,
                                message: t('recommendations.errorAddOutcome')
                            });
                        }
                    }
                    break;
                case ERecommendationType.UPGRADE_OUTCOME:
                    if (targetId) {
                        try {
                            await mutatePostMasterOutcomeAsync({
                                action: EMasterAction.ENABLE_MASTER,
                                outcomeId: targetId,
                                isRecommendation
                            });
                            setToastMessage({
                                isOpen: true,
                                severity: EToastSeverity.SUCCESS,
                                message: t('recommendations.successUpgradeOutcome')
                            });
                        } catch (e) {
                            setToastMessage({
                                isOpen: true,
                                severity: EToastSeverity.ERROR,
                                message: t('recommendations.errorUpgradeOutcome')
                            });
                        }
                    }
                    break;
                case ERecommendationType.ADD_SKILL:
                    if (targetId) {
                        try {
                            await mutateAssignSkillAsync({
                                skillId: targetId.toString(),
                                assignmentType: EAssignmentType.ASSIGN,
                                isRecommendation
                            });
                            setToastMessage({
                                isOpen: true,
                                severity: EToastSeverity.SUCCESS,
                                message: t('recommendations.successAddSkill')
                            });
                        } catch (e) {
                            setToastMessage({
                                isOpen: true,
                                severity: EToastSeverity.ERROR,
                                message: t('recommendations.errorAddSkill')
                            });
                        }
                    }
                    break;
                case ERecommendationType.UPGRADE_SKILL:
                    if (targetId) {
                        try {
                            await mutatePostMasterSkillAsync({
                                skillId: targetId,
                                action: EMasterAction.ENABLE_MASTER,
                                isRecommendation
                            });
                            setToastMessage({
                                isOpen: true,
                                severity: EToastSeverity.SUCCESS,
                                message: t('recommendations.successUpgradeSkill')
                            });
                        } catch (e) {
                            setToastMessage({
                                isOpen: true,
                                severity: EToastSeverity.ERROR,
                                message: t('recommendations.errorUpgradeSkill')
                            });
                        }
                    }
                    break;
                case ERecommendationType.BEHAVIOR:
                    break;
            }
            if (recommendationId !== undefined) {
                try {
                    const responseData: IRecommendationVM = await mutateCompleteUserRecommendationAsync({
                        userRecommendationId: recommendationId,
                        completed: true
                    });
                    if (successRecommendationCallback) successRecommendationCallback(responseData);
                } catch (e) {
                    console.error(e);
                }
            }
        },
        [recommendationType, recommendationId, targetId]
    );

    const headerCardTypeTitleMemo = useMemo(() => {
        switch (recommendationType) {
            case ERecommendationType.UPGRADE_OUTCOME:
                return "Let's advance to the next level";
            case ERecommendationType.UPGRADE_SKILL:
                return "Let's advance to the next level";
            case ERecommendationType.ADD_OUTCOME:
                return 'Time for something new';
            case ERecommendationType.ADD_SKILL:
                return 'Time for something new';
            case ERecommendationType.BEHAVIOR:
                return 'Here is your next step';
            default:
                return null;
        }
    }, [recommendationType]);

    return {
        recommendationActionCallback: actionCallback,
        isActionSuccess,
        isActionLoading,
        isAssignOutcomeLoading,
        isAssignOutcomeSuccess,
        isAssignOutcomeError,
        isAssignSkillLoading,
        isAssignSkillSuccess,
        isAssignSkillError,
        isMasterOutcomeLoading,
        isMasterOutcomeSuccess,
        isMasterOutcomeError,
        isMasterSkillLoading,
        isMasterSkillSuccess,
        isMasterSkillError,
        headerCardTypeTitle: headerCardTypeTitleMemo
    };
};

export default useRecommendation;
