import { Paper } from '@material-ui/core';
import styled from '../../../../../ui/theme/styled';

export const GraphCardWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 16,
    flexWrap: 'wrap',
    padding: '12px 16px 12px 16px',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column'
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: 4
    }
}));

export const GraphWrapper = styled('div')(({ theme }) => ({
    flex: 2,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
        overflowX: 'scroll'
    }
}));

export const GraphDetailsWrapper = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    gap: 16,
    [theme.breakpoints.down('lg')]: {
        padding: '0 20px'
    }
}));

export const LgDownGraphInfoWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
});

export const LgDownScoreKeyValueBox = styled('div')({
    gridColumn: '1'
});

export const LgDownStreakKeyValueBox = styled('div')({
    gridColumn: '2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

export const CardHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 25px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    lineHeight: '22px'
});

export const KeyValueBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 8
});

export const StreakBox = styled('div')(({ theme }) => ({
    color: theme.palette.gray.medium
}));

export const MasterSwitchBox = styled('div')({
    marginLeft: 28,
    flex: 1
});

export const MasterLabelBox = styled('div')({
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    gap: 10
});

export const RootPaper = styled(Paper)({
    minWidth: '100%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)'
});

export const ExpandedCollapsedBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: 20,
    padding: '12px 8px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.background.default
    }
}));
