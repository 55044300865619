import { FC, useRef } from 'react';
import { useFocusEffect, useRovingTabIndex } from 'react-roving-tabindex';
import { SkillItemRootTitle } from './Style';

interface IProps {
    title: string;
    skillId: number;
    skillClickHandler: (id: number) => void;
}

const SkillItemTitle: FC<IProps> = ({ title, skillId, skillClickHandler }) => {
    const ref = useRef<HTMLAnchorElement>(null);
    const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(ref, false);

    useFocusEffect(focused, ref);

    return (
        <SkillItemRootTitle
            ref={ref}
            href='javascript:void(0)'
            tabIndex={tabIndex}
            onKeyDown={handleKeyDown}
            onClick={() => {
                handleClick();
                skillClickHandler(skillId);
            }}
        >
            {title}
        </SkillItemRootTitle>
    );
};

export default SkillItemTitle;
