import { FC, useRef, KeyboardEvent } from 'react';
import { useFocusEffect, useRovingTabIndex } from 'react-roving-tabindex';
import { CategoryImage, RootBox, CategoryTypography } from './Style';
import { createUrl } from '../../../../utils/createUrl';
import loadImageGraduallyHandler from '../../../../utils/loadImageGradually';
import { accessibilityEnterKeyCallback } from '../../../../utils/accessibilityUtils';

interface IProps {
    categoryId: string;
    image: string;
    title: string;
    onClick: (id: string, title: string) => void;
}

const HowToCategory: FC<IProps> = ({ categoryId, image, title, onClick }) => {
    const ref = useRef<HTMLAnchorElement>(null);
    const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(ref, false);

    useFocusEffect(focused, ref);
    const clickHandler = () => {
        onClick(categoryId, title);
    };

    return (
        <RootBox
            ref={ref}
            tabIndex={tabIndex}
            onKeyDown={(e: KeyboardEvent<any>) => {
                handleKeyDown(e);
                accessibilityEnterKeyCallback(e, clickHandler);
            }}
            onClick={() => {
                handleClick();
                clickHandler();
            }}
        >
            <CategoryImage onLoad={loadImageGraduallyHandler} src={createUrl(image)?.generatedUrl} alt={title} />
            <CategoryTypography variant='subtitle1'>{title}</CategoryTypography>
        </RootBox>
    );
};

export default HowToCategory;
