import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const AchievementBox = styled('a', {
    shouldForwardProp: (prop) => prop !== 'isClickAvailable'
})<{ isClickAvailable: boolean }>(({ isClickAvailable, theme }) => ({
    display: 'grid',
    gridTemplateColumns: '85px auto',
    gridTemplateRows: '1fr 60px',
    width: '308px',
    minHeight: '185px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '30px',
    padding: '16px',
    boxShadow: '0px 2.5px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.08)',
    border: `1px solid ${theme.palette.common.white}`,
    '&:hover': {
        cursor: isClickAvailable ? 'pointer' : 'inherit',
        border: '1px solid rgb(245,245,245)',
        boxShadow: isClickAvailable
            ? '0px 1px 4px 0px #00000014'
            : '0px 2.5px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.08)',
        backgroundColor: isClickAvailable && 'rgb(245,245,245)'
    }
}));

export const AchievementImg = styled('img')({
    visibility: 'hidden',
    width: '70px',
    height: '70px'
});

export const AchievementImageBox = styled('div')({
    display: 'grid',
    gap: '5px'
});

export const TitleBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
});

export const TitleInnerBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '100%'
});

export const TitleTypography = styled(Typography)(({ theme }) => ({
    marginTop: '4px',
    marginBottom: '8px',
    color: theme.palette.common.black,
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
}));

export const DescriptionTypography = styled(Typography)({
    textAlign: 'center',
    minHeight: '40px',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    width: '100%'
});

export const EngagementPointsBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '8px',
    gap: '4px'
});

export const EngagementPointsTypography = styled(Typography)({
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '20px',
    letterSpacing: '0.42px',
    color: 'rgba(97, 96, 99, 1)'
});

export const ScoreBox = styled('div')({
    fontSize: '10px',
    minHeight: '11px',
    marginTop: '1px',
    textAlign: 'center',
    width: '70px'
});

export const ValueTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '11px',
    letterSpacing: '1.25px',
    color: theme.palette.primary.main
}));

export const DetailsBox = styled('div')({
    gridArea: '2 / span 2',
    display: 'flex',
    alignItems: 'center'
});
