import { FC, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RovingTabIndexProvider } from 'react-roving-tabindex';
import { useShowcaseStateValue } from '../../contexts/ShowcaseContext';
import {
    AchievementTypeBox,
    ContentBox,
    RootBox,
    TitleBox,
    TitleTypography,
    RootInnerBox,
    ContentItemsBox,
    PaginationBox
} from './Style';
import PrevNextItem from '../../ui/general/prev-next-item/PrevNextItem';
import Achievement from '../../pages/showcase/components/achievement/Achievement';
import Dropdown, { IDropdownMenuItem } from '../../ui/general/dropdown/Dropdown';
import { EAchievementCategory } from '../../interfaces/enums/EAchievementCategory';
import { makeAchievementDetailsRoute } from '../../pages/routes';
import { useCrumbsStateValue } from '../../contexts/CrumbsContext';
import BasicCard from '../../ui/cards/basic-card/BasicCard';
import NoDataCard from '../../ui/cards/no-data-card/NoDataCard';
import { StyledTablePagination } from '../../css/CommonComponents';
import useGridPagination from '../../hooks/useGridPagination';
import Loading from '../../ui/general/loading/Loading';
import { IAchievementOverviewVM } from '../../interfaces/views/IAchievementOverviewVM';

const AchievementsPreview: FC = () => {
    const {
        achievementGroupList,
        visibleAchievementsIndex,
        selectedAchievementTypeFilterValue,
        handleAchievementTypeFilterDropdownChange,
        changeAchievementTypeFilterDropdownValue,
        allAchievementsStateData,
        changeVisibleAchievementsIndexHandler,
        changeVisibleAchievementsIndex,
        isAchievementsDataLoading
    } = useShowcaseStateValue();

    const { visibleFromTo, changeActivePageHandler, activePage, rowsPerPage, changeRowsPerPageHandler } =
        useGridPagination({
            step: 12
        });

    const achievementTypeFilterDropdownItemsRef = useRef<IDropdownMenuItem[]>([
        {
            name: 'All',
            value: 'All'
        },
        {
            name: 'Series Achievements',
            value: EAchievementCategory.SERIES
        },
        {
            name: 'Single Step Achievements',
            value: EAchievementCategory.SINGLE_STEP
        },
        // {
        //     name: 'Multiple Step Achievements',
        //     value: EAchievementType.MULTIPLE_STEP,
        // },
        {
            name: 'Special and Holiday Achievements',
            value: EAchievementCategory.DATE
        }
    ]);
    const navigate = useNavigate();
    const { appendCrumb } = useCrumbsStateValue();
    const { t } = useTranslation();

    useEffect(() => {
        changeAchievementTypeFilterDropdownValue('All');
    }, []);

    useEffect(() => {
        if (visibleAchievementsIndex !== undefined) changeVisibleAchievementsIndex(visibleAchievementsIndex);
    }, [visibleAchievementsIndex]);

    const onAchievementClick = (achievementGroupId: number) => {
        if (achievementGroupId) {
            const route = makeAchievementDetailsRoute(achievementGroupId.toString());
            appendCrumb({
                name: 'Achievements',
                pathname: route
            });
            navigate(route);
        }
    };

    const contentMemo = useMemo(() => {
        if (allAchievementsStateData === null) {
            return (
                <ContentItemsBox isEmpty>
                    <BasicCard title='Achievement group does not exist.' />
                </ContentItemsBox>
            );
        }
        if (!allAchievementsStateData || !achievementGroupList || isAchievementsDataLoading)
            return (
                <ContentItemsBox isEmpty>
                    <Loading />
                </ContentItemsBox>
            );
        if (
            achievementGroupList &&
            (!allAchievementsStateData?.achievements || allAchievementsStateData.achievements.length === 0)
        )
            return (
                <ContentItemsBox isEmpty>
                    <NoDataCard
                        textStyle={{ fontSize: 14 }}
                        message={t('showcase.home.noAchievements')}
                        noBorderShadow
                    />
                </ContentItemsBox>
            );
        let achievements: IAchievementOverviewVM[] = [];
        if (selectedAchievementTypeFilterValue === 'All') achievements = allAchievementsStateData.achievements;
        else
            achievements = allAchievementsStateData.achievements.filter((achievement) => {
                return achievement.category === selectedAchievementTypeFilterValue;
            });
        if (achievements.length === 0)
            return (
                <ContentItemsBox isEmpty>
                    <NoDataCard
                        textStyle={{ fontSize: 14 }}
                        message={t('showcase.home.noAchievements')}
                        noBorderShadow
                    />
                </ContentItemsBox>
            );
        return (
            <RovingTabIndexProvider options={{ direction: 'horizontal' }}>
                <ContentItemsBox>
                    {achievements.slice(visibleFromTo.from, visibleFromTo.to).map((achievement) => {
                        return (
                            <Achievement
                                key={achievement.title}
                                description={achievement.description}
                                title={achievement.title}
                                engagementPointsEarned={achievement.engagementPoints}
                                imageUrl={achievement.image}
                                valueTotal={achievement.target}
                                valueAchieved={achievement.currentProgress}
                                groupId={achievement.groupId}
                                clickHandler={onAchievementClick}
                                state={achievement.state}
                                category={achievement.category}
                            />
                        );
                    })}
                </ContentItemsBox>
            </RovingTabIndexProvider>
        );
    }, [
        allAchievementsStateData,
        visibleAchievementsIndex,
        achievementGroupList,
        selectedAchievementTypeFilterValue,
        isAchievementsDataLoading
    ]);

    const titleMemo = useMemo(() => {
        if (allAchievementsStateData === null) return '';
        if (!achievementGroupList)
            return (
                <>
                    <Skeleton width='60%' />
                    <Skeleton width='60%' />
                    <Skeleton width='40%' />
                </>
            );
        if (allAchievementsStateData)
            return (
                <>
                    <TitleTypography variant='subtitle1'>
                        {allAchievementsStateData?.title ? (
                            `Achievements ${allAchievementsStateData?.title}`
                        ) : (
                            <Skeleton variant='rectangular' width='140px' height='36px' />
                        )}
                    </TitleTypography>
                    <AchievementTypeBox>
                        <Dropdown
                            items={achievementTypeFilterDropdownItemsRef.current}
                            value={selectedAchievementTypeFilterValue}
                            handleChange={handleAchievementTypeFilterDropdownChange}
                            defaultValue={achievementTypeFilterDropdownItemsRef.current[0].value}
                        />
                    </AchievementTypeBox>
                    <PrevNextItem
                        isNextDisabled={
                            !achievementGroupList || visibleAchievementsIndex + 1 >= achievementGroupList.length
                        }
                        isPreviousDisabled={visibleAchievementsIndex <= 0}
                        changeHandler={changeVisibleAchievementsIndexHandler}
                        infoJsx={
                            achievementGroupList && achievementGroupList.length > 0
                                ? `${visibleAchievementsIndex + 1} / ${achievementGroupList.length}`
                                : ''
                        }
                    />
                </>
            );
        return <Skeleton variant='rectangular' width='140px' height='36px' />;
    }, [allAchievementsStateData, achievementGroupList, selectedAchievementTypeFilterValue]);

    return (
        <RootBox>
            <RootInnerBox>
                <TitleBox>{titleMemo}</TitleBox>
                <ContentBox>
                    {contentMemo}
                    {allAchievementsStateData && allAchievementsStateData.achievements && (
                        <PaginationBox>
                            <StyledTablePagination
                                rowsPerPageOptions={[16]}
                                count={
                                    selectedAchievementTypeFilterValue === 'All'
                                        ? allAchievementsStateData?.achievements.length
                                        : allAchievementsStateData?.achievements.filter((achievement) => {
                                              return achievement.category === selectedAchievementTypeFilterValue;
                                          })?.length
                                }
                                rowsPerPage={rowsPerPage}
                                page={activePage}
                                onPageChange={changeActivePageHandler}
                                onRowsPerPageChange={changeRowsPerPageHandler}
                                style={{ borderBottom: 'none' }}
                            />
                        </PaginationBox>
                    )}
                </ContentBox>
            </RootInnerBox>
        </RootBox>
    );
};

export default AchievementsPreview;
