import { FC, ReactElement } from 'react';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { EAssignerRole } from '../../../interfaces/enums/EAssignerRole';
import { ECompetenceState } from '../../../interfaces/enums/ECompetenceState';
import AssignedControl from '../assigned-control/AssignedControl';
import { GraphBox, RootBox, SkillInfoBox, TitleDescriptionWrapperBox, AssignedBox, TitleBox } from './Style';

interface IProps {
    title?: string;
    assigned: {
        isAssigned?: boolean;
        assignerName?: string;
        assignedDate?: Date | string;
        assignedTitle?: string;
        notAssignedTitle?: string;
        unassignTooltipText?: string;
        assignTooltipText?: string;
        assignerRole?: EAssignerRole;
        streak?: number;
        state?: ECompetenceState;
        assignerId?: string;
    };
    leftSectionJSX?: ReactElement;
    toggleAssign: (isAssigned: boolean) => void;
    className?: string;
    isError?: boolean;
}

const PageTitle: FC<IProps> = ({
    title,
    assigned: {
        isAssigned,
        assignerName,
        assignedDate,
        assignedTitle,
        notAssignedTitle,
        unassignTooltipText,
        assignTooltipText,
        assignerRole,
        state,
        streak,
        assignerId
    },
    leftSectionJSX,
    toggleAssign,
    isError = false
}) => {
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <RootBox>
            <SkillInfoBox>
                {!isError && (
                    <>
                        {leftSectionJSX && <GraphBox className='skill-doughnut-wrapper'>{leftSectionJSX}</GraphBox>}
                        <TitleDescriptionWrapperBox>
                            <TitleBox variant={isLgDown ? 'h6' : 'h4'}>{title ?? <Skeleton width='50%' />}</TitleBox>
                        </TitleDescriptionWrapperBox>
                        <AssignedBox>
                            {isAssigned === undefined ? (
                                <Skeleton variant='rectangular' width='180px' height='80px'></Skeleton>
                            ) : (
                                <>
                                    <AssignedControl
                                        assignment={{
                                            assignerId: assignerId,
                                            assignerName: assignerName,
                                            assignerRole: assignerRole,
                                            date: assignedDate,
                                            inProgressForWeeks:
                                                state === ECompetenceState.IN_PROGRESS && streak !== undefined
                                                    ? streak
                                                    : undefined,
                                            maintainedForWeeks:
                                                state === ECompetenceState.MAINTAIN && streak !== undefined
                                                    ? streak
                                                    : undefined
                                        }}
                                        isAssigned={isAssigned}
                                        toggleAssign={toggleAssign}
                                        translations={{
                                            assignTooltipText: assignTooltipText,
                                            unassignTooltipText: unassignTooltipText,
                                            assignedTitle: assignedTitle,
                                            notAssignedTitle: notAssignedTitle
                                        }}
                                        wrap={false}
                                    />
                                </>
                            )}
                        </AssignedBox>
                    </>
                )}
            </SkillInfoBox>
        </RootBox>
    );
};

export default PageTitle;
