import { FC, useEffect } from 'react';
import { Outlet, matchPath, useNavigate } from 'react-router';
import { ITab, useTabsStateValue } from '../../contexts/TabsContext';
import { useCrumbsStateValue } from '../../contexts/CrumbsContext';
import usePageHeaderConfig from '../../hooks/usePageHeaderConfig';
import {
    makeInsightsOrganizationalUsersBadges,
    makeInsightsOrganizationalUsersEngagement,
    makeInsightsOrganizationalUsersOutcome,
    makeInsightsOrganizationalUsersSkill,
    routes
} from '../../pages/routes';
import { useUserAuthStateValue } from '../../contexts/UserAuthContext';
import { InsightsRootBox, TabLinkItemBox, TabBox, OutletWrapper, TabInnerGridBox } from './Style';
import { isUserAllowedToAccess } from '../../pages/routeRules';
import { ERoute } from '../../interfaces/enums/ERoute';
import { useInsightsStateValue } from '../../contexts/InsightsContext';
import { EInsightsMode } from '../../interfaces/enums/EInsightsMode';
import { appendInsightRoutes } from '../../utils/appendInsightsRoute';
import { ICrumb } from '../../interfaces/ICrumb';
import { useNavigationStateValue } from '../../contexts/NavigationContext';

const Insights: FC = () => {
    const { setTabs, activeTab } = useTabsStateValue();
    const { config: pageHeaderConfig } = usePageHeaderConfig();
    const navigate = useNavigate();
    const { currentUserData } = useUserAuthStateValue();
    const { mode, userName } = useInsightsStateValue();
    const { userId } = useInsightsStateValue();
    const { crumbs, setCrumbs } = useCrumbsStateValue();
    const { showNav } = useNavigationStateValue();

    useEffect(() => {
        if (!currentUserData) {
            setTabs([]);
            return;
        }
        if (mode === EInsightsMode.STANDARD || mode === EInsightsMode.ORG_PERSONAL_OUTCOME_DETAILS) {
            const personalInnerTabs = [
                {
                    id: 3,
                    title: 'Skills',
                    fullTitle: 'Personal Skills Insights',
                    path: routes.INSIGHTS_PERSONAL_SKILLS,
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_PERSONAL_SKILLS,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 4,
                    title: 'Outcomes',
                    fullTitle: 'Personal Outcomes Insights',
                    path: routes.INSIGHTS_PERSONAL_OUTCOMES,
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_PERSONAL_OUTCOMES,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 5,
                    title: 'Engagement',
                    fullTitle: 'Personal Engagement Insights',
                    path: routes.INSIGHTS_PERSONAL_ENGAGEMENT,
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_PERSONAL_ENGAGEMENT,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 6,
                    title: 'Badges',
                    fullTitle: 'Personal Badge Insights',
                    path: routes.INSIGHTS_PERSONAL_BADGES,
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_PERSONAL_BADGES,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                }
            ];
            const organizationalInnerTabs = [
                {
                    id: 7,
                    title: 'Users',
                    fullTitle: 'Organization Users Insights',
                    path: routes.INSIGHTS_ORGANIZATIONAL_USERS,
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_ORGANIZATION_USERS,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 8,
                    title: 'Outcomes',
                    fullTitle: 'Organization Outcomes Insights',
                    path: [
                        routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES,
                        routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS,
                        routes.INSIGHTS_ORGANIZATIONAL_SKILL_DETAILS
                    ],
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_ORGANIZATION_OUTCOMES,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 9,
                    title: 'Engagement',
                    fullTitle: 'Organization Engagement Insights',
                    path: routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT,
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_ORGANIZATION_ENGAGEMENT,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 10,
                    title: 'Badges',
                    fullTitle: 'Organization Badge Insights',
                    path: [routes.INSIGHTS_ORGANIZATIONAL_BADGES, routes.INSIGHTS_ORGANIZATIONAL_SINGLE_BADGE],
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_ORGANIZATION_BADGES,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 11,
                    title: 'Assessment',
                    fullTitle: 'Organization Assessment Insights',
                    path: [
                        routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT,
                        routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS
                    ],
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT,
                        currentUserData?.roles,
                        currentUserData.permissions
                    ),
                    children: [
                        {
                            id: 12,
                            title: 'Assessment Details',
                            fullTitle: 'Organization Assessment Details Insights',
                            path: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS,
                            isUserAllowedToAccess: isUserAllowedToAccess(
                                ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT_DETAILS,
                                currentUserData?.roles
                            )
                        }
                    ]
                }
            ];

            const tabs: ITab[] = [
                {
                    id: 1,
                    title: 'My Own',
                    fullTitle: 'My Own Insights',
                    path: routes.INSIGHTS_PERSONAL,
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_PERSONAL,
                        currentUserData?.roles,
                        currentUserData.permissions
                    ),
                    children: personalInnerTabs
                },
                {
                    id: 2,
                    title: 'Organization',
                    fullTitle: 'Organization Insights',
                    path: routes.INSIGHTS_ORGANIZATIONAL,
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_ORGANIZATION,
                        currentUserData?.roles,
                        currentUserData.permissions
                    ),
                    children: organizationalInnerTabs
                }
            ];
            setTabs(tabs.filter((tab) => tab.isUserAllowedToAccess));
        } else {
            const orgUserInnerTabs = [
                {
                    id: 3,
                    title: 'Skills',
                    fullTitle: 'Organization User Skills Insights',
                    path: makeInsightsOrganizationalUsersSkill(userId),
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_ORGANIZATION_USER_SKILLS,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 4,
                    title: 'Outcomes',
                    fullTitle: 'Organization User Outcomes Insights',
                    path: makeInsightsOrganizationalUsersOutcome(userId),
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_ORGANIZATION_USER_OUTCOMES,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 5,
                    title: 'Engagement',
                    fullTitle: 'Organization User Engagement Insights',
                    path: makeInsightsOrganizationalUsersEngagement(userId),
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_PERSONAL_ENGAGEMENT,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                },
                {
                    id: 6,
                    title: 'Badges',
                    fullTitle: 'Organization User Badge Insights',
                    path: makeInsightsOrganizationalUsersBadges(userId),
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_PERSONAL_BADGES,
                        currentUserData?.roles,
                        currentUserData.permissions
                    )
                }
            ];

            const tabs: ITab[] = [
                {
                    id: 1,
                    title: userName,
                    fullTitle: 'Organization User Insights',
                    path: routes.INSIGHTS_PERSONAL,
                    alwaysVisible: true,
                    isUserAllowedToAccess: isUserAllowedToAccess(
                        ERoute.INSIGHTS_PERSONAL,
                        currentUserData?.roles,
                        currentUserData.permissions
                    ),
                    children: orgUserInnerTabs
                }
            ];
            setTabs(tabs.filter((tab) => tab.isUserAllowedToAccess));
        }
        return () => {
            setTabs([]);
        };
    }, [currentUserData, mode, userName, userId]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <InsightsRootBox>
            <TabBox isBackMode={crumbs && crumbs.length > 2} isNavOpened={showNav}>
                <TabInnerGridBox>
                    {activeTab &&
                        activeTab.children &&
                        activeTab.children.length > 1 &&
                        activeTab.children
                            .filter((tab) => tab.isUserAllowedToAccess)
                            .map((tab) => {
                                let isActive = false;
                                if (typeof tab.path === 'string')
                                    isActive = matchPath(tab.path as string, location.pathname) !== null;
                                else {
                                    isActive =
                                        tab.path.find((path) => {
                                            return matchPath(path, location.pathname) !== null;
                                        }) !== undefined;
                                }
                                return (
                                    <TabLinkItemBox
                                        key={tab.id}
                                        onClick={() => {
                                            const pathname = typeof tab.path === 'string' ? tab.path : tab.path[0];
                                            if (pathname !== location.pathname) {
                                                const newCrumbs: ICrumb[] = appendInsightRoutes(
                                                    crumbs,
                                                    pathname,
                                                    pageHeaderConfig.pageName
                                                );
                                                setCrumbs(newCrumbs);
                                                navigate(pathname);
                                            }
                                        }}
                                        isActive={isActive}
                                    >
                                        {tab.title}
                                    </TabLinkItemBox>
                                );
                            })}
                </TabInnerGridBox>
            </TabBox>
            <OutletWrapper>
                <Outlet />
            </OutletWrapper>
        </InsightsRootBox>
    );
};

export default Insights;
