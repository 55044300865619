import { styled } from '@mui/system';
import { Button } from '@mui/material';

export const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isdisabled'
})<{ isdisabled: boolean }>(({ theme, isdisabled }) => ({
    width: 95,
    height: 36,
    minHeight: 36,
    maxHeight: 36,
    borderRadius: 4,
    fontFamily: 'Ubuntu',
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: isdisabled ? 0.15 : 1.25,
    color: isdisabled ? 'rgba(0, 0, 0, 0.2)' : theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    boxShadow: isdisabled ? 'none' : '0 1.5px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.16)',
    elevation: isdisabled ? 0 : 1,
    cursor: isdisabled ? 'not-allowed' : 'pointer',
    border: isdisabled ? '1px solid rgba(0, 0, 0, 0.16)' : 0,
    '&:disabled': {
        color: 'rgba(0, 0, 0, 0.2)',
        backgroundColor: theme.palette.common.white,
        border: '1px solid rgba(0, 0, 0, 0.16)',
        boxShadow: 'none',
        // cursor: 'not-allowed',
        elevation: 0
    },
    '&:focus': {
        outline: 0,
        boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
        background: 'linear-gradient(0deg, rgba(101, 78, 163, 0.08), rgba(101, 78, 163, 0.08)), #FFFFFF'
    },
    '&:active': {
        background: 'linear-gradient(0deg, rgba(101, 78, 163, 0.32), rgba(101, 78, 163, 0.32)), #FFFFFF',
        boxShadow: 'none'
    },
    '&:hover': {
        elevation: 3,
        background: theme.palette.common.white,
        boxShadow: '0 6.5px 16px 1px rgba(0,0,0,0.12), 0 4px 8px 0 rgba(0,0,0,0.08)'
    }
}));
