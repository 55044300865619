export enum EApiQueryKey {
    // Accounts query service
    ACCOUNTS_FETCH_ALL = 'accounts.getAll',
    ACCOUNTS_DETAILS_FETCH = 'accounts.details.get',
    ACCOUNTS_FETCH_RESELLERS = 'accounts.getResellers',
    ACCOUNTS_FETCH_CUSTOMERS = 'accounts.getCustomers',
    RESOURCES_FETCH_ALL = 'resources.getAll',

    // Partner query service
    PARTNER_FETCH_LICENSE_SUMMARY = 'partner.getLicenseSummary'
}
