import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { FC, ChangeEvent, CSSProperties } from 'react';
import { Colors } from '../Colors';
import { InputBase, Select as MuiSelect } from '@material-ui/core';
import SelectIcon from '../icons/SelectIcon';

const useStyles = makeStyles({
    selectPaper: {
        backgroundColor: Colors.white,
        boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)'
    },
    select: {
        '&:focus': {
            backgroundColor: 'rgba(101,78,163,0.08) !important'
        }
    },
    error: {
        border: '1px solid red !important'
    },
    icon: {
        top: 'calc(50% - 8px)',
        right: 4,
        height: 16,
        width: 16
    }
});

const DarkInput = withStyles({
    root: {
        height: 36,
        width: 166
    },
    input: {
        backgroundColor: Colors.background,
        border: 0,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        paddingLeft: 16,
        color: Colors.textSecondary
    }
})(InputBase);

const LightInput = withStyles({
    root: {
        height: 36,
        width: 166
    },
    input: {
        backgroundColor: Colors.white,
        border: 0,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        paddingLeft: 16,
        color: Colors.textSecondary
    }
})(InputBase);

interface Props {
    value: string | number;
    onChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
    light?: boolean;
    style?: CSSProperties;
    error?: boolean;
    disabled?: boolean;
    displayEmpty?: boolean;
    renderValue?: (value: unknown) => string;
    inputProps?: object;
    children?: React.ReactNode;
}

const Select: FC<Props> = ({ children, value, onChange, light, style, error, disabled, displayEmpty, renderValue }) => {
    const classes = useStyles();
    return (
        <MuiSelect
            value={value}
            onChange={onChange}
            input={light ? <LightInput /> : <DarkInput />}
            MenuProps={{
                classes: {
                    paper: classes.selectPaper
                }
            }}
            classes={{
                root: error ? classes.error : undefined,
                select: classes.select,
                icon: classes.icon
            }}
            style={style}
            disabled={disabled}
            displayEmpty={displayEmpty}
            renderValue={renderValue}
            IconComponent={(props) => <SelectIcon {...props} />}
        >
            {children}
        </MuiSelect>
    );
};

export default Select;
