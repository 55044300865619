import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { ContentBox, RootBox, TitleBox, TitleTypography, RankWrapper } from './Style';
import NoDataCard from '../../../../ui/cards/no-data-card/NoDataCard';
import PrevNextItem from '../../../../ui/general/prev-next-item/PrevNextItem';
import usePrevNextVisible from '../../../../hooks/usePrevNextVisible';
import Rank from '../rank/Rank';
import { ERankType } from '../../../../interfaces/enums/ERankType';
import { IUserRankingsVM } from '../../../../interfaces/views/IUserRankingsVM';
import { EUserRanking } from '../../../../interfaces/enums/EUserRanking';
import Loading from '../../../../ui/general/loading/Loading';
import SomethingWentWrongCard from '../../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import { IUserRankingVM } from '../../../../interfaces/views/IUserRankingVM';

interface IProps {
    rankingsData?: IUserRankingsVM[];
    isLoading: boolean;
    isError: boolean;
    errorCallback: () => void;
}

const RankingsCard: FC<IProps> = ({ isLoading = false, rankingsData, isError, errorCallback }) => {
    const { t } = useTranslation();
    const { changeHandler, visibleIndex } = usePrevNextVisible({ step: 1 });

    const contentMemo = useMemo(() => {
        if (isError) {
            return <SomethingWentWrongCard boxStyle={{ boxShadow: 'none' }} actionCallback={errorCallback} />;
        }
        if (!isLoading && rankingsData?.length === 0) {
            return <NoDataCard textStyle={{ fontSize: 14 }} message={t('showcase.home.noRanking')} noBorderShadow />;
        }
        if (isLoading) return <Loading />;
        if (rankingsData) {
            const rank: IUserRankingVM | undefined = rankingsData[visibleIndex].ranking;
            if (rank)
                return (
                    <RankWrapper>
                        <Rank
                            rank={rank.engagementPointRank}
                            medal={rank.engagementPointMedal}
                            total={rank.engagementPointTotalUsers}
                            type={ERankType.ENGAGEMENT_POINTS}
                            isPercentageMode={rankingsData[visibleIndex].type === EUserRanking.GLOBAL}
                        />
                        <Rank
                            rank={rank.badgesClaimedRank}
                            medal={rank.badgesClaimedMedal}
                            total={rank.badgesClaimedTotalUsers}
                            type={ERankType.BADGES_CLAIMED}
                            isPercentageMode={rankingsData[visibleIndex].type === EUserRanking.GLOBAL}
                        />
                        <Rank
                            rank={rank.skillsMaintainedRank}
                            medal={rank.skillsMaintainedMedal}
                            total={rank.skillsMaintainedTotalUsers}
                            type={ERankType.SKILLS}
                            isPercentageMode={rankingsData[visibleIndex].type === EUserRanking.GLOBAL}
                        />
                        <Rank
                            rank={rank.achievementsEarnedRank}
                            medal={rank.achievementsEarnedMedal}
                            total={rank.achievementsEarnedTotalUsers}
                            type={ERankType.ACHIEVEMENTS}
                            isPercentageMode={rankingsData[visibleIndex].type === EUserRanking.GLOBAL}
                        />
                        <Rank
                            rank={rank.badgesSharedRank}
                            medal={rank.badgesSharedMedal}
                            total={rank.badgesSharedTotalUsers}
                            type={ERankType.BADGES_SHARED}
                            isPercentageMode={rankingsData[visibleIndex].type === EUserRanking.GLOBAL}
                        />
                    </RankWrapper>
                );
        }
        return <></>;
    }, [isLoading, rankingsData, visibleIndex, isError, errorCallback]);

    const titleMemo = useMemo(() => {
        if (isError) {
            return <TitleTypography variant='subtitle1'>{t('showcase.home.rankings')}</TitleTypography>;
        }
        if (isLoading) {
            return (
                <>
                    <Skeleton width='100px' />
                    <Skeleton width='60px' />
                </>
            );
        }
        if (!rankingsData || rankingsData.length === 0) return <></>;
        return (
            <>
                <TitleTypography variant='subtitle1'>{rankingsData[visibleIndex].title}</TitleTypography>
                <PrevNextItem
                    isNextDisabled={!rankingsData || visibleIndex + 1 >= rankingsData.length}
                    isPreviousDisabled={visibleIndex < 1}
                    changeHandler={changeHandler}
                    infoJsx={
                        rankingsData && rankingsData.length > 0 ? `${visibleIndex + 1} / ${rankingsData?.length}` : ''
                    }
                />
            </>
        );
    }, [rankingsData, isLoading, visibleIndex, isError]);

    return (
        <RootBox>
            <TitleBox id='rankings-card-header'>{titleMemo}</TitleBox>
            <ContentBox>{contentMemo}</ContentBox>
        </RootBox>
    );
};

export default RankingsCard;
