export enum ResellerType {
    Unknown = 'Unknown',
    Default = 'Default',
    Starter = 'Starter'
}

export enum AccessLevel {
    User = 'User',
    PlatformAdmin = 'PlatformAdmin'
}

export interface User {
    resellerId: string;
    resellerName: string;
    userName: string;
    resellerType: ResellerType;
    accessLevel: AccessLevel;
    currencySymbol: string;
    currencyCode: string;
    oid: string;
    sid: string;
}

export enum AccountType {
    Unknown = 'Unknown',
    Reseller = 'Reseller',
    Customer = 'Customer'
}

export interface AccountBase {
    type: AccountType;
    id: string;
    name: string;
    description: string;
    o365Licenses: number;
    completeLicensesUsed: number;
    completeLicensesAvailable: number;
    starterLicensesUsed?: number;
}

export interface Reseller extends AccountBase {
    type: AccountType.Reseller;
    parentId?: string;
    resellerType: ResellerType;
}

export enum AccountStatus {
    New = 'New',
    Initiated = 'Initiated',
    Good = 'Good',
    NeedsAttention = 'NeedsAttention',
    WithinTwoWeeks = 'WithinTwoWeeks'
}

export enum LicenseClass {
    Unknown = 'Unknown',
    Complete = 'Complete',
    Starter = 'Starter',
    StarterCompleteSync = 'StarterCompleteSync',
    CompleteSync = 'CompleteSync'
}

export interface Customer extends AccountBase {
    type: AccountType.Customer;
    resellerId: string;
    msName: string;
    status: AccountStatus;
    connected?: string;
    appId: string;
    licenseClass: LicenseClass;
    starterOutcomeId1?: number;
    starterOutcomeId2?: number;
}

export type Account = Reseller | Customer;

export enum ActionStatusType {
    NotCompleted = 'NotCompleted',
    Completed = 'Completed'
}

export interface ActionStatus {
    status: ActionStatusType;
    date?: string;
}

export interface AccountSetupProgress {
    selectedStarterOutcome: ActionStatus;
    addedCompleteLicenses: ActionStatus;
    establishedConnection: ActionStatus;
    addedNuliaAdmins: ActionStatus;
    globalAdminEmailSent: ActionStatus;
    nuliaAdminEmailSent: ActionStatus;
    nuliaAdminLoggedIn: ActionStatus;
    firstLicenseAssigned: ActionStatus;
    firstOutcomeAssigned: ActionStatus;
    twoWeekWaitCompleted: ActionStatus;
    firstUserLogin: ActionStatus;
    [key: string]: ActionStatus;
}

export enum LicenseType {
    Unknown = 'Unknown',
    Complete = 'Complete',
    Starter = 'Starter'
}

export type OrderByAccounts = 'status' | 'name' | 'o365Licenses' | 'complete' | 'starter';

export enum AdministratorState {
    Pending = 0,
    ErrorUnknown = 1,
    ErrorToken = 2,
    ErrorPermission = 3,
    ErrorUserNotFound = 4,
    Adding = 5
}

export interface Administrator {
    customerId: string;
    name: string;
    email: string;
    state?: AdministratorState;
    errorMessage?: string;
}

export enum EndpointCode {
    NoResponse,
    GetUserLogin,
    GetAccounts,
    GetNuliaAdmins,
    AddNuliaAdmin,
    RemoveNuliaAdmin,
    GetActivities,
    GetCustomerNotes,
    UpdateCustomerNotes,
    AddNewCustomer,
    AddNewReseller,
    AssociateExistingAccount,
    GetAllAccounts,
    AddLicenses,
    RemoveLicenses,
    SendContactEmail,
    SendConnectEmail,
    GetInvoices,
    GetResources,
    GetStarterOutcomes,
    SetStarterOutcome,
    GetCustomerStatus,
    GetOpportunities,
    NuliaGetAllAccounts,
    AddRootReseller,
    GetAllSkus,
    AddResellerUser,
    SetCompleteLicenseSync,
    GetProjectedCharges,
    GetAccountInfo,
    GetCsvInvoice,
    AddPartnerUser = 31,
    UpdateCustomerName = 32,
    UpdateResellerName = 33,
    ReportBug = 34
}

export interface Resource {
    title: string;
    description: string;
    url: string;
    type: 'pdf' | 'mp4';
    starter: boolean;
}

export interface Activity {
    message: string;
    timestamp: string;
}

export interface Notes {
    customerId: string;
    notes: string;
}

export interface ResellerSimple {
    id: string;
    name: string;
    parentId?: string;
}

export type Order = 'desc' | 'asc' | undefined;

export interface Tab {
    name: string;
    selected: boolean;
    onClick: () => void;
}

export interface WarningData {
    sid: string;
    responseCode: number;
    endpointCode: number;
    statusCode: number;
    warningMessage?: string;
    warningTitle?: string;
}

export interface Outcome {
    id: number;
    name: string;
    keywords: string;
    description: string;
    image: string;
    overviewContentId?: number;
    created: string;
    updated: string;
}

export interface Opportunities {
    requestUpgradeCount: number;
    completedStarterCount: number;
}

export interface StarterOutcome {
    id: number;
    name: string;
    description: string;
    image: string;
    included: boolean;
}

export enum AnnualUsageState {
    PastMonth,
    CurrentMonth,
    RemainingMonth,
    RunOutMonth,
    NoCreditsMonth
}

export enum RemainingUsageState {
    PastMonth,
    CurrentMonth,
    FutureMonth
}

export interface PartnerLicense {
    sku: string;
    price: number;
    count: number;
}

export interface Skus {
    default: Sku[];
    starter: Sku[];
}

export interface Sku {
    code: string;
    name: string;
    price: number;
    count: number;
    priceError: boolean;
    countError: boolean;
}

export interface LicenseUsage {
    quantity: number; // high water mark
    costPerUnit: string;
    preBuysApplied: number;
    total: string;
    displayName: string;
}

export interface PreBuyUsage {
    total: number;
    used: number;
    displayName: string;
}

export interface ProjectedCharges {
    directCompleteInternal: LicenseUsage;
    directCompleteMonthToMonth: LicenseUsage;
    directCompleteNotForResale: LicenseUsage;
    directCompleteTerm: LicenseUsage;
    directStarterMonthToMonth: LicenseUsage;
    directCompleteLegacy: LicenseUsage;

    indirectCompleteInternal: LicenseUsage;
    indirectCompleteMonthToMonth: LicenseUsage;
    indirectCompleteNotForResale: LicenseUsage;
    indirectCompleteTerm: LicenseUsage;
    indirectStarterMonthToMonth: LicenseUsage;
    indirectCompleteLegacy: LicenseUsage;

    totalQuantity: number;
    totalPreBuysApplied: number;
    totalBill: string;

    prebuyDirectCompleteMonthToMonth: PreBuyUsage;
    prebuyIndirectCompleteMonthToMonth: PreBuyUsage;

    prebuyDirectStarterMonthToMonth: PreBuyUsage;
    prebuyIndirectStarterMonthToMonth: PreBuyUsage;
}

export interface AccountLicenseUsageDTO {
    sku: string;
    quantity: number; // high water
    current: number;
    used: number;
    price: string;
    prebuysApplied: number;
    total: string;
}

export interface AccountInfo {
    directCompleteInternal: AccountLicenseUsageDTO;
    directCompleteMonthToMonth: AccountLicenseUsageDTO;
    directCompleteNotForResale: AccountLicenseUsageDTO;
    directCompletePrebuy: AccountLicenseUsageDTO;
    directCompleteTerm: AccountLicenseUsageDTO;
    directStarterMonthToMonth: AccountLicenseUsageDTO;
    directStarterPrebuy: AccountLicenseUsageDTO;
    directCompleteLegacy: AccountLicenseUsageDTO;

    indirectCompleteInternal: AccountLicenseUsageDTO;
    indirectCompleteMonthToMonth: AccountLicenseUsageDTO;
    indirectCompleteNotForResale: AccountLicenseUsageDTO;
    indirectCompletePrebuy: AccountLicenseUsageDTO;
    indirectCompleteTerm: AccountLicenseUsageDTO;
    indirectStarterMonthToMonth: AccountLicenseUsageDTO;
    indirectStarterPrebuy: AccountLicenseUsageDTO;
    indirectCompleteLegacy: AccountLicenseUsageDTO;

    o365Licenses: number;
    completeLicensesUsed: number;
    completeLicensesAvailable: number;
    starterLicensesUsed: number;
    numIndirectCustomers: number;
    numDirectCustomers: number;
    numResellers: number;
}

export interface Invoice {
    date: string;
    invoiceNumber: string;
    amount: string;
}

export interface PartnerLicense {
    sku: string;
    price: number;
    count: number;
}

export interface Skus {
    default: Sku[];
    starter: Sku[];
}

export interface Sku {
    code: string;
    name: string;
    price: number;
    count: number;
    priceError: boolean;
    countError: boolean;
}

export enum CsvInvoiceType {
    Unknown = 'Unknown',
    Summary = 'Summary',
    DirectAccounts = 'DirectAccounts',
    CustomerList = 'CustomerList'
}

export interface SnackbarData {
    message: string;
    onClick?: () => void;
    buttonLabel?: string;
    autoHide?: boolean;
}

export interface EmailSettings {
    customerId: string;
    disableOnboarding: boolean;
    disableWeekly: boolean;
}
