import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const ContentRootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '24px'
}));

export const HeaderTitleTypography = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Ubuntu',
    lineHeight: 1.2,
    marginBottom: '16px',
    letterSpacing: '0.25px'
});

export const DescriptionTypography = styled(Typography)({
    width: '100%',
    marginBottom: '21px'
});

export const FormBox = styled('div')({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 100px'
});

export const FormColumnNameTypography = styled(Typography)({});

export const FormItemRow = styled('div')({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 100px',
    '.MuiFormControl-root': {
        width: '80%'
    }
});

export const AdministratorStatusTypography = styled(Typography)({
    display: 'flex',
    alignItems: 'flex-end'
});

export const StyledForm = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gridColumn: '1/-1',
    marginBottom: '16px'
});
