import React, { FC, useEffect } from 'react';
import { IPublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { useAccount } from '@azure/msal-react';
import Routes from './Routes';
import {
    TokenProvider,
    UserAuthProvider,
    NavigationProvider,
    useCrumbsStateValue,
    appInsightsInit,
    EClientType
} from 'nulia-ui';

interface IProps {
    instance: IPublicClientApplication;
    accounts: Partial<Pick<AccountInfo, 'homeAccountId' | 'localAccountId' | 'username'>>[];
}

const Token: FC<IProps> = ({ instance, accounts }) => {
    const account = useAccount(accounts[0]);
    const { removeLatestCrumb, setCrumbs } = useCrumbsStateValue();

    useEffect(() => {
        if (account?.tenantId && account.localAccountId)
            appInsightsInit(`${account.tenantId} - ${account.localAccountId}`);
    }, [account]);

    useEffect(() => {
        window.onpopstate = () => {
            removeLatestCrumb();
        };
        let savedCrumb = sessionStorage.getItem('crumbs');
        if (savedCrumb && savedCrumb !== 'undefined') setCrumbs(JSON.parse(savedCrumb));
        return () => {
            window.onpopstate = null;
        };
    }, []);

    return (
        <TokenProvider
            propOid={account?.localAccountId ?? ''}
            prodTid={account?.tenantId ?? ''}
            propClientType={EClientType.WEB}
        >
            <UserAuthProvider>
                <NavigationProvider>
                    <Routes />
                </NavigationProvider>
            </UserAuthProvider>
        </TokenProvider>
    );
};

export default Token;
