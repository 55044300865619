import { FC } from 'react';
import { useNavigate } from 'react-router';
import { RovingTabIndexProvider } from 'react-roving-tabindex';
import { useTranslation } from 'react-i18next';
import AboutCard from '../../../ui/cards/about-card/AboutCard';
import { CategoryWrapper, InfoBox, PaperHeaderBox, RootBox, RootPaper, TitleSpan, RootInnerBox } from './Style';
import HowToCategory from '../components/category/HowToCategory';
import { useHowTosStateValue } from '../../../contexts/HowTosContext';
import { makeHowToRoute, routes } from '../../../pages/routes';
import Loading from '../../../ui/general/loading/Loading';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import { isInTeams } from '../../../utils/isInTeams';

const HowTosHomepage: FC = () => {
    const { categories, data, isDataLoading } = useHowTosStateValue();
    const { appendCrumb, setCrumbs } = useCrumbsStateValue();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const clickHandler = (id: string, title: string) => {
        const pathname = makeHowToRoute(id);

        if (isInTeams()) {
            setCrumbs([
                {
                    name: t('pages.howTos'),
                    pathname: routes.HOW_TOS_HOMEPAGE
                },
                {
                    name: categories?.find((category) => category.id === id)?.name || '',
                    pathname
                }
            ]);
        } else {
            appendCrumb({
                name: title || '',
                pathname
            });
        }

        navigate(pathname);
    };

    return (
        <RootBox>
            <RootInnerBox>
                <AboutCard
                    title={t('howTos.home.aboutCardTitle')}
                    description={data?.introduction}
                    loading={isDataLoading}
                />
                <RootPaper elevation={1}>
                    <PaperHeaderBox>
                        <InfoBox>
                            <TitleSpan>{t('howTos.home.allTopics')}</TitleSpan>
                        </InfoBox>
                    </PaperHeaderBox>
                    <CategoryWrapper isContentLoading={isDataLoading}>
                        {isDataLoading ? (
                            <Loading />
                        ) : (
                            <RovingTabIndexProvider options={{ direction: 'horizontal' }}>
                                {categories?.map((category) => {
                                    return (
                                        <HowToCategory
                                            key={category.id}
                                            image={category.image}
                                            title={category.name}
                                            onClick={clickHandler}
                                            categoryId={category.id}
                                        />
                                    );
                                })}
                            </RovingTabIndexProvider>
                        )}
                    </CategoryWrapper>
                </RootPaper>
            </RootInnerBox>
        </RootBox>
    );
};

export default HowTosHomepage;
