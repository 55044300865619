import React, { FC, useCallback, useMemo, KeyboardEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { IAppDTO } from '../../../interfaces/dtos/IAppDTO';
import HeartIcon from '../../../assets/icons/HeartIcon';
import useExpand from '../../../hooks/useExpand';
import Tooltip from '../../general/tooltip/Tooltip';
import { ESkillStatus } from '../../../interfaces/enums/ESkillStatus';
import { IScoreDTO } from '../../../interfaces/dtos/IScoreDTO';
import { ESkillLevel } from '../../../interfaces/enums/ESkillLevel';
import { IAssignmentDTO } from '../../../interfaces/dtos/IAssignmentDTO';
import BlueWarningIcon from '../../../assets/icons/BlueWarningIcon';
import { ESkillType } from '../../../interfaces/enums/ESkillType';
import {
    AppIconImg,
    AppIconWrapperBox,
    AppsBox,
    CollapsableRowBox,
    Description,
    FirstRowBox,
    FooterActionBox,
    HeartIconWrapper,
    ImageBox,
    InfoAssignedControlWrapper,
    InfoBox,
    InfoTextBox,
    MasterLevelRequestedWrapper,
    MdDownActionControlRow,
    MdDownChartDescriptionBox,
    RootBox,
    ShowDescriptionBox,
    MdHeartWrapper,
    DescriptionSegment
} from './Style';
import { createUrl } from '../../../utils/createUrl';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import BestPracticeProgressDoughnutChart from '../../general/progress-doughnut-chart/BestPracticeProgressDoughnutChart';
import StandardProgressDoughnutChart from '../../general/progress-doughnut-chart/StandardProgressDoughnutChart';

interface IProps {
    id: number;
    name: string;
    isAssigned: boolean;
    appIcons: IAppDTO[];
    skillClickHandler?: (id: string) => void;
    description?: string;
    isUserFocus: boolean;
    isMasterLevelRequested: boolean;
    progressDoughnutChartType?: ESkillStatus;
    score?: IScoreDTO;
    level?: ESkillLevel;
    assignment: IAssignmentDTO;
    isUserFavoriteControlDisabled?: boolean;
    toggleFavoriteCallback?: (id: number) => void;
    skillType?: ESkillType;
    index?: number;
    isActionDisabled?: boolean;
    maxLevel: string;
    userActivitySummary?: {
        total: number;
        completed: number;
    };
}

const OutcomeSkillCard: FC<IProps> = ({
    id,
    name,
    appIcons,
    skillClickHandler,
    description,
    isUserFocus,
    isMasterLevelRequested,
    progressDoughnutChartType,
    score,
    level,
    isAssigned,
    assignment,
    isUserFavoriteControlDisabled,
    toggleFavoriteCallback,
    skillType,
    index,
    isActionDisabled,
    maxLevel,
    userActivitySummary
}) => {
    const { t } = useTranslation();
    const { collapse, expand, isExpanded } = useExpand({
        defaultExpanded: false
    });
    const toggleDescriptionRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const favouriteSkillChangeHandler = useCallback((e?: React.MouseEvent) => {
        if (toggleFavoriteCallback) toggleFavoriteCallback(id);
        e?.stopPropagation();
    }, []);

    const progressDoughnutMemo = useMemo(() => {
        if (progressDoughnutChartType && score && level && skillType) {
            let targetScore = 0;
            if (level === ESkillLevel.PRODUCER) targetScore = score.targetProducer;
            if (level === ESkillLevel.MASTER) {
                if (maxLevel === (ESkillLevel.MASTER as string)) targetScore = score.targetMaster;
                else targetScore = score.targetProducer;
            }
            if (skillType === ESkillType.STANDARD) {
                return (
                    <ImageBox>
                        <StandardProgressDoughnutChart
                            index={index}
                            currentValue={score.score}
                            expectedValue={skillType === ESkillType.STANDARD ? targetScore : undefined}
                            dimension={{
                                width: 80,
                                height: 80,
                                margin: {
                                    bottom: 5,
                                    left: 10,
                                    right: 10,
                                    top: 7
                                },
                                starSize: 32,
                                starX: -8,
                                starY: -10
                            }}
                            isStarted={isAssigned}
                            skillStatus={progressDoughnutChartType}
                            isAssigned={assignment.level !== ESkillLevel.NONE}
                            noWaitingForAnimation={!!(index !== undefined && index < 4)}
                        />
                    </ImageBox>
                );
            } else if (skillType === ESkillType.BEST_PRACTICE) {
                return (
                    <ImageBox>
                        <BestPracticeProgressDoughnutChart
                            index={index}
                            achievedStepNumber={userActivitySummary?.completed || 0}
                            totalStepNumber={userActivitySummary?.total || 0}
                            dimension={{
                                width: 80,
                                height: 80,
                                margin: {
                                    bottom: 5,
                                    left: 10,
                                    right: 10,
                                    top: 7
                                },
                                starSize: 32,
                                starX: -8,
                                starY: -10
                            }}
                            skillStatus={progressDoughnutChartType}
                            isAssigned={assignment.level !== ESkillLevel.NONE}
                            noWaitingForAnimation={!!(index !== undefined && index < 4)}
                            isDataMissing={!userActivitySummary || !userActivitySummary.total}
                        />
                    </ImageBox>
                );
            }
        }
        return null;
    }, [isAssigned, progressDoughnutChartType, score, level, assignment, index, userActivitySummary]);

    const onSkillClick = useCallback(() => {
        if (skillClickHandler) skillClickHandler(id.toString());
    }, [id]);

    const onSkillKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            e.stopPropagation();
            e.preventDefault();
            if (e.key === 'Enter' && skillClickHandler) skillClickHandler(id.toString());
            if (e.key === 'ArrowDown' && index !== undefined) {
                const nextElement = document.getElementById(`skill-card-${index + 1}`);
                if (nextElement) {
                    nextElement.focus();
                }
            }
            if (e.key === 'ArrowUp' && index !== undefined) {
                const previousElement = document.getElementById(`skill-card-${index - 1}`);
                if (previousElement) {
                    previousElement.focus();
                }
            }
        },
        [id]
    );

    const onToggleDescriptionKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && toggleDescriptionRef.current) {
                toggleDescriptionRef.current.click();
            }
            e.stopPropagation();
        },
        [id, isExpanded, collapse, expand]
    );

    const onFavoriteSkillKeyDown = useCallback((e: KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === 'Enter') {
            favouriteSkillChangeHandler();
        }
        e.stopPropagation();
    }, []);

    const appIconsMemo = useMemo(() => {
        if (appIcons)
            return (
                <AppIconWrapperBox>
                    {appIcons.map((appIcon) => {
                        return (
                            <AppIconImg
                                key={appIcon.name}
                                alt={appIcon.name}
                                src={createUrl(appIcon.icon)?.generatedUrl}
                                onError={fetchImageErrorCallback}
                            />
                        );
                    })}
                </AppIconWrapperBox>
            );
        return <></>;
    }, [appIcons]);

    const heartMemo = useMemo(() => {
        return (
            <HeartIconWrapper
                isActive={isUserFocus}
                tabIndex={0}
                onClick={
                    isUserFavoriteControlDisabled || isActionDisabled
                        ? (e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                          }
                        : favouriteSkillChangeHandler
                }
                onKeyDown={onFavoriteSkillKeyDown}
            >
                <Tooltip
                    title={
                        isUserFavoriteControlDisabled
                            ? 'You need to assign skill first'
                            : isUserFocus
                              ? t('components.skillCard.removeFavourite')
                              : t('components.skillCard.setFavourite')
                    }
                >
                    <HeartIcon liked={isUserFocus} hoverEffect />
                </Tooltip>
            </HeartIconWrapper>
        );
    }, [
        isUserFocus,
        isUserFavoriteControlDisabled,
        isActionDisabled,
        favouriteSkillChangeHandler,
        onFavoriteSkillKeyDown
    ]);

    const descriptionControlMemo = useMemo(() => {
        return (
            <ShowDescriptionBox
                onClick={isExpanded ? collapse : expand}
                onKeyDown={onToggleDescriptionKeyDown}
                tabIndex={0}
                ref={toggleDescriptionRef}
            >
                {isExpanded
                    ? isMdDown
                        ? t('skill.viewLess')
                        : t('skill.hideDescription')
                    : isMdDown
                      ? t('skill.viewMore')
                      : t('skill.showDescription')}
            </ShowDescriptionBox>
        );
    }, [isExpanded, collapse, expand, onToggleDescriptionKeyDown, isMdDown]);

    const masterLevelRequestedMemo = useMemo(() => {
        return isMasterLevelRequested ? (
            <Tooltip title={t('components.skillCard.masterLevelRequested')}>
                <MasterLevelRequestedWrapper>
                    <BlueWarningIcon />
                </MasterLevelRequestedWrapper>
            </Tooltip>
        ) : (
            <></>
        );
    }, [isMasterLevelRequested]);

    const descriptionMemo = useMemo(() => {
        if (!description) return '';
        if (Array.isArray(description)) {
            return description.map((descriptionItem) => {
                return (
                    <DescriptionSegment key={descriptionItem} dangerouslySetInnerHTML={{ __html: descriptionItem }} />
                );
            });
        }
        if (description) {
            return <DescriptionSegment dangerouslySetInnerHTML={{ __html: description }} />;
        }
        return '';
    }, [description]);

    return (
        <RootBox
            className={`skill-card-${index ?? 0}`}
            id={`skill-card-${index ?? 0}`}
            tabIndex={0}
            onKeyDown={onSkillKeyDown}
            onClick={onSkillClick}
        >
            {!isMdDown && (
                <FirstRowBox>
                    {progressDoughnutMemo}
                    <InfoAssignedControlWrapper isPaddingNeeded>
                        <InfoBox>
                            <InfoTextBox variant={isLgDown && !isMdDown ? 'caption' : 'subtitle2'}>{name}</InfoTextBox>

                            <AppsBox>
                                {appIconsMemo}
                                <FooterActionBox>
                                    {isMasterLevelRequested && masterLevelRequestedMemo}
                                    {!isUserFavoriteControlDisabled && heartMemo}
                                    {descriptionControlMemo}
                                </FooterActionBox>
                            </AppsBox>
                        </InfoBox>
                    </InfoAssignedControlWrapper>
                </FirstRowBox>
            )}
            {isMdDown && (
                <MdDownActionControlRow>
                    <MdDownChartDescriptionBox>
                        {progressDoughnutMemo}
                        {descriptionControlMemo}
                    </MdDownChartDescriptionBox>{' '}
                    <InfoTextBox variant='subtitle2'>{name}</InfoTextBox>
                    <MdHeartWrapper>{heartMemo}</MdHeartWrapper>
                </MdDownActionControlRow>
            )}
            {isExpanded && (
                <CollapsableRowBox>
                    <Description variant={isMdDown ? 'label' : 'body2'}>{descriptionMemo}</Description>
                </CollapsableRowBox>
            )}
        </RootBox>
    );
};

export default OutcomeSkillCard;
