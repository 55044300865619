import { FC, KeyboardEvent } from 'react';
import { IActivity } from '../../../../../interfaces/IActivity';
import { RootBox } from './Style';

export interface IProps {
    title: string;
    activities?: IActivity[];
    behaviorClickHandler?: (id: number, behaviorItem: string, activities?: IActivity[]) => void;
    isSelected: boolean;
    isStandardType?: boolean;
    id: number;
    index: number;
    behaviorLevel: number;
}

const Behavior: FC<IProps> = ({
    id,
    title,
    behaviorClickHandler,
    activities,
    isSelected,
    index,
    behaviorLevel,
    isStandardType = true
}) => {
    return (
        <RootBox
            onClick={() => {
                if (behaviorClickHandler) behaviorClickHandler(id, title, activities);
            }}
            onKeyDown={(e: KeyboardEvent<any>) => {
                if (e.key === 'ArrowDown') {
                    const element = document.getElementById(`behavior-${behaviorLevel}-${index + 1}`);
                    if (!element) {
                        const nextGroupElement = document.getElementById(`behavior-${behaviorLevel + 1}-${0}`);
                        if (nextGroupElement) {
                            (nextGroupElement as HTMLElement).focus();
                        }
                    } else (element as HTMLElement).focus();
                }
                if (e.key === 'ArrowUp') {
                    const element = document.getElementById(`behavior-${behaviorLevel}-${index - 1}`);
                    if (!element) {
                        const previosGroupElements = document.querySelectorAll(`[id^=behavior-${behaviorLevel - 1}-]`);
                        if (previosGroupElements) {
                            (previosGroupElements[previosGroupElements.length - 1] as HTMLElement).focus();
                        }
                    } else (element as HTMLElement).focus();
                }
            }}
            isClickable={isStandardType}
            className='behavior-item'
            id={`behavior-${behaviorLevel}-${index}`}
            isSelected={isSelected}
            tabIndex={-1}
        >
            {title}
        </RootBox>
    );
};

export default Behavior;
