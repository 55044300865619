import { createContext, FC, useContext, PropsWithChildren } from 'react';
import { IResourceVM } from '../interfaces/views/IResourceVM';
import { useGetResourcesQuery } from '../services/ResourcesQueryService';

export interface IResourcesContext {
    data?: IResourceVM[];
    isResourcesDataLoading: boolean;
    isResourcesDataFetchingError: boolean;
    refetchResourcesCallback: () => void;
}

export const ResourcesContext = createContext<IResourcesContext>({} as IResourcesContext);

interface IProps {}

export const ResourcesProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const {
        data: fetchedResourcesData,
        isLoading: isResourcesDataLoading,
        isError: isResourcesDataFetchingError,
        refetch: refetchResourcesCallback
    } = useGetResourcesQuery();

    const resourcesState: IResourcesContext = {
        data: fetchedResourcesData,
        isResourcesDataLoading,
        isResourcesDataFetchingError,
        refetchResourcesCallback
    };

    return <ResourcesContext.Provider value={resourcesState}>{children}</ResourcesContext.Provider>;
};

export const useResourcesStateValue: () => IResourcesContext = () => useContext(ResourcesContext);
