import useExpand from '../../../hooks/useExpand';
import React, { FC, useEffect, useMemo, useState, KeyboardEvent } from 'react';
import { useFiltersStateValue } from '../../../contexts/FiltersContext';
import CheckboxUncheckedIcon from '../../../assets/icons/CheckboxUncheckedIcon';
import CheckboxCheckedIcon from '../../../assets/icons/CheckboxCheckedIcon';
import UpArrowBoldIcon from '../../../assets/icons/UpArrowBoldIcon';
import DownArrowBoldIcon from '../../../assets/icons/DownArrowBoldIcon';
import { RadioGroup } from '@material-ui/core';
import {
    StyledFormControl,
    FilterCategoryTitleSpan,
    OrderSpan,
    OrderSpanWrapper,
    RootBox,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
    StyledCheckbox,
    StyledFormControlLabel,
    StyledRadio,
    TitleBox,
    ValueBox,
    ValuesWrapper
} from './Style';

interface IProps {
    radioGroupId?: string;
    title: string;
    radioButtonMode?: boolean;
    defaultOpen?: boolean;
    values: IFilterCategoryValue[];
    defaultValue?: string;
}

interface IFilterCategoryValue {
    key: string;
    name: string;
}

const FilterCategory: FC<IProps> = ({ title, values, radioButtonMode, defaultOpen, defaultValue, radioGroupId }) => {
    const { collapse, expand, setExpanded, isExpanded } = useExpand({
        defaultExpanded: true
    });
    const { changeActiveFilter, activeFilters } = useFiltersStateValue();
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    useEffect(() => {
        if (defaultOpen === false || defaultOpen === true) setExpanded(!!defaultOpen);
    }, [defaultOpen, setExpanded]);

    useEffect(() => {
        if (activeFilters.length === 0) {
            if (radioButtonMode) {
                if (defaultValue) {
                    setSelectedValue(defaultValue);
                } else {
                    setSelectedValue(null);
                }
            }
        }
    }, [activeFilters]);

    useEffect(() => {
        if (defaultValue) {
            setSelectedValue(defaultValue);
            changeActiveFilter(
                defaultValue,
                values.filter((categoryValue) => categoryValue.key !== defaultValue).map((c) => c.key)
            );
        }
    }, [defaultValue]);

    const valuesMemo = useMemo(() => {
        if (!values) return null;

        const handleChangeFilter = (key: string) => {
            changeActiveFilter(key);
        };

        const onRadioOptionKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'Enter') {
                handleRadioButtonChange(e as unknown as React.ChangeEvent<HTMLInputElement>, false);
            }
        };

        const onCheckboxOptionKeyDown = (e: KeyboardEvent<HTMLButtonElement>, key: string) => {
            if (e.key === 'Enter') {
                handleChangeFilter(key);
                e.stopPropagation();
            }
        };

        const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            const value = (event.target as HTMLInputElement).value;
            changeActiveFilter(
                value,
                values.filter((categoryValue) => categoryValue.key !== value).map((c) => c.key)
            );
            setSelectedValue((oldValue) => {
                return oldValue !== value ? value : null;
            });
        };

        let valuesJSX;
        if (radioButtonMode) {
            valuesJSX = (
                <>
                    <StyledFormControl>
                        <RadioGroup value={selectedValue} name={`${radioGroupId}-controlled-radio-buttons-group`}>
                            {values.map((value) => {
                                return (
                                    <ValueBox key={value.key} variant='body2'>
                                        <StyledFormControlLabel
                                            value={value.key}
                                            control={
                                                <StyledRadio
                                                    onChange={handleRadioButtonChange}
                                                    icon={<CheckboxUncheckedIcon />}
                                                    checkedIcon={<CheckboxCheckedIcon />}
                                                    onKeyDown={onRadioOptionKeyDown}
                                                    tabIndex={0}
                                                />
                                            }
                                            label={value.name}
                                            color='primary'
                                        />
                                    </ValueBox>
                                );
                            })}
                        </RadioGroup>
                    </StyledFormControl>
                </>
            );
        } else {
            valuesJSX = values.map((value) => {
                return (
                    <StyledFormControl key={value.key}>
                        <ValueBox variant='body2' key={value.key}>
                            <StyledFormControlLabel
                                value={value.key}
                                control={
                                    <StyledCheckbox
                                        checked={activeFilters.includes(value.key)}
                                        onChange={() => handleChangeFilter(value.key)}
                                        onKeyDown={(e) => onCheckboxOptionKeyDown(e, value.key)}
                                    />
                                }
                                label={value.name}
                                color='primary'
                            />
                        </ValueBox>
                    </StyledFormControl>
                );
            });
        }

        return <div>{valuesJSX}</div>;
    }, [values, activeFilters, selectedValue]);

    const onAccordionKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            if (isExpanded) collapse();
            else expand();
        }
    };

    return (
        <RootBox>
            <StyledAccordion expanded={isExpanded}>
                <StyledAccordionSummary tabIndex={0} onKeyDown={onAccordionKeyDown}>
                    <TitleBox isExpanded={isExpanded} onClick={isExpanded ? collapse : expand}>
                        <FilterCategoryTitleSpan>{title}</FilterCategoryTitleSpan>
                        <OrderSpanWrapper>
                            {isExpanded ? (
                                <OrderSpan>
                                    <UpArrowBoldIcon />
                                </OrderSpan>
                            ) : (
                                <OrderSpan>
                                    <DownArrowBoldIcon />
                                </OrderSpan>
                            )}
                        </OrderSpanWrapper>
                    </TitleBox>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <ValuesWrapper>{valuesMemo}</ValuesWrapper>
                </StyledAccordionDetails>
            </StyledAccordion>
        </RootBox>
    );
};

export default FilterCategory;
