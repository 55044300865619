import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';

export const ContentRootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '24px'
}));

export const StyledSelect = styled(Select<string[]>)(({ theme }) => ({
    '.MuiSvgIcon-root': {
        top: 3
    },
    '.MuiSelect-select': {
        outline: '0 !important',
        width: '210px',
        overflow: 'hidden',
        display: 'inline-block !important',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        position: 'relative',
        top: '2px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px !important'
        }
    },
    '.MuiSelect-icon': {
        top: '6px'
    }
}));

export const StyledMenuItem = styled(MenuItem)({});

export const MenuItemTitleSpan = styled('span')({
    paddingLeft: 6,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '28px',
    letterSpacing: '0.5px'
});

export const TableAdditionalHeader = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
});
