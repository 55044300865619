import { ITableHeadCell, Table, makeAccountDetailsRoute } from 'nulia-ui';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IPartnerVM } from '../../../interfaces/views/IPartnerVM';
import ResellerIcon from '../../../components/icons/ResellerIcon';

interface ITableHeader {
    status: string;
    companyName: string;
    officeLicenses: string;
    nuliaLicenses: string;
}

interface IProps {
    data?: IPartnerVM[];
    isLoading: boolean;
}

const PartnersTable: FC<IProps> = ({ data, isLoading }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'status',
            label: t('partners.table.status'),
            customRender(value, row) {
                if (value === 'Reseller') {
                    return <ResellerIcon />;
                } else if (value === 'Customer') {
                    return <ResellerIcon />;
                }
                return <ResellerIcon />;
            }
        },
        {
            id: 'companyName',
            label: t('partners.table.companyName')
        },
        {
            id: 'officeLicenses',
            label: t('partners.table.officeLicenses')
        },
        {
            id: 'nuliaLicenses',
            label: t('partners.table.nuliaLicenses')
        }
    ];

    const onAccountTableRowClick = useCallback(() => {
        navigate(makeAccountDetailsRoute(1));
    }, []);

    return (
        <div>
            <Table
                headCells={headCells}
                isLoading={isLoading}
                data={data}
                propertyKeys={headCells.map((headCell) => headCell.id)}
                onRowClick={onAccountTableRowClick}
            />
        </div>
    );
};

export default PartnersTable;
