import {
    createContext,
    FC,
    useCallback,
    useContext,
    useState,
    ChangeEvent,
    useEffect,
    PropsWithChildren,
    useRef
} from 'react';
import { matchPath, useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import {
    adminHistoryByActionNameFilterCallback,
    adminHistoryByCreatedByFilterCallback,
    adminHistoryByStatusFilterCallback,
    adminHistoryCategories
} from '../contexts/util/filterCategories';
import useFilterSearch from '../hooks/useFilterSearch';
import { useGetOutcomeTenantHistoriesQuery, useGetTenantHistoriesQuery } from '../services/TenantQueryService';
import { ITenantHistoryVM } from '../interfaces/views/ITenantHistoryVM';
import { convertHistoryTypeToStringValues } from '../utils/convertHistoryTypeToStringValues';
import { ETenantHistoryType } from '../interfaces/enums/ETenantHistoryType';
import { routes } from '../pages/routes';

export interface IAdminHistoryContext {
    historyData: ITenantHistoryVM[] | undefined;
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    searchText: string;
    changeSearchText: (e: ChangeEvent<HTMLInputElement>) => void;
    filterCategories: IFilterCategories[];
    refetchTenantHistoryData: () => void;
    refetchOutcomeTenantHistoryData: () => void;
    isTenantHistoryDataLoading: boolean;
    isErrorFetching: boolean;
    defaultActiveFilters: string[];
}

export const AdminHistoryContext = createContext<IAdminHistoryContext>({} as IAdminHistoryContext);

interface IProps {}

export const AdminHistoryProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [historyData, setHistoryData] = useState<ITenantHistoryVM[] | undefined>();
    const [filteredHistoryData, setFilteredHistoryData] = useState<ITenantHistoryVM[] | undefined>();
    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(adminHistoryCategories);
    const {
        data: fetchedTenantHistoryData,
        refetch: refetchTenantHistoryData,
        isFetching: isTenantHistoryDataFetching,
        isError: isTenantHistoryDataError
    } = useGetTenantHistoriesQuery();
    const {
        data: fetchedOutcomeTenantHistoryData,
        refetch: refetchOutcomeTenantHistoryData,
        isFetching: isOutcomeTenantHistoryDataFetching,
        isError: isOutcomeTenantHistoryDataError
    } = useGetOutcomeTenantHistoriesQuery();
    const runUrlCheck = useRef<boolean>(false);

    let location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (
            matchPath(routes.HISTORY_OUTCOMES, location.pathname) &&
            historyData &&
            historyData?.length > 0 &&
            !runUrlCheck.current
        ) {
            runUrlCheck.current = true;
            setActiveFilters([ETenantHistoryType.OUTCOME_ASSIGN, ETenantHistoryType.OUTCOME_UNASSIGN]);
            onFilterValueChange(
                filterCategories,
                [...activeFilters, ETenantHistoryType.OUTCOME_ASSIGN, ETenantHistoryType.OUTCOME_UNASSIGN],
                true
            );
        } else {
            const typeQueryParam = searchParams.get('type');
            switch (typeQueryParam) {
                case 'role':
                    setActiveFilters([
                        ETenantHistoryType.ROLE_ASSIGN,
                        ETenantHistoryType.ROLE_UNASSIGN,
                        ETenantHistoryType.ROLE_UNASSIGN_ALL
                    ]);
                    setFilteredHistoryData(
                        historyData?.filter((item) => {
                            return (
                                item.type === ETenantHistoryType.ROLE_ASSIGN ||
                                item.type === ETenantHistoryType.ROLE_UNASSIGN ||
                                item.type === ETenantHistoryType.ROLE_UNASSIGN_ALL
                            );
                        })
                    );
                    onFilterValueChange(
                        filterCategories,
                        [
                            ...activeFilters,
                            ETenantHistoryType.ROLE_ASSIGN,
                            ETenantHistoryType.ROLE_UNASSIGN,
                            ETenantHistoryType.ROLE_UNASSIGN_ALL
                        ],
                        true
                    );
                    break;
                case 'license':
                    setActiveFilters([ETenantHistoryType.LICENSE_ASSIGN, ETenantHistoryType.LICENSE_UNASSIGN]);
                    setFilteredHistoryData(
                        historyData?.filter((item) => {
                            return (
                                item.type === ETenantHistoryType.LICENSE_ASSIGN ||
                                item.type === ETenantHistoryType.LICENSE_UNASSIGN
                            );
                        })
                    );
                    onFilterValueChange(
                        filterCategories,
                        [...activeFilters, ETenantHistoryType.LICENSE_ASSIGN, ETenantHistoryType.LICENSE_UNASSIGN],
                        true
                    );
                    break;
            }
        }
    }, [location, historyData, filterCategories]);

    useEffect(() => {
        if (fetchedTenantHistoryData) {
            setFilteredHistoryData(fetchedTenantHistoryData);
            setHistoryData(fetchedTenantHistoryData);
        }
    }, [fetchedTenantHistoryData]);

    useEffect(() => {
        if (fetchedOutcomeTenantHistoryData) {
            setFilteredHistoryData(fetchedOutcomeTenantHistoryData);
            setHistoryData(fetchedOutcomeTenantHistoryData);
        }
    }, [fetchedOutcomeTenantHistoryData]);

    const onAnythingChange: any = useCallback(
        (adminHistoryNeedToBeFiltered: ITenantHistoryVM[], runSearchText?: boolean) => {
            let newFilteredAdminHistory = [...adminHistoryNeedToBeFiltered];

            if (runSearchText) {
                newFilteredAdminHistory = instantFilterByText(searchText, newFilteredAdminHistory);
                setFilteredHistoryData(newFilteredAdminHistory);
            } else {
                onFilterValueChange(filterCategories, activeFilters, true);
                setFilteredHistoryData(newFilteredAdminHistory);
            }
            return newFilteredAdminHistory;
        },
        [
            historyData,
            // @ts-ignore
            searchText,
            // @ts-ignore
            activeFilters,
            filterCategories
        ]
    );

    useEffect(() => {
        if (historyData) {
            setFilteredHistoryData(historyData);
            const statuses = new Set<string>();
            historyData.forEach((historyDataItem) => {
                if (historyDataItem.status) statuses.add(historyDataItem.status);
            });
            const actionNames = new Set<string>();
            historyData.forEach((historyDataItem) => {
                actionNames.add(historyDataItem.type);
            });
            const createdBys = new Set<string>();
            historyData.forEach((historyDataItem) => {
                createdBys.add(historyDataItem.initiatorName);
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'status') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    statuses.forEach((status) => {
                        categoryValues.push({
                            key: status,
                            name: status,
                            callback: adminHistoryByStatusFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'actionName') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    actionNames.forEach((actionName) => {
                        categoryValues.push({
                            key: actionName,
                            name: convertHistoryTypeToStringValues(actionName as ETenantHistoryType),
                            callback: adminHistoryByActionNameFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'createdBy') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    createdBys.forEach((createdBy) => {
                        categoryValues.push({
                            key: createdBy,
                            name: createdBy,
                            callback: adminHistoryByCreatedByFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [historyData]);

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch({
        data: historyData || [],
        dataSerachablePropertyName: 'type',
        onChangeCallback: onAnythingChange,
        setDataCallback: setFilteredHistoryData
    });
    const [activeFilters, setActiveFilters] = useState<string[]>([]);

    const changeSearchText = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value);
        },
        [setSearchText]
    );

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => {
        setActiveFilters(activeFilters);
        let newFilteredHistoryData = historyData ? [...historyData] : [];
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                if (filterCategory.operator !== 'OR') {
                    filterCategory.values!.forEach((filterCategoryValue) => {
                        if (filterCategoryValue.callback && activeFilters.includes(filterCategoryValue.key)) {
                            newFilteredHistoryData = newFilteredHistoryData.filter((historyDataItem) => {
                                if (filterCategoryValue.callback) {
                                    const isValid = filterCategoryValue.callback(
                                        historyDataItem,
                                        filterCategoryValue.name,
                                        filterCategoryValue.key
                                    );
                                    return isValid;
                                }
                                return false;
                            });
                        }
                    });
                } else {
                    const filtersFromThisCategory: IFilterCategoryValue[] = [];
                    filterCategory.values!.forEach((filterCategoryValue) => {
                        if (filterCategoryValue.callback && activeFilters.includes(filterCategoryValue.key)) {
                            filtersFromThisCategory.push(filterCategoryValue);
                        }
                    });
                    if (filtersFromThisCategory.length > 0) {
                        newFilteredHistoryData = newFilteredHistoryData.filter((historyDataItem) => {
                            let isValid = false;
                            filtersFromThisCategory.forEach((filterFromThisCategory) => {
                                if (
                                    filterFromThisCategory.callback &&
                                    !isValid &&
                                    filterFromThisCategory.key !== filterCategory.defaultValue
                                ) {
                                    isValid = filterFromThisCategory.callback(
                                        historyDataItem,
                                        filterFromThisCategory.name,
                                        filterFromThisCategory.key
                                    );
                                }
                            });
                            return isValid;
                        });
                    }
                }
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredHistoryData, true);
    };

    const adminHistoryContext: IAdminHistoryContext = {
        historyData: filteredHistoryData,
        onFilterValueChange,
        searchText,
        changeSearchText,
        filterCategories,
        refetchTenantHistoryData,
        refetchOutcomeTenantHistoryData,
        isTenantHistoryDataLoading: isTenantHistoryDataFetching || isOutcomeTenantHistoryDataFetching,
        isErrorFetching: isTenantHistoryDataError || isOutcomeTenantHistoryDataError,
        defaultActiveFilters: activeFilters
    };

    return <AdminHistoryContext.Provider value={adminHistoryContext}>{children}</AdminHistoryContext.Provider>;
};

export const useAdminHistoryStateValue: () => IAdminHistoryContext = () => useContext(AdminHistoryContext);
