export enum EUserPermission {
    NONE = 0,

    //User Bits 0-15
    USER = 1 << 0, // 1
    CHAMPION_ADMINISTRATION = 1 << 1, // 2
    ASSESSMENT = 1 << 2, // 4
    TENANT_INSIGHTS = 1 << 3, // 8
    TENANT_OUTCOME_ASSIGNMENT = 1 << 4, // 16
    LICENSE_ASSIGNMENT = 1 << 5, // 32
    ROLE_ASSIGNMENT = 1 << 6, // 64
    OUTCOME_FAVORITED_FOR_TENANT = 1 << 7, // 128
    OUTCOME_MASTER_LEVEL_REQUESTED_FOR_TENANT = 1 << 8, // 256
    TENANT_PREFERENCES = 1 << 9, // 512

    //Partner Bits 16-23
    PARTNER = 1 << 16, // 65536

    //Nulia Bits 24-31
    PLATFORM_ADMINISTRATION = 1 << 24, // 16777216
    PARTNER_ADMINISTRATION = 1 << 25 // 33554432
}
