import { FC, useCallback, useMemo, useEffect, KeyboardEvent } from 'react';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate, generatePath, useLocation, matchPath } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    FiltersWrapper,
    RefreshControlWrapper,
    RootBox,
    RootInnerBox,
    TableContainer,
    RefreshSpan,
    RefreshActionBox
} from './Style';
import { FiltersProvider } from '../../../../contexts/FiltersContext';
import { useAdminHistoryStateValue } from '../../../../contexts/AdminHistoryContext';
import Table from '../../../../ui/general/table/Table';
import { ITableHeadCell } from '../../../../interfaces/ITableHeaderCell';
import Filters from '../../../../ui/filters/filters/Filters';
import { EAdminHistoryStatus } from '../../../../interfaces/enums/EAdminHistoryStatus';
import {
    DecreaseStatusCircle,
    InProgressStatusCircle,
    IncreaseStatusCircle,
    UnassignedStatusCircle,
    WarningStatusCircle
} from '../../../../css/CommonComponents';
import TextButton from '../../../../ui/buttons/text-button/TextButton';
import { routes } from '../../../../pages/routes';
import RefreshIcon from '../../../../assets/icons/RefreshIcon';
import { useCrumbsStateValue } from '../../../../contexts/CrumbsContext';
import useFilterDrawer from '../../../../hooks/useFilterDrawer';
import { ITenantHistoryVM } from '../../../../interfaces/views/ITenantHistoryVM';
import { ETenantHistoryStatus } from '../../../../interfaces/enums/ETenantHistoryStatus';
import { useAdminHistoryDetailsStateValue } from '../../../../contexts/AdminHistoryDetailsContext';
import { convertHistoryTypeToStringValues } from '../../../../utils/convertHistoryTypeToStringValues';
import { ETenantHistoryType } from '../../../../interfaces/enums/ETenantHistoryType';
import { Order } from '../../../../hooks/useTable';
import { accessibilityEnterKeyCallback } from '../../../../utils/accessibilityUtils';

interface ITableHeader {
    status: EAdminHistoryStatus;
    type: string;
    date: string;
    initiatorName: string;
    successesCount: string;
    failuresCount: string;
    processedCount: string;
    details: string;
}

const HistoryTab: FC = () => {
    const {
        filterCategories,
        historyData,
        onFilterValueChange,
        refetchTenantHistoryData,
        refetchOutcomeTenantHistoryData,
        isTenantHistoryDataLoading,
        isErrorFetching,
        defaultActiveFilters
    } = useAdminHistoryStateValue();
    const { setSelectedAdminHistoryItem } = useAdminHistoryDetailsStateValue();
    const { appendCrumb } = useCrumbsStateValue();
    const navigate = useNavigate();
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const { isDrawerOpen, toggleFilterDrawer } = useFilterDrawer();
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (matchPath(location.pathname, routes.HISTORY_OUTCOMES)) {
            refetchOutcomeTenantHistoryData();
        } else {
            refetchTenantHistoryData();
        }
    }, []);

    const handleTableRowClick = (id: string) => {
        if (setSelectedAdminHistoryItem) setSelectedAdminHistoryItem(undefined);
        const adminHistoryDetailsPath = generatePath(routes.ADMIN_HISTORY_DETAILS, { id });
        appendCrumb({
            name: 'Admin History',
            pathname: adminHistoryDetailsPath
        });
        navigate(adminHistoryDetailsPath);
    };

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'status',
            disablePadding: true,
            label: '',
            customRender: (value: ETenantHistoryStatus) => {
                switch (value) {
                    case ETenantHistoryStatus.COMPLETED:
                        return <IncreaseStatusCircle />;
                    case ETenantHistoryStatus.ERROR:
                        return <DecreaseStatusCircle />;
                    case ETenantHistoryStatus.IN_PROGRESS:
                    case ETenantHistoryStatus.INITIATED:
                        return <InProgressStatusCircle />;
                    case ETenantHistoryStatus.WARNING:
                        return <WarningStatusCircle />;
                    default:
                        return <UnassignedStatusCircle />;
                }
            }
        },
        {
            id: 'type',
            label: 'Action Name',
            minWidth: '120px',
            maxWidth: '120px',
            disablePadding: true,
            customRender: (value) => {
                return convertHistoryTypeToStringValues(value as ETenantHistoryType);
            }
        },
        {
            id: 'date',
            label: 'Date',
            minWidth: '95px',
            align: 'center',
            customRender: (value) => {
                return <>{format(value as Date, 'LLL dd, yyyy')}</>;
            }
        },
        {
            id: 'initiatorName',
            align: 'center',
            label: 'Created By',
            disablePadding: true
        },
        {
            id: 'successesCount',
            numeric: true,
            disablePadding: true,
            label: 'Succeeded'
        },
        {
            id: 'failuresCount',
            numeric: true,
            disablePadding: true,
            label: 'Failed'
        },
        {
            id: 'processedCount',
            numeric: true,
            disablePadding: true,
            label: 'Processed',
            align: 'center',
            customRender: (value = undefined, row: ITenantHistoryVM) => {
                return (
                    <>
                        {`${row.failuresCount + row.successesCount} / ${
                            row.failuresCount + row.inProgressCount + row.successesCount
                        }`}
                    </>
                );
            },
            customSort: (a: ITenantHistoryVM, b: ITenantHistoryVM, order: Order) => {
                if (order === 'asc') {
                    const initialSortResult = a.successesCount + a.failuresCount - (b.successesCount + b.failuresCount);
                    if (initialSortResult === 0) return a.userCount - b.userCount;
                    return initialSortResult;
                }
                if (order === 'desc') {
                    const initialSortResult = b.successesCount + b.failuresCount - (a.successesCount + a.failuresCount);
                    if (initialSortResult === 0) return b.userCount - a.userCount;
                    return initialSortResult;
                }
                return 0;
            }
        },
        {
            id: 'details',
            label: '',
            disablePadding: true,
            disableSort: true,
            customRender: (value = undefined, row: ITenantHistoryVM) => {
                return (
                    <TextButton
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleTableRowClick(row.id);
                        }}
                    >
                        Details
                    </TextButton>
                );
            }
        }
    ];

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={false}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                isQuickFiltersSelected={false}
                dropdownMode
                defaultActiveFilters={defaultActiveFilters}
            />
        );
    }, [onFilterValueChange, filterCategories, defaultActiveFilters]);

    const refreshHistoryTable = useCallback(() => {
        if (!isTenantHistoryDataLoading) refetchTenantHistoryData();
    }, [refetchTenantHistoryData, isTenantHistoryDataLoading]);

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <RootInnerBox>
                    <TableContainer>
                        <RefreshControlWrapper>
                            <RefreshActionBox
                                isDisabled={isTenantHistoryDataLoading}
                                id='refresh-action-box'
                                onClick={refreshHistoryTable}
                                tabIndex={0}
                                onKeyDown={(e: KeyboardEvent<any>) =>
                                    accessibilityEnterKeyCallback(e, refreshHistoryTable)
                                }
                            >
                                <RefreshIcon
                                    color={isTenantHistoryDataLoading ? theme.palette.gray.medium : undefined}
                                />
                                <RefreshSpan color={isTenantHistoryDataLoading ? theme.palette.gray.medium : undefined}>
                                    {t('adminHistory.refresh')}
                                </RefreshSpan>
                            </RefreshActionBox>
                        </RefreshControlWrapper>
                        <Table<ITenantHistoryVM, ITableHeader>
                            headCells={headCells}
                            data={historyData || []}
                            propertyKeys={headCells.map((headCell) => {
                                return headCell.id;
                            })}
                            tableTitlePlural='History'
                            onRowClick={handleTableRowClick}
                            isFilterControlVisible={isLgDown}
                            isFilterDrawerOpen={isDrawerOpen}
                            filterControlToggleHandler={toggleFilterDrawer}
                            filtersDrawerMemo={filtersMemo}
                            isLoading={isTenantHistoryDataLoading}
                            isError={isErrorFetching}
                            customMediaColumnName='status'
                            customMediaSecondaryColumns={['details']}
                            initialOrderBy='date'
                            initialOrderDirection='desc'
                        />
                    </TableContainer>
                    {!isLgDown && <FiltersWrapper>{filtersMemo}</FiltersWrapper>}
                </RootInnerBox>
            </FiltersProvider>
        </RootBox>
    );
};

export default HistoryTab;
