import { createContext, FC, useContext, PropsWithChildren, useState } from 'react';
import { QueryClient } from '@tanstack/react-query';

export interface IApiContext {
    queryClient?: QueryClient;
    setQueryClient: (queryClient: any) => void;
    invalidateQueryCache: (queryKey: string) => void;
    resetQuery: (queryKey: string) => void;
}

export const ApiContext = createContext<IApiContext>({} as IApiContext);

interface IProps {}

export const ApiProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [queryClient, setQueryClient] = useState<any | undefined>();

    const invalidateQueryCache = (queryKey: string) => {
        if (queryClient)
            queryClient.invalidateQueries({
                queryKey: [queryKey]
            });
    };

    const resetQuery = (queryKey: string) => {
        if (queryClient)
            queryClient.resetQueries({
                queryKey: [queryKey],
                exact: true
            });
    };

    const apiState: IApiContext = {
        queryClient,
        invalidateQueryCache,
        setQueryClient,
        resetQuery
    };

    return <ApiContext.Provider value={apiState}>{children}</ApiContext.Provider>;
};

export const useApiStateValue: () => IApiContext = () => useContext(ApiContext);
