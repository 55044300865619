import { styled } from '@mui/material/styles';
import { Colors } from '../../../Colors';

export const RootBox = styled('div')({
    position: 'relative'
});

export const ButtonBox = styled('div')({
    height: 36,
    minHeight: 36,
    width: 149,
    minWidth: 149,
    borderRadius: 4,
    boxShadow: '0 1.5px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.16)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
        boxShadow: '0 6.5px 16px 1px rgba(0,0,0,0.12), 0 4px 8px 0 rgba(0,0,0,0.08)'
    }
});

export const MainButtonBox = styled('div')({
    height: '100%',
    width: '100%',
    backgroundColor: Colors.purple,
    color: Colors.white,
    fontFamily: 'Ubuntu',
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 1,
    lineHeight: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    '&:active': {
        backgroundColor: Colors.darkPurple,
        boxShadow: 'none'
    }
});

export const ExpandBox = styled('div')<{ isExpanded: boolean }>(({ isExpanded }) => ({
    width: 32,
    minWidth: 32,
    height: '100%',
    borderLeft: isExpanded ? '1px solid #44346E' : '1px solid rgba(255,255,255,0.16)',
    backgroundColor: isExpanded ? Colors.darkPurple : Colors.purple,
    color: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    '&:active': {
        backgroundColor: Colors.darkPurple,
        boxShadow: 'none'
    }
}));

export const DropdownOptionsBox = styled('div')(() => ({
    width: 211,
    backgroundColor: Colors.white,
    boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)',
    borderRadius: 4,
    marginTop: 4,
    paddingTop: 4,
    paddingBottom: 8,
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 1
}));

export const DropdownOptionBox = styled('div')(() => ({
    width: '100%',
    height: 40,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: Colors.purple,
    fontFamily: 'Open Sans',
    fontSize: 14,
    letterSpacing: 0.25,
    lineHeight: '20px',
    paddingLeft: 16,
    paddingRight: 16,
    '&:hover': {
        backgroundColor: 'rgba(101,78,163,0.08) !important'
    }
}));
