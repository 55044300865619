import { FC, useCallback, KeyboardEvent, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { IAppDTO } from '../../../interfaces/dtos/IAppDTO';
import ToExpandIconSVG from '../../../assets/icons/ToExpandIcon';
import ToCollapseIconSVG from '../../../assets/icons/ToCollapseIcon';
import useExpand from '../../../hooks/useExpand';
import Loading from '../../general/loading/Loading';
import { isFullLink } from '../../../utils/isFullLink';
import HeartIcon from '../../../assets/icons/HeartIcon';
import Tooltip from '../../general/tooltip/Tooltip';
import {
    RootPaper,
    AboutSkillBox,
    AboutSkillInfoBox,
    AboutSkillVideoBox,
    AppIconImg,
    AppIconsBox,
    ExpandedCollapsedBox,
    HeartBox,
    InfoBox,
    PaperHeaderBox,
    TitleSpan,
    RootBox,
    AboutSkillInfoDescriptionItem,
    OrganizationPriorityBox
} from './Style';
import SomethingWentWrongCard from '../something-went-wrong-card/SomethingWentWrongCard';
import { createUrl, createFullVideoUrls, IFullVideoUrlsResponse } from '../../../utils/createUrl';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import loadImageGraduallyHandler from '../../../utils/loadImageGradually';
import { accessibilityEnterSpaceKeyCallback } from '../../../utils/accessibilityUtils';
import VidstackVideo from '../../../ui/general/video/VidstackVideo';
import { useNavigationStateValue } from '../../../contexts/NavigationContext';
import ClipboardCheckIcon from '../../../assets/icons/ClipboardCheck';

interface IProps {
    title: string;
    appIcons?: IAppDTO[];
    description?: string | string[];
    defaultExpanded?: boolean;
    loading?: boolean;
    videoImage?: string;
    videoUrl?: string;
    isUserFocus?: boolean;
    isUserFavoriteControlVisible?: boolean;
    isUserFavoriteControlDisabled?: boolean;
    toggleUserFavorite?: () => void;
    isToggleUserFavoriteRequestSent?: boolean;
    isError?: boolean;
    errorRefetchCallback?: () => void;
    contentId?: number;
    videoWatchedCallback?: (relatedId?: number) => Promise<void>;
    isOrganizationPriority?: boolean;
}

const AboutCard: FC<IProps> = ({
    appIcons,
    description,
    title,
    defaultExpanded = true,
    loading = false,
    videoImage,
    videoUrl,
    isUserFocus,
    isUserFavoriteControlVisible,
    isUserFavoriteControlDisabled,
    toggleUserFavorite,
    isToggleUserFavoriteRequestSent,
    isError,
    errorRefetchCallback,
    contentId,
    videoWatchedCallback,
    isOrganizationPriority = false
}) => {
    const { collapse, expand, isExpanded } = useExpand({
        defaultExpanded: defaultExpanded
    });
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const [fullVideoUrls, setFullVideoUrls] = useState<IFullVideoUrlsResponse | undefined>();
    const { showNav } = useNavigationStateValue();

    useEffect(() => {
        const urls = createFullVideoUrls(videoUrl);
        setFullVideoUrls(urls);
    }, [videoUrl]);

    const onToggleUserFocus = () => {
        if (toggleUserFavorite) toggleUserFavorite();
    };

    const refetchDataCallback = useCallback(() => {
        if (errorRefetchCallback) errorRefetchCallback();
    }, [errorRefetchCallback]);

    const descriptionMemo = useMemo(() => {
        if (Array.isArray(description)) {
            return description.map((descriptionItem) => {
                return (
                    <AboutSkillInfoDescriptionItem
                        key={descriptionItem}
                        dangerouslySetInnerHTML={{ __html: descriptionItem }}
                    />
                );
            });
        }
        if (description) {
            // const descriptionSegments = lineBreakSplitter(description);
            // return descriptionSegments.map((segment, index) => {
            //     return <AboutSkillInfoDescriptionItem key={index} dangerouslySetInnerHTML={{ __html: segment }} />;
            // });
            return <AboutSkillInfoDescriptionItem dangerouslySetInnerHTML={{ __html: description }} />;
        }
        return '';
    }, [description]);

    const organizationPriorityMemo = useMemo(() => {
        return isOrganizationPriority ? (
            <Tooltip title={t('tooltips.organizationPriority')}>
                <OrganizationPriorityBox>
                    <ClipboardCheckIcon />
                </OrganizationPriorityBox>
            </Tooltip>
        ) : (
            <></>
        );
    }, [isOrganizationPriority]);

    return (
        <RootBox>
            <RootPaper elevation={1}>
                <PaperHeaderBox>
                    <InfoBox>
                        <TitleSpan>{title}</TitleSpan>
                        {appIcons && (
                            <AppIconsBox>
                                {appIcons.map((appIcon) => {
                                    return (
                                        <Tooltip key={appIcon.name} title={appIcon.name}>
                                            <AppIconImg
                                                alt={appIcon.name}
                                                src={createUrl(appIcon.icon)?.generatedUrl}
                                                onLoad={loadImageGraduallyHandler}
                                                onError={fetchImageErrorCallback}
                                            />
                                        </Tooltip>
                                    );
                                })}
                            </AppIconsBox>
                        )}
                    </InfoBox>
                    {isUserFavoriteControlVisible && !isUserFavoriteControlDisabled && !isError && !loading && (
                        <Tooltip
                            title={
                                isUserFavoriteControlDisabled
                                    ? t('tooltips.assignSkillFirst')
                                    : isUserFocus
                                      ? t('tooltips.removeFocusSkill')
                                      : t('tooltips.setFocusSkill')
                            }
                        >
                            <HeartBox
                                tabIndex={0}
                                disableClick={!!isUserFavoriteControlDisabled || !!isToggleUserFavoriteRequestSent}
                                onClick={!isUserFavoriteControlDisabled ? onToggleUserFocus : undefined}
                                isActive={!!isUserFocus}
                                onKeyDown={(e: KeyboardEvent<any>) =>
                                    accessibilityEnterSpaceKeyCallback(e, onToggleUserFocus)
                                }
                            >
                                <HeartIcon liked={isUserFocus} hoverEffect={true} />
                            </HeartBox>
                        </Tooltip>
                    )}
                    {organizationPriorityMemo}
                    <ExpandedCollapsedBox
                        tabIndex={0}
                        onClick={isExpanded ? collapse : expand}
                        onKeyDown={(e: KeyboardEvent<any>) =>
                            accessibilityEnterSpaceKeyCallback(e, isExpanded ? collapse : expand)
                        }
                    >
                        {isExpanded ? <ToCollapseIconSVG /> : <ToExpandIconSVG />}
                    </ExpandedCollapsedBox>
                </PaperHeaderBox>
                {loading ? (
                    <Loading />
                ) : (
                    isExpanded && (
                        <AboutSkillBox>
                            {isError ? (
                                <SomethingWentWrongCard actionCallback={refetchDataCallback} />
                            ) : (
                                <>
                                    <AboutSkillInfoBox variant={isLgDown ? 'label' : 'body2'}>
                                        {descriptionMemo}
                                    </AboutSkillInfoBox>
                                    {videoUrl && showNav && !isSmDown && (
                                        <AboutSkillVideoBox>
                                            <VidstackVideo
                                                videoUrl={
                                                    videoUrl && isFullLink(videoUrl)
                                                        ? videoUrl
                                                        : fullVideoUrls?.videoUrl
                                                }
                                                captionUrl={fullVideoUrls?.captionUrl}
                                                videoImage={
                                                    videoImage && isFullLink(videoImage)
                                                        ? videoImage
                                                        : createUrl(videoImage)?.generatedUrl || ''
                                                }
                                                contentId={contentId}
                                                videoWatchedCallback={videoWatchedCallback}
                                                chaptersUrl={fullVideoUrls?.chaptersUrl}
                                            />
                                        </AboutSkillVideoBox>
                                    )}
                                </>
                            )}
                        </AboutSkillBox>
                    )
                )}
            </RootPaper>
        </RootBox>
    );
};

export default AboutCard;
