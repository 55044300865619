import { FC, useEffect, useRef, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import {
    FiltersBox,
    RootBox,
    HeaderActionBox,
    DownloadReportBox,
    ScoreLegendItem,
    ScoreLegendBox,
    Dot,
    DotWrapper,
    AppsIconWrapper,
    AppIconImg,
    ScoreLegendTypography
} from './Style';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import Table from '../../../../../ui/general/table/Table';
import {
    EUserReportType,
    useInsightsOrganizationAssessmentStateValue
} from '../../../../../contexts/InsightsOrganizationalAssessmentContext';
import Dropdown, { IDropdownMenuItem } from '../../../../../ui/general/dropdown/Dropdown';
import PrimaryButton from '../../../../../ui/buttons/primary-button/PrimaryButton';
import ProgressGraph, { EValueSidePosition } from '../../../../../ui/general/progress-graph/ProgressGraph';
import { useCrumbsStateValue } from '../../../../../contexts/CrumbsContext';
import { makeInsightsAssessmentDetailsRoute } from '../../../../../pages/routes';
import { IAssessmentReportItemVM } from '../../../../../interfaces/views/IAssessmentReportItemVM';
import { sortNumber } from '../../../../../utils/sortNumber';
import { Order } from '../../../../../hooks/useTable';
import { useUserAuthStateValue } from '../../../../../contexts/UserAuthContext';
import AssessmentBenchmarkAdoptionLegendIcon from '../../../../../assets/icons/AssessmentBenhcmarkAdoptionLegendIcon';
import AssessmentBenchmarkScoreLegendIcon from '../../../../../assets/icons/AssessmentBenchmarkScoreLegendIcon';
import Tooltip from '../../../../../ui/general/tooltip/Tooltip';
import fetchImageErrorCallback from '../../../../../utils/fetchImageErrorCallback';
import loadImageGraduallyHandler from '../../../../../utils/loadImageGradually';
import sortPossibleUndefinedStrings from '../../../../../utils/sortPossibleUndefinedStrings';

interface ITableHeader {
    application: string;
    skillName: string;
    score: string;
    adoption: string;
}

const OrganizationalAssessmentTab: FC = () => {
    const [weeksFilterDropdownItems, setWeeksFilterDropdownItems] = useState<IDropdownMenuItem[]>([]);
    const [departmentsFilterDropdownItems, setDepartmentsFilterDropdownItems] = useState<IDropdownMenuItem[]>([]);
    const usersFilterDropdownItemsRef = useRef<IDropdownMenuItem[]>([
        {
            name: 'All Users',
            value: EUserReportType.ALL
        },
        {
            name: 'Users with Nulia Works',
            value: EUserReportType.WITH_LICENSE
        },
        {
            name: 'Users without Nulia Works',
            value: EUserReportType.WITHOUT_LICENSE
        }
    ]);

    const {
        data,
        isAssessmentDataLoading,
        isAssessmentReportLoading,
        assessmentReportData,
        handleWeeksFilterDropdownChange,
        handleDepartmentFilterDropdownChange,
        handleUserFilterDropdownChange,
        changeDepartmentFilterDropdownValue,
        changeWeekFilterDropdownValue,
        selectedWeeksFilterValue,
        selectedDepartmentFilterValue,
        selectedUserFilterValue,
        departments,
        downloadAssessmentReportAsCSV,
        isDownloadAssessmentLoading,
        isAssesmentDataFetchingError
    } = useInsightsOrganizationAssessmentStateValue();
    const { currentUserData } = useUserAuthStateValue();
    const theme = useTheme();
    const matchesLgDownBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { appendCrumb } = useCrumbsStateValue();

    useEffect(() => {
        if (assessmentReportData) {
            setWeeksFilterDropdownItems(
                assessmentReportData?.map((assessmentReport) => {
                    return {
                        name: format(parseISO(assessmentReport.reportDate), 'MMM do, y'),
                        value: assessmentReport.reportDate
                    };
                })
            );
            if (assessmentReportData.length > 0) changeWeekFilterDropdownValue(assessmentReportData[0].reportDate);
        }
    }, [assessmentReportData]);

    useEffect(() => {
        if (departments) {
            setDepartmentsFilterDropdownItems(
                departments?.map((department) => {
                    return {
                        name: department,
                        value: department
                    };
                })
            );
            if (departments.length > 0) changeDepartmentFilterDropdownValue(departments[0]);
        }
    }, [departments]);

    const generateAppIcon = (officeApp?: string) => {
        if (!officeApp) return <span>No image</span>;

        let preparedOfficeAppName = officeApp.toLowerCase() === 'exchange' ? 'Outlook' : officeApp;

        return (
            <Tooltip title={preparedOfficeAppName}>
                <AppIconImg
                    alt='app-icon'
                    src={`${process.env.REACT_APP_CDN_URL}appimages/icons/office365-color-${preparedOfficeAppName}.svg`}
                    onError={fetchImageErrorCallback}
                    onLoad={loadImageGraduallyHandler}
                />
            </Tooltip>
        );
    };

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'application',
            label: 'App',
            maxWidth: '30px',
            align: 'center',
            customRender: (value: string, row: IAssessmentReportItemVM) => {
                return <AppsIconWrapper>{generateAppIcon(row.application)}</AppsIconWrapper>;
            },
            customSort(a: IAssessmentReportItemVM, b: IAssessmentReportItemVM, order) {
                return sortPossibleUndefinedStrings(a.application, b.application, order);
            }
        },
        {
            id: 'skillName',
            label: 'Skill',
            customRender: (value: string) => {
                return <span className='skill-name'>{value}</span>;
            }
        },
        {
            id: 'score',
            label: 'Skill Score',
            minWidth: '120px',
            height: '50px',
            customRender: (_, row: IAssessmentReportItemVM) => {
                let benchmarkRange: number[] = [row.scoreTargetLow, row.scoreTargetHigh];
                let rating = 0;
                if (selectedUserFilterValue === EUserReportType.ALL) {
                    rating = row.scoreAll;
                } else if (selectedUserFilterValue === EUserReportType.WITH_LICENSE) {
                    rating = row.scoreLicensed;
                } else if (selectedUserFilterValue === EUserReportType.WITHOUT_LICENSE) {
                    rating = row.scoreUnlicensed;
                }
                return (
                    <ProgressGraph
                        chartId={`score-progress-${row.id}`}
                        benchmarkRange={benchmarkRange}
                        rating={rating}
                        valueSidePosition={EValueSidePosition.LEFT}
                        maxValue={135}
                        dottedSeparator
                        className='score-column'
                    />
                );
            },
            customColumnLegend: () => {
                return (
                    <ScoreLegendBox>
                        <ScoreLegendItem>
                            <DotWrapper>
                                <AssessmentBenchmarkScoreLegendIcon />
                            </DotWrapper>
                            <ScoreLegendTypography variant='label'>
                                {t('insights.organization.assessment.benchmarkRange')}
                            </ScoreLegendTypography>
                        </ScoreLegendItem>
                        <ScoreLegendItem>
                            <Dot big />
                            <ScoreLegendTypography variant='label'>
                                {currentUserData?.companyName || ''}
                            </ScoreLegendTypography>
                        </ScoreLegendItem>
                    </ScoreLegendBox>
                );
            },
            customSort: (a: IAssessmentReportItemVM, b: IAssessmentReportItemVM, order: Order) => {
                let aToSort = 0;
                let bToSort = 0;
                if (selectedUserFilterValue === EUserReportType.ALL) {
                    aToSort = a.scoreAll;
                    bToSort = b.scoreAll;
                } else if (selectedUserFilterValue === EUserReportType.WITH_LICENSE) {
                    aToSort = a.scoreLicensed;
                    bToSort = b.scoreLicensed;
                } else if (selectedUserFilterValue === EUserReportType.WITHOUT_LICENSE) {
                    aToSort = a.scoreUnlicensed;
                    bToSort = b.scoreUnlicensed;
                }
                return sortNumber(aToSort, bToSort, order);
            }
        },
        {
            id: 'adoption',
            label: 'Skill Adoption',
            minWidth: '130px',
            height: '50px',
            customRender: (_, row: IAssessmentReportItemVM) => {
                let benchmarkRange: number[] = [row.adoptionTarget];
                let rating = 0;
                if (selectedUserFilterValue === EUserReportType.ALL) {
                    rating = row.adoptionAll;
                } else if (selectedUserFilterValue === EUserReportType.WITH_LICENSE) {
                    rating = row.adoptionLicensed;
                } else if (selectedUserFilterValue === EUserReportType.WITHOUT_LICENSE) {
                    rating = row.adoptionUnlicensed;
                }
                return (
                    <ProgressGraph
                        chartId={`adoption-progress-${row.id}`}
                        benchmarkRange={benchmarkRange}
                        rating={rating}
                        valueSidePosition={EValueSidePosition.RIGHT}
                        valueSuffix='%'
                        className='adoption-column'
                    />
                );
            },
            customColumnLegend: () => {
                return (
                    <ScoreLegendBox>
                        <ScoreLegendItem>
                            <DotWrapper>
                                <AssessmentBenchmarkAdoptionLegendIcon />
                            </DotWrapper>
                            <ScoreLegendTypography variant='label'>
                                {t('insights.organization.assessment.benchmark')}
                            </ScoreLegendTypography>
                        </ScoreLegendItem>
                        <ScoreLegendItem>
                            <Dot big />
                            <ScoreLegendTypography variant='label'>
                                {currentUserData?.companyName || ''}
                            </ScoreLegendTypography>
                        </ScoreLegendItem>
                    </ScoreLegendBox>
                );
            },
            customSort: (a: IAssessmentReportItemVM, b: IAssessmentReportItemVM, order: Order) => {
                let aToSort = 0;
                let bToSort = 0;
                if (selectedUserFilterValue === EUserReportType.ALL) {
                    aToSort = a.adoptionAll;
                    bToSort = b.adoptionAll;
                } else if (selectedUserFilterValue === EUserReportType.WITH_LICENSE) {
                    aToSort = a.adoptionLicensed;
                    bToSort = b.adoptionLicensed;
                } else if (selectedUserFilterValue === EUserReportType.WITHOUT_LICENSE) {
                    aToSort = a.adoptionUnlicensed;
                    bToSort = b.adoptionUnlicensed;
                }
                return sortNumber(aToSort, bToSort, order);
            }
        }
    ];

    const handleTableRowClick = (_: string, obj: IAssessmentReportItemVM) => {
        const route = makeInsightsAssessmentDetailsRoute(obj.skillId);
        appendCrumb({
            name: 'Assessment',
            pathname: route
        });
        navigate(route);
    };

    return (
        <RootBox>
            <HeaderActionBox>
                <FiltersBox>
                    <Dropdown
                        items={weeksFilterDropdownItems}
                        value={selectedWeeksFilterValue}
                        handleChange={handleWeeksFilterDropdownChange}
                        disabled={!selectedDepartmentFilterValue}
                        id='date-dropdown'
                    />
                    <Dropdown
                        items={departmentsFilterDropdownItems}
                        value={selectedDepartmentFilterValue}
                        handleChange={handleDepartmentFilterDropdownChange}
                        disabled={!selectedDepartmentFilterValue}
                        id='department-dropdown'
                    />
                    <Dropdown
                        items={usersFilterDropdownItemsRef.current}
                        value={selectedUserFilterValue}
                        handleChange={handleUserFilterDropdownChange}
                        disabled={!selectedDepartmentFilterValue}
                        style={{
                            width: matchesLgDownBreakpoint ? 'unset' : '240px',
                            maxWidth: matchesLgDownBreakpoint ? 'unset' : '240px'
                        }}
                        id='user-dropdown'
                    />
                </FiltersBox>
                <DownloadReportBox id='download-report-box'>
                    <PrimaryButton
                        disabled={isDownloadAssessmentLoading || !selectedDepartmentFilterValue}
                        title={t('insights.organization.assessment.downloadReportButton')}
                        clickHandler={downloadAssessmentReportAsCSV}
                    />
                </DownloadReportBox>
            </HeaderActionBox>
            <Table<IAssessmentReportItemVM, ITableHeader>
                key={Math.random()}
                headCells={headCells}
                data={data}
                propertyKeys={headCells.map((headCell) => {
                    return headCell.id;
                })}
                tableTitlePlural=''
                isFilterControlVisible={false}
                isFilterDrawerOpen={false}
                isLoading={isAssessmentDataLoading || isAssessmentReportLoading || !selectedDepartmentFilterValue}
                onRowClick={handleTableRowClick}
                tableCellStyle={{ borderBottom: 0, fontSize: '16px', color: '#616063' }}
                initialOrderBy='skillName'
                customMediaColumnName='application'
                isError={isAssesmentDataFetchingError}
            />
        </RootBox>
    );
};

export default OrganizationalAssessmentTab;
