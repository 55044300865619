import { createContext, FC, useContext, useState, PropsWithChildren, useEffect, ChangeEvent } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export interface INavigationContext {
    showNav: boolean;
    setShowNav: (showNav: boolean) => void;
    pageName: string;
    setPageName: (pageName: string) => void;
    searchCallback: () => void;
    setSearchCallback: (callback: (e: ChangeEvent<HTMLInputElement>) => void) => void;
}

export const NavigationContext = createContext<INavigationContext>({} as INavigationContext);

interface IProps {}

export const NavigationProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [showNav, setShowNav] = useState<boolean>(true);
    const [pageName, setPageName] = useState<string>('');
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [searchCallback, setSearchCallback] = useState<any>();

    const navigationContext: INavigationContext = {
        showNav,
        setShowNav,
        pageName,
        setPageName,
        searchCallback,
        setSearchCallback
    };

    useEffect(() => {
        if (matches) {
            const contentWrapperHTML = document.getElementById('content-wrapper');
            if (contentWrapperHTML) {
                contentWrapperHTML.style.display = showNav ? 'none' : 'flex';
            }
        }
    }, [showNav]);

    return <NavigationContext.Provider value={navigationContext}>{children}</NavigationContext.Provider>;
};

export const useNavigationStateValue: () => INavigationContext = () => useContext(NavigationContext);
