import { styled } from '@mui/system';
import { ButtonBase, Popover } from '@mui/material';

export const UserInfo = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ isActive }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
    width: 38,
    height: 38,
    borderRadius: '50%',
    '&:hover': {
        backgroundColor: isActive ? 'unset' : 'rgba(0, 0, 0, 0.04)',
        borderRadius: '50%'
    },
    '&:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
}));

export const StyledAvatar = styled('img', {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ isActive, theme }) => ({
    height: 28,
    border: isActive ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.common.white}`,
    width: 28,
    borderRadius: 14
}));

export const StyledUserControl = styled('div')({
    width: 38,
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20
});

export const StyledPopover = styled(Popover)({
    '& .MuiPopover-paper': {
        width: 275,
        backgroundColor: '#FFF',
        padding: '16px !important',
        borderRadius: 4,
        boxShadow: '0 12.5px 128px 4px rgba(0,0,0,0.08), 0 24px 32px 2px rgba(0,0,0,0.08)',
        display: 'flex',
        flexDirection: 'column'
    }
});
