import { createContext, FC, useContext, PropsWithChildren, useState, useEffect } from 'react';
import { matchPath, useLocation, useParams } from 'react-router';
import { EInsightsMode } from '../interfaces/enums/EInsightsMode';
import { routes } from '../pages/routes';

export interface IInsightsContext {
    mode: EInsightsMode;
    userName: string;
    userId: string;
    changeMode: (mode: EInsightsMode) => void;
    setUserName: (name: string) => void;
    setUserId: (userId: string) => void;
    selectedOutcomeName: string | undefined;
    setSelectedOutcomeName: (outcomeName: string | undefined) => void;
    selectedSkillName: string | undefined;
    setSelectedSkillName: (skillName: string | undefined) => void;
    selectedBadgeName: string | undefined;
    setSelectedBadgelName: (badgeName: string | undefined) => void;
}

export const InsightsContext = createContext<IInsightsContext>({} as IInsightsContext);

interface IProps {}

export const InsightsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [mode, setMode] = useState<EInsightsMode>(EInsightsMode.STANDARD);
    const [userName, setUserName] = useState<string>('');
    const [userId, setUserId] = useState<string>('');
    const [selectedOutcomeName, setSelectedOutcomeName] = useState<string | undefined>();
    const [selectedSkillName, setSelectedSkillName] = useState<string | undefined>();
    const [selectedBadgeName, setSelectedBadgelName] = useState<string | undefined>();
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        if (params && params.id) {
            setUserId(params.id);
            setMode(EInsightsMode.ORG_PERSONAL);
            if (
                matchPath(routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS, location.pathname) ||
                matchPath(routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS, location.pathname)
            ) {
                setMode(EInsightsMode.ORG_PERSONAL_OUTCOME_DETAILS);
            }
        } else {
            setUserId('');
            setMode(EInsightsMode.STANDARD);
        }
    }, [params]);

    const changeMode = (mode: EInsightsMode) => {
        setMode(mode);
    };

    const insightsContext: IInsightsContext = {
        mode,
        changeMode,
        userName,
        setUserName,
        userId,
        setUserId,
        selectedOutcomeName,
        setSelectedOutcomeName,
        selectedSkillName,
        setSelectedSkillName,
        selectedBadgeName,
        setSelectedBadgelName
    };

    return <InsightsContext.Provider value={insightsContext}>{children}</InsightsContext.Provider>;
};

export const useInsightsStateValue: () => IInsightsContext = () => useContext(InsightsContext);
