import { FC, useRef, useState } from 'react';
import Dialog from '../dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { StyledForm, StyledSelect } from './Style';
import { FormControl, FormHelperText, MenuItem } from '@mui/material';
import { useAccountsStateValue } from '../../../contexts/AccountsContext';
import { EToastSeverity, useToastContextStateValue } from 'nulia-ui';

interface Props {
    open: boolean;
    onClose: () => void;
}

const MoveAccountDialog: FC<Props> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        reset,
        formState: { isValid },
        control,
        handleSubmit
    } = useForm();
    const formRef = useRef<HTMLFormElement>(null);
    const { customersData, resellersData } = useAccountsStateValue();
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const { setToastMessage } = useToastContextStateValue();

    const handleAccept = async () => {
        handleSubmitForm();
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const handleSubmitForm = () => {
        setSubmitting(true);
        setTimeout(() => {
            setToastMessage({
                isOpen: true,
                message: 'Account is moved',
                severity: EToastSeverity.INFO
            });
            setSubmitting(false);
            onClose();
        }, 300);
    };

    const resetForm = () => {
        reset();
    };

    return (
        <Dialog
            open={open}
            onAccept={handleAccept}
            acceptText={t('accounts.moveAccount.confirmButton')}
            onClose={handleClose}
            cancelText={t('accounts.moveAccount.cancelButton')}
            title={t('accounts.moveAccount.title')}
            isSubmitActionDisabled={!isValid || isSubmitting}
            minHeight='200px'
            content={
                <div>
                    <StyledForm ref={formRef} onSubmit={handleSubmit(handleSubmitForm)}>
                        <FormControl fullWidth>
                            <FormHelperText style={{ marginLeft: 0 }}>
                                {t('accounts.moveAccount.resellerInput')}
                            </FormHelperText>
                            <Controller
                                name={'reseller'}
                                control={control}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <StyledSelect id='reseller-select' value={field.value} onChange={field.onChange}>
                                        {resellersData?.map((reseller) => {
                                            return (
                                                <MenuItem key={reseller.id} value={reseller.id}>
                                                    {reseller.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </StyledSelect>
                                )}
                            />
                            <FormControl fullWidth>
                                <FormHelperText style={{ marginLeft: 0 }}>
                                    {t('accounts.moveAccount.accountInput')}
                                </FormHelperText>
                                <Controller
                                    name={'account'}
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field }) => (
                                        <StyledSelect id='account-select' value={field.value} onChange={field.onChange}>
                                            {customersData?.map((customer) => {
                                                return (
                                                    <MenuItem key={customer.id} value={customer.id}>
                                                        {customer.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </StyledSelect>
                                    )}
                                />
                            </FormControl>
                        </FormControl>
                    </StyledForm>
                </div>
            }
        />
    );
};

export default MoveAccountDialog;
