import styled from '../../../../../ui/theme/styled';

export const GraphBox = styled('div')({
    width: '100%',
    minHeight: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
});
