import { FC, useCallback, useEffect, useState, KeyboardEvent, useMemo } from 'react';
import { Switch, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import { useParams } from 'react-router';
import BadgeCard from '../badge-card/BadgeCard';
import Loading from '../../general/loading/Loading';
import { formatISOString } from '../../../utils/dateUtil';
import { EBadgeLevel } from '../../../interfaces/enums/EBadgeLevel';
import { ESkillLevel } from '../../../interfaces/enums/ESkillLevel';
import { IBadgeSkillLevel } from '../../../interfaces/IBadgeSkillLevel';
import useExpand from '../../../hooks/useExpand';
import ToExpandIconSVG from '../../../assets/icons/ToExpandIcon';
import ToCollapseIconSVG from '../../../assets/icons/ToCollapseIcon';
import Tooltip from '../../general/tooltip/Tooltip';
import {
    AboutBadgeBox,
    BadgeHeaderBox,
    BadgeHeaderTitle,
    BadgeIconSpan,
    BadgeTabsWrapper,
    EmptyLineHr,
    ExpandedCollapsedBox,
    HeaderBadgeSpan,
    HeaderBox,
    InnerBadgeHeaderContent,
    LineHr,
    MasterBox,
    MasterLabelBox,
    MasterSwitchBox,
    RootBox,
    RootPaper,
    TitleErrorSpan
} from './Style';
import BlueWarningIcon from '../../../assets/icons/BlueWarningIcon';
import SomethingWentWrongCard from '../something-went-wrong-card/SomethingWentWrongCard';
import UserNotAchieved from '../../../assets/icons/badge-statuses/UserNotAchieved';
import UserClaimed from '../../../assets/icons/badge-statuses/UserClaimed';
import MasterNotAchieved from '../../../assets/icons/badge-statuses/MasterNotAchieved';
import MasterClaimed from '../../../assets/icons/badge-statuses/MasterClaimed';
import MasterNeedsAttention from '../../../assets/icons/badge-statuses/MasterNeedsAttention';
import MasterExpired from '../../../assets/icons/badge-statuses/MasterExpired';
import MasterAvailableToClaim from '../../../assets/icons/badge-statuses/MasterAvailableToClaim';
import ProducerNotAchieved from '../../../assets/icons/badge-statuses/ProducerNotAchieved';
import ProducerClaimed from '../../../assets/icons/badge-statuses/ProducerClaimed';
import ProducerNeedsAttention from '../../../assets/icons/badge-statuses/ProducerNeedsAttention';
import ProducerExpired from '../../../assets/icons/badge-statuses/ProducerExpired';
import ProducerAvailableToClaim from '../../../assets/icons/badge-statuses/ProducerAvailableToClaim';
import { accessibilityEnterKeyCallback } from '../../../utils/accessibilityUtils';
import { useOutcomeStateValue } from '../../../contexts/OutcomeContext';

interface IProps {
    badges?: IBadgeSkillLevel[];
    isLoading: boolean;
    isMasterTargetDisabled?: boolean;
    isOutcomeAssigned: boolean;
    isMasterLevelRequired?: boolean;
    isError: boolean;
    errorRefetchCallback: () => void;
    assignmentLevel?: ESkillLevel;
    outcomeId?: number;
}

const BadgeListCard: FC<IProps> = ({
    badges,
    isLoading,
    isMasterTargetDisabled,
    isOutcomeAssigned,
    isMasterLevelRequired,
    isError,
    errorRefetchCallback,
    assignmentLevel,
    outcomeId
}) => {
    const [visibleBadgeIndex, setVisibleBadgeIndex] = useState<number>(0);
    const { collapse, expand, isExpanded } = useExpand({ defaultExpanded: true });
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { toggleMasterTarget, isPostMasterOutcomeLoading } = useOutcomeStateValue();

    const params = useParams<{ id?: string; badgeId?: string }>();

    useEffect(() => {
        if (params && params.badgeId && badges) {
            const activeBadgeIndex = parseInt(params.badgeId) - 1;
            if (activeBadgeIndex > 0 && activeBadgeIndex < badges.length) setVisibleBadgeIndex(activeBadgeIndex);
        }
    }, [params, badges]);

    const findStatusIconCallback = useCallback(
        (badgeSkillLevel: IBadgeSkillLevel) => {
            const { badge, level } = badgeSkillLevel;
            if (level === ESkillLevel.USER) {
                if (badge.state === EBadgeLevel.NONE || badge.state === EBadgeLevel.EARNED) return <UserNotAchieved />;
                else if (badge.state === EBadgeLevel.CLAIMED) return <UserClaimed />;
                return <UserNotAchieved />;
            } else {
                // if (isMasterLevelRequired && level === ESkillLevel.MASTER) return <BlueWarningIcon />;
                if (level === ESkillLevel.MASTER) {
                    switch (badge.state) {
                        case EBadgeLevel.NONE:
                            return <MasterNotAchieved />;
                        case EBadgeLevel.CLAIMED:
                            return <MasterClaimed />;
                        case EBadgeLevel.NEEDS_ATTENTION:
                            return <MasterNeedsAttention />;
                        case EBadgeLevel.EXPIRED:
                            return <MasterExpired />;
                        case EBadgeLevel.EARNED:
                            return <MasterAvailableToClaim />;
                    }
                }
                if (level === ESkillLevel.PRODUCER) {
                    switch (badge.state) {
                        case EBadgeLevel.NONE:
                            return <ProducerNotAchieved />;
                        case EBadgeLevel.CLAIMED:
                            return <ProducerClaimed />;
                        case EBadgeLevel.NEEDS_ATTENTION:
                            return <ProducerNeedsAttention />;
                        case EBadgeLevel.EXPIRED:
                            return <ProducerExpired />;
                        case EBadgeLevel.EARNED:
                            return <ProducerAvailableToClaim />;
                    }
                }
            }
            return '';
        },
        [isMasterLevelRequired]
    );

    const handleBadgeClick = (index: number) => {
        setVisibleBadgeIndex(index);
    };

    const onMasterCheckedChange = (e: any, value: boolean) => {
        toggleMasterTarget(value);
    };

    const generateBadgeIconTooltip = (badgeSkillLevel: IBadgeSkillLevel) => {
        switch (badgeSkillLevel.badge.state) {
            case EBadgeLevel.NONE:
                if (isOutcomeAssigned) return t('badges.notEarned');
                return t('badges.notAssigned');
            case EBadgeLevel.CLAIMED:
                return t('badges.claimed');
            case EBadgeLevel.EARNED:
                return t('badges.earned');
            case EBadgeLevel.NEEDS_ATTENTION:
                return t('badges.needsAttention');
            case EBadgeLevel.EXPIRED:
                return t('badges.expired');
        }
    };

    const masterBoxMemo = useMemo(() => {
        if (!badges || badges.length === 0 || !badges.find((badge) => badge.level === ESkillLevel.MASTER)) return <></>;
        return (
            <MasterBox isFullWidth={isLgDown} id='master-level-toggle'>
                <MasterSwitchBox isFullWidth={isLgDown && !isMdDown}>
                    {isMasterLevelRequired && (
                        <Tooltip title={t('tooltips.masterLevelRequested')}>
                            <BlueWarningIcon />
                        </Tooltip>
                    )}
                    <MasterLabelBox>{t('components.badgeCard.target')}</MasterLabelBox>
                    <Switch
                        color='secondary'
                        checked={assignmentLevel === ESkillLevel.MASTER}
                        onChange={onMasterCheckedChange}
                        disabled={isPostMasterOutcomeLoading || !isOutcomeAssigned}
                        onKeyDown={(e: KeyboardEvent<any>) =>
                            accessibilityEnterKeyCallback(e, () =>
                                onMasterCheckedChange(e, !(assignmentLevel === ESkillLevel.MASTER))
                            )
                        }
                        inputProps={{ role: 'switch', 'aria-label': 'Master toggle switch' }}
                    />
                </MasterSwitchBox>
            </MasterBox>
        );
    }, [
        isPostMasterOutcomeLoading,
        isOutcomeAssigned,
        onMasterCheckedChange,
        isLgDown,
        isMdDown,
        isMasterLevelRequired
    ]);

    return (
        <RootBox className='badge-list-card'>
            <RootPaper elevation={1}>
                <HeaderBox className='badge-list-header'>
                    <BadgeTabsWrapper>
                        {isError || isLoading ? (
                            <TitleErrorSpan>Badges</TitleErrorSpan>
                        ) : (
                            badges?.map((badgeSkillLevel: IBadgeSkillLevel, index) => {
                                return (
                                    <BadgeHeaderBox key={badgeSkillLevel.badge.id}>
                                        <BadgeHeaderTitle variant='subtitle1' isActive={visibleBadgeIndex === index}>
                                            <span>
                                                <InnerBadgeHeaderContent>
                                                    <HeaderBadgeSpan
                                                        isActive={visibleBadgeIndex === index}
                                                        onClick={() => handleBadgeClick(index)}
                                                        onKeyDown={(e: KeyboardEvent<any>) =>
                                                            accessibilityEnterKeyCallback(e, () =>
                                                                handleBadgeClick(index)
                                                            )
                                                        }
                                                        tabIndex={0}
                                                    >
                                                        <Tooltip title={generateBadgeIconTooltip(badgeSkillLevel)}>
                                                            <BadgeIconSpan id={`badge-icon-${index}`}>
                                                                {findStatusIconCallback(badgeSkillLevel)}
                                                            </BadgeIconSpan>
                                                        </Tooltip>
                                                        {badgeSkillLevel.level}
                                                    </HeaderBadgeSpan>
                                                </InnerBadgeHeaderContent>
                                                {visibleBadgeIndex === index ? <LineHr /> : <EmptyLineHr />}
                                            </span>
                                        </BadgeHeaderTitle>
                                    </BadgeHeaderBox>
                                );
                            })
                        )}
                    </BadgeTabsWrapper>
                    {masterBoxMemo}
                    <ExpandedCollapsedBox onClick={isExpanded ? collapse : expand}>
                        {isExpanded ? <ToCollapseIconSVG /> : <ToExpandIconSVG />}
                    </ExpandedCollapsedBox>
                </HeaderBox>
                {isLoading || !badges ? (
                    <Loading />
                ) : (
                    <>
                        {((badges && badges.length > 0) || isError) && (
                            <AboutBadgeBox>
                                {isError && isExpanded ? (
                                    <SomethingWentWrongCard actionCallback={errorRefetchCallback} />
                                ) : (
                                    isExpanded && (
                                        <BadgeCard
                                            level={badges[visibleBadgeIndex].level}
                                            claimed={formatISOString(badges[visibleBadgeIndex].badge.claimedDate)}
                                            isOutcomeAssigned={isOutcomeAssigned}
                                            badgeImage={badges[visibleBadgeIndex].badge.image}
                                            verified={formatISOString(badges[visibleBadgeIndex].badge.verifiedDate)}
                                            status={badges[visibleBadgeIndex].badge.state}
                                            statInfo={`${badges[visibleBadgeIndex].badge.companyPercent}% of your organization has achieved this level`}
                                            assignmentLevel={assignmentLevel}
                                            userBadgeUrl={badges[visibleBadgeIndex].badge.userBadgeUrl}
                                            outcomeId={outcomeId}
                                        />
                                    )
                                )}
                            </AboutBadgeBox>
                        )}
                    </>
                )}
            </RootPaper>
        </RootBox>
    );
};

export default BadgeListCard;
