import { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

const SelectIcon: FC = (props) => (
    <SvgIcon {...props} viewBox='0 0 16 16'>
        <path
            d='M4.47140452,6.19526215 C4.21105499,5.93491262 3.78894501,5.93491262 3.52859548,6.19526215 C3.26824595,6.45561167 3.26824595,6.87772166 3.52859548,7.13807119 L7.52859548,11.1380712 C7.78894501,11.3984207 8.21105499,11.3984207 8.47140452,11.1380712 L12.4714045,7.13807119 C12.731754,6.87772166 12.731754,6.45561167 12.4714045,6.19526215 C12.211055,5.93491262 11.788945,5.93491262 11.5285955,6.19526215 L8,9.72385763 L4.47140452,6.19526215 Z'
            fillOpacity='0.4'
            fill='#000000'
            fillRule='nonzero'
        />
    </SvgIcon>
);

export default SelectIcon;
