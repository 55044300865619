import { ChangeEvent, FC, useState } from 'react';
import { Box, Collapse, makeStyles, Typography } from '@material-ui/core';
import { useStore } from '../../../store/StoreProvider';
import { useApiService } from '../../../services/ApiServiceProvider';
import { RegExpEmailPattern, RegExpGuidPattern } from '../../../utils/constants';
import ChevronDownIcon from '../../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../../icons/ChevronUpIcon';
import { PrimaryButton } from 'nulia-ui';
import Select from '../../../components/Select';
import SelectItem from '../../../components/SelectItem';
import { Colors } from '../../../Colors';
import Input from '../../../Input';

const useStyles = makeStyles({
    addPartner: {
        width: '100%',
        // width: 1088,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        marginBottom: 24
    },
    header: {
        height: 64,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer'
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    body: {
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
        padding: 24,
        paddingTop: 16
    },
    sku: {
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        paddingBottom: 16,
        marginBottom: 16
    },
    countPrice: {
        display: 'flex'
    },
    countInput: {
        display: 'flex',
        alignItems: 'baseline'
    },
    countInputHelper: {
        width: 100
    },
    label: {
        marginBottom: 4
    }
});

const AddPartnerUser: FC = () => {
    const classes = useStyles();
    const [closed, setClosed] = useState<boolean>(false);
    const [reseller, setReseller] = useState<string>('');
    const [oid, setOid] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [oidError, setOidError] = useState<boolean>(false);
    const [nameError, setNameError] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { user, setPartners, partners } = useStore();
    const ApiService = useApiService();
    const regExpGuid = new RegExp(RegExpGuidPattern);
    const regExpEmail = new RegExp(RegExpEmailPattern);

    const toggleClosed = () => setClosed(!closed);

    const onChangeReseller = (event: ChangeEvent<{ value: unknown }>) => setReseller(event.target.value as string);
    const onChangeOid = (event: ChangeEvent<{ value: unknown }>) => setOid(event.target.value as string);
    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value as string);
    const onChangeEmail = (event: ChangeEvent<{ value: unknown }>) => setEmail(event.target.value as string);

    const handleAccept = async () => {
        setSubmitting(true);
        let hasOidError = false;
        let hasNameError = false;
        let hasEmailError = false;
        if (oid === '' || !regExpGuid.test(oid)) {
            hasOidError = true;
        }
        if (name === '') {
            hasNameError = true;
        }
        if (email === '' || !regExpEmail.test(email)) {
            hasEmailError = true;
        }
        if (user && !hasOidError && !hasNameError && !hasEmailError) {
            await ApiService.addPartnerUser(reseller, oid, name, email);
            const response = await ApiService.getPartners();
            if (!response) {
                return;
            }
            setPartners(response.data);
            setOid('');
            setName('');
            setEmail('');
        }
        setSubmitting(false);
        setOidError(hasOidError);
        setNameError(hasNameError);
        setEmailError(hasEmailError);
    };

    const Label: FC<React.PropsWithChildren> = ({ children }) => (
        <Typography variant='body1' className={classes.label}>
            {children}
        </Typography>
    );

    return (
        <div className={classes.addPartner}>
            <div className={classes.header} onClick={toggleClosed}>
                <Typography variant='h6' className={classes.title}>
                    Add Partner User
                </Typography>
                {closed ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </div>
            <Collapse in={closed}>
                <div className={classes.body}>
                    <Box marginBottom={2}>
                        <Label>Reseller</Label>
                        {partners && (
                            <Select value={reseller} onChange={onChangeReseller}>
                                {partners.map((partner) => (
                                    <SelectItem key={partner.id} value={partner.id}>
                                        {partner.name}
                                    </SelectItem>
                                ))}
                            </Select>
                        )}
                    </Box>
                    <Box marginBottom={2}>
                        <Label>OID</Label>
                        <Input value={oid} onChange={onChangeOid} style={{ width: '50%' }} error={oidError} />
                    </Box>
                    <Box marginBottom={2}>
                        <Label>Name</Label>
                        <Input value={name} onChange={onChangeName} style={{ width: '50%' }} error={nameError} />
                    </Box>
                    <Box marginBottom={2}>
                        <Label>Email</Label>
                        <Input value={email} onChange={onChangeEmail} style={{ width: '50%' }} error={emailError} />
                    </Box>
                    <PrimaryButton clickHandler={handleAccept} disabled={submitting} style={{ width: 100 }}>
                        SUBMIT
                    </PrimaryButton>
                </div>
            </Collapse>
        </div>
    );
};

export default AddPartnerUser;
