import { Paper, Typography } from '@mui/material';
import styled from '../../../ui/theme/styled';

export const GraphCardWrapper = styled('div')(({ theme }) => ({
    minWidth: '100%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export const ActivitiesBox = styled('div')<{ isBestPracticeMode?: boolean }>(({ isBestPracticeMode = false }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: isBestPracticeMode ? '10px 10px 10px 20px' : 0
}));

export const ActivitiesInnerBox = styled('div')<{ isBestPracticeMode?: boolean }>(
    ({ isBestPracticeMode = false, theme }) => ({
        flex: 1,
        display: isBestPracticeMode ? 'grid' : 'flex',
        gridTemplateColumns: isBestPracticeMode ? '1fr 1fr' : 'auto',
        flexDirection: 'column',
        gap: isBestPracticeMode ? 28 : 16,
        transition: 'all 300ms',
        opacity: 1,
        '&.preAnimation': {
            opacity: 0,
            maxHeight: 0
        },
        [theme.breakpoints.down(800)]: {
            gap: isBestPracticeMode ? 12 : 16,
            gridTemplateColumns: 'auto'
        }
    })
);

export const AboutSkillContentWrapper = styled('div')(({ theme }) => ({
    padding: '39px 14px 20px 14px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('lg')]: {
        padding: '12px 20px'
    }
}));

export const RootBox = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    paddingLeft: '20px',
    paddingRight: '20px'
}));

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: 1080,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}));

export const BehaviorsBox = styled('div')({
    flex: 1
});

export const SkillDetailsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 16
});

export const BehaviorsActivitiesWrapper = styled('div')<{ isBestPracticeMode?: boolean }>(
    ({ theme, isBestPracticeMode = false }) => ({
        display: 'flex',
        flexDirection: isBestPracticeMode ? 'column' : 'row',
        gap: 32,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    })
);

export const BehaviorsTitleBox = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 20,
    paddingBottom: 12,
    paddingLeft: 25,
    marginBottom: 16,
    fontFamily: 'Ubuntu'
}));

export const ActivitiesTitleSpan = styled('span')(({ theme }) => ({
    fontSize: 20,
    fontFamily: 'Ubuntu'
}));

export const BehaviorsTitleSpanLgDown = styled('span')({
    fontSize: 20,
    fontFamily: 'Ubuntu'
});

export const SkillsInfoPaper = styled(Paper)({
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    width: '100%'
});

export const ActivitiesTabWrapper = styled('div')({
    display: 'flex',
    gap: '4px',
    alignItems: 'flex-end'
});

export const ActivitiesTabTitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    paddingBottom: 12
}));

export const ActivitiesTabCountSpan = styled('span')(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.25px',
    paddingBottom: '1px',
    [theme.breakpoints.down('md')]: {
        fontSize: '12px'
    }
}));

export const SeeAllWrapper = styled('div')({
    lineHeight: '48px'
});

export const SeeAllTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
    }
}));

export const TabsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
});
