import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/system';
import TextButton from '../../../../ui/buttons/text-button/TextButton';
import useConfirmModal from '../../../../hooks/useConfirmModal';
import ConfirmDialog from '../../../../ui/general/confirm-dialog/ConfirmDialog';
import { ELicenseAssignmentType } from '../../../../interfaces/enums/ELicenseAssignmentType';
import { useLicensesStateValue } from '../../../../contexts/LicensesContext';

interface EnhancedTableToolbarProps {
    numberOfSelectedUsers: number;
    selected: string[];
}

const LicenseTableToolbar: FC<EnhancedTableToolbarProps> = ({ numberOfSelectedUsers, selected }) => {
    const theme = useTheme();
    const {
        isOpen: isBulkAssignLicenseDialogOpen,
        open: openBulkAssignLicenseDialog,
        close: closeBulkAssignLicenseDialog
    } = useConfirmModal({ defaultOpen: false });
    const {
        isOpen: isBulkRemoveLicenseDialogOpen,
        open: openBulkRemoveLicenseDialog,
        close: closeBulkRemoveLicenseDialog
    } = useConfirmModal({ defaultOpen: false });
    const { t } = useTranslation();
    const { bulkAssignmentLicenses } = useLicensesStateValue();

    const onBulkRemoveLicenseClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        openBulkRemoveLicenseDialog();
    };

    const onBulkAssignLicenseClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        openBulkAssignLicenseDialog();
    };

    const onConfirmBulkAssignLicense = useCallback(async () => {
        bulkAssignmentLicenses(selected, ELicenseAssignmentType.ASSIGN);
        closeBulkAssignLicenseDialog();
    }, [selected]);

    const onCancelBulkAssignLicense = useCallback(() => {
        closeBulkAssignLicenseDialog();
    }, []);

    const onConfirmBulkRemoveLicense = useCallback(async () => {
        bulkAssignmentLicenses(selected, ELicenseAssignmentType.UNASSIGN);
        closeBulkRemoveLicenseDialog();
    }, [selected]);

    const onCancelBulkRemoveLicense = useCallback(() => {
        closeBulkRemoveLicenseDialog();
    }, []);

    return (
        <>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    backgroundColor: theme.palette.common.white,
                    gap: '40px',
                    flex: 10,
                    flexWrap: 'wrap'
                }}
            >
                {numberOfSelectedUsers > 0 && (
                    <>
                        <TextButton onClick={onBulkAssignLicenseClick} mode='primary'>
                            {t('licenses.addLicenses')}
                        </TextButton>
                        <TextButton onClick={onBulkRemoveLicenseClick} mode='primary'>
                            {t('licenses.removeLicenses')}
                        </TextButton>
                    </>
                )}
            </Toolbar>
            <ConfirmDialog
                title={t('licenses.bulkAssignLicenseConfirmModal.title')}
                description={t('licenses.bulkAssignLicenseConfirmModal.description')}
                isOpen={isBulkAssignLicenseDialogOpen}
                handleConfirm={onConfirmBulkAssignLicense}
                handleClose={onCancelBulkAssignLicense}
            />
            <ConfirmDialog
                title={t('licenses.bulkRemoveLicenseConfirmModal.title')}
                description={t('licenses.bulkRemoveLicenseConfirmModal.description')}
                isOpen={isBulkRemoveLicenseDialogOpen}
                handleConfirm={onConfirmBulkRemoveLicense}
                handleClose={onCancelBulkRemoveLicense}
            />
        </>
    );
};

export default LicenseTableToolbar;
