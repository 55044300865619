import { styled } from '@mui/system';

export const TableCard = styled('div')(({ theme }) => ({
    width: 1040,
    borderRadius: 4,
    boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.common.white,
    paddingTop: 24
}));

export const PartnersContentBox = styled('div')({
    marginTop: 16
});
