import styled from '../../theme/styled';
import { Typography } from '@mui/material';
import { pulseKeyFrame } from '../../../css/CommonComponents';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    ':focus': {
        outlineStyle: 'auto',
        outlineColor: 'rgba(0,0,0,0.54)'
    },
    [theme.breakpoints.down('lg')]: {
        padding: '20px 0'
    },
    [theme.breakpoints.down('md')]: {
        '&:hover': {
            backgroundColor: theme.palette.background.default,
            cursor: 'pointer'
        },
        padding: '20px 0'
    }
}));

export const ImageBox = styled('div')({
    maxWidth: 140
});

export const InfoBox = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        paddingBottom: '20px'
    }
}));

export const InfoTextBox = styled(Typography)(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '16px',
    flex: 1,
    [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        height: '60px',
        display: 'flex',
        alignItems: 'center'
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '14px'
    }
}));

export const AppsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '14%',
    [theme.breakpoints.down('lg')]: {
        marginRight: 0
    },
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap'
    }
}));

export const AppIconWrapperBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: 18,
    [theme.breakpoints.down('lg')]: {
        alignItems: 'center'
    }
}));

export const AppIconImg = styled('img')({
    width: 26,
    height: 26
});

export const ShowDescriptionBox = styled('div')(({ theme }) => ({
    fontFamily: 'Ubuntu',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    letterSpacing: '1.25px',
    lineHeight: '40px',
    fontSize: 10,
    fontWeight: 500,
    textTransform: 'uppercase',
    padding: '0px 6px',
    borderRadius: '3px',
    minWidth: 140,
    textAlign: 'center',
    '&:hover': {
        textDecoration: 'underline'
    },
    [theme.breakpoints.down('lg')]: {
        textAlign: 'start',
        paddingLeft: 18,
        lineHeight: 'unset',
        minWidth: 'unset'
    }
}));

export const FooterActionBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 8
});

export const HeartIconWrapper = styled('span', {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ isActive, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    animation: isActive ? `${pulseKeyFrame} 0.6s 4 alternate` : 'none',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        justifyContent: 'center'
    }
}));

export const MasterLevelRequestedWrapper = styled('span')({
    display: 'flex',
    alignItems: 'center'
});

export const FirstRowBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '2px',
    cursor: 'pointer',
    minHeight: '136px',
    '&:hover': {
        backgroundColor: theme.palette.background.default
    },
    [theme.breakpoints.down('lg')]: {
        minHeight: '100px'
    },
    [theme.breakpoints.down('md')]: {
        minHeight: '40px'
    }
}));

export const CollapsableRowBox = styled('div')({
    padding: '10px 16px 16px 12px'
});

export const Description = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.88)'
});

export const InfoAssignedControlWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isPaddingNeeded'
})<{ isPaddingNeeded: boolean }>(({ isPaddingNeeded, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: isPaddingNeeded ? 20 : 4,
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'row',
        flexWrap: 'nowrap'
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        paddingBottom: 0
    }
}));

export const MdDownActionControlRow = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start'
    }
}));

export const MdDownChartDescriptionBox = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

export const MdHeartWrapper = styled('div')({
    display: 'flex',
    paddingTop: '20px'
});

export const DescriptionSegment = styled('div')({
    paddingTop: '4px'
});
