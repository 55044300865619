import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import NSelect from '../../ui/form/n-select/NSelect';
import PrimaryButton from '../../ui/buttons/primary-button/PrimaryButton';
import NTextArea from '../../ui/form/n-text-area/NTextArea';
import {
    RootBox,
    StyledForm,
    FormCard,
    HeaderDescription,
    HeaderTitle,
    HeaderSubtitle,
    SubmitBox,
    InfoTextBox,
    HeaderSuccessTypography
} from './Style';
import { usePostSendFeedback } from '../../services/FeedbackQueryService';
import { EToastSeverity, useToastContextStateValue } from '../../contexts/ToastContext';

enum EFeedbackFormState {
    BEFORE_SEND = 'prepare',
    SENT = 'sent'
}

const Feedback: FC = () => {
    const {
        control,
        handleSubmit,
        formState: { isValid },
        setValue,
        reset
    } = useForm({
        defaultValues: {
            sourcePage: 'User Interface in General',
            feedback: ''
        }
    });
    const { t } = useTranslation();
    const location = useLocation();
    const {
        mutateAsync: mutatePostSendFeedbackAsync,
        isError: isPostSendFeedbackError,
        isSuccess: isPostSendFeedbackSuccess,
        isPending: isPostSendFeedbackLoading
    } = usePostSendFeedback();
    const { setToastMessage } = useToastContextStateValue();
    const [feedbackFormState, setFeedbackFormState] = useState<EFeedbackFormState>(EFeedbackFormState.BEFORE_SEND);

    useEffect(() => {
        if (location.state && location.state.from) {
            let fromPage = location.state.from;
            if (!fromPage) return;
            const fromPageSplitParts = fromPage.split('/');

            if (fromPageSplitParts && fromPageSplitParts.length > 1 && fromPageSplitParts[1] === 'skill')
                fromPage = '/skill';
            if (fromPageSplitParts && fromPageSplitParts.length > 1 && fromPageSplitParts[1] === 'outcome')
                fromPage = '/outcome';
            setValue('sourcePage', fromPage);
        }
    }, [location]);

    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (isPostSendFeedbackSuccess) {
            setToastMessage({
                isOpen: true,
                message: t('success.feedback.successSendFeedback'),
                severity: EToastSeverity.SUCCESS
            });
            setFeedbackFormState(EFeedbackFormState.SENT);
            reset();
        } else {
            setFeedbackFormState(EFeedbackFormState.BEFORE_SEND);
        }
    }, [isPostSendFeedbackSuccess]);

    useEffect(() => {
        if (isPostSendFeedbackError) {
            setToastMessage({
                isOpen: true,
                message: t('errors.feedback.errorSendFeedback'),
                severity: EToastSeverity.ERROR
            });
        }
    }, [isPostSendFeedbackError]);

    const handleSendFeedback = async (data: any) => {
        const { sourcePage, feedback } = data;
        await mutatePostSendFeedbackAsync({
            sourcePage,
            feedback
        });
    };

    const submitAnotherFeedbackHandler = () => {
        setFeedbackFormState(EFeedbackFormState.BEFORE_SEND);
    };

    const headerContentMemo = useMemo(() => {
        if (feedbackFormState === EFeedbackFormState.SENT)
            return (
                <HeaderSuccessTypography variant='subtitle1'>{t('feedback.successMessage')}</HeaderSuccessTypography>
            );
        return (
            <>
                <HeaderTitle variant='subtitle1'>{t('feedback.headerTitle')}</HeaderTitle>
                <HeaderDescription variant='caption'>{t('feedback.headerDescription')}</HeaderDescription>
                <HeaderSubtitle variant='subtitle2'>{t('feedback.headerSubtitle')}</HeaderSubtitle>
            </>
        );
    }, [feedbackFormState]);

    return (
        <RootBox>
            <StyledForm onSubmit={handleSubmit(handleSendFeedback)} ref={formRef}>
                <FormCard>
                    <InfoTextBox>{headerContentMemo}</InfoTextBox>
                    <Controller
                        name={'sourcePage'}
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={({ field }) => {
                            return (
                                <NSelect
                                    onChange={field.onChange}
                                    value={field.value || ''}
                                    field={field}
                                    options={[
                                        { value: 'Broken Feature', label: 'Broken Feature' },
                                        { value: '/home', label: 'Home Page' },
                                        { value: 'Notifications', label: 'Notifications' },
                                        { value: 'Other', label: 'Other' },
                                        { value: '/outcomes/user', label: 'Outcomes Page' },
                                        { value: '/outcomes/history', label: 'Outcomes History Page' },
                                        { value: 'Recommendations', label: 'Recommendations' },
                                        { value: 'Scoring', label: 'Scoring' },
                                        { value: '/skills', label: 'Skills Page' },
                                        { value: '/outcome', label: 'Specific Outcome' },
                                        { value: '/skill', label: 'Specific Skill' },
                                        { value: 'User Interface in General', label: 'User Interface in General' }
                                    ]}
                                />
                            );
                        }}
                    />
                    <Controller
                        name={'feedback'}
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={({ field }) => (
                            <NTextArea
                                onChange={field.onChange}
                                value={field.value}
                                placeholder={'Tell me more'}
                                field={field}
                            />
                        )}
                    />
                    <SubmitBox>
                        {feedbackFormState === EFeedbackFormState.BEFORE_SEND && (
                            <PrimaryButton
                                disabled={!isValid || isPostSendFeedbackLoading}
                                title='Send feedback'
                                style={{ letterSpacing: '1.25px' }}
                                clickHandler={() => {
                                    formRef?.current?.dispatchEvent(
                                        new Event('submit', { cancelable: true, bubbles: true })
                                    );
                                }}
                            />
                        )}
                    </SubmitBox>
                </FormCard>
            </StyledForm>
            {feedbackFormState === EFeedbackFormState.SENT && (
                <div>
                    <PrimaryButton
                        title='Submit another'
                        style={{ letterSpacing: '1.25px' }}
                        clickHandler={submitAnotherFeedbackHandler}
                    />
                </div>
            )}
        </RootBox>
    );
};

export default Feedback;
