import { FC, useCallback, useState } from 'react';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import Connection from './connection/Connection';
import { RootBox } from './Style';
import { EConnectionStatus } from '../../interfaces/enums/EConnectionStatus';
import { EAccountType } from '../../interfaces/enums/EAccountType';
import Administrators from './administrators/Administrators';
import Activity from './activity/Activity';
import { useAccountDetailsStateValue } from '../../contexts/AccountDetailsContext';

const AccountDetails: FC = () => {
    const [connectionStatus, setConnectionStatus] = useState<EConnectionStatus>(EConnectionStatus.ACTIVE);
    const { activityData } = useAccountDetailsStateValue();

    const changeConnectionStatus = useCallback((newConnectionStatus: EConnectionStatus) => {
        setConnectionStatus(newConnectionStatus);
    }, []);

    return (
        <Page>
            <PageContent>
                <RootBox>
                    <Connection
                        connectionStatus={connectionStatus}
                        changeConnectionStatus={changeConnectionStatus}
                        defaultExpanded
                        accountType={EAccountType.CUSTOMER}
                    />
                    <Administrators
                        administratorList={[
                            {
                                email: 'email1@gmail.com',
                                id: '1',
                                name: 'Dusko Dimitric',
                                status: 'Pending'
                            },
                            {
                                email: 'email2@gmail.com',
                                id: '2',
                                name: 'Nikola Mirkov',
                                status: 'Active'
                            }
                        ]}
                    />
                    <Activity data={activityData} />
                </RootBox>
            </PageContent>
        </Page>
    );
};

export default AccountDetails;
