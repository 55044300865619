import { FC, useState, ChangeEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'nulia-ui';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import {
    ActionBox,
    ContactContainer,
    FeedbackSentBox,
    FeedbackSentMessageTypography,
    RootBox,
    StyledTextArea
} from './Style';
import { usePostSendFeedback } from '../../services/FeedbackQueryService';

const Contact: FC = () => {
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const { t } = useTranslation();
    const { mutateAsync: sendFeedback } = usePostSendFeedback();

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value);

    const submit = (event: MouseEvent) => {
        event.preventDefault();
        sendFeedback({ feedback: message, sourcePage: '' });
        setSubmitted(true);
    };

    const goBack = () => {
        setMessage('');
        setSubmitted(false);
    };

    return (
        <Page>
            <PageContent>
                <RootBox>
                    {!isSubmitted ? (
                        <ContactContainer>
                            <StyledTextArea
                                placeholder={t('contact.textfieldInputPlaceholder')}
                                onChange={onChange}
                                value={message}
                            />
                            <ActionBox>
                                <PrimaryButton
                                    type='submit'
                                    clickHandler={submit}
                                    style={{ width: 124 }}
                                    disabled={message === ''}
                                    title={t('contact.submitButton')}
                                ></PrimaryButton>
                            </ActionBox>
                        </ContactContainer>
                    ) : (
                        <FeedbackSentBox>
                            <FeedbackSentMessageTypography>
                                {t('contact.feedbackSubmitted')}
                            </FeedbackSentMessageTypography>
                            <PrimaryButton clickHandler={goBack} style={{ width: 100, margin: 'auto' }}>
                                {t('contact.goBackButton')}
                            </PrimaryButton>
                        </FeedbackSentBox>
                    )}
                </RootBox>
            </PageContent>
        </Page>
    );
};

export default Contact;
