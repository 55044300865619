import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Skeleton, Switch } from '@mui/material';
import {
    RootBox,
    HeaderTitle,
    ActionBox,
    ContentBox,
    InfoBox,
    InfoTextBox,
    MasterBox,
    MasterLabelBox,
    MasterSwitchBox,
    AddControlWrapper,
    AddControlWrapperInfoBox,
    AssignedByTypography,
    AssignedDateTypography,
    CompetenceTitle
} from './Style';
import { ERecommendationType } from '../../../../interfaces/enums/ERecommendationType';
import { IRecommendationVM } from '../../../../interfaces/views/IRecommendationVM';
import useRecommendation from '../../../../hooks/useRecommendation';
import { formatDate } from '../../../../utils/dateUtil';
import PrimaryButton from '../../../../ui/buttons/primary-button/PrimaryButton';
import { makeOutcomeRoute, makeSkillRoute } from '../../../../pages/routes';
import { useCrumbsStateValue } from '../../../../contexts/CrumbsContext';

interface IProps {
    recommendation?: IRecommendationVM;
    isVisible?: boolean;
    isLoading?: boolean;
    successRecommendationCallback?: () => void;
}

const RecommendationSimpleCardItem: FC<IProps> = ({
    recommendation: { type, competenceTitle, apps, targetId, id } = {},
    isVisible,
    isLoading = false,
    successRecommendationCallback
}) => {
    const {
        recommendationActionCallback,
        isActionLoading,
        isActionSuccess,
        isAssignOutcomeSuccess,
        isAssignSkillSuccess,
        headerCardTypeTitle
    } = useRecommendation({
        recommendationId: id,
        recommendationType: type,
        targetId,
        successRecommendationCallback
    });
    const navigate = useNavigate();
    const { appendCrumb } = useCrumbsStateValue();

    const actionControlMemo = useMemo(() => {
        switch (type) {
            case ERecommendationType.UPGRADE_OUTCOME:
            case ERecommendationType.UPGRADE_SKILL:
                return (
                    <MasterBox>
                        <MasterLabelBox>Master</MasterLabelBox>
                        <MasterSwitchBox>
                            <Switch
                                disabled={isActionLoading || isActionSuccess}
                                color='secondary'
                                checked={isActionSuccess}
                                onChange={() => {
                                    recommendationActionCallback(true);
                                }}
                                aria-label='Switch to Master Level'
                                inputProps={{ 'aria-label': 'Switch to' }}
                            />
                        </MasterSwitchBox>
                    </MasterBox>
                );
            case ERecommendationType.ADD_OUTCOME:
            case ERecommendationType.ADD_SKILL:
                if (isAssignOutcomeSuccess || isAssignSkillSuccess) {
                    return (
                        <AddControlWrapperInfoBox>
                            <AssignedByTypography variant='label'>Assigned by you</AssignedByTypography>
                            <AssignedDateTypography variant='label'>{formatDate(new Date())}</AssignedDateTypography>
                        </AddControlWrapperInfoBox>
                    );
                }
                return (
                    <AddControlWrapper>
                        <PrimaryButton
                            title='Assign'
                            disabled={isActionLoading || isActionSuccess}
                            clickHandler={() => {
                                if (!isActionLoading) recommendationActionCallback(true);
                            }}
                        />
                    </AddControlWrapper>
                );
            default:
                return <></>;
        }
    }, [
        type,
        recommendationActionCallback,
        isActionLoading,
        isActionSuccess,
        isAssignOutcomeSuccess,
        isAssignSkillSuccess
    ]);

    const titleMemo = useMemo(() => {
        return competenceTitle ? (
            <CompetenceTitle
                onClick={() => {
                    if (targetId) {
                        if (type === ERecommendationType.UPGRADE_SKILL || type === ERecommendationType.ADD_SKILL) {
                            const skillRoute = makeSkillRoute(targetId);
                            appendCrumb({
                                name: competenceTitle || 'Skills',
                                pathname: skillRoute
                            });
                            navigate(skillRoute);
                        } else {
                            const outcomeRoute = makeOutcomeRoute(targetId);
                            appendCrumb({
                                name: competenceTitle || 'Outcomes',
                                pathname: outcomeRoute
                            });
                            navigate(outcomeRoute);
                        }
                    }
                }}
            >
                {competenceTitle}
            </CompetenceTitle>
        ) : (
            <Skeleton variant='text' />
        );
    }, [competenceTitle, targetId]);

    return (
        <RootBox isVisible={(isVisible || isLoading) ?? false}>
            <HeaderTitle variant='subtitle2'>
                {isLoading ? <Skeleton variant='text' /> : headerCardTypeTitle}
            </HeaderTitle>
            <ContentBox>
                <InfoBox>
                    <InfoTextBox variant='label'>
                        {competenceTitle ? titleMemo : <Skeleton variant='text' />}
                    </InfoTextBox>
                </InfoBox>
                <ActionBox>
                    {isLoading ? <Skeleton variant='rectangular' width={100} height={30} /> : actionControlMemo}
                </ActionBox>
            </ContentBox>
        </RootBox>
    );
};

export default RecommendationSimpleCardItem;
