import { FC, useEffect } from 'react';
import { Outlet } from 'react-router';
import { ITab, useTabsStateValue } from '../../contexts/TabsContext';
import { routes } from '../../pages/routes';
import { useUserAuthStateValue } from '../../contexts/UserAuthContext';
import { isUserAllowedToAccess } from '../../pages/routeRules';
import { ERoute } from '../../interfaces/enums/ERoute';
import { RootBox } from './Style';
import usePageName from '../../hooks/usePageName';

const Settings: FC = () => {
    const { setTabs } = useTabsStateValue();
    const { currentUserData } = useUserAuthStateValue();
    usePageName({ pageName: 'Settings' });

    useEffect(() => {
        if (!currentUserData) {
            setTabs([]);
            return;
        }
        const tabs: ITab[] = [
            {
                id: 1,
                title: 'My Own',
                fullTitle: 'My Own Settings',
                path: routes.SETTINGS_PERSONAL,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.SETTINGS_PERSONAL,
                    currentUserData.roles,
                    currentUserData.permissions
                ),
                showLastUpdatedInfo: false
            },
            {
                id: 2,
                title: 'Organization',
                fullTitle: 'Organizational settings',
                path: routes.SETTINGS_ORGANIZATIONAL,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.SETTINGS_ORGANIZATIONAL,
                    currentUserData.roles,
                    currentUserData.permissions
                ),
                showLastUpdatedInfo: false
            }
        ];
        setTabs(tabs.filter((tab) => tab.isUserAllowedToAccess));
        return () => {
            setTabs([]);
        };
    }, [currentUserData]);

    return (
        <RootBox>
            <Outlet />
        </RootBox>
    );
};

export default Settings;
