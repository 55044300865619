import { styled, Typography } from '@mui/material';

export const TextInputRootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column'
}));

export const ErrorTypography = styled(Typography)(({ theme }) => ({
    color: '#B00020 !important',
    fontSize: '11px'
}));
