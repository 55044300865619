import {
    ActionBox,
    ActionButtonBox,
    ActionTitle,
    ActiveConnectionBox,
    CategoryWrapper,
    ConnectionDateTypography,
    ExpandedCollapsedBox,
    InfoBox,
    PaperHeaderBox,
    RootPaper,
    StatusCircle,
    TitleTypography
} from './Style';
import { Loading, useExpand, ToCollapseIcon, ToExpandIcon, PrimaryButton, Tooltip, SecondaryButton } from 'nulia-ui';
import { useAccountDetailsStateValue } from '../../../contexts/AccountDetailsContext';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { EConnectionStatus } from '../../../interfaces/enums/EConnectionStatus';
import ConnectToTenantDialog from './connect-to-tenant-dialog/ConnectToTenantDialog';
import SendConnectInstructionsDialog from './send-connect-instructions-dialog/SendConnectInstructionsDialog';
import { EAccountType } from '../../../interfaces/enums/EAccountType';

interface IProps {
    connectionStatus: EConnectionStatus;
    changeConnectionStatus: (newConnectionStatus: EConnectionStatus) => void;
    accountType: EAccountType;
    defaultExpanded: boolean;
}

const Connection: FC<IProps> = ({ connectionStatus, accountType, defaultExpanded, changeConnectionStatus }) => {
    const { isAccountDetailsDataLoading } = useAccountDetailsStateValue();
    const { collapse, expand, isExpanded } = useExpand({
        defaultExpanded: defaultExpanded
    });
    const [openSendInstructionsDialog, setOpenSendInstructionsDialog] = useState<boolean>(false);
    const [openConnectDialog, setOpenConnectDialog] = useState<boolean>(false);
    const isReconnectModeRef = useRef<boolean>(false);

    useEffect(() => {
        if (connectionStatus === EConnectionStatus.ACTIVE) {
            isReconnectModeRef.current = true;
        }
    }, [connectionStatus]);

    const toggleTenantDialog = () => {
        setOpenConnectDialog((openConnectDialog) => !openConnectDialog);
    };

    const toggleSendInstructionsDialog = () => {
        setOpenSendInstructionsDialog((openSendInstructionsDialog) => !openSendInstructionsDialog);
    };

    const connectionStatusColorMemo = useMemo(() => {
        switch (connectionStatus) {
            case EConnectionStatus.ACTIVE:
                return '#b0cb3e';
            case EConnectionStatus.DEACTIVATED:
                return 'red';
            case EConnectionStatus.IN_PROGRESS:
                if (isReconnectModeRef.current) {
                    return '#b0cb3e';
                }
                return '#b0cb3e';
            case EConnectionStatus.NEEDS_ATTENTION:
                return 'yellow';
            default:
                return 'gray';
        }
    }, [connectionStatus]);

    const tooltipTitleMemo = useMemo(() => {
        switch (connectionStatus) {
            case EConnectionStatus.ACTIVE:
                return 'Complete';
            default:
                if (isReconnectModeRef.current) {
                    return 'Complete';
                }
                return 'Not Complete';
        }
    }, [connectionStatus]);

    const contentMemo = useMemo(() => {
        switch (connectionStatus) {
            case EConnectionStatus.IN_PROGRESS:
                return (
                    <>
                        <ActionBox>
                            <ActionTitle>I am the administrator for this tenant</ActionTitle>
                            <ActionButtonBox>
                                <PrimaryButton
                                    style={{ width: '176px' }}
                                    title='Connect'
                                    clickHandler={toggleTenantDialog}
                                />
                            </ActionButtonBox>
                        </ActionBox>
                        <ActionBox>
                            <ActionTitle>I am NOT the administrator for this tenant</ActionTitle>
                            <ActionButtonBox>
                                <PrimaryButton
                                    style={{ width: '196px' }}
                                    title='Send Instructions'
                                    clickHandler={toggleSendInstructionsDialog}
                                />
                            </ActionButtonBox>
                        </ActionBox>
                    </>
                );
            case EConnectionStatus.ACTIVE:
                return (
                    <ActiveConnectionBox>
                        <ConnectionDateTypography>
                            Successfully connected on <strong>Sep 1, 2020</strong>
                        </ConnectionDateTypography>
                        <SecondaryButton
                            onClick={() => {
                                changeConnectionStatus(EConnectionStatus.IN_PROGRESS);
                            }}
                            style={{ width: '170px' }}
                        >
                            Reconnect
                        </SecondaryButton>
                    </ActiveConnectionBox>
                );
            default:
                return <></>;
        }
    }, [connectionStatus, isExpanded]);

    return (
        <RootPaper elevation={1}>
            <PaperHeaderBox>
                <InfoBox>
                    <Tooltip title={tooltipTitleMemo}>
                        <StatusCircle color={connectionStatusColorMemo} />
                    </Tooltip>
                    <TitleTypography variant='h6'>Connection</TitleTypography>
                </InfoBox>
                <ExpandedCollapsedBox tabIndex={0} onClick={isExpanded ? collapse : expand}>
                    {isExpanded ? <ToExpandIcon /> : <ToCollapseIcon />}
                </ExpandedCollapsedBox>
            </PaperHeaderBox>
            {isAccountDetailsDataLoading ? (
                <Loading />
            ) : isExpanded ? (
                <CategoryWrapper isContentLoading={isAccountDetailsDataLoading}>{contentMemo}</CategoryWrapper>
            ) : (
                <></>
            )}

            <ConnectToTenantDialog
                accountType={accountType}
                open={openConnectDialog}
                onClose={toggleTenantDialog}
                accountAppId={22}
                accountId={33}
            />
            <SendConnectInstructionsDialog
                accountType={accountType}
                open={openSendInstructionsDialog}
                onClose={toggleSendInstructionsDialog}
            />
        </RootPaper>
    );
};

export default Connection;
