import { FC, useRef, useState } from 'react';
import Dialog from '../dialog/Dialog';
import { StyledForm, StyledSelect } from './Style';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, MenuItem } from '@mui/material';
import { useAccountsStateValue } from '../../../contexts/AccountsContext';
import { ERegion } from '../../../interfaces/enums/ERegion';
import { EToastSeverity, NTextInput, useToastContextStateValue } from 'nulia-ui';
import { Controller, useForm } from 'react-hook-form';

interface Props {
    open: boolean;
    onClose: () => void;
}

const AddCustomer: FC<Props> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const { resellersData } = useAccountsStateValue();
    const {
        control,
        handleSubmit,
        reset,
        formState: { isValid, errors }
    } = useForm({
        mode: 'onChange'
    });
    const { setToastMessage } = useToastContextStateValue();
    const formRef = useRef<HTMLFormElement>(null);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const handleSubmitForm = () => {
        setSubmitting(true);
        setTimeout(() => {
            setToastMessage({
                isOpen: true,
                message: 'Customer is saved.',
                severity: EToastSeverity.INFO
            });
            onClose();
            setSubmitting(false);
        }, 100);
    };

    const handleAccept = async () => {
        handleSubmitForm();
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const resetForm = () => {
        reset();
    };

    return (
        <Dialog
            open={open}
            onAccept={handleAccept}
            acceptText={t('accounts.addCustomer.confirmButton')}
            isSubmitActionDisabled={!isValid || isSubmitting}
            onClose={handleClose}
            cancelText={t('accounts.addCustomer.cancelButton')}
            title={t('accounts.addCustomer.title')}
            content={
                <div>
                    <StyledForm onSubmit={handleSubmit(handleSubmitForm)} ref={formRef}>
                        <FormControl fullWidth>
                            <Controller
                                name={'customerName'}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: {
                                        message: 'Maximum length is 50',
                                        value: 50
                                    }
                                }}
                                render={({ field }) => (
                                    <NTextInput
                                        field={field}
                                        label={t('accounts.addCustomer.customerNameInput')}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['customerName']?.message as string}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <FormHelperText style={{ marginLeft: 0 }}>
                                {t('accounts.addCustomer.resellerInput')}
                            </FormHelperText>
                            <Controller
                                name={'reseller'}
                                control={control}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <StyledSelect id='reseller-select' value={field.value} onChange={field.onChange}>
                                        {resellersData?.map((reseller) => {
                                            return (
                                                <MenuItem key={reseller.id} value={reseller.id}>
                                                    {reseller.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </StyledSelect>
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <FormHelperText style={{ marginLeft: 0 }}>
                                {t('accounts.addCustomer.regionInput')}
                            </FormHelperText>
                            <Controller
                                name={'region'}
                                control={control}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) => (
                                    <StyledSelect id='region-select' value={field.value} onChange={field.onChange}>
                                        <MenuItem key='0' value={ERegion.US}>
                                            US
                                        </MenuItem>
                                        <MenuItem key='1' value={ERegion.EUROPE}>
                                            Europe
                                        </MenuItem>
                                        <MenuItem key='2' value={ERegion.UK}>
                                            UK
                                        </MenuItem>
                                        <MenuItem key='3' value={ERegion.AUSTRALIA}>
                                            Australia
                                        </MenuItem>
                                    </StyledSelect>
                                )}
                            />
                        </FormControl>
                    </StyledForm>
                </div>
            }
        />
    );
};

export default AddCustomer;
