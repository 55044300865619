import { Typography } from '@mui/material';
import styled from '../../ui/theme/styled';

export const MainRoot = styled('div')({
    display: 'flex',
    justifyContent: 'center'
});

export const DocumentBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 1px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.08)',
    borderRadius: 5,
    padding: '15px 30px',
    width: '100%',
    maxWidth: '1040px'
}));

export const TitleBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0'
});

export const PrimaryTitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontFamily: 'Ubuntu',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px'
}));

export const Image = styled('img')(({ theme }) => ({
    maxHeight: 500,
    boxShadow: '0 1px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.08)',
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
        maxHeight: 300
    },
    [theme.breakpoints.down('xs')]: {
        maxHeight: 200
    }
}));

export const ImageContainerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        textAlign: 'center'
    }
}));

export const MainDescriptionTypography = styled('span')({
    fontSize: '14px',
    lineHeight: '30px',
    color: '#666666',
    paddingBottom: '10px',
    letterSpacing: '0.25px'
});

export const TutorialUlRoot = styled('ul')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontFamily: 'Open Sans',
    fontSize: 14,
    letterSpacing: 0.25,
    lineHeight: '30px',
    paddingBottom: 10,
    [theme.breakpoints.down('md')]: {
        display: 'block'
    }
}));

export const TutorialLi = styled('li')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderTop: `2px solid ${theme.palette.primary.main}`,
    margin: 0,
    padding: 0,
    width: '100%'
}));

export const BoldSpan = styled('span')({
    fontWeight: 600
});

export const StackBox = styled('div')({
    display: 'block',
    width: '100%'
});

export const TutorialUl = styled('ul')({
    listStyle: 'disc'
});
