import styled from '../../theme/styled';

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'valueSidePosition'
})<{ valueSidePosition: 'left' | 'right' }>(({ valueSidePosition }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingLeft: valueSidePosition === 'right' ? '20px' : 0
}));

export const RatingBox = styled('div')(({ theme }) => ({
    paddingRight: 4,
    color: theme.palette.primary.main,
    fontWeight: 500,
    minWidth: 34,
    fontSize: 14,
    letterSpacing: '1.25px'
}));

export const LeftRatingBox = styled(RatingBox, {
    shouldForwardProp: (prop) => prop !== 'paddingRight'
})<{ paddingRight?: number }>(({ paddingRight }) => ({
    paddingRight: paddingRight || '40px'
}));

export const RightRatingBox = styled(RatingBox, {
    shouldForwardProp: (prop) => prop !== 'paddingLeft'
})<{ paddingLeft?: number }>(({ paddingLeft }) => ({
    minWidth: '90px',
    paddingLeft: paddingLeft || '40px'
}));
