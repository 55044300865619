import BasicStatusCard from '../../../components/basic-status-card/BasicStatusCard';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ContentRootBox, MenuItemTitleSpan, StyledMenuItem, StyledSelect, TableAdditionalHeader } from './Style';
import { ITableHeadCell, Table } from 'nulia-ui';
import { IActivityVM } from '../../../interfaces/views/IActivityVM';
import { EActivityType } from '../../../interfaces/enums/EActivityType';
import { Input, SelectChangeEvent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ChevronDown } from 'react-feather';

interface IProps {
    data?: IActivityVM[];
}

interface ITableHeader {
    timestamp: string;
    activityType: string;
    activityBy: string;
    message: string;
}

export interface IDropdownMenuItem {
    value: string | number;
    name: string | number;
}

enum EActivityFilter {
    ALL = 'All',
    REMOVE_ADMIN = EActivityType.REMOVE_ADMIN,
    ADD_ADMIN = EActivityType.ADD_ADMIN,
    CHANGE_NAME = EActivityType.CHANGE_NAME
}

const activityFilterMenuItems: IDropdownMenuItem[] = [
    {
        value: EActivityFilter.ALL,
        name: 'All'
    },
    {
        value: EActivityFilter.REMOVE_ADMIN,
        name: 'Remove Admin'
    },
    {
        value: EActivityFilter.ADD_ADMIN,
        name: 'Add Admin'
    },
    {
        value: EActivityFilter.CHANGE_NAME,
        name: 'Change Name'
    }
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 210
        }
    }
};

const Activity: FC<IProps> = ({ data }) => {
    const selectRef = useRef();
    const [selectedFilterValue, setSelectedFilterValue] = useState<string[]>(['All']);
    const [filteredData, setFilteredData] = useState<IActivityVM[]>();

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'timestamp',
            label: 'Time'
        },
        {
            id: 'activityType',
            label: 'Activity Type',
            customRender(value, row: IActivityVM) {
                switch (row.activityType) {
                    case EActivityType.ADD_ADMIN:
                        return 'Add Admin';
                    case EActivityType.REMOVE_ADMIN:
                        return 'Remove Admin';
                    case EActivityType.CHANGE_NAME:
                        return 'Change Name';
                    default:
                        return '';
                }
            }
        },
        {
            id: 'activityBy',
            label: 'Activity By'
        },
        {
            id: 'message',
            label: 'Message'
        }
    ];

    const filterActivitiesByType = (activities: IActivityVM[], filters: string[]) => {
        const newFilteredActivityData = activities?.filter((activity) => {
            return selectedFilterValue.indexOf(activity.activityType) !== -1 || filters.includes('All');
        });
        return newFilteredActivityData;
    };

    const onActivityFilterChange = useCallback(() => {
        let newFilteredItems: IActivityVM[] = [];
        if (data) {
            newFilteredItems = filterActivitiesByType(data, selectedFilterValue);
            setFilteredData(newFilteredItems);
        }
    }, [selectedFilterValue, data]);

    useEffect(() => {
        onActivityFilterChange();
    }, [selectedFilterValue]);

    const handleChange = (event: SelectChangeEvent<typeof selectedFilterValue>) => {
        const {
            target: { value }
        } = event;
        setTimeout(() => {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '17px';
        }, 0);
        if (Array.isArray(value)) {
            if (value.includes('All')) {
                if (value.length > 1 && value[value.length - 1] !== 'All')
                    setSelectedFilterValue(value.filter((val) => val !== 'All'));
                else setSelectedFilterValue(['All']);
            } else {
                if (
                    value.length === 0 ||
                    (value.length === activityFilterMenuItems.length - 1 && !value.includes('All'))
                )
                    setSelectedFilterValue(['All']);
                else setSelectedFilterValue(value);
            }
        } else
            setSelectedFilterValue(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value
            );
    };

    return (
        <BasicStatusCard title='Activity'>
            <ContentRootBox>
                <TableAdditionalHeader>
                    <StyledSelect
                        placeholder='Filter'
                        multiple
                        ref={selectRef}
                        value={selectedFilterValue}
                        onChange={handleChange}
                        onOpen={() => {
                            setTimeout(() => {
                                document.body.style.overflow = 'hidden';
                                document.body.style.paddingRight = '17px';
                            }, 50);
                        }}
                        input={<Input disableUnderline />}
                        renderValue={(selected) => {
                            if (selected.length === 1 && selected[0] === 'All') return 'All filters';
                            else if (selected.length >= 1) return 'Active filters';
                            return undefined;
                        }}
                        MenuProps={MenuProps}
                        IconComponent={ChevronDown}
                        tabIndex={0}
                    >
                        {activityFilterMenuItems.map((activityFilterMenuItem) => {
                            const isChecked = selectedFilterValue.findIndex(
                                (filterValue) => filterValue === activityFilterMenuItem.value
                            );
                            return (
                                <StyledMenuItem
                                    sx={{ height: 32, paddingLeft: '4px' }}
                                    key={activityFilterMenuItem.value}
                                    value={activityFilterMenuItem.value}
                                >
                                    <Checkbox checked={isChecked !== -1} />
                                    <MenuItemTitleSpan>{activityFilterMenuItem.name}</MenuItemTitleSpan>
                                </StyledMenuItem>
                            );
                        })}
                    </StyledSelect>
                </TableAdditionalHeader>
                <Table headCells={headCells} data={filteredData} propertyKeys={headCells.map((cell) => cell.id)} />
            </ContentRootBox>
        </BasicStatusCard>
    );
};

export default Activity;
