import { EClientType } from '../interfaces/enums/EClientType';
import { FC, useState, createContext, PropsWithChildren } from 'react';

export interface ITokenContext {
    ssoToken?: string;
    setSsoToken: (ssoToken?: string) => void;
    expire: Date | null;
    setExpire: (expire: Date | null) => void;
    oid: string;
    setOid: (oid: string) => void;
    tid: string;
    setTid: (tid: string) => void;
    clientType?: EClientType;
    setClientType: (clientType?: EClientType) => void;
}

export const TokenContext = createContext<ITokenContext>({} as ITokenContext);

interface IProps {
    propToken?: string;
    propOid?: string;
    prodTid?: string;
    propClientType?: EClientType;
}

export const TokenProvider: FC<PropsWithChildren<IProps>> = ({
    propToken,
    propOid,
    prodTid,
    propClientType,
    children
}) => {
    const [ssoToken, setSsoToken] = useState<string | undefined>(propToken);
    const [expire, setExpire] = useState<Date | null>(null);
    const [oid, setOid] = useState<string>(propOid ?? '');
    const [tid, setTid] = useState<string>(prodTid ?? '');
    const [clientType, setClientType] = useState<EClientType | undefined>(propClientType);

    const tokenContext: ITokenContext = {
        ssoToken,
        setSsoToken,
        expire,
        setExpire,
        oid,
        setOid,
        tid,
        setTid,
        clientType,
        setClientType
    };

    return <TokenContext.Provider value={tokenContext}>{children}</TokenContext.Provider>;
};
