import { FC, useEffect, useMemo, useState } from 'react';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import { useStore } from '../../store/StoreProvider';
import { useApiService } from '../../services/ApiServiceProvider';
// import UsageSummary from './usage-summary/UsageSummary';
import { CardBox, CustomersBox, FilterByTypography, FiltersBox, HeaderActionBox, MainContentBox } from './Style';
import AccountsTable from './table/AccountsTable';
import { useAccountsStateValue } from '../../contexts/AccountsContext';
import UsageSummarySimple from './usage-summary-simple/UsageSummarySimple';
import AddButton from '../../components/buttons/account-add-button/AccountAddButton';
import { SelectChangeEvent } from '@mui/material';
import { Dropdown } from 'nulia-ui';

const Accounts: FC = () => {
    const { user, accounts, setAccounts } = useStore();
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const ApiService = useApiService();
    const [resellerFilter, setResellerFilter] = useState<string | 'All Customers'>('All Customers');
    // const getAccountData = useGetAccountData();
    const {
        customersData,
        licenseSummaryData,
        isLicenseSummaryDataLoading,
        isLicenseSummaryDataFetchingError,
        refetchLicenseSummaryDataCallback,
        resellersData,
        isCustomersDataLoading,
        refetchUsageSummary
    } = useAccountsStateValue();

    const onChangeResellerFilter = (event: SelectChangeEvent<unknown>) => {
        let resellerId: string | undefined = event.target.value as string;
        setResellerFilter(resellerId);
        if ('All Customers' === resellerId) resellerId = undefined;
        refetchUsageSummary(resellerId);
    };

    useEffect(() => {
        let stop = false;
        if (user && !retrievedData && !stop) {
            setRetrievedData(true);
            // getAccountData(user.resellerId);
        }
        return () => {
            stop = true;
        };
    }, [ApiService, accounts, retrievedData, setAccounts, user]);

    const licenseSummaryMemo = useMemo(() => {
        return (
            <UsageSummarySimple
                isError={isLicenseSummaryDataFetchingError}
                isLoading={licenseSummaryData === undefined || isLicenseSummaryDataLoading}
                allocatedNuliaLicenses={licenseSummaryData?.nuliaLicensesAllocated || 0}
                assignedNuliaLicenses={licenseSummaryData?.nuliaLicensesAssigned || 0}
                unassignedNuliaLicenses={licenseSummaryData?.nuliaLicensesUnassigned || 0}
                totalOfficeLicenses={licenseSummaryData?.o365LicensedUsers || 0}
                totalOfficeWithNoLicenses={licenseSummaryData?.o365UsersWithoutNuliaLicenses || 0}
                totalNumberOfCustomers={licenseSummaryData?.totalCustomers || 0}
                totalNumberOfResellers={licenseSummaryData?.totalResellers || 0}
                refetchDataCallback={refetchLicenseSummaryDataCallback}
            />
        );
    }, [isLicenseSummaryDataLoading, isLicenseSummaryDataFetchingError, licenseSummaryData]);

    const filtersMemo = useMemo(() => {
        return (
            <Dropdown
                items={[
                    { value: 'All Customers', name: 'All Customers' },
                    ...(resellersData || []).map((reseller) => {
                        return {
                            value: reseller.id,
                            name: reseller.name
                        };
                    })
                ]}
                value={resellerFilter}
                handleChange={onChangeResellerFilter}
                defaultValue={resellerFilter}
            />
        );
    }, [resellersData, resellerFilter]);

    return (
        <Page>
            <PageContent>
                <HeaderActionBox>
                    <AddButton resellerId='22' resellerName='Name' />
                </HeaderActionBox>
                <MainContentBox>
                    <CustomersBox>
                        {licenseSummaryMemo}
                        <CardBox>
                            <AccountsTable
                                isLoading={isCustomersDataLoading}
                                customersData={customersData}
                                resellerFilter={resellerFilter}
                            />
                        </CardBox>
                    </CustomersBox>
                    <FiltersBox>
                        <FilterByTypography>Filter by:</FilterByTypography>
                        {filtersMemo}
                    </FiltersBox>
                </MainContentBox>
            </PageContent>
        </Page>
    );
};

export default Accounts;
