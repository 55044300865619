import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const RootPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)'
    // borderRadius: theme.borderRadius?.outside?.small
}));

export const PaperHeaderBox = styled('div')(({ theme }) => ({
    padding: '8px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        padding: '4px 25px'
    }
}));

export const InfoBox = styled('div')({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: '40px',
    gap: '16px'
});

export const TitleTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Ubuntu',
    fontSize: 20,
    lineHeight: '24px',
    color: 'rgba(0,0,0,0.8)',
    letterSpacing: '0.25px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '14px'
    }
}));

export const CategoryWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isContentLoading'
})<{ isContentLoading?: boolean }>(({ theme, isContentLoading = false }) => ({
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px 24px 24px 24px',
    [theme.breakpoints.down('lg')]: {
        padding: '16px'
    }
}));

export const StatusCircle = styled('span', {
    shouldForwardProp: (prop) => prop !== 'color'
})<{ color?: string }>(({ color }) => ({
    backgroundColor: color,
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '12px'
}));

export const ExpandedCollapsedBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: 20,
    padding: '12px 8px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.background.default
    }
}));

export const ActionBox = styled('div')({
    border: '1px solid rgba(0, 0, 0, 0.12)',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    padding: '24px'
});

export const ActionTitle = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    lineHeight: '1.5',
    letterSpacing: '0.5px'
});

export const ActionButtonBox = styled('div')({});

export const ConnectionDateTypography = styled(Typography)({
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontFamily: 'Open Sans',
    lineHeight: 1.5,
    letterSpacing: '0.5px'
});

export const ActiveConnectionBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gap: '16px'
});
