import PrimaryButton from '../../../ui/buttons/primary-button/PrimaryButton';
import { ActionBox, ContentWrapper, DescriptionBox, MessageBox, MessageTypography, RootBox } from '../Style';
import { useNavigate } from 'react-router';
import { routes } from '../../../pages/routes';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
import { Typography } from '@mui/material';
import NavigationSidemenu from '../../../ui/navigation/navigation-sidemenu/NavigationSidemenu';
import { FC } from 'react';

interface IProps {
    title?: string;
    description?: string;
}

const SomethingWentWrong: FC<IProps> = ({ title, description }) => {
    const navigate = useNavigate();
    const { setCrumbs } = useCrumbsStateValue();

    const goBack = () => {
        navigate(routes.HOME);
        setCrumbs([]);
    };

    return (
        <RootBox applyPadding isOpen>
            <NavigationSidemenu isAlwaysOpen />
            <ContentWrapper isOpen>
                <MessageBox>
                    <MessageTypography variant='h4'>{title || 'Something Went Wrong.'}</MessageTypography>
                </MessageBox>
                <DescriptionBox>
                    <Typography variant='body1'>{description || 'Please, try again.'}</Typography>
                </DescriptionBox>
                <ActionBox>
                    <PrimaryButton title='Back' clickHandler={goBack} />
                </ActionBox>
            </ContentWrapper>
        </RootBox>
    );
};

export default SomethingWentWrong;
