import { IResourceDTO } from 'interfaces/dtos/IResourceDTO';
import { IAccountDTO } from '../interfaces/dtos/IAccountDTO';
import { IAccountVM } from '../interfaces/views/IAccountVM';
import { IResourceVM } from '../interfaces/views/IResourceVM';
import { EAccountStatus } from '../interfaces/enums/EAccountStatus';
import { EAccountType } from '../interfaces/enums/EAccountType';
import { ILicenseSummaryDTO } from '../interfaces/dtos/ILicenseSummaryDTO';
import { ILicenseSummaryVM } from '../interfaces/views/ILicenseSummaryVM';
import { IResellerDTO } from '../interfaces/dtos/IResellerDTO';
import { IResellerVM } from '../interfaces/views/IResellerVM';
import { ICustomerDTO } from '../interfaces/dtos/ICustomerDTO';
import { ICustomerVM } from '../interfaces/views/ICustomerVM';
import { ERegion } from '../interfaces/enums/ERegion';
import { EAccountNeedsAttentionReason } from '../interfaces/enums/EAccountNeedsAttentionReason';
import { ETenantStatus } from '../interfaces/enums/ETenantStatus';
import { ELicenseClass } from '../interfaces/enums/ELicenseClass';

export const mapAccounts: (accountDTOList: IAccountDTO[]) => IAccountVM[] = (accountDTOList) => {
    return accountDTOList.map((accountDTO: IAccountDTO) => {
        return {
            ...accountDTO,
            status: accountDTO.status as EAccountStatus,
            type: accountDTO.type as EAccountType
        };
    });
};

export const mapResources: (resourceDTOList: IResourceDTO[]) => IResourceVM[] = (resourceDTOList) => {
    return resourceDTOList.map((resourceDTO: IResourceDTO) => {
        return {
            ...resourceDTO
        };
    });
};

export const mapLicenseSummary: (licenseSummaryDTO: ILicenseSummaryDTO[]) => ILicenseSummaryVM[] = (
    licenseSummaryDTO
) => {
    return {
        ...licenseSummaryDTO
    };
};

export const mapResellers: (resellers: IResellerDTO[]) => IResellerVM[] = (resellers) => {
    return resellers;
};

export const mapCustomers: (customers: ICustomerDTO[]) => ICustomerVM[] = (customers) => {
    return customers.map((customerDTO) => {
        return {
            ...customerDTO,
            id: customerDTO.tenantId,
            region: customerDTO.region as ERegion,
            needsAttentionReason: customerDTO.needsAttentionReason as EAccountNeedsAttentionReason,
            tenantStatus: customerDTO.tenantStatus as ETenantStatus,
            licenseClass: customerDTO.licenseClass as ELicenseClass
        };
    });
};
