import { Typography } from '@mui/material';
import styled from '../../theme/styled';
import { fullRotateKeyFrame } from '../../../css/CommonComponents';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '20px 28px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
    },
    [theme.breakpoints.down('lg')]: {
        padding: '10px 14px'
    }
}));

export const ImageBox = styled('div')({
    paddingRight: 24,
    minHeight: 180,
    minWidth: 180,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 16,
    position: 'relative'
});

export const InfoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    height: '100%',
    padding: '0 32px',
    [theme.breakpoints.down('lg')]: {
        padding: '0 8px'
    }
}));

export const VerifiedAttentionBox = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: 10,
    width: '100%',
    flexWrap: 'wrap'
});

export const ClaimedVerifiedBox = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.light,
    opacity: '0.88'
}));

export const DescriptionTextBox = styled(Typography)(({ theme }) => ({
    minHeight: 40,
    paddingBottom: 12,
    flex: 3,
    paddingTop: 12,
    color: 'rgba(97, 96, 99, 1)',
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    [theme.breakpoints.down('lg')]: {
        letterSpacing: '0.5px',
        width: '100%'
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '12px'
    }
}));

export const StatBox = styled(Typography)(({ theme }) => ({
    maxWidth: 180,
    paddingLeft: 12,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    flex: 1,
    color: 'rgba(68, 53, 111, 1)',
    fontSize: 14,
    [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        letterSpacing: '1.25px',
        textAlign: 'right',
        paddingBottom: 0
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        flex: 1,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }
}));

export const QualifiedInfoBox = styled('div')(({ theme }) => ({
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    color: theme.palette.gray.light,
    opacity: '0.88'
}));

export const StatusMessagesBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingBottom: 10,
    [theme.breakpoints.down('md')]: {
        paddingBottom: 0
    }
}));

export const NotQualifiedTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.light,
    opacity: '0.88'
}));

export const BadgeImg = styled('img')({
    maxWidth: 160,
    '&:hover': {
        animation: `${fullRotateKeyFrame} 2s infinite ease-in-out`
    }
});

export const ClaimedBox = styled(Typography)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '170px',
    [theme.breakpoints.down('lg')]: {
        minWidth: 'unset'
    }
}));

export const VerifiedBox = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    paddingTop: '3px'
});

export const BadgeActionsBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '20px',
    left: '2px',
    width: 'calc(100% - 26px)'
});

export const DownloadBadgeBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isLoading'
})<{ isLoading: boolean }>(({ theme, isLoading }) => ({
    cursor: isLoading ? 'initial' : 'pointer',
    '&:hover svg path': {
        fill: isLoading ? 'initial' : `${theme.palette.primary.main} !important`
    }
}));

export const ShareBadgeBox = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    '&:hover svg path': {
        fill: `${theme.palette.primary.main} !important`
    }
}));

export const VerifiedSpan = styled('span')(({ theme }) => ({
    minWidth: '170px',
    marginRight: '26px',
    [theme.breakpoints.down('lg')]: {
        minWidth: 'unset',
        marginRight: 'unset'
    }
}));
