import { SelectChangeEvent } from '@mui/material';
import { useFiltersStateValue } from '../../../contexts/FiltersContext';
import { FC, useEffect, useState } from 'react';
import Dropdown from '../../../ui/general/dropdown/Dropdown';
import { IFilterCategories } from '../filters/Filters';

interface IProps {
    category: IFilterCategories;
}

const FilterDropdown: FC<IProps> = ({ category }) => {
    const { changeActiveFilter, activeFilters } = useFiltersStateValue();
    const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (activeFilters.length === 0) {
            if (category.defaultValue) {
                setSelectedValue(category.defaultValue);
            } else {
                setSelectedValue(undefined);
            }
        }
    }, [activeFilters]);

    useEffect(() => {
        if (category.defaultValue) {
            setSelectedValue(category.defaultValue);
            // changeActiveFilter(
            //     category.defaultValue,
            //     category.values
            //         ?.filter((categoryValue) => categoryValue.key !== category.defaultValue)
            //         .map((c) => c.key)
            // );
        }
    }, [category]);

    const handleDropdownChange = (event: SelectChangeEvent<unknown>) => {
        const newValue = event.target.value as string;
        setSelectedValue(newValue);
        if (category.defaultValue && category.values) {
            changeActiveFilter(
                newValue,
                category.values.filter((categoryValue) => categoryValue.key !== newValue).map((c) => c.key)
            );
        } else changeActiveFilter(event.target.value as string);
    };

    // useEffect(() => {
    //     if (defaultValue) {
    //         setSelectedValue(defaultValue);
    //         changeActiveFilter(
    //             defaultValue,
    //             values.filter((categoryValue) => categoryValue.key !== defaultValue).map((c) => c.key)
    //         );
    //     }
    // }, [defaultValue]);

    return (
        <Dropdown
            items={
                category.defaultValue && category.values
                    ? [
                          {
                              value: category.defaultValue,
                              name: category.name
                          },
                          ...category.values.map((value) => {
                              return {
                                  value: value.key,
                                  name: value.name
                              };
                          })
                      ]
                    : category.values?.map((value) => {
                          return {
                              value: value.key,
                              name: value.name
                          };
                      }) || []
            }
            value={selectedValue || ''}
            handleChange={handleDropdownChange}
            defaultValue={category.defaultValue}
        />
    );
};

export default FilterDropdown;
