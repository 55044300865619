import React, { FC, useCallback, useMemo, useRef, useState, KeyboardEvent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { ELicensesStatuses, useLicensesStateValue } from '../../../../contexts/LicensesContext';
import {
    RootBox,
    RootInnerBox,
    LicensesStatusOverallWrapper,
    RootInnerStatusWrapper,
    LicenseFiltersWrapper,
    LicenseRowContentWrapper,
    HeaderActionWrapper,
    LicensesMainContentWrapper,
    LicenseActionBox,
    RemoveIconImg,
    AddIconImg,
    LicenseAssignmentHistoryBox
} from './Style';
import NumberStatusBox from '../../../../ui/general/number-status-box/NumberStatusBox';
import Filters from '../../../../ui/filters/filters/Filters';
import { FiltersProvider } from '../../../../contexts/FiltersContext';
import TwoNumberStatusBox from '../../../../ui/general/two-number-status-box/TwoNumberStatusBox';
import TextButton from '../../../../ui/buttons/text-button/TextButton';
import useFilterDrawer from '../../../../hooks/useFilterDrawer';
import Table from '../../../../ui/general/table/Table';
import { ITableHeadCell } from '../../../../interfaces/ITableHeaderCell';
import LicenseTableToolbar from './LicenseTableToolbar';
import { ENuliaLicenseType } from '../../../../interfaces/enums/ENuliaLicenseType';
import ConfirmDialog from '../../../../ui/general/confirm-dialog/ConfirmDialog';
import useConfirmModal from '../../../../hooks/useConfirmModal';
import Tooltip from '../../../../ui/general/tooltip/Tooltip';
import { ITenantAllUserVM } from '../../../../interfaces/views/ITenantAllUserVM';
import { routes } from '../../../../pages/routes';
import { accessibilityEnterKeyCallback } from '../../../../utils/accessibilityUtils';
import sortPossibleUndefinedStrings from '../../../../utils/sortPossibleUndefinedStrings';
import { ITenantLicensedUserVM } from '../../../../interfaces/views/ITenantLicensedUserVM';
import { ILicensesCountDTO } from '../../../../interfaces/dtos/ILicensesCountDTO';

interface ITableHeader {
    image: string;
    name: string;
    department: string;
    sourceLicenseType: string;
    country: string;
    nwLicenseType: ENuliaLicenseType;
}

const LicensesTab: FC = () => {
    const {
        isLicensesLoading,
        licenses,
        licensesStatusOverallInfo,
        activeLicensesStatusOverallFilters,
        changeLicensesStatusOverallActiveFilters,
        onFilterValueChange,
        filterCategories,
        changeSelectedLicensesList,
        selectedLicensesList,
        assignLicense,
        removeLicense,
        isErrorFetchingData,
        refetchData,
        isLoadingFetchingData,
        refetchLicensesCountData,
        licensesCount
    } = useLicensesStateValue();
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const { isDrawerOpen, toggleFilterDrawer } = useFilterDrawer();
    const { t } = useTranslation();
    const [actionLicenseId, setActionLicenseId] = useState<string | undefined>();
    const {
        isOpen: isAssignLicenseDialogOpen,
        open: openAssignLicenseDialog,
        close: closeAssignLicenseDialog
    } = useConfirmModal({ defaultOpen: false });
    const {
        isOpen: isRemoveLicenseDialogOpen,
        open: openRemoveLicenseDialog,
        close: closeRemoveLicenseDialog
    } = useConfirmModal({ defaultOpen: false });
    const navigate = useNavigate();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const licenseCountRef = useRef<ILicensesCountDTO | undefined>(undefined);

    useEffect(() => {
        licenseCountRef.current = licensesCount;
    }, [licensesCount]);

    const onRemoveLicenseClick = async (e: React.MouseEvent | React.KeyboardEvent, id: string) => {
        e.stopPropagation();
        setActionLicenseId(id);
        openRemoveLicenseDialog();
    };

    const onAddLicenseClick = async (e: React.MouseEvent | React.KeyboardEvent, id: string) => {
        e.stopPropagation();
        setActionLicenseId(id);
        openAssignLicenseDialog();
    };

    const onConfirmAssignLicense = useCallback(async () => {
        if (!actionLicenseId) return;
        if (await assignLicense(actionLicenseId)) {
            refetchLicensesCountData();
            closeAssignLicenseDialog();
        }
        setActionLicenseId(undefined);
    }, [actionLicenseId]);

    const onCancelAssignLicense = useCallback(() => {
        closeAssignLicenseDialog();
        setActionLicenseId(undefined);
    }, []);

    const onConfirmRemoveLicense = useCallback(async () => {
        if (!actionLicenseId) return;
        if (await removeLicense(actionLicenseId)) {
            refetchLicensesCountData();
            closeRemoveLicenseDialog();
        }
        setActionLicenseId(undefined);
    }, [actionLicenseId]);

    const onCancelRemoveLicense = useCallback(() => {
        closeRemoveLicenseDialog();
        setActionLicenseId(undefined);
    }, []);

    const tableHeadCellsRef = useRef<ITableHeadCell<ITableHeader>[]>([
        {
            id: 'image',
            label: '',
            disableSort: true
        },
        {
            id: 'name',
            label: 'Name'
        },
        {
            id: 'department',
            label: 'Department',
            customSort(a: ITenantLicensedUserVM, b: ITenantLicensedUserVM, order) {
                return sortPossibleUndefinedStrings(a.department, b.department, order);
            }
        },
        {
            id: 'sourceLicenseType',
            label: 'License Type',
            customSort(a: ITenantLicensedUserVM, b: ITenantLicensedUserVM, order) {
                return sortPossibleUndefinedStrings(a.sourceLicenseType, b.sourceLicenseType, order);
            }
        },
        {
            id: 'country',
            label: 'Region',
            customSort(a: ITenantLicensedUserVM, b: ITenantLicensedUserVM, order) {
                return sortPossibleUndefinedStrings(a.country, b.country, order);
            }
        },
        {
            id: 'nwLicenseType',
            label: '',
            align: 'center',
            customRender: (value: ENuliaLicenseType, row: ITenantAllUserVM) => {
                const availableLicensesCount: number | undefined = licenseCountRef.current?.available;
                return (
                    <>
                        {value === ENuliaLicenseType.COMPLETE ? (
                            <LicenseActionBox
                                tabIndex={0}
                                onKeyDown={(e: KeyboardEvent<any>) =>
                                    accessibilityEnterKeyCallback(e, () => onRemoveLicenseClick(e, row.id))
                                }
                                onClick={(e) => onRemoveLicenseClick(e, row.id)}
                            >
                                <Tooltip title={t('tooltips.removeLicense')}>
                                    <RemoveIconImg />
                                </Tooltip>
                            </LicenseActionBox>
                        ) : (
                            <LicenseActionBox
                                tabIndex={0}
                                isDisabled={availableLicensesCount === 0}
                                onKeyDown={(e: KeyboardEvent<any>) =>
                                    accessibilityEnterKeyCallback(e, () => onAddLicenseClick(e, row.id))
                                }
                                onClick={(e) => {
                                    if (availableLicensesCount !== 0) onAddLicenseClick(e, row.id);
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <Tooltip
                                    title={
                                        availableLicensesCount === 0
                                            ? t('tooltips.noAvailableLicenses')
                                            : t('tooltips.addLicense')
                                    }
                                >
                                    <AddIconImg />
                                </Tooltip>
                            </LicenseActionBox>
                        )}
                    </>
                );
            }
        }
    ]);

    const viewAssignmentHistoryCallback = () => {
        navigate(`${routes.ADMIN_HISTORY}?type=license`);
    };

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={isLicensesLoading}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                isQuickFiltersSelected={activeLicensesStatusOverallFilters.length > 0}
                dropdownMode
                // filterElements={skills}
            />
        );
    }, [onFilterValueChange, isLicensesLoading, filterCategories, activeLicensesStatusOverallFilters]);

    const licensesStatusOverallMemo = useMemo(() => {
        return (
            <LicensesStatusOverallWrapper isDisabled={isLicensesLoading} className='licenses-status-filter-buttons'>
                {licensesStatusOverallInfo.map((status) => {
                    if (Array.isArray(status)) {
                        return (
                            <TwoNumberStatusBox
                                key={status[0].key}
                                elements={status.map((statusItem) => {
                                    return {
                                        elementId: statusItem.key,
                                        isActive: activeLicensesStatusOverallFilters.includes(statusItem.key),
                                        circleColor: statusItem.circleColor,
                                        label: statusItem.label,
                                        value: statusItem.value,
                                        icon: statusItem.icon,
                                        isLoading: statusItem.isLoading,
                                        isClickable: statusItem.isClickable
                                    };
                                })}
                            />
                        );
                    }
                    return (
                        <NumberStatusBox
                            clickHandler={(key) =>
                                status.value !== null && status.isClickable
                                    ? changeLicensesStatusOverallActiveFilters(key as ELicensesStatuses)
                                    : undefined
                            }
                            elementId={status.key}
                            isActive={activeLicensesStatusOverallFilters.includes(status.key)}
                            circleColor={status.circleColor}
                            label={status.label}
                            value={status.value}
                            icon={status.icon}
                            isLoading={status.isLoading}
                            isClickable={status.value !== null && status.isClickable}
                            key={status.key}
                        />
                    );
                })}
            </LicensesStatusOverallWrapper>
        );
    }, [licensesStatusOverallInfo, activeLicensesStatusOverallFilters]);

    const customToolbar = useMemo(() => {
        return (
            <LicenseTableToolbar numberOfSelectedUsers={selectedLicensesList.length} selected={selectedLicensesList} />
        );
    }, [licenses, selectedLicensesList]);

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <RootInnerBox>
                    <HeaderActionWrapper>
                        <LicenseAssignmentHistoryBox>
                            <TextButton onClick={viewAssignmentHistoryCallback} mode='primary'>
                                {t('licenses.viewLicenseAssignmentHistory')}
                            </TextButton>
                        </LicenseAssignmentHistoryBox>
                    </HeaderActionWrapper>
                    <LicensesMainContentWrapper>
                        <RootInnerStatusWrapper>
                            {licensesStatusOverallMemo}
                            <Table<ITenantAllUserVM, ITableHeader>
                                setSelectedCallback={changeSelectedLicensesList}
                                headCells={tableHeadCellsRef.current}
                                data={licenses}
                                propertyKeys={tableHeadCellsRef.current.map((headCell) => headCell.id)}
                                tableTitlePlural='Users'
                                isFilterControlVisible={isLgDown}
                                isFilterDrawerOpen={isDrawerOpen}
                                filterControlToggleHandler={toggleFilterDrawer}
                                filtersDrawerMemo={filtersMemo}
                                isMultipleSelectionAvailable
                                customToolbar={customToolbar}
                                isImageColumnPresent
                                isError={isErrorFetchingData}
                                refetchDataCallback={refetchData}
                                isLoading={isLoadingFetchingData}
                                userImageInfoColumn='name'
                                customMediaSecondaryColumns={['nwLicenseType']}
                                userImageInfoColumnPosition={isMdDown ? 'bottom' : 'right'}
                            />
                        </RootInnerStatusWrapper>
                        {!isLgDown && (
                            <LicenseRowContentWrapper>
                                <LicenseFiltersWrapper>{filtersMemo}</LicenseFiltersWrapper>
                            </LicenseRowContentWrapper>
                        )}
                    </LicensesMainContentWrapper>
                </RootInnerBox>
            </FiltersProvider>
            <ConfirmDialog
                title={t('licenses.assignLicenseConfirmModal.title')}
                description={t('licenses.assignLicenseConfirmModal.description')}
                isOpen={isAssignLicenseDialogOpen}
                handleConfirm={onConfirmAssignLicense}
                handleClose={onCancelAssignLicense}
            />
            <ConfirmDialog
                title={t('licenses.removeLicenseConfirmModal.title')}
                description={t('licenses.removeLicenseConfirmModal.description')}
                isOpen={isRemoveLicenseDialogOpen}
                handleConfirm={onConfirmRemoveLicense}
                handleClose={onCancelRemoveLicense}
            />
        </RootBox>
    );
};

export default LicensesTab;
