import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isVisible'
})<{ isVisible: boolean }>(({ isVisible, theme }) => ({
    display: isVisible ? 'flex' : 'none',
    flexDirection: 'column',
    minHeight: '204px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    borderRadius: theme.borderRadius?.inside?.large,
    background: theme.palette.common.white,
    flex: 1,
    width: '100%',
    padding: '25px 15px 12px 30px',
    transition: 'ease-out 200s display'
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.light,
    flex: 1
}));

export const ContentBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'gapActive'
})<{ gapActive?: boolean }>(({ gapActive, theme }) => ({
    marginTop: 14,
    display: 'flex',
    flexWrap: 'wrap',
    gap: gapActive ? '24px' : 'unset',
    flex: 3,
    [theme.breakpoints.down('lg')]: {
        flexWrap: 'nowrap'
    },
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap'
    }
}));

export const SVGBox = styled('div')({
    overflow: 'unset',
    minHeight: 80
});

export const InfoBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isFooterPresent'
})<{ isFooterPresent: boolean }>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    gap: '20px',
    padding: '0px',
    height: '100%',
    paddingBottom: '20px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
}));

export const InfoTextBox = styled(Typography)(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
}));

export const ActionBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'gapActive'
})<{ gapActive?: boolean }>(({ gapActive }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: gapActive ? '24px' : 'unset'
}));

export const MasterBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.gray.medium,
    fontSize: 14,
    paddingBottom: '40px',
    marginRight: '10px',
    gap: '4px',
    [theme.breakpoints.down('md')]: {
        paddingBottom: 0
    }
}));

export const MasterLabelBox = styled('div')({
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans'
});

export const MasterSwitchBox = styled('div')({
    flex: 1
});

export const AddActionTitle = styled('span')(({ theme }) => ({
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.gray.medium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const AddControlWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '22px',
    gap: '6px',
    marginRight: '10px'
});

// export const AppIconWrapperBox = styled('div')({
//     display: 'flex',
//     gap: 18,
//     paddingLeft: '100px',
// });

// export const AppIconImg = styled('img')({
//     width: 32,
//     height: 32,
// });

export const FooterBox = styled('div')({
    display: 'flex',
    flex: 1
});

export const BehaviorFooter = styled('div')({
    display: 'grid',
    gridTemplateColumns: '100px auto'
});

export const SkillLink = styled('a')(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.gray.medium,
    fontSize: 12,
    paddingRight: 3,
    paddingLeft: 3,
    fontWeight: 600
}));

export const BehaviorTypography = styled(Typography)(({ theme }) => ({
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    color: theme.palette.gray.dark,
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
}));

export const AddControlWrapperInfoBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
    textAlign: 'right',
    justifyContent: 'flex-end',
    height: '100%'
});

export const AssignedByTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.medium,
    lineHeight: '15px'
}));

export const AssignedDateTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.gray.medium,
    lineHeight: '15px'
}));

export const CompetenceTitle = styled('span')({
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
        textUnderlineOffset: '2px'
    }
});
