import React, { ChangeEvent, FC, useState } from 'react';
import { Box, Collapse, makeStyles, Typography } from '@material-ui/core';
import Input from '../../../Input';
import { useStore } from '../../../store/StoreProvider';
import { useApiService } from '../../../services/ApiServiceProvider';
import { ResellerType } from '../../../types/types';
import ChevronDownIcon from '../../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../../icons/ChevronUpIcon';
import { Colors } from '../../../Colors';
import { Dropdown, PrimaryButton } from 'nulia-ui';
import { SelectChangeEvent } from '@mui/material';

const useStyles = makeStyles({
    addPartner: {
        width: '100%',
        // width: 1088,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        marginBottom: 24
    },
    header: {
        height: 64,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer'
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    body: {
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
        padding: 24,
        paddingTop: 16
    },
    sku: {
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        paddingBottom: 16,
        marginBottom: 16
    },
    countPrice: {
        display: 'flex'
    },
    countInput: {
        display: 'flex',
        alignItems: 'baseline'
    },
    countInputHelper: {
        width: 100
    },
    label: {
        marginBottom: 4
    }
});

const AddPartner: FC = () => {
    const classes = useStyles();
    const [closed, setClosed] = useState<boolean>(false);
    const [partnerName, setPartnerName] = useState<string>('');
    const [tid, setTid] = useState<string>('');
    const [type, setType] = useState<ResellerType>(ResellerType.Default);
    const [partnerNameError, setPartnerNameError] = useState<boolean>(false);
    const [tidError, setTidError] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { user, setPartners } = useStore();
    const ApiService = useApiService();

    const toggleClosed = () => setClosed(!closed);

    const onChangePartnerName = (event: ChangeEvent<HTMLInputElement>) => setPartnerName(event.target.value);
    const onChangeTid = (event: ChangeEvent<HTMLInputElement>) => setTid(event.target.value);
    const onChangeType = (event: SelectChangeEvent<unknown>) => setType(event.target.value as ResellerType);
    const handleAccept = async () => {
        setSubmitting(true);
        let hasNameError = false;
        let hasTidError = false;
        if (partnerName === '') {
            hasNameError = true;
        }
        if (tid === '') {
            hasTidError = true;
        }
        if (user && !hasNameError && !hasTidError) {
            await ApiService.addPartner(partnerName, false, tid, type);
            const response = await ApiService.getPartners();
            if (!response) {
                return;
            }
            setPartners(response.data);
            setPartnerName('');
            setTid('');
            setType(ResellerType.Default);
        }
        setSubmitting(false);
        setPartnerNameError(hasNameError);
        setTidError(hasTidError);
    };

    const Label: FC<React.PropsWithChildren> = ({ children }) => (
        <Typography variant='body1' className={classes.label}>
            {children}
        </Typography>
    );

    return (
        <div className={classes.addPartner}>
            <div className={classes.header} onClick={toggleClosed}>
                <Typography variant='h6' className={classes.title}>
                    Add Partner
                </Typography>
                {closed ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </div>
            <Collapse in={closed}>
                <div className={classes.body}>
                    <Box marginBottom={2}>
                        <Label>Partner Name</Label>
                        <Input
                            value={partnerName}
                            onChange={onChangePartnerName}
                            style={{ width: '50%' }}
                            error={partnerNameError}
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <Label>Tenant Id</Label>
                        <Input value={tid} onChange={onChangeTid} style={{ width: '50%' }} error={tidError} />
                    </Box>
                    <Box marginBottom={2}>
                        <Label>Type</Label>
                        <Dropdown
                            handleChange={onChangeType}
                            value={type}
                            items={[{ value: ResellerType.Default, name: 'Complete' }]}
                        />
                    </Box>
                    <PrimaryButton clickHandler={handleAccept} disabled={submitting} style={{ width: 100 }}>
                        SUBMIT
                    </PrimaryButton>
                </div>
            </Collapse>
        </div>
    );
};

export default AddPartner;
