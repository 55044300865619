export const VERSION = 'v1.4.4';

export const CDN_URL = process.env.REACT_APP_PARTNER_CDN_URL;
export const IMAGE_PATH = CDN_URL + 'appimages/';
export const LOGOS_PATH = IMAGE_PATH + 'logos/';
export const OTHER_PATH = IMAGE_PATH + 'other/';
export const RESOURCES_PATH = CDN_URL + 'partner/';
export const OUTCOMES_PATH = IMAGE_PATH + 'outcomes/';

export const ACCEPTED_COOKIES = 'accepted-cookies';
export const LAST_PATHNAME_KEY = 'last-pathname';
export const CURRENT_PATHNAME_KEY = 'current-pathname';
export const ENABLE_ANALYTICS = 'enable-analytics';

export const REDIRECT_URI = `${window.location.origin}/login`;
export const LOGOUT_REDIRECT_URI = `${window.location.origin}/login`;

export const StarterGettingStarted =
    'Getting Started with Office 365 makes getting ROI from your Office 365' +
    ' investment quick and easy. Build employees’ confidence using Office 365 with this outcome designed to focus on' +
    ' the basics of the core applications of this powerful productivity suite. The included skills highlight' +
    ' activities that quickly get employees using Outlook email, OneDrive and SharePoint for file storage and' +
    ' collaboration, and instant messaging in Teams. Getting started with Office 365 has never been easier.';
export const StarterOrganizingFiles =
    'Poor organization of employee files and folders has a negative effect on' +
    ' productivity and efficiency, and can lead to employees searching for content instead of working on it.' +
    ' Organizing Your Files and Folders provides the skills needed to use OneDrive to store and share personal files,' +
    ' utilize SharePoint collaboration features to create shared document libraries, and co-author from apps like Word' +
    ' and Excel. Stop wasting your organization’s time searching for content and get employees using the' +
    ' organizational features available in Office 365.';
export const StarterOutlook =
    'Limiting Outlook usage to email is a missed opportunity for your organization. Making' +
    ' the Most of Outlooks provides skills to more fully utilize Outlook features, organize time by creating tasks and' +
    ' managing calendars, create automated replies to share availability, and de-clutter inboxes by setting inbox' +
    ' rules. Unlock the value of your investment in Outlook, and move your organization beyond basic email.';
export const StarterCollaboration =
    'Effective collaboration among multiple locations, with a remote workforce, or' +
    ' for those that rely heavily on audio and video meetings is a challenge for many organizations. Real Time' +
    ' Collaboration is a more advanced outcome that provides employees the opportunity to utilize the higher level' +
    ' collaboration features in Office 365 to share and manage permissions on OneDrive files, utilize SharePoint' +
    ' advanced features to pin to top or check in/check out documents, co-author and communicate directly with in the' +
    ' core apps, and use Teams communication features to chat, host audio and video meetings, and share desktops.' +
    ' Drive collaboration and communication in your organization by empowering employees with the advanced skills they' +
    ' need to utilize these sophisticated features of Office 365.';

export const RegExpGuidPattern = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/; // eslint-disable-line

export const RegExpEmailPattern =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
