import { createContext, FC, useContext, PropsWithChildren, useState } from 'react';
import { useGetPartnerCustomersQuery, useGetPartnerResellersQuery } from '../services/AccountsQueryService';
import { useGetLicenseSummaryQuery } from '../services/PartnerQueryService';
import { ILicenseSummaryVM } from '../interfaces/views/ILicenseSummaryVM';
import { IResellerVM } from '../interfaces/views/IResellerVM';
import { ICustomerVM } from '../interfaces/views/ICustomerVM';
import { QueryObserverResult } from '@tanstack/react-query';

export interface IAccountsContext {
    licenseSummaryData?: ILicenseSummaryVM;
    isLicenseSummaryDataLoading: boolean;
    isLicenseSummaryDataFetchingError: boolean;
    refetchLicenseSummaryDataCallback: () => void;
    resellersData?: IResellerVM[];
    customersData?: ICustomerVM[];
    isCustomersDataLoading: boolean;
    isCustomersDataFetchingError: boolean;
    refetchResellersData: () => Promise<QueryObserverResult<IResellerVM[], Error>>;
    refetchUsageSummary: (resellerId: string | undefined) => void;
}

export const AccountsContext = createContext<IAccountsContext>({} as IAccountsContext);

interface IProps {}

export const AccountsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [selectedResellerId, setSelectedResellerId] = useState<string | undefined>();
    const {
        data: fetchedLicenseSummaryData,
        isLoading: isLicenseSummaryDataLoading,
        isError: isLicenseSummaryDataFetchingError,
        refetch: refetchLicenseSummaryDataCallback
    } = useGetLicenseSummaryQuery(selectedResellerId);
    const { data: fetchedResellersData, refetch: refetchResellersData } = useGetPartnerResellersQuery();
    const {
        data: fetchedPartnerCustomers,
        isLoading: isCustomersDataLoading,
        isError: isCustomersDataFetchingError
    } = useGetPartnerCustomersQuery();

    const refetchUsageSummary = (resellerId: string | undefined) => {
        setSelectedResellerId(resellerId);
        // refetchLicenseSummaryDataCallback({});
    };

    const accountsState: IAccountsContext = {
        isLicenseSummaryDataFetchingError,
        isLicenseSummaryDataLoading,
        licenseSummaryData: fetchedLicenseSummaryData,
        refetchLicenseSummaryDataCallback,
        resellersData: fetchedResellersData,
        customersData: fetchedPartnerCustomers,
        isCustomersDataLoading,
        isCustomersDataFetchingError,
        refetchResellersData,
        refetchUsageSummary
    };

    return <AccountsContext.Provider value={accountsState}>{children}</AccountsContext.Provider>;
};

export const useAccountsStateValue: () => IAccountsContext = () => useContext(AccountsContext);
