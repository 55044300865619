import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/system';
import TextButton from '../../../../ui/buttons/text-button/TextButton';
import { ActionButtonsBox } from './Style';
import useConfirmModal from '../../../../hooks/useConfirmModal';
import { EToastSeverity, useToastContextStateValue } from '../../../../contexts/ToastContext';
import ConfirmDialog from '../../../../ui/general/confirm-dialog/ConfirmDialog';
import { useRolesStateValue } from '../../../../contexts/RolesContext';
import { EAssignmentRoles } from '../../../../interfaces/enums/EAssignmentRoles';

interface EnhancedTableToolbarProps {
    totalNumberOfUsers: number;
    numberOfSelectedUsers: number;
    selected: string[];
    toggleFilterDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const RolesTableToolbar: FC<EnhancedTableToolbarProps> = ({
    totalNumberOfUsers,
    numberOfSelectedUsers,
    toggleFilterDrawer,
    selected
}) => {
    const theme = useTheme();
    const {
        isOpen: isBulkAddRoleDialogOpen,
        open: openBulkAddRoleDialog,
        close: closeBulkAddRoleDialog
    } = useConfirmModal({ defaultOpen: false });
    const {
        isOpen: isBulkRemoveRoleDialogOpen,
        open: openBulkRemoveRoleDialog,
        close: closeBulkRemoveRoleDialog
    } = useConfirmModal({ defaultOpen: false });
    const { setToastMessage } = useToastContextStateValue();
    const { t } = useTranslation();
    const { bulkAssignmentRoleCallback, selectedRolesList } = useRolesStateValue();
    const selectedUserRole = useRef<EAssignmentRoles | undefined>();

    const handleBulkAddLicenseAdminClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onBulkAddRoleClick(EAssignmentRoles.LICENSE_ADMIN);
    };

    const handleBulkAddGlobalAdminClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onBulkAddRoleClick(EAssignmentRoles.GLOBAL_ADMIN);
    };

    const handleBulkAddReportAdminClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onBulkAddRoleClick(EAssignmentRoles.REPORT_ADMIN);
    };

    const handleBulkRemoveRoleClick = () => {
        selectedUserRole.current = EAssignmentRoles.REMOVE_ROLES;
        onBulkRemoveRoleClick(EAssignmentRoles.REMOVE_ROLES);
    };

    const onConfirmBulkAddRole = useCallback(async () => {
        if (selectedUserRole.current) {
            if (await bulkAssignmentRoleCallback(selectedRolesList, selectedUserRole.current)) {
                setToastMessage({
                    isOpen: true,
                    message: t('success.role.rolesAreBeingSet'),
                    severity: EToastSeverity.INFO
                });
                closeBulkAddRoleDialog();
            } else {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.role.errorForMultipleUsers'),
                    severity: EToastSeverity.ERROR
                });
            }
        } else {
            setToastMessage({
                isOpen: true,
                message: t('errors.role.errorForMultipleUsers'),
                severity: EToastSeverity.ERROR
            });
            closeBulkAddRoleDialog();
        }
    }, [selected]);

    const onCancelBulkAddRole = useCallback(() => {
        closeBulkAddRoleDialog();
    }, []);

    const onConfirmBulkRemoveRole = useCallback(async () => {
        if (selectedUserRole.current) {
            if (await bulkAssignmentRoleCallback(selectedRolesList, selectedUserRole.current)) {
                setToastMessage({
                    isOpen: true,
                    message: t('success.role.rolesAreBeingRemoved'),
                    severity: EToastSeverity.INFO
                });
                closeBulkRemoveRoleDialog();
            } else {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.role.errorRemovingMultipleUsers'),
                    severity: EToastSeverity.ERROR
                });
                closeBulkRemoveRoleDialog();
            }
        } else {
            setToastMessage({
                isOpen: true,
                message: t('errors.role.errorRemovingMultipleUsers'),
                severity: EToastSeverity.ERROR
            });
            closeBulkRemoveRoleDialog();
        }
    }, [selected]);

    const onCancelBulkRemoveRole = useCallback(() => {
        closeBulkRemoveRoleDialog();
    }, []);

    const onBulkRemoveRoleClick = async (role: EAssignmentRoles) => {
        selectedUserRole.current = role;
        openBulkRemoveRoleDialog();
    };

    const onBulkAddRoleClick = async (role: EAssignmentRoles) => {
        selectedUserRole.current = role;
        openBulkAddRoleDialog();
    };

    return (
        <>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    backgroundColor: theme.palette.common.white,
                    flex: 20
                }}
                id='role-assignment-buttons'
            >
                {numberOfSelectedUsers > 0 && (
                    <ActionButtonsBox>
                        <TextButton onClick={handleBulkAddGlobalAdminClick} mode='primary'>
                            Global Admin
                        </TextButton>
                        {/* <TextButton onClick={handleBulkUnassignClick} mode="primary">
                        Champion Admin
                    </TextButton> */}
                        <TextButton onClick={handleBulkAddLicenseAdminClick} mode='primary'>
                            License Admin
                        </TextButton>
                        <TextButton onClick={handleBulkAddReportAdminClick} mode='primary'>
                            Report Admin
                        </TextButton>
                        {/* <TextButton onClick={handleBulkUnassignClick} mode="primary">
                        Champion
                    </TextButton> */}
                        <TextButton onClick={handleBulkRemoveRoleClick} mode='primary'>
                            Remove Roles
                        </TextButton>
                    </ActionButtonsBox>
                )}
            </Toolbar>
            <ConfirmDialog
                title={t('roles.bulkAddRoleConfirmModal.title')}
                description={t('roles.bulkAddRoleConfirmModal.description')}
                isOpen={isBulkAddRoleDialogOpen}
                handleConfirm={onConfirmBulkAddRole}
                handleClose={onCancelBulkAddRole}
            />
            <ConfirmDialog
                title={t('roles.bulkRemoveRoleConfirmModal.title')}
                description={t('roles.bulkRemoveRoleConfirmModal.description')}
                isOpen={isBulkRemoveRoleDialogOpen}
                handleConfirm={onConfirmBulkRemoveRole}
                handleClose={onCancelBulkRemoveRole}
            />
        </>
    );
};

export default RolesTableToolbar;
