import React, { FC, useCallback, useMemo, KeyboardEvent, useRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IAppDTO } from '../../../interfaces/dtos/IAppDTO';
import HeartIcon from '../../../assets/icons/HeartIcon';
import useExpand from '../../../hooks/useExpand';
import AssignedControl from '../../general/assigned-control/AssignedControl';
import Tooltip from '../../general/tooltip/Tooltip';
import { ESkillStatus } from '../../../interfaces/enums/ESkillStatus';
import { IScoreDTO } from '../../../interfaces/dtos/IScoreDTO';
import { ESkillLevel } from '../../../interfaces/enums/ESkillLevel';
import { IAssignmentDTO } from '../../../interfaces/dtos/IAssignmentDTO';
import { ECompetenceState } from '../../../interfaces/enums/ECompetenceState';
import BlueWarningIcon from '../../../assets/icons/BlueWarningIcon';
import { ESkillType } from '../../../interfaces/enums/ESkillType';
import {
    ActionControlRow,
    ActionControlRows,
    AppIconImg,
    AppIconWrapperBox,
    AppsBox,
    AssignedControlWrapper,
    CollapsableRowBox,
    Description,
    DescriptionActionControlRow,
    FirstRowBox,
    FooterActionBox,
    HeartIconWrapper,
    ImageBox,
    InfoAssignedControlWrapper,
    InfoBox,
    InfoTextBox,
    MasterLevelRequestedWrapper,
    MdDownActionControlRow,
    MdDownChartDescriptionBox,
    RootBox,
    ShowDescriptionBox,
    MdHeartWrapper,
    DescriptionSegment,
    MasterFavoriteLgDownBox
} from './Style';
import { createUrl } from '../../../utils/createUrl';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import StandardProgressDoughnutChart from '../../general/progress-doughnut-chart/StandardProgressDoughnutChart';
import BestPracticeProgressDoughnutChart from '../../general/progress-doughnut-chart/BestPracticeProgressDoughnutChart';

interface IProps {
    id: number;
    name: string;
    isAssigned: boolean;
    appIcons: IAppDTO[];
    skillClickHandler?: (id: string) => void;
    description?: string;
    isAssignedControlActive?: boolean;
    isUserFocus: boolean;
    isMasterLevelRequested: boolean;
    progressDoughnutChartType?: ESkillStatus;
    score?: IScoreDTO;
    level?: ESkillLevel;
    assignment: IAssignmentDTO;
    translations?: {
        assignedTitle?: string;
        notAssignedTitle?: string;
        assignTooltipText?: string;
        unassignTooltipText?: string;
    };
    isUserFavoriteControlDisabled?: boolean;
    toggleAssignCallback?: (skillId: number, isAssigned: boolean) => void;
    toggleFavoriteCallback?: (id: number) => void;
    skillType?: ESkillType;
    index?: number;
    isActionDisabled?: boolean;
    maxLevel: string;
    userActivitySummary?: {
        completed: number;
        total: number;
    };
}

const SkillCard: FC<IProps> = ({
    id,
    name,
    appIcons,
    skillClickHandler,
    description,
    isAssignedControlActive,
    isUserFocus,
    isMasterLevelRequested,
    progressDoughnutChartType,
    score,
    level,
    isAssigned,
    assignment,
    translations,
    isUserFavoriteControlDisabled,
    toggleAssignCallback,
    toggleFavoriteCallback,
    skillType,
    index,
    isActionDisabled,
    maxLevel,
    userActivitySummary
}) => {
    const { t } = useTranslation();
    const { collapse, expand, isExpanded } = useExpand({
        defaultExpanded: false
    });
    const toggleDescriptionRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const noWatingForAnimationRef = useRef<boolean>(false);

    const favouriteSkillChangeHandler = useCallback((e?: React.MouseEvent) => {
        if (toggleFavoriteCallback) toggleFavoriteCallback(id);
        e?.stopPropagation();
    }, []);

    const toggleAssign = (assigned: boolean) => {
        if (toggleAssignCallback) {
            noWatingForAnimationRef.current = true;
            toggleAssignCallback(id, assigned);
        }
    };

    const progressDoughnutMemo = useMemo(() => {
        if (progressDoughnutChartType && score && level && skillType) {
            let targetScore = 0;
            if (level === ESkillLevel.PRODUCER) targetScore = score.targetProducer;
            if (level === ESkillLevel.MASTER) {
                if (maxLevel === (ESkillLevel.MASTER as string)) targetScore = score.targetMaster;
                else targetScore = score.targetProducer;
            }
            return (
                <ImageBox
                    className={
                        index !== undefined && index >= 0 ? `skill-image-wrapper-${index}` : `skill-image-wrapper`
                    }
                >
                    {skillType === ESkillType.STANDARD ? (
                        <StandardProgressDoughnutChart
                            index={index}
                            currentValue={score.score}
                            expectedValue={skillType === ESkillType.STANDARD ? targetScore : undefined}
                            dimension={{
                                width: 80,
                                height: 80,
                                margin: {
                                    bottom: 5,
                                    left: 10,
                                    right: 10,
                                    top: 7
                                },
                                starSize: 32,
                                starX: -8,
                                starY: -10
                            }}
                            isStarted={isAssigned}
                            skillStatus={progressDoughnutChartType}
                            isAssigned={assignment.level !== ESkillLevel.NONE}
                            noWaitingForAnimation={
                                !!(index !== undefined && index < 4) || noWatingForAnimationRef.current
                            }
                        />
                    ) : (
                        <BestPracticeProgressDoughnutChart
                            index={index}
                            achievedStepNumber={userActivitySummary?.completed || 0}
                            totalStepNumber={userActivitySummary?.total || 0}
                            dimension={{
                                width: 80,
                                height: 80,
                                margin: {
                                    bottom: 5,
                                    left: 10,
                                    right: 10,
                                    top: 7
                                },
                                starSize: 32,
                                starX: -8,
                                starY: -10
                            }}
                            skillStatus={progressDoughnutChartType}
                            isAssigned={assignment.level !== ESkillLevel.NONE}
                            noWaitingForAnimation={
                                !!(index !== undefined && index < 4) || noWatingForAnimationRef.current
                            }
                            isDataMissing={!userActivitySummary || !userActivitySummary.total}
                        />
                    )}
                </ImageBox>
            );
        }
        return null;
    }, [isAssigned, progressDoughnutChartType, score, level, assignment, index, userActivitySummary]);

    const onSkillClick = useCallback(() => {
        if (skillClickHandler) skillClickHandler(id.toString());
    }, [id]);

    const onSkillKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && skillClickHandler) skillClickHandler(id.toString());
            const currentElement = e.target as HTMLElement;
            if (e.key === 'ArrowDown') {
                if (index !== undefined && index >= 0) {
                    const nextElement = currentElement?.nextElementSibling;
                    if (nextElement) {
                        nextElement.setAttribute('tabindex', '0');
                        if (currentElement) currentElement?.setAttribute('tabindex', '-1');
                        (nextElement as HTMLElement).focus();
                    } else {
                        if (currentElement) currentElement.setAttribute('tabindex', '-1');
                    }
                }
            }
            if (e.key === 'ArrowUp') {
                if (index !== undefined && index >= 0) {
                    const nextElement = currentElement?.previousElementSibling;
                    if (nextElement) {
                        nextElement.setAttribute('tabindex', '0');
                        if (currentElement) currentElement?.setAttribute('tabindex', '-1');
                        (nextElement as HTMLElement).focus();
                    } else {
                        if (currentElement) currentElement.setAttribute('tabindex', '-1');
                    }
                }
            }
            if (e.key === 'ArrowRight') {
                if (index !== undefined && index >= 0) {
                    const heartControl = document.getElementById(`heart-control-${index}`);
                    const descriptionControl = document.getElementById(`description-control-${index}`);
                    const assignControl = document.getElementById(`assign-control-${index}`);
                    const unassignControl = document.getElementById(`unassign-control-${index}`);
                    if (heartControl) heartControl.setAttribute('tabindex', '0');
                    if (descriptionControl) descriptionControl.setAttribute('tabindex', '0');
                    if (assignControl) assignControl.setAttribute('tabindex', '0');
                    if (unassignControl) unassignControl.setAttribute('tabindex', '0');
                    if (assignControl) assignControl?.focus();
                    else if (unassignControl) unassignControl?.focus();
                    else if (heartControl) heartControl?.focus();
                    else if (descriptionControl) descriptionControl?.focus();
                }
            }
        },
        [id, index]
    );

    const onToggleDescriptionKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && toggleDescriptionRef.current) {
                toggleDescriptionRef.current.click();
            }
            e.stopPropagation();
        },
        [id, isExpanded, collapse, expand, index]
    );

    const onFavoriteSkillKeyDown = useCallback(
        (e: KeyboardEvent<HTMLSpanElement>) => {
            const currentElement = e.target as HTMLElement;
            if (e.key === 'Enter') {
                favouriteSkillChangeHandler();
            } else if (e.key === 'ArrowLeft') {
                const cardElement = document.getElementById(`skill-card-${index}`);
                if (cardElement) {
                    cardElement.setAttribute('tabindex', '0');
                    cardElement?.focus();
                }
                if (currentElement) currentElement.setAttribute('tabindex', '-1');
            }
            e.stopPropagation();
        },
        [index]
    );

    const userSkillState = useMemo(() => {
        if (assignment.level === ESkillLevel.MASTER) return assignment.masterState;
        if (assignment.level === ESkillLevel.PRODUCER) return assignment.producerState;
        return undefined;
    }, [assignment]);

    const appIconsMemo = useMemo(() => {
        if (appIcons)
            return (
                <AppIconWrapperBox>
                    {appIcons.map((appIcon) => {
                        return (
                            <AppIconImg
                                key={appIcon.name}
                                alt={appIcon.name}
                                src={createUrl(appIcon.icon)?.generatedUrl}
                                onError={fetchImageErrorCallback}
                            />
                        );
                    })}
                </AppIconWrapperBox>
            );
        return <></>;
    }, [appIcons]);

    const heartMemo = useMemo(() => {
        return (
            <HeartIconWrapper
                isActive={isUserFocus}
                tabIndex={-1}
                className='heart-control'
                id={`heart-control-${index}`}
                onClick={
                    isUserFavoriteControlDisabled || isActionDisabled
                        ? (e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                          }
                        : favouriteSkillChangeHandler
                }
                onKeyDown={onFavoriteSkillKeyDown}
            >
                <Tooltip
                    title={
                        isUserFavoriteControlDisabled
                            ? t('tooltips.assignSkillFirst')
                            : isUserFocus
                              ? t('tooltips.removeFocusSkill')
                              : t('tooltips.setFocusSkill')
                    }
                >
                    <HeartIcon liked={isUserFocus} hoverEffect />
                </Tooltip>
            </HeartIconWrapper>
        );
    }, [
        isUserFocus,
        isUserFavoriteControlDisabled,
        isActionDisabled,
        favouriteSkillChangeHandler,
        onFavoriteSkillKeyDown
    ]);

    const descriptionControlMemo = useMemo(() => {
        return (
            <ShowDescriptionBox
                onClick={isExpanded ? collapse : expand}
                onKeyDown={onToggleDescriptionKeyDown}
                id={`description-control-${index}`}
                tabIndex={-1}
                ref={toggleDescriptionRef}
                className={index !== undefined && index >= 0 ? `view-description-${index}` : 'view-description'}
            >
                {isExpanded
                    ? isLgDown
                        ? t('skill.viewLess')
                        : t('skill.hideDescription')
                    : isLgDown
                      ? t('skill.viewMore')
                      : t('skill.showDescription')}
            </ShowDescriptionBox>
        );
    }, [isExpanded, collapse, expand, onToggleDescriptionKeyDown, isLgDown, index]);

    const masterLevelRequestedMemo = useMemo(() => {
        return isMasterLevelRequested ? (
            <Tooltip title={t('tooltips.masterLevelRequested')}>
                <MasterLevelRequestedWrapper>
                    <BlueWarningIcon />
                </MasterLevelRequestedWrapper>
            </Tooltip>
        ) : (
            <></>
        );
    }, [isMasterLevelRequested]);

    const assignedControlMemo = useMemo(() => {
        return (
            <AssignedControl
                assignment={{
                    assignerId: assignment.assignerId,
                    assignerName: assignment.assignerName,
                    assignerRole: assignment.assignerRole,
                    date: assignment.date,
                    inProgressForWeeks:
                        userSkillState === ECompetenceState.IN_PROGRESS && assignment.streak !== undefined
                            ? assignment.streak
                            : undefined,
                    maintainedForWeeks:
                        userSkillState === ECompetenceState.MAINTAIN && assignment.streak !== undefined
                            ? assignment.streak
                            : undefined
                }}
                isAssigned={isAssigned}
                toggleAssign={toggleAssign}
                translations={translations}
                isActionDisabled={isActionDisabled}
                isCardMode
                index={index}
            />
        );
    }, [assignment, userSkillState, isAssigned, toggleAssign, translations, isActionDisabled, index]);

    const descriptionMemo = useMemo(() => {
        if (!description) return '';
        if (Array.isArray(description)) {
            return description.map((descriptionItem) => {
                return (
                    <DescriptionSegment key={descriptionItem} dangerouslySetInnerHTML={{ __html: descriptionItem }} />
                );
            });
        }
        if (description) {
            return <DescriptionSegment dangerouslySetInnerHTML={{ __html: description }} />;
        }
        return '';
    }, [description]);

    return (
        <RootBox
            className={`skill-card-${index ?? 0}`}
            id={`skill-card-${index}`}
            tabIndex={index === 0 ? 0 : -1}
            onKeyDown={onSkillKeyDown}
            onClick={onSkillClick}
        >
            <FirstRowBox>
                {!isMdDown && progressDoughnutMemo}
                <InfoAssignedControlWrapper isPaddingNeeded={!isAssignedControlActive}>
                    <InfoBox>
                        <InfoTextBox variant={isLgDown && !isMdDown ? 'caption' : 'subtitle2'}>{name}</InfoTextBox>
                        <AppsBox>
                            {!isLgDown && appIconsMemo}
                            <FooterActionBox>
                                {!isLgDown && isMasterLevelRequested && masterLevelRequestedMemo}
                                {!isUserFavoriteControlDisabled && !isLgDown && heartMemo}
                                {!isLgDown && descriptionControlMemo}
                            </FooterActionBox>
                        </AppsBox>
                    </InfoBox>
                    {isAssignedControlActive && !isMdDown && (
                        <AssignedControlWrapper>{assignedControlMemo}</AssignedControlWrapper>
                    )}
                </InfoAssignedControlWrapper>
            </FirstRowBox>
            {isLgDown && !isMdDown && (
                <ActionControlRows>
                    <ActionControlRow>
                        {appIconsMemo}
                        <MasterFavoriteLgDownBox>
                            {masterLevelRequestedMemo}
                            {heartMemo}
                        </MasterFavoriteLgDownBox>
                    </ActionControlRow>
                    <DescriptionActionControlRow>{descriptionControlMemo}</DescriptionActionControlRow>
                </ActionControlRows>
            )}
            {isMdDown && (
                <MdDownActionControlRow>
                    <MdDownChartDescriptionBox>
                        {progressDoughnutMemo}
                        {descriptionControlMemo}
                    </MdDownChartDescriptionBox>{' '}
                    <MdHeartWrapper>{heartMemo}</MdHeartWrapper> {assignedControlMemo}
                </MdDownActionControlRow>
            )}
            {isExpanded && (
                <CollapsableRowBox
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <Description variant={isMdDown ? 'label' : 'body2'}>{descriptionMemo}</Description>
                </CollapsableRowBox>
            )}
        </RootBox>
    );
};

export default SkillCard;
