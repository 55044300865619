import { FC } from 'react';

const BenchmarkIcon: FC = () => {
    return (
        <svg width='20' height='1' viewBox='0 0 20 1' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <line x1='20' y1='0.5' x2='4.37114e-08' y2='0.500002' stroke='black' strokeDasharray='2 2' />
        </svg>
    );
};

export default BenchmarkIcon;
