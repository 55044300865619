import styled from '../../theme/styled';
import { Typography } from '@mui/material';

export const RootBox = styled('div', { shouldForwardProp: (prop) => prop !== 'isDescriptionExpanded' })<{
    isDescriptionExpanded: boolean;
}>(({ theme, isDescriptionExpanded }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    [theme.breakpoints.down('lg')]: {
        maxHeight: isDescriptionExpanded ? 'unset' : '160px'
    },
    [theme.breakpoints.down('md')]: {
        maxHeight: 'unset',
        '&:hover': {
            backgroundColor: theme.palette.background.default,
            cursor: 'pointer'
        }
    }
}));

export const ImageBox = styled('div')({
    maxWidth: 140
});

export const InfoBox = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    paddingLeft: 16,
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        paddingBottom: '50px'
    },
    [theme.breakpoints.down('md')]: {
        paddingBottom: '5px',
        paddingTop: '5px'
    }
}));

export const InfoTextBox = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '16px'
});

export const AppsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '14%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap'
    }
}));

export const AppIconWrapperBox = styled('div')({
    display: 'flex',
    gap: 18
});

export const AppIconImg = styled('img')({
    width: 26,
    height: 26
});

export const ShowDescriptionBox = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
    letterSpacing: '1.25px',
    lineHeight: '40px',
    fontSize: 10,
    fontWeight: 500,
    textTransform: 'uppercase',
    padding: '0px 6px',
    borderRadius: '3px',
    minWidth: 140,
    textAlign: 'center',
    fontFamily: 'Ubuntu',
    '&:hover': {
        textDecoration: 'underline'
    },
    [theme.breakpoints.down('lg')]: {
        textAlign: 'start',
        paddingLeft: 12,
        lineHeight: 'unset',
        minWidth: 'unset'
    },
    [theme.breakpoints.down('md')]: {
        paddingLeft: 6,
        textAlign: 'start',
        paddingTop: 2,
        marginTop: '4px'
    }
}));

export const FooterActionBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    width: '100%',
    justifyContent: 'flex-end'
});

export const OrgPriorityMasterLevelBox = styled('div')({
    display: 'flex',
    gap: '12px'
});

export const MasterLevelRequestedWrapper = styled('span')({
    display: 'flex',
    alignItems: 'center'
});

export const CompanyImportantWrapper = styled('span')({
    display: 'flex',
    alignItems: 'center'
});

export const FirstRowBox = styled('div', { shouldForwardProp: (prop) => prop !== 'isHoverModeActive' })<{
    isHoverModeActive: boolean;
}>(({ theme, isHoverModeActive }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '2px 8px',
    cursor: 'pointer',
    minHeight: '136px',
    '&:hover': {
        backgroundColor: isHoverModeActive ? theme.palette.background.default : 'inherit'
    },
    [theme.breakpoints.down('md')]: {
        minHeight: '40px'
    }
}));

export const CollapsableRowBox = styled('div', { shouldForwardProp: (prop) => prop !== 'isDescriptionExpanded' })<{
    isDescriptionExpanded: boolean;
}>(({ theme, isDescriptionExpanded }) => ({
    padding: '10px 16px 16px 12px',
    [theme.breakpoints.down('lg')]: {
        position: 'relative',
        top: isDescriptionExpanded ? 'unset' : '-23px'
    }
}));

export const Description = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.88)'
});

export const InfoAssignedControlWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 0,
    width: '100%'
}));

export const AssignedControlWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.between('md', 'lg')]: {
        paddingBottom: '25px'
    }
}));

export const BadgesBox = styled('div')({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: 15,
    marginTop: 12,
    marginBottom: 7,
    paddingRight: 36
});

export const ActionControlRows = styled('div')({
    display: 'grid',
    gridTemplateColumns: '120px 1fr 1fr',
    gridTemplateRows: '30px 20px',
    position: 'relative',
    top: '-43px'
});

export const ActionControlRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: '150px 10fr 1fr',
    width: 'calc(calc(100% - 100px) / 2 + 100px)',
    justifyContent: 'start',
    alignItems: 'center',
    gridRow: 1,
    gridColumn: 2
});

export const DescriptionActionControlRow = styled('div')({
    gridColumn: 1,
    gridRow: 2
});

export const MdDownActionControlRow = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: theme.palette.background.default
    }
}));

export const MdDownChartDescriptionBox = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

export const DescriptionSegment = styled('div')({
    paddingTop: '4px'
});

export const OutcomeImg = styled('img')({
    width: '70px',
    height: '70px'
});
