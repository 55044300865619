import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { EBadgeLevel } from '../../../interfaces/enums/EBadgeLevel';
import { ESkillLevel } from '../../../interfaces/enums/ESkillLevel';
import { useOutcomeStateValue } from '../../../contexts/OutcomeContext';
import Tooltip from '../../general/tooltip/Tooltip';
import {
    RootBox,
    ClaimedBox,
    DescriptionTextBox,
    ImageBox,
    InfoBox,
    QualifiedInfoBox,
    StatBox,
    StatusMessagesBox,
    VerifiedAttentionBox,
    VerifiedBox,
    BadgeImg,
    ClaimedVerifiedBox,
    BadgeActionsBox,
    DownloadBadgeBox,
    ShareBadgeBox,
    VerifiedSpan
} from './Style';
import PrimaryButton from '../../../ui/buttons/primary-button/PrimaryButton';
import DownloadBadgeIcon from '../../../assets/icons/DownloadBadgeIcon';
import ShareBadgeIcon from '../../../assets/icons/ShareBadgeIcon';
import ShareBadgeModal from '../../modals/share-badge-modal/ShareBadgeModal';
import { createUrl } from '../../../utils/createUrl';
import { ELinkedinRedirectUrl } from '../../../interfaces/enums/ELinkedinRedirectUrl';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import loadImageGraduallyHandler from '../../../utils/loadImageGradually';
import { EToastSeverity, useToastContextStateValue } from '../../../contexts/ToastContext';

interface IProps {
    claimed?: string | null;
    verified?: string | null;
    needsAttention?: boolean | null;
    statInfo?: string;
    status: EBadgeLevel;
    level: ESkillLevel;
    isOutcomeAssigned: boolean;
    badgeImage: string;
    assignmentLevel?: ESkillLevel;
    userBadgeUrl?: string;
    outcomeId?: number;
}

const BadgeCard: FC<IProps> = ({
    claimed,
    verified,
    needsAttention,
    statInfo,
    status,
    level,
    isOutcomeAssigned,
    badgeImage,
    assignmentLevel,
    userBadgeUrl,
    outcomeId
}) => {
    const { t } = useTranslation();
    const {
        userOutcomeData,
        onClaimClick,
        isPostClaimMasterBadgeLoading,
        isPostClaimProducerBadgeLoading,
        onDownloadBadgeClick,
        isDownloadBadgeLoading
    } = useOutcomeStateValue();
    const [isShareBadgeModalOpen, setShareBadgeModalOpen] = useState<boolean>(false);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { setToastMessage } = useToastContextStateValue();

    const handleShareBadgeModalClose = useCallback(() => {
        setShareBadgeModalOpen(false);
    }, []);

    const handleShareBadgeModalOpen = useCallback(() => {
        setShareBadgeModalOpen(true);
    }, []);

    const handleDownloadBadgeClick = useCallback(() => {
        if (isDownloadBadgeLoading) return;
        if (userOutcomeData?.id) {
            if (outcomeId && level) onDownloadBadgeClick(outcomeId, level);
            else {
                setToastMessage({
                    isOpen: true,
                    message: 'Something went wrong',
                    severity: EToastSeverity.ERROR
                });
            }
        }
    }, [userOutcomeData, level, isDownloadBadgeLoading, outcomeId]);

    const imageLevelMemo = useMemo(() => {
        return createUrl(badgeImage)?.generatedUrl;
    }, [badgeImage]);

    const claimBadgeHandler = useCallback(() => {
        onClaimClick(level);
    }, [level]);

    const badgeStatusMemo = useMemo(() => {
        // If User tab
        switch (level) {
            case ESkillLevel.USER:
                switch (status) {
                    case EBadgeLevel.NONE:
                        return <></>;
                    // return <NotQualifiedTypography variant="caption">You are not qualified</NotQualifiedTypography>;
                    case EBadgeLevel.CLAIMED:
                        return (
                            <ClaimedBox variant='caption'>
                                <>{t('components.badgeCard.achieved')}</>
                                <>{claimed ? `: ${claimed}` : ''}</>
                            </ClaimedBox>
                        );
                    case EBadgeLevel.EARNED:
                        // if (assignmentLevel === ESkillLevel.NONE)
                        //     return (
                        //         <QualifiedInfoBox>
                        //             <span>{t('components.badgeCard.qualified')}</span>
                        //         </QualifiedInfoBox>
                        //     );
                        return '';
                    default:
                        return '';
                }
            case ESkillLevel.PRODUCER:
            case ESkillLevel.MASTER:
                switch (status) {
                    case EBadgeLevel.NONE:
                        return <></>;
                    // return <NotQualifiedTypography variant="caption">You are not qualified</NotQualifiedTypography>;
                    case EBadgeLevel.EARNED:
                        return (
                            <QualifiedInfoBox>
                                {/* <span>{t('components.badgeCard.qualified')}</span> */}
                                {((level === ESkillLevel.PRODUCER && assignmentLevel !== ESkillLevel.NONE) ||
                                    (level === ESkillLevel.MASTER && assignmentLevel === ESkillLevel.MASTER)) && (
                                    <PrimaryButton
                                        disabled={
                                            !isOutcomeAssigned ||
                                            isPostClaimMasterBadgeLoading ||
                                            isPostClaimProducerBadgeLoading
                                        }
                                        title={t('components.badgeCard.claimButton')}
                                        clickHandler={claimBadgeHandler}
                                    />
                                )}
                            </QualifiedInfoBox>
                        );
                    case EBadgeLevel.CLAIMED:
                        return (
                            <ClaimedVerifiedBox>
                                <ClaimedBox variant='caption'>
                                    <>{t('components.badgeCard.claimed')}</>
                                    <>{claimed ? `: ${claimed}` : ''}</>
                                </ClaimedBox>
                                <VerifiedBox variant='caption'>
                                    <VerifiedSpan>
                                        {verified && `${t('components.badgeCard.verified')}: ${verified}`}
                                    </VerifiedSpan>
                                    {/* <ColoredLabel
                                        style={{
                                            minHeight: 16,
                                            background: 'rgba(176, 203, 62, 0.32)',
                                            color: '#879B31',
                                            borderRadius: '20px',
                                        }}
                                    >
                                        {t('components.badgeCard.upToDate')}
                                    </ColoredLabel> */}
                                </VerifiedBox>
                            </ClaimedVerifiedBox>
                        );
                    case EBadgeLevel.NEEDS_ATTENTION:
                        return (
                            <ClaimedVerifiedBox>
                                <ClaimedBox variant='caption'>
                                    <>{t('components.badgeCard.claimed')}</>
                                    <>{claimed ? `: ${claimed}` : ''}</>
                                </ClaimedBox>
                                <VerifiedBox variant='caption'>
                                    <VerifiedSpan>
                                        {verified && `${t('components.badgeCard.verified')}: ${verified}`}
                                    </VerifiedSpan>
                                    {/* <ColoredLabel
                                        style={{
                                            minHeight: 16,
                                            background: 'rgba(170, 182, 192, 0.32)',
                                            color: '#6C8193',
                                            borderRadius: '20px',
                                        }}
                                    >
                                        {t('components.badgeCard.certificationInDanger')}
                                    </ColoredLabel> */}
                                </VerifiedBox>
                            </ClaimedVerifiedBox>
                        );
                    case EBadgeLevel.EXPIRED:
                        return (
                            <ClaimedVerifiedBox>
                                <ClaimedBox variant='caption'>
                                    <>{t('components.badgeCard.claimed')}</>
                                    <>{claimed ? `: ${claimed}` : ''}</>
                                </ClaimedBox>
                                <VerifiedBox variant='caption'>
                                    <VerifiedSpan>
                                        {verified && `${t('components.badgeCard.verified')}: ${verified}`}
                                    </VerifiedSpan>
                                    {/* <ColoredLabel
                                        style={{
                                            minHeight: 16,
                                            background: 'rgba(250, 208, 70, 0.32)',
                                            color: '#AC5521',
                                            borderRadius: '20px',
                                        }}
                                    >
                                        {t('components.badgeCard.certificationRevoked')}
                                    </ColoredLabel> */}
                                </VerifiedBox>
                            </ClaimedVerifiedBox>
                        );
                    default:
                        return <></>;
                }
            default:
                return <></>;
        }
    }, [
        status,
        isOutcomeAssigned,
        verified,
        needsAttention,
        claimed,
        level,
        isPostClaimMasterBadgeLoading,
        isPostClaimProducerBadgeLoading,
        t,
        assignmentLevel
    ]);

    const statInfoMemo = useMemo(() => {
        return <StatBox variant='overline'>{statInfo && <span>{statInfo}</span>}</StatBox>;
    }, [statInfo]);

    const descriptionMemo = useMemo(() => {
        switch (level) {
            case ESkillLevel.USER:
                switch (status) {
                    case EBadgeLevel.NONE:
                        return t('components.badgeCard.descriptions.user.none');
                    case EBadgeLevel.EARNED:
                        return t('components.badgeCard.descriptions.user.earned');
                    case EBadgeLevel.CLAIMED:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.user.claimedAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.user.claimedAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.user.claimedAndMasterAssigned');
                            default:
                                return '';
                        }
                    default:
                        return '';
                }
            case ESkillLevel.PRODUCER:
                switch (status) {
                    case EBadgeLevel.NONE:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.producer.noneAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.producer.noneAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.producer.noneAndMasterAssigned');
                            default:
                                return '';
                        }
                    case EBadgeLevel.EARNED:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.producer.earnedAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.producer.earnedAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.producer.earnedAndMasterAssigned');
                            default:
                                return '';
                        }
                    case EBadgeLevel.CLAIMED:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.producer.claimedAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.producer.claimedAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.producer.claimedAndMasterAssigned');
                            default:
                                return '';
                        }
                    case EBadgeLevel.NEEDS_ATTENTION:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.producer.needsAttentionAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t(
                                    'components.badgeCard.descriptions.producer.needsAttentionAndProducerAssigned'
                                );
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.producer.needsAttentionAndMasterAssigned');
                            default:
                                return '';
                        }
                    case EBadgeLevel.EXPIRED:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.producer.expiredAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.producer.expiredAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.producer.expiredAndMasterAssigned');
                            default:
                                return '';
                        }
                    default:
                        return '';
                }
            case ESkillLevel.MASTER:
                switch (status) {
                    case EBadgeLevel.NONE:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.master.noneAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.master.noneAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.master.noneAndMasterAssigned');
                            default:
                                return '';
                        }
                    case EBadgeLevel.EARNED:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.master.earnedAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.master.earnedAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.master.earnedAndMasterAssigned');
                            default:
                                return '';
                        }
                    case EBadgeLevel.CLAIMED:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.master.claimedAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.master.claimedAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.master.claimedAndMasterAssigned');
                            default:
                                return '';
                        }
                    case EBadgeLevel.NEEDS_ATTENTION:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.master.needsAttentionAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.master.needsAttentionAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.master.needsAttentionAndMasterAssigned');
                            default:
                                return '';
                        }
                    case EBadgeLevel.EXPIRED:
                        switch (assignmentLevel) {
                            case ESkillLevel.NONE:
                                return t('components.badgeCard.descriptions.master.expiredAndNoneAssigned');
                            case ESkillLevel.PRODUCER:
                                return t('components.badgeCard.descriptions.master.expiredAndProducerAssigned');
                            case ESkillLevel.MASTER:
                                return t('components.badgeCard.descriptions.master.expiredAndMasterAssigned');
                            default:
                                return '';
                        }
                    default:
                        return '';
                }
            default:
                return '';
        }
    }, [status, level, assignmentLevel]);

    return (
        <RootBox style={{ display: 'flex', flexDirection: 'column' }}>
            <div id='badge-card-content-box' style={{ display: 'flex', flexDirection: isMdDown ? 'column' : 'row' }}>
                <ImageBox>
                    <BadgeImg
                        src={imageLevelMemo}
                        onError={fetchImageErrorCallback}
                        onLoad={loadImageGraduallyHandler}
                        alt={`${level} level badge`}
                    />
                    {level !== ESkillLevel.USER && status === EBadgeLevel.CLAIMED && (
                        <BadgeActionsBox id='badge-actions-box'>
                            <Tooltip title={t('tooltips.downloadBadge')}>
                                <DownloadBadgeBox isLoading={isDownloadBadgeLoading} onClick={handleDownloadBadgeClick}>
                                    <DownloadBadgeIcon />
                                </DownloadBadgeBox>
                            </Tooltip>
                            <Tooltip title={t('tooltips.shareBadge')}>
                                <ShareBadgeBox onClick={handleShareBadgeModalOpen}>
                                    <ShareBadgeIcon />
                                </ShareBadgeBox>
                            </Tooltip>
                        </BadgeActionsBox>
                    )}
                </ImageBox>
                <InfoBox>
                    <DescriptionTextBox variant='body2'>{descriptionMemo}</DescriptionTextBox>
                    <VerifiedAttentionBox>
                        <StatusMessagesBox>{badgeStatusMemo}</StatusMessagesBox>
                        {isLgDown && statInfoMemo}
                    </VerifiedAttentionBox>
                </InfoBox>
                {!isLgDown && statInfoMemo}
                {isShareBadgeModalOpen && (
                    <ShareBadgeModal
                        handleClose={handleShareBadgeModalClose}
                        badgeImage={badgeImage}
                        level={level}
                        outcomeId={userOutcomeData?.id}
                        userBadgeUrl={userBadgeUrl}
                        calledFrom={ELinkedinRedirectUrl.OUTCOME}
                    />
                )}
            </div>
        </RootBox>
    );
};

export default BadgeCard;
