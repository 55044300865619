import { FC, useCallback, useEffect, useMemo, useRef, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Switch, useMediaQuery, useTheme } from '@mui/material';
import { UserSkillProgressDTO } from '../../../../../interfaces/dtos/UserSkillProgressDTO';
import Loading from '../../../../../ui/general/loading/Loading';
import KeyValueInfoBox from '../key-value-info/KeyValueInfoBox';
import SkillGraph from '../skill-graph/SkillGraph';
import useExpand from '../../../../../hooks/useExpand';
import ToCollapseIconSVG from '../../../../../assets/icons/ToCollapseIcon';
import ToExpandIconSVG from '../../../../../assets/icons/ToExpandIcon';
import BlueWarningIcon from '../../../../../assets/icons/BlueWarningIcon';
import Tooltip from '../../../../../ui/general/tooltip/Tooltip';
import SomethingWentWrongCard from '../../../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import { accessibilityEnterSpaceKeyCallback } from '../../../../../utils/accessibilityUtils';
import { useSkillStateValue } from '../../../../../contexts/SkillContext';
import {
    CardHeader,
    ExpandedCollapsedBox,
    GraphCardWrapper,
    GraphDetailsWrapper,
    GraphWrapper,
    KeyValueBox,
    LgDownGraphInfoWrapper,
    LgDownScoreKeyValueBox,
    LgDownStreakKeyValueBox,
    MasterLabelBox,
    MasterSwitchBox,
    RootPaper,
    StreakBox
} from './Style';

const useStyles = makeStyles(() => ({
    masterBox: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 24,
        color: '#616063',
        fontSize: 14
    },
    disabledMasterBox: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 24,
        color: '#616063',
        fontSize: 14,
        opacity: 0.5
    }
}));

interface IProps {
    skillProgress: UserSkillProgressDTO;
    isLoading: boolean;
    isSkillAssigned?: boolean;
    masterLevelRequested?: boolean;
    isProducerMaxLevel?: boolean;
    errorRefetchCallback?: () => void;
    isError?: boolean;
    isLeaderboardActive?: boolean;
}

const SkillGraphCard: FC<IProps> = ({
    skillProgress,
    isLoading,
    isSkillAssigned,
    masterLevelRequested,
    isProducerMaxLevel,
    isError,
    errorRefetchCallback,
    isLeaderboardActive
}) => {
    const classes = useStyles();
    const [isMasterChecked, setMasterChecked] = useState<boolean>(skillProgress?.level === 'Master');
    const graphWrapperRef = useRef<any | undefined>(undefined);
    const { t } = useTranslation();
    const { toggleUserMaster } = useSkillStateValue();

    const [graphWidth, setGraphWidth] = useState<number>(600);
    const { isExpanded, expand, collapse } = useExpand({ defaultExpanded: true });
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        if (skillProgress !== undefined && skillProgress !== null) setMasterChecked(skillProgress.level === 'Master');
    }, [skillProgress]);

    useEffect(() => {
        const updateWindowWidth = () => {
            setTimeout(
                () => {
                    if (window.innerWidth < 1200 && graphWrapperRef.current)
                        setGraphWidth(graphWrapperRef.current.clientWidth);
                    else setGraphWidth(600);
                },
                !graphWrapperRef.current ? 300 : 0
            );
        };

        window.addEventListener('resize', updateWindowWidth);
        updateWindowWidth();

        return () => window.removeEventListener('resize', updateWindowWidth);
    }, []);

    const onMasterCheckedChange = (e: any, value: boolean) => {
        toggleUserMaster(value);
    };

    const longestWeekSuffixMemo = useMemo(() => {
        if (skillProgress) {
            const value = isMasterChecked ? skillProgress.masterStreakLongest : skillProgress.streakLongest;
            if (value === 1) return 'Week';
            return 'Weeks';
        }
        return '';
    }, [skillProgress]);

    const currentWeekSuffixMemo = useMemo(() => {
        if (skillProgress) {
            const value = isMasterChecked ? skillProgress?.masterStreakCurrent : skillProgress?.streakCurrent;
            if (value === 1) return 'Week';
            return 'Weeks';
        }
        return '';
    }, [skillProgress]);

    const refetchDataCallback = useCallback(() => {
        if (errorRefetchCallback) errorRefetchCallback();
    }, [errorRefetchCallback]);

    const scoreKeyValueMemo = useMemo(() => {
        return (
            <>
                <KeyValueInfoBox
                    isEmphasized
                    keyLabel={t('skill.graphCard.target')}
                    valueLabel={
                        !isSkillAssigned
                            ? 'N/A'
                            : isMasterChecked
                              ? skillProgress?.scoreDetail.targetMaster
                              : skillProgress?.scoreDetail.targetProducer
                    }
                    valueSuffix={isSkillAssigned ? 'Points' : undefined}
                ></KeyValueInfoBox>
                <KeyValueInfoBox
                    keyLabel={t('skill.graphCard.personalHighScore')}
                    valueLabel={skillProgress?.scoreDetail.highScore}
                    valueSuffix='Points'
                ></KeyValueInfoBox>
                {isLeaderboardActive && (
                    <>
                        <KeyValueInfoBox
                            keyLabel={t('skill.graphCard.companyAverage')}
                            valueLabel={skillProgress?.scoreDetail.companyAverage}
                            valueSuffix='Points'
                        ></KeyValueInfoBox>
                        <KeyValueInfoBox
                            keyLabel={t('skill.graphCard.industryAverage')}
                            valueLabel={skillProgress?.scoreDetail.industryAverage}
                            valueSuffix='Points'
                        ></KeyValueInfoBox>
                    </>
                )}
            </>
        );
    }, [skillProgress, isSkillAssigned, isMasterChecked, isLeaderboardActive]);

    const streakKeyValueMemo = useMemo(() => {
        return (
            <>
                <KeyValueInfoBox
                    secondaryMode
                    keyLabel={t('skill.graphCard.longestStreak')}
                    valueLabel={isMasterChecked ? skillProgress?.masterStreakLongest : skillProgress?.streakLongest}
                    valueSuffix={longestWeekSuffixMemo}
                ></KeyValueInfoBox>
                <KeyValueInfoBox
                    secondaryMode
                    keyLabel={t('skill.graphCard.currentStreak')}
                    valueLabel={isMasterChecked ? skillProgress?.masterStreakCurrent : skillProgress?.streakCurrent}
                    valueSuffix={currentWeekSuffixMemo}
                ></KeyValueInfoBox>
            </>
        );
    }, [isMasterChecked, skillProgress, longestWeekSuffixMemo]);

    const masterSwitchMemo = useMemo(() => {
        return (
            <div className={isProducerMaxLevel || !isSkillAssigned ? classes.disabledMasterBox : classes.masterBox}>
                <MasterLabelBox>
                    <>
                        {masterLevelRequested && (
                            <Tooltip title={t('tooltips.masterLevelRequested')}>
                                <BlueWarningIcon />
                            </Tooltip>
                        )}
                        {t('skill.graphCard.master')}
                    </>
                </MasterLabelBox>
                <MasterSwitchBox>
                    <Tooltip title={isSkillAssigned || isProducerMaxLevel ? '' : t('tooltips.assignSkillFirst')}>
                        <Switch
                            color='secondary'
                            checked={isMasterChecked}
                            onChange={onMasterCheckedChange}
                            disabled={!isSkillAssigned || isProducerMaxLevel}
                            onKeyDown={(e: KeyboardEvent<any>) =>
                                accessibilityEnterSpaceKeyCallback(e, () => onMasterCheckedChange(e, !isMasterChecked))
                            }
                            inputProps={{ role: 'switch', 'aria-label': 'Master toggle switch' }}
                        />
                    </Tooltip>
                </MasterSwitchBox>
            </div>
        );
    }, [isSkillAssigned, isProducerMaxLevel, isMasterChecked, onMasterCheckedChange]);

    return (
        <RootPaper elevation={1}>
            <>
                <CardHeader>
                    <>
                        {t('skill.graphCard.title')}
                        <ExpandedCollapsedBox
                            tabIndex={0}
                            onKeyDown={(e: KeyboardEvent<any>) =>
                                accessibilityEnterSpaceKeyCallback(e, isExpanded ? collapse : expand)
                            }
                            onClick={isExpanded ? collapse : expand}
                        >
                            {isExpanded ? <ToCollapseIconSVG /> : <ToExpandIconSVG />}
                        </ExpandedCollapsedBox>
                    </>
                </CardHeader>
                {isLoading ? (
                    <Loading />
                ) : (
                    isExpanded && (
                        <>
                            {!isError && skillProgress ? (
                                <GraphCardWrapper>
                                    <GraphWrapper ref={graphWrapperRef}>
                                        <SkillGraph
                                            scores={skillProgress.scoreTimeline}
                                            dimension={{ width: graphWidth }}
                                            currentScore={skillProgress?.scoreDetail.score}
                                            currentScoreDate={skillProgress.currentScoreDate}
                                            target={
                                                isMasterChecked
                                                    ? skillProgress?.scoreDetail.targetMaster
                                                    : isSkillAssigned
                                                      ? skillProgress?.scoreDetail.targetProducer
                                                      : undefined
                                            }
                                            isEmpty={skillProgress.scoreTimeline.length === 0}
                                        />
                                    </GraphWrapper>
                                    <GraphDetailsWrapper className='skill-success-details'>
                                        {!isLgDown && (
                                            <>
                                                <KeyValueBox>
                                                    {masterSwitchMemo}
                                                    {scoreKeyValueMemo}
                                                </KeyValueBox>
                                                <StreakBox>{streakKeyValueMemo}</StreakBox>
                                            </>
                                        )}
                                        {isLgDown && (
                                            <LgDownGraphInfoWrapper>
                                                <LgDownScoreKeyValueBox>
                                                    {scoreKeyValueMemo}
                                                    {!isLeaderboardActive && streakKeyValueMemo}
                                                </LgDownScoreKeyValueBox>
                                                <LgDownStreakKeyValueBox>
                                                    {masterSwitchMemo}
                                                    {isLeaderboardActive && streakKeyValueMemo}
                                                </LgDownStreakKeyValueBox>
                                            </LgDownGraphInfoWrapper>
                                        )}
                                    </GraphDetailsWrapper>
                                </GraphCardWrapper>
                            ) : (
                                <SomethingWentWrongCard actionCallback={refetchDataCallback} />
                            )}
                        </>
                    )
                )}
            </>
        </RootPaper>
    );
};

export default SkillGraphCard;
