import { Dropdown, ITableHeadCell, Table, Tooltip, makeAccountDetailsRoute } from 'nulia-ui';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from 'react-feather';
import UpdateLicenseCountModal from '../../../components/modals/UpdateLicenseCountModal';
import { AddRemoveLicenceIconBox, CircleSpan, FilterBox, FilterTypography } from './Style';
import { SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ICustomerVM } from '../../../interfaces/views/ICustomerVM';
import { ETenantStatus } from '../../../interfaces/enums/ETenantStatus';
import { useTheme } from '@mui/system';

interface ITableHeader {
    tenantStatus: string;
    name: string;
    m365Licenses: string;
    nuliaLicensesAssigned: string;
    m365UsersWithoutNuliaLicenses: string;
    nuliaLicensesUnassigned: string;
    addRemoveLicenses: any;
}

interface IProps {
    customersData?: ICustomerVM[];
    isLoading: boolean;
    resellerFilter: string;
}

const AccountsTable: FC<IProps> = ({ customersData, isLoading, resellerFilter }) => {
    const { t } = useTranslation();
    const [isUpdateLicenseCountModalOpen, setUpdateLicenseCountModalOpen] = useState<boolean>(false);
    const [tenantStatusFilter, setTenantStatusFilter] = useState<ETenantStatus | 'All Statuses'>('All Statuses');
    const [filteredCustomersData, setFilteredCustomersData] = useState<ICustomerVM[] | undefined>();
    const currentNumberOfLicensesForOpenModal = useRef<number | undefined>(undefined);
    const navigate = useNavigate();
    const theme = useTheme();

    const onOpenUpdateLicenseModalClick = async (
        e: React.MouseEvent | React.KeyboardEvent,
        id: string,
        numberOfNuliaLicenses?: number
    ) => {
        e.stopPropagation();
        currentNumberOfLicensesForOpenModal.current = numberOfNuliaLicenses;
        setUpdateLicenseCountModalOpen(true);
    };

    const onCloseCallback = () => {
        setUpdateLicenseCountModalOpen(false);
    };

    useEffect(() => {
        let newFilteredCustomersData = customersData;
        if (resellerFilter === 'All Customers') {
            newFilteredCustomersData = customersData;
        } else {
            newFilteredCustomersData = customersData?.filter((customer) => customer.folderId === resellerFilter);
        }

        if (tenantStatusFilter !== 'All Statuses') {
            newFilteredCustomersData = newFilteredCustomersData?.filter(
                (customer) => customer.tenantStatus === tenantStatusFilter
            );
        }

        if (newFilteredCustomersData?.length === 0) setFilteredCustomersData(undefined);
        else setFilteredCustomersData(newFilteredCustomersData);
    }, [customersData, tenantStatusFilter, resellerFilter]);

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'tenantStatus',
            label: 'Status',
            customRender(value, row) {
                switch (value) {
                    case ETenantStatus.NEW:
                        return <CircleSpan color={theme.palette.status.assigned} />;
                    case ETenantStatus.ACTIVE:
                        return <CircleSpan color={theme.palette.status.attained} />;
                    case ETenantStatus.NEEDS_ATTENTION:
                        return <CircleSpan color={theme.palette.status.needAttention} />;
                    case ETenantStatus.RETIRED:
                        return <CircleSpan color='rgba(0, 0, 0, 0.08)' />;
                }
                return <></>;
            }
        },
        {
            id: 'name',
            label: 'Company name',
            minWidth: '140px'
        },
        {
            id: 'm365Licenses',
            label: 'Office 365 licenses',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'nuliaLicensesAssigned',
            label: 'Nulia licenses assigned',
            disablePadding: true,
            numeric: true,
            minWidth: '100px'
        },
        {
            id: 'm365UsersWithoutNuliaLicenses',
            label: 'Office 365 Users without Nulia',
            disablePadding: true,
            numeric: true,
            minWidth: '100px'
        },
        {
            id: 'nuliaLicensesUnassigned',
            label: 'Available licences',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'addRemoveLicenses',
            label: '',
            disablePadding: true,
            customRender(value, row: ICustomerVM) {
                return (
                    <AddRemoveLicenceIconBox
                        tabIndex={0}
                        onClick={(e) => onOpenUpdateLicenseModalClick(e, row.id, row.nuliaLicensesUnassigned)}
                    >
                        <Tooltip title={t('partnerTooltips.openUpdateLicenseModal')}>
                            <Edit />
                        </Tooltip>
                    </AddRemoveLicenceIconBox>
                );
            }
        }
    ];

    const onChangeAccountStatusFilter = (event: SelectChangeEvent<unknown>) =>
        setTenantStatusFilter(event.target.value as ETenantStatus);

    const onAccountTableRowClick = useCallback((id: string) => {
        navigate(makeAccountDetailsRoute(id));
    }, []);

    return (
        <div>
            <FilterBox>
                <FilterTypography variant='overline'>FILTER BY</FilterTypography>
                <Dropdown
                    items={[
                        { value: 'All Statuses', name: 'All Statuses' },
                        { value: ETenantStatus.NEW, name: 'New' },
                        { value: ETenantStatus.ACTIVE, name: 'Active' },
                        { value: ETenantStatus.NEEDS_ATTENTION, name: 'Needs Attention' },
                        { value: ETenantStatus.RETIRED, name: 'Retired' }
                    ]}
                    value={tenantStatusFilter}
                    handleChange={onChangeAccountStatusFilter}
                    defaultValue={tenantStatusFilter}
                />
            </FilterBox>
            <Table
                headCells={headCells}
                data={filteredCustomersData}
                propertyKeys={headCells.map((headCell) => headCell.id)}
                onRowClick={onAccountTableRowClick}
                customMediaColumnName='companyName'
                customMediaSecondaryColumns={['addRemoveLicenses']}
                initialOrderBy='companyName'
                isLoading={isLoading}
            />
            <UpdateLicenseCountModal
                currentLicenseNumber={currentNumberOfLicensesForOpenModal.current}
                isOpen={isUpdateLicenseCountModalOpen}
                onCloseCallback={onCloseCallback}
            />
        </div>
    );
};

export default AccountsTable;
