import { styled } from '@mui/system';
import { Colors } from '../../Colors';
import { Typography } from '@mui/material';

export const RootBox = styled('div')({
    height: '100vh',
    width: '100%',
    maxWidth: '100%'
});

export const ContactContainer = styled('div')({
    padding: 24,
    borderRadius: 4,
    backgroundColor: Colors.white,
    boxShadow: '0 1px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.08)'
});

export const ActionBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16
});

export const FeedbackSentBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

export const FeedbackSentMessageTypography = styled(Typography)({
    color: Colors.textPrimary,
    fontFamily: 'Open Sans',
    fontSize: 16,
    letterSpacing: 0.5,
    lineHeight: '24px',
    marginBottom: 24
});

export const StyledTextArea = styled('textarea')({
    color: Colors.textSecondary,
    fontFamily: 'Open Sans',
    fontSize: 16,
    letterSpacing: 0.5,
    lineHeight: '24px',
    border: 0,
    height: 200,
    width: '100%',
    resize: 'none',
    outline: 'none',
    caretColor: 'rgba(101,78,163,0.88)'
});
