import { Typography } from '@mui/material';
import styled from '../../theme/styled';

export const RootBox = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8
});

export const SkillInfoBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
});

export const GraphBox = styled('div')({
    minWidth: 94
});

export const TitleDescriptionWrapperBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 12,
    flex: 1
});

export const TitleBox = styled(Typography)(({ theme }) => ({
    minHeight: 70,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        fontSize: 12,
        lineHeight: '20px',
        letterSpacing: '0.5px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        lineHeight: '16px'
    }
}));

export const AssignedBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-around',
    justifyContent: 'center'
});
