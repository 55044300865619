import styled from '../../theme/styled';
import { LabelTypography } from '../../../css/CommonComponents';
import { RemoveCircleOutline, AddCircleOutline } from '@material-ui/icons';
import { IconButton } from '@mui/material';

export const AssignedBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-around',
    minWidth: 190,
    flex: 1,
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
        minWidth: 130
    }
}));

export const AssignedInnerBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isassigned'
})((props: { isassigned: number }) => ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 500,
    gap: '12px',
    color: props.isassigned ? theme.palette.primary.main : 'inherit'
}));

export const RemoveIconImg = styled(RemoveCircleOutline)({
    width: 20,
    height: 20
});

export const AddIconImg = styled(AddCircleOutline)({
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    maxHeight: 20
});

export const AssignedBySpan = styled(LabelTypography)({
    color: 'rgba(97, 96, 99, 1)'
});

export const AssignedDateSpan = styled(LabelTypography)({
    paddingLeft: 4,
    color: 'rgba(97, 96, 99, 1)'
});

export const AssignedInfoBox = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}));

export const AssignedInfoInnerBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'wrap'
})<{ wrap: boolean }>(({ theme, wrap }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    lineHeight: '15px',
    paddingRight: '42px',
    marginTop: 2,
    minHeight: 60,
    [theme.breakpoints.down('lg')]: {
        paddingRight: wrap ? 0 : '42px'
    }
}));

export const IsAssignedTitleSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'isCardMode'
})<{ isCardMode: boolean }>(({ theme, isCardMode }) => ({
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.25px',
    color: theme.palette.primary.main,
    textAlign: 'right',
    [theme.breakpoints.down('lg')]: {
        paddingRight: 0
    },
    [theme.breakpoints.down('md')]: {
        paddingRight: isCardMode ? 26 : 0
    }
}));

export const StyledIconButton = styled(IconButton)({
    padding: '4px'
});

export const MaintainedSpan = styled(LabelTypography)({
    textAlign: 'right',
    color: 'rgba(97, 96, 99, 1)'
});

export const InProgressSpan = styled(LabelTypography)({
    textAlign: 'right',
    color: 'rgba(97, 96, 99, 1)'
});

export const AssignControlWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'wrap' && prop !== 'isAssigned' && prop !== 'isCardMode'
})<{ wrap: boolean; isCardMode: boolean; isAssigned: boolean }>(({ theme, wrap, isCardMode, isAssigned }) => ({
    [theme.breakpoints.between('md', 'lg')]: {
        position: wrap ? 'absolute' : 'inherit',
        left: wrap ? '50%' : 'unset',
        zIndex: 2,
        top: wrap ? (isCardMode ? '100%' : isAssigned ? 'calc(100% + 10px)' : 'calc(100% + 27px)') : 'unset'
    }
}));
