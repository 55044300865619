import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const StyledButton = styled(Button)<{ disabled: boolean }>(({ theme, disabled }) => ({
    height: 36,
    minHeight: 36,
    maxHeight: 36,
    borderRadius: 4,
    fontSize: 10,
    letterSpacing: 1.25,
    fontWeight: 600,
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: disabled ? 'rgba(0,0,0,0.16)' : theme.palette.text.secondary,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: disabled ? 'not-allowed' : 'pointer',
    border: 0,
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        color: theme.palette.text.primary
    },
    '&:focus': {
        backgroundColor: 'rgba(101,78,163,0.08)',
        boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`
    },
    '&:active': {
        color: theme.palette.primary.main
    },
    padding: '6px 2px'
}));

export const PrimaryStyledButton = styled(Button)<{ disabled: boolean }>(({ theme, disabled }) => ({
    maxWidth: 240,
    height: 36,
    minHeight: 36,
    maxHeight: 36,
    borderRadius: 8,
    fontSize: 10,
    letterSpacing: 1.25,
    fontWeight: 500,
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: disabled ? 'rgba(0,0,0,0.16)' : theme.palette.primary.main,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: disabled ? 'not-allowed' : 'pointer',
    border: 0,
    outline: 0,
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.main,
        backgroundColor: 'transparent'
    },
    '&:active': {
        color: theme.palette.primary.main
    },
    '&:focus, &:focus-visible': {
        textDecoration: 'underline',
        outline: 'black auto 1px',
        borderRadius: 0
    },
    padding: '6px 2px'
}));
