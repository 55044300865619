import { Table, TableCell, TableContainer, TableRow, TableSortLabel, Typography, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTable = styled(Table)(({ theme }) => ({
    backgroundColor: theme.palette.common.white
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: theme.borderRadius?.outside?.small,
    width: '100%',
    backgroundColor: theme.palette.common.white
}));

export const StyledTableCell = styled(TableCell)({
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.64)',
    padding: '8px 16px'
});

export const StyledCheckboxTableCell = styled(StyledTableCell)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        padding: '8px 0px 8px 4px'
    }
}));

export const LgDownTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.64)',
    padding: '8px 16px',

    [theme.breakpoints.down('md')]: {
        padding: '10px 5px 2px 4px !important'
    },

    '& > div': {
        paddingBottom: '6px'
    }
}));

export const LgDownCellContentBox = styled('div')({
    display: 'flex',
    flexDirection: 'column'
});

export const LgDownCellItemSpan = styled('span')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gap: '20px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr'
    }
}));

export const LgDownCellItemLabel = styled('span')(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    color: 'rgba(140, 140, 140, 1)',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(700)]: {
        justifyContent: 'flex-start'
    }
}));

export const LgDownCellItemValue = styled('span')(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.64)',
    display: 'flex',
    [theme.breakpoints.down(700)]: {
        justifyContent: 'flex-start'
    }
}));

export const LgDownTableCellCustomMediaBox = styled('div')({
    display: 'flex',
    justifyContent: 'center'
});

export const StyledNoBorderTableCell = styled(StyledTableCell)({
    border: 'none'
});

export const TableStatusHeader = styled('div')(({ theme }) => ({
    padding: '13px 22px',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
        maxWidth: '160px'
    }
}));

export const ToolbarTypography = styled(Typography)({
    minWidth: 120,
    fontFamily: 'Open Sans',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.32)',
    whiteSpace: 'nowrap'
});

export const StyledTableRow = styled(TableRow, {
    shouldForwardProp: (prop) => prop !== 'isClickable'
})<{ isClickable?: boolean }>(({ isClickable = true }) => ({
    ':hover': {
        cursor: isClickable ? 'pointer' : 'default'
    }
}));

export const TableColumnImg = styled('img')({
    width: '32px',
    height: '32px',
    borderRadius: '16px'
});

export const LgDownTableColumnImg = styled('img')({
    width: '64px',
    height: '64px',
    borderRadius: '32px'
});

export const LgDownUserImageBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'orientation'
})<{ orientation?: 'right' | 'bottom' }>(({ theme, orientation }) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    flexDirection: orientation === 'bottom' ? 'column' : 'row',
    justifyContent: orientation === 'bottom' ? 'center' : 'flex-start',
    gap: '16px',
    paddingLeft: '10%',
    [theme.breakpoints.down('md')]: {
        paddingLeft: '5%'
    }
}));

export const LgDownUserInfoLabel = styled('span', {
    shouldForwardProp: (prop) => prop !== 'orientation'
})<{ orientation?: 'right' | 'bottom' }>(({ orientation }) => ({
    textAlign: orientation === 'bottom' ? 'center' : 'left'
}));

export const TableToolbar = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap'
    }
}));

export const DropdownLgDownBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isBorderBottomPresent'
})<{ isBorderBottomPresent?: boolean }>(({ theme, isBorderBottomPresent = false }) => ({
    backgroundColor: theme.palette.common.white,
    paddingTop: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px 0 8px',
    borderBottom: isBorderBottomPresent ? '1px solid rgba(0, 0, 0, 0.16)' : 'none',
    marginLeft: 'auto',
    marginTop: '8px',
    paddingBottom: '8px'
}));

export const DropdownTypography = styled(Typography)({
    width: '100px',
    marginTop: '4px',
    marginRight: '8px',
    color: 'rgba(0, 0, 0, 0.88)',
    textWrap: 'nowrap'
});

export const StyledTableSortLabel = styled(TableSortLabel, {
    shouldForwardProp: (prop) => prop !== 'isNotClickable'
})<{ isNotClickable?: boolean }>(({ isNotClickable, theme }) => ({
    '&.MuiTableSortLabel-root:hover, &.MuiTableSortLabel-root:focus': {
        color: isNotClickable && 'inherit'
    },
    '&.MuiTableSortLabel-root:focus': {
        outline: 'black auto 1px'
    },
    '& svg': {
        width: '24px',
        height: '24px',
        [theme.breakpoints.down('md')]: {
            width: '20px',
            height: '20px'
        }
    }
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    '&.MuiButtonBase-root': {
        [theme.breakpoints.down('md')]: {
            padding: '0 !important'
        }
    },
    '&.MuiCheckbox-root': {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    }
}));
