import { makeStyles } from '@material-ui/styles';
import CloseIcon from '../../icons/CloseIcon';
import { CSSProperties, FC, useState } from 'react';

const useStyles = makeStyles({
    closeDialogButton: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    }
});

export interface Props {
    onClose: () => void;
    style?: CSSProperties;
}

const CloseDialogButton: FC<Props> = ({ onClose, style }) => {
    const classes = useStyles();
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const [mouseDown, setMouseDown] = useState<boolean>(false);

    const onMouseOver = () => setMouseOver(true);
    const onMouseOut = () => {
        setMouseOver(false);
        setMouseDown(false);
    };
    const onMouseDown = () => setMouseDown(true);
    const onMouseUp = () => setMouseDown(false);

    return (
        <div
            style={style}
            className={classes.closeDialogButton}
            onClick={onClose}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        >
            <CloseIcon mouseOver={mouseOver} mouseDown={mouseDown} />
        </div>
    );
};

export default CloseDialogButton;
