import { Typography } from '@mui/material';
import styled from '../../theme/styled';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    [theme.breakpoints.down('md')]: {
        gap: 0
    }
}));

export const LeftArrowIconBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled'
})<{ isDisabled: boolean }>(({ isDisabled }) => ({
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 9px 6px 8px',
    '&:hover': {
        background: '#F5F5F5'
    }
}));

export const RightArrowIconBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled'
})<{ isDisabled: boolean }>(({ isDisabled }) => ({
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 8px 6px 9px',
    '&:hover': {
        background: '#F5F5F5'
    }
}));

export const InfoTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
        minWidth: '30px',
        display: 'flex',
        justifyContent: 'center'
    }
}));
