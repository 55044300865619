import BasicStatusCard, { EStatus } from '../../../components/basic-status-card/BasicStatusCard';
import { FC, useState } from 'react';
import {
    AdministratorStatusTypography,
    ContentRootBox,
    DescriptionTypography,
    FormBox,
    FormColumnNameTypography,
    FormItemRow,
    HeaderTitleTypography,
    StyledForm
} from './Style';
import { Controller, useForm } from 'react-hook-form';
import { NTextInput, PrimaryButton } from 'nulia-ui';

interface IAdministrator {
    id: string;
    name: string;
    email: string;
    status: string;
}

interface IProps {
    administratorList: IAdministrator[];
}

const Administrators: FC<IProps> = ({ administratorList }) => {
    const [newAdministrators, setNewAdministrators] = useState<IAdministrator[]>([]);

    const { control } = useForm({
        mode: 'onChange'
    });

    const addNewAdministrator = () => {
        const defaultAdministrator = {
            id: `new-${newAdministrators.length}`,
            name: '',
            email: '',
            status: 'new'
        };
        setNewAdministrators((newAdministrators) => [...newAdministrators, defaultAdministrator]);
    };

    return (
        <BasicStatusCard title='Administrators' status={EStatus.SUCCESS}>
            <ContentRootBox>
                <HeaderTitleTypography>
                    Connect your Office 365 Tenant to Fully Add Administrators
                </HeaderTitleTypography>
                <DescriptionTypography variant='body2'>
                    These people can bulk assign outcomes across the tenant.
                </DescriptionTypography>
                <FormBox>
                    <FormColumnNameTypography variant='body2'>Name</FormColumnNameTypography>
                    <FormColumnNameTypography variant='body2'>Email</FormColumnNameTypography>
                    <FormColumnNameTypography variant='body2'>Status</FormColumnNameTypography>
                    <StyledForm>
                        {[...administratorList, ...newAdministrators].map((administrator) => {
                            return (
                                <FormItemRow key={administrator.id}>
                                    <Controller
                                        name={`name-${administrator.id}`}
                                        control={control}
                                        defaultValue={administrator.name}
                                        render={({ field }) => (
                                            <NTextInput
                                                onChange={field.onChange}
                                                value={field.value}
                                                label=''
                                                field={field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`email-${administrator.id}`}
                                        control={control}
                                        defaultValue={administrator.email}
                                        render={({ field }) => (
                                            <NTextInput
                                                onChange={field.onChange}
                                                value={field.value}
                                                label=''
                                                field={field}
                                            />
                                        )}
                                    />
                                    <AdministratorStatusTypography variant='body2'>
                                        {administrator.status}
                                    </AdministratorStatusTypography>
                                </FormItemRow>
                            );
                        })}
                    </StyledForm>
                    <PrimaryButton clickHandler={addNewAdministrator} title='Add Another' style={{ width: '200px' }} />
                </FormBox>
            </ContentRootBox>
        </BasicStatusCard>
    );
};

export default Administrators;
