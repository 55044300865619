import { createContext, FC, useContext, PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetHowToPlaylistCategoriesQuery } from '../services/HowToService';
import { IHowTo } from '../interfaces/IHowTo';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

interface IProps {
    howToList?: IHowTo[];
    isDataLoading?: boolean;
}

const HowToContext = createContext<IProps>({} as IProps);

export const HowToProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const params = useParams();
    const {
        data: fetchedHowToData,
        refetch: refetchHowTo,
        isLoading: isDataLoading
    } = useGetHowToPlaylistCategoriesQuery(params?.id);
    const { resetQuery } = useApiStateValue();

    useEffect(() => {
        return () => {
            resetQuery(EApiQueryKey.HOW_TO_FETCH_PLAYLIST_BY_CATEGORY);
        };
    }, []);

    useEffect(() => {
        if (params && params.id) {
            refetchHowTo();
        }
    }, [params]);

    const howToState: IProps = {
        howToList: fetchedHowToData,
        isDataLoading
    };

    return <HowToContext.Provider value={howToState}>{children}</HowToContext.Provider>;
};

export const useHowToStateValue: () => IProps = () => useContext(HowToContext);

export default HowToContext;
