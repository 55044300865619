import { Typography } from '@mui/material';
import styled from '../../../../../ui/theme/styled';

export const RootBox = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
});

export const OutcomeNameTypography = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.8)'
});

export const DotTableBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingRight: '40px',
    paddingLeft: 0,
    [theme.breakpoints.down('lg')]: {
        paddingRight: 'inherit',
        paddingLeft: 'inherit'
    }
}));
