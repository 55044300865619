import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '20px',
    background: theme.palette.common.white,
    gap: '26px',
    borderRadius: theme.borderRadius?.outside?.large
}));

export const TitleBox = styled('div')({
    width: '100%'
});

export const TitleTypography = styled(Typography)({
    fontFamily: 'Ubuntu',
    fontWeight: 500
});

export const HeaderBox = styled('div')({
    width: '100%',
    display: 'flex',
    gap: '50px',
    flexWrap: 'wrap'
});

export const FooterBox = styled('div')({
    width: '100%'
});

export const ItemSpan = styled('span')({
    display: 'inline-flex',
    alignItems: 'center',
    gap: '15px'
});

export const ItemTitleTypography = styled(Typography)({});

export const HeaderContentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
});

export const HeaderRowBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '25px'
});
