import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const FilterBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    maxWidth: '330px'
}));

export const FilterTypography = styled(Typography)(({ theme }) => ({
    color: 'rgba(0,0,0,0.64)',
    marginRight: 16,
    marginLeft: 24,
    flex: 1,
    minWidth: '100px'
}));

export const AddRemoveLicenceIconBox = styled('div')({
    cursor: 'pointer',
    flex: 2
});

export const CircleSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'color'
})<{ color?: string }>(({ color, theme }) => ({
    backgroundColor: color,
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '12px',
    border: '2px solid rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('lg')]: {
        marginLeft: 0
    }
}));
