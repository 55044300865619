import { Typography } from '@mui/material';
import styled from '../../ui/theme/styled';

export const StyledForm = styled('form')({
    width: '100%',
    maxWidth: '820px'
});

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: 1080,
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
        width: 740
    },
    [theme.breakpoints.down('md')]: {
        width: 600,
        flexDirection: 'column'
    },
    [theme.breakpoints.down('sm')]: {
        width: 400
    }
}));

export const FormCard = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    boxShadow: '0px 2.5px 8px rgb(0 0 0 / 8%), 0px 1px 4px rgb(0 0 0 / 8%)',
    flex: 1,
    backgroundColor: theme.palette.common.white,
    padding: '24px',
    height: 'fit-content',
    maxWidth: 820,
    width: '100%'
}));

export const InfoTextBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
});

export const HeaderTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black
}));

export const HeaderDescription = styled(Typography)({});

export const HeaderSubtitle = styled(Typography)({
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.8)'
});

export const HeaderSuccessTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black
}));

export const SubmitBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end'
});
