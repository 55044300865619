import { createContext, FC, useContext, PropsWithChildren, useState } from 'react';
import { ICrumb } from '../interfaces/ICrumb';
import { routes } from '../pages/routes';

export interface ICrumbsContext {
    crumbs: ICrumb[];
    appendCrumb: (newCrumb: ICrumb) => void;
    startNewCrumbs: (newCrumb: ICrumb, includeHome?: boolean) => void;
    replaceLatestCrumb: (newCrumb: ICrumb) => void;
    clearCrumbsAfter: (crumbName: string, pathname: string) => void;
    removeLatestCrumb: () => void;
    setCrumbs: (crumbs: ICrumb[]) => void;
}

export const CrumbsContext = createContext<ICrumbsContext>({} as ICrumbsContext);

interface IProps {}

export const CrumbsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [crumbs, setCrumbs] = useState<ICrumb[]>([]);

    const appendCrumb = (newCrumb: ICrumb) => {
        setCrumbs((currentCrumbs) => {
            if (currentCrumbs.length === 0)
                return [
                    {
                        name: 'Home',
                        pathname: routes.HOME
                    },
                    newCrumb
                ];
            if (currentCrumbs[currentCrumbs.length - 1].pathname !== newCrumb.pathname)
                return [...currentCrumbs, newCrumb];
            return currentCrumbs;
        });
    };

    const startNewCrumbs = (newCrumb: ICrumb, includeHome: boolean = false) => {
        setCrumbs(
            includeHome && newCrumb.name !== 'Home'
                ? [
                      {
                          name: 'Home',
                          pathname: routes.HOME
                      },
                      newCrumb
                  ]
                : [newCrumb]
        );
    };

    const replaceLatestCrumb = (newCrumb: ICrumb) => {
        setCrumbs((currentCrumbs) => {
            return [...currentCrumbs.slice(0, -1), newCrumb];
        });
    };

    const clearCrumbsAfter = (name: string, pathname: string) => {
        setCrumbs((currentCrumbs) => {
            const crumbIndex = currentCrumbs.findIndex((crumb) => crumb.name === name && crumb.pathname === pathname);
            if (crumbIndex) return currentCrumbs.slice(0, crumbIndex + 1);
            return [];
        });
    };

    const removeLatestCrumb = () => {
        setCrumbs((currentCrumbs) => {
            if (currentCrumbs.length > 1) {
                return [...currentCrumbs.slice(0, -1)];
            }
            return [];
        });
    };

    const crumbsState: ICrumbsContext = {
        crumbs,
        appendCrumb,
        startNewCrumbs,
        replaceLatestCrumb,
        clearCrumbsAfter,
        removeLatestCrumb,
        setCrumbs
    };

    return <CrumbsContext.Provider value={crumbsState}>{children}</CrumbsContext.Provider>;
};

export const useCrumbsStateValue: () => ICrumbsContext = () => useContext(CrumbsContext);
