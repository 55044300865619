import { FC } from 'react';
import { Colors } from '../Colors';

interface Props {
    small?: boolean;
    color?: string;
}

const ChevronDownIcon: FC<Props> = ({ small, color }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='feather feather-chevron-down'
        color={color ? color : Colors.textSecondary}
        style={{ height: small ? 16 : undefined, width: small ? 16 : undefined }}
    >
        <polyline points='6 9 12 15 18 9' />
    </svg>
);

export default ChevronDownIcon;
