import { X } from 'react-feather';
import { styled } from '@mui/system';

export const NavigationLogoImg = styled('img')<{ color?: string }>(({ color }) => ({
    width: 42,
    color
}));

export const NavigationBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 2.5px 8px 0.5px rgba(0,0,0,0.08), 0 1px 4px 0 rgba(0,0,0,0.08)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: 110,
    paddingTop: 16,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 1,
    flex: 1,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
        width: '100vw',
        minHeight: 'unset',
        height: '100vh'
    }
}));

export const PartnerNavigationBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 2.5px 8px 0.5px rgba(0,0,0,0.08), 0 1px 4px 0 rgba(0,0,0,0.08)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: 110,
    paddingTop: 16,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 1,
    flex: 1,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
        width: '100vw',
        minHeight: 'unset',
        height: '100vh'
    }
}));

export const Logo = styled('div')(({ theme }) => ({
    margin: '0 auto',
    paddingBottom: 32,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: 0
    }
}));

export const Links = styled('div')({
    overflow: 'auto',
    overflowX: 'hidden',
    marginBottom: 'auto',
    position: 'relative',
    height: '100%'
});

export const Version = styled('div')<{ color?: string }>(({ theme, color }) => ({
    width: '100%',
    textAlign: 'center',
    marginTop: 25,
    marginBottom: 25,
    color: color,
    [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginBottom: 10
    }
}));

export const StyledX = styled(X)<{ color?: string }>(({ color }) => ({
    cursor: 'pointer',
    color
}));
