import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@mui/system';
import {
    useGetInsightsOrganizationUserSkillsQuery,
    useGetInsightsPersonalSkillsQuery
} from '../services/InsightsQueryService';
import { IInsightsPersonalSkillVM } from '../interfaces/views/IInsightsPersonalSkillVM';
import useFilterSearch from '../hooks/useFilterSearch';
import { IInsightsPersonalSkillGraphVM } from '../interfaces/views/IInsightsPersonalSkillGraphVM';
import { useInsightsPersonalStateValue } from './InsightsPersonalContext';
import { IScoreLine } from '../interfaces/IScoreLine';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import { EInsightsMode } from '../interfaces/enums/EInsightsMode';
import { useInsightsStateValue } from './InsightsContext';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

export interface IInsightsPersonalSkillsContext {
    data?: IInsightsPersonalSkillVM[];
    graphData: IInsightsPersonalSkillGraphVM[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    scoreLines?: IScoreLine[];
    changeScoreLinesInterval: (option: EGraphCardSelect) => void;
    isLoading: boolean;
    isError: boolean;
}

export const InsightsPersonalSkillsContext = createContext<IInsightsPersonalSkillsContext>(
    {} as IInsightsPersonalSkillsContext
);

export const InsightsPersonalSkillsProvider: FC<PropsWithChildren> = ({ children }) => {
    const { id: paramsId } = useParams<{ id?: string }>();
    const { mode } = useInsightsStateValue();
    const {
        data: fetchedData,
        isFetching: isPersonalSkillsFetching,
        isRefetching: isPersonalSkillsRefetching,
        refetch: refetchPersonalSkills,
        isError: isErrorFetchPersonalSkillData
    } = useGetInsightsPersonalSkillsQuery();
    const [data, setData] = useState<IInsightsPersonalSkillVM[]>([]);
    const [filteredData, setFilteredData] = useState<IInsightsPersonalSkillVM[] | undefined>();
    const [graphData, setGraphData] = useState<IInsightsPersonalSkillGraphVM[]>([]);
    const [scoreLines, setScoreLines] = useState<IScoreLine[] | undefined>();
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const { getInsightsPersonalSkills, data: graphTotalData } = useInsightsPersonalStateValue();
    const {
        refetch: refetchOrganizationUserSkills,
        data: fetchedOrganizitonUserSkillData,
        isFetching: isOrganizationUserSkillsFetching,
        isRefetching: isOrganizationUserSkillsRefetching,
        isError: isErrorFetchOrganizationUserSkillData
    } = useGetInsightsOrganizationUserSkillsQuery(paramsId);
    const theme = useTheme();
    const { setUserName } = useInsightsStateValue();
    const { invalidateQueryCache } = useApiStateValue();

    useEffect(() => {
        setData([]);
    }, []);

    useEffect(() => {
        if (fetchedData) {
            setData(fetchedData);
            setFilteredData(fetchedData);
        }
    }, [fetchedData]);

    useEffect(() => {
        if (fetchedOrganizitonUserSkillData && fetchedOrganizitonUserSkillData.data) {
            setData(fetchedOrganizitonUserSkillData.data);
            setFilteredData(fetchedOrganizitonUserSkillData.data);
            setUserName(fetchedOrganizitonUserSkillData.userName);
        }
    }, [fetchedOrganizitonUserSkillData]);

    useEffect(() => {
        if (paramsId) {
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_PERSONAL_SKILL);
            refetchOrganizationUserSkills();
        } else {
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_SKILLS);
            refetchPersonalSkills();
        }
    }, [paramsId, mode]);

    useEffect(() => {
        if (graphTotalData && graphTotalData.length > 0) {
            const data = getInsightsPersonalSkills();
            setGraphData(data);

            const assignedScoreLine: IScoreLine = {
                id: 'W1',
                name: 'Assigned',
                color: theme.palette.status.assigned,
                scores: []
            };
            const inProgressScoreLine: IScoreLine = {
                id: 'W4',
                name: 'In Progress',
                color: theme.palette.status.inProgress,
                scores: []
            };
            const attainedScoreLine: IScoreLine = {
                id: 'W2',
                name: 'Attained',
                color: theme.palette.status.attained,
                scores: []
            };
            const needAttentionScoreLine: IScoreLine = {
                id: 'W3',
                name: 'Need Attention',
                color: theme.palette.status.needAttention,
                legendColor: '#ED8000',
                scores: []
            };
            data.forEach((dataItem) => {
                assignedScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.skillsAssigned
                });
                inProgressScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.skillsInProgress
                });
                attainedScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.skillsAttained
                });
                needAttentionScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.skillsNeedAttention
                });
            });

            const allIndividualScoreTimes = [
                expandDatesToLastYearPerWeek(assignedScoreLine),
                expandDatesToLastYearPerWeek(inProgressScoreLine),
                expandDatesToLastYearPerWeek(attainedScoreLine),
                expandDatesToLastYearPerWeek(needAttentionScoreLine)
            ];

            const scoreLines4Month = allIndividualScoreTimes.map((scoreTime) => {
                return {
                    ...scoreTime,
                    scores: scoreTime.scores.slice(-15)
                };
            });
            setScoreLines(scoreLines4Month);

            scoreLinesAllOptionsRef.current = [scoreLines4Month, allIndividualScoreTimes];
        } else {
            setScoreLines([]);
            scoreLinesAllOptionsRef.current = [];
        }
    }, [graphTotalData]);

    const { searchText, setSearchText } = useFilterSearch<IInsightsPersonalSkillVM>({
        data: data,
        dataSerachablePropertyName: 'skillName',
        setDataCallback: setFilteredData
    });

    const changeScoreLinesInterval = (option: EGraphCardSelect) => {
        if (option === EGraphCardSelect.MONTH_4) {
            setScoreLines(scoreLinesAllOptionsRef.current[0]);
        } else if (option === EGraphCardSelect.YEAR_WITH_WEEKS) {
            setScoreLines(scoreLinesAllOptionsRef.current[1]);
        }
    };

    const insightsPersonalSkillsContext: IInsightsPersonalSkillsContext = {
        data: filteredData,
        graphData,
        searchText,
        setSearchText,
        scoreLines,
        changeScoreLinesInterval,
        isLoading:
            mode === EInsightsMode.STANDARD
                ? isPersonalSkillsFetching || isPersonalSkillsRefetching
                : isOrganizationUserSkillsFetching || isOrganizationUserSkillsRefetching,
        isError: mode === EInsightsMode.STANDARD ? isErrorFetchPersonalSkillData : isErrorFetchOrganizationUserSkillData
    };

    return (
        <InsightsPersonalSkillsContext.Provider value={insightsPersonalSkillsContext}>
            {children}
        </InsightsPersonalSkillsContext.Provider>
    );
};

export const useInsightsPersonalSkillsStateValue: () => IInsightsPersonalSkillsContext = () =>
    useContext(InsightsPersonalSkillsContext);
