import { PublicClientApplication } from '@azure/msal-browser';

export const getAuthService = (clientId: string, redirectUri: string) =>
    new PublicClientApplication({
        auth: {
            clientId,
            authority: 'https://login.microsoftonline.com/common',
            redirectUri
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false
        }
    });
