import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useShowcaseStateValue } from '../../../contexts/ShowcaseContext';
import AchievementCard from '../components/achievement-card/AchievementCard';
import RankingsCard from '../components/rankings-card/RankingsCard';
import ShowcaseBadgeCard from '../components/showcase-badge-card/ShowcaseBadgeCard';
import { AchievementWrapper, RootBox, RootInnerBox } from './Style';
import { EToastSeverity, useToastContextStateValue } from '../../../contexts/ToastContext';
import { routes } from '../../../pages/routes';

const ShowcaseHome: FC = () => {
    const {
        achievementGroupList,
        showcaseBadgeCardData,
        isBadgesDataLoading,
        isAchievementsDataLoading,
        rankingsData,
        isRankingsDataLoading,
        isRankingsDataFetchingError,
        isBadgesDataFetchingError,
        refetchRankingsCallback,
        refetchBadgesCallback,
        isAchievementsDataFetchingError,
        refetchAchievementsCallback,
        enableLeaderboard
    } = useShowcaseStateValue();
    const [searchParams] = useSearchParams();
    const { setToastMessage } = useToastContextStateValue();
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        const successShareBadge = searchParams.get('successShareBadge');
        if (successShareBadge === '1' && location.state?.from === routes.LINKEDIN)
            setToastMessage({
                isOpen: true,
                message: t('badges.shareBadgeSuccess'),
                severity: EToastSeverity.SUCCESS
            });
    }, [searchParams]);

    return (
        <RootBox>
            <RootInnerBox isRankingPresent={!!enableLeaderboard}>
                <ShowcaseBadgeCard
                    badgeGroups={showcaseBadgeCardData}
                    isLoading={isBadgesDataLoading}
                    isError={isBadgesDataFetchingError}
                    errorCallback={refetchBadgesCallback}
                    isMaxSize={!enableLeaderboard}
                />
                {enableLeaderboard && (
                    <RankingsCard
                        rankingsData={rankingsData}
                        isLoading={isRankingsDataLoading}
                        isError={isRankingsDataFetchingError}
                        errorCallback={refetchRankingsCallback}
                    />
                )}
                <AchievementWrapper isFullWidth={!enableLeaderboard}>
                    <AchievementCard
                        achievementGroupList={achievementGroupList}
                        isLoading={isAchievementsDataLoading}
                        isError={isAchievementsDataFetchingError}
                        errorCallback={refetchAchievementsCallback}
                    />
                </AchievementWrapper>
            </RootInnerBox>
        </RootBox>
    );
};

export default ShowcaseHome;
