import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IRecommendationVM } from '../../../interfaces/views/IRecommendationVM';
import PrevNextItem from '../../../ui/general/prev-next-item/PrevNextItem';
import RecommendationCardItem from './recommendation-card-item/RecommendationCardItem';
import { RootBox, TitleBox, TitleTypography, ContentBox, RecommendationCardItemWrapper } from './Style';
import NoDataCard from '../no-data-card/NoDataCard';
import usePrevNextVisible from '../../../hooks/usePrevNextVisible';

interface IProps {
    cardItems?: IRecommendationVM[];
    isLoading: boolean;
    successRecommendationCallback?: () => void;
}

const RecommendationCard: FC<IProps> = ({ cardItems, isLoading, successRecommendationCallback }) => {
    const { changeHandler, isVisible, visibleIndex } = usePrevNextVisible({ step: 2 });
    const { t } = useTranslation();

    const contentMemo = useMemo(() => {
        const loadingSkeleton = (
            <>
                <RecommendationCardItemWrapper isVisible>
                    <RecommendationCardItem isLoading />
                </RecommendationCardItemWrapper>

                <RecommendationCardItemWrapper isVisible>
                    <RecommendationCardItem isLoading />
                </RecommendationCardItemWrapper>
            </>
        );
        if (!isLoading) {
            if (cardItems && cardItems.length > 0) {
                return cardItems.map((cardItem, index) => {
                    return (
                        <RecommendationCardItemWrapper key={index} isVisible={isVisible(index) ?? false}>
                            <RecommendationCardItem
                                isVisible={isVisible(index)}
                                recommendation={cardItem}
                                successRecommendationCallback={successRecommendationCallback}
                            />
                        </RecommendationCardItemWrapper>
                    );
                });
            } else if (!cardItems) {
                return loadingSkeleton;
            }
            return (
                <NoDataCard
                    textStyle={{ fontSize: 14 }}
                    message={t('recommendations.noRecommendationsAvailable')}
                    noBorderShadow
                />
            );
        }
        return loadingSkeleton;
    }, [isLoading, cardItems, visibleIndex]);

    return (
        <RootBox>
            <TitleBox>
                <TitleTypography variant='subtitle1'>{t('recommendations.goalsForThisWeek')}</TitleTypography>
                <PrevNextItem
                    isNextDisabled={!cardItems || visibleIndex + 2 >= cardItems.length}
                    isPreviousDisabled={visibleIndex <= 0}
                    changeHandler={changeHandler}
                    infoJsx={
                        cardItems && cardItems.length > 0
                            ? `${visibleIndex / 2 + 1} / ${Math.ceil(cardItems?.length / 2)}`
                            : ''
                    }
                />
            </TitleBox>
            <ContentBox>{contentMemo}</ContentBox>
        </RootBox>
    );
};

export default RecommendationCard;
