import styled from '../../../../ui/theme/styled';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '12px',
    paddingLeft: '20px',
    paddingRight: '20px',
    width: 1080,
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        flexDirection: 'column'
    }
}));

export const RoleMainContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px'
});

export const RootInnerStatusWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    flex: 11,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}));

export const RolesStatusOverallWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled'
})<{ isDisabled: boolean }>(({ isDisabled }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    paddingBottom: 24,
    flex: 4,
    pointerEvents: isDisabled ? 'none' : 'unset',
    opacity: isDisabled ? 0.4 : 1
}));

export const RoleFiltersWrapper = styled('div')({
    flex: 1
});

export const RoleFilterContentWrapper = styled('div')({
    display: 'flex',
    gap: '20px',
    flex: 4
});

export const HeaderActionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row-reverse'
});

export const RolesAssignmentHistoryBox = styled('div')(({ theme }) => ({
    flex: 4,
    maxWidth: '26%',
    [theme.breakpoints.down('lg')]: {
        flex: 'unset',
        maxWidth: 'unset'
    }
}));

export const ActionButtonsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    paddingLeft: '20px',
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}));
