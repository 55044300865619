import { FC, useState } from 'react';
import { Colors } from '../../../Colors';
import ChevronDownIcon from '../../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../../icons/ChevronUpIcon';
import { useTranslation } from 'react-i18next';
import { ButtonBox, DropdownOptionBox, DropdownOptionsBox, ExpandBox, MainButtonBox, RootBox } from './Style';
import AddCustomerDialog from '../../dialogs/add-customer-dialog/AddCustomerDialog';
import AddResellerDialog from '../../dialogs/add-reseller-dialog/AddResellerDialog';
import MoveAccountDialog from '../../dialogs/move-account-dialog/MoveAccountDialog';
import { useModal } from 'nulia-ui';

interface Props {
    resellerId: string;
    resellerName: string;
    parentId?: string;
}

const AccountAddButton: FC<Props> = ({ resellerId, resellerName, parentId }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { t } = useTranslation();
    const { isOpen: isAddCustomerOpen, setOpen: setAddCustomerOpen } = useModal({ defaultOpen: false });
    const { isOpen: isAddResellerOpen, setOpen: setAddResellerOpen } = useModal({ defaultOpen: false });
    const { isOpen: isAssociateAccountOpen, setOpen: setAssociateAccountOpen } = useModal({ defaultOpen: false });

    const toggleExpand = () => setExpanded(!expanded);
    const toggleAddCustomer = () => {
        setAddCustomerOpen(!isAddCustomerOpen);
        setExpanded(false);
    };
    const toggleAddReseller = () => {
        setAddResellerOpen(!isAddResellerOpen);
        setExpanded(false);
    };
    const toggleAssociateAccount = () => {
        setAssociateAccountOpen(!isAssociateAccountOpen);
        setExpanded(false);
    };

    // const resellerAccounts = accounts.get(resellerId);

    // let resellers: ResellerSimple[] = resellerAccounts
    //     ? resellerAccounts
    //           .filter((a) => a.type === AccountType.Reseller)
    //           .map((a) => ({
    //               id: a.id,
    //               name: a.name,
    //               // @ts-ignore
    //               parentId: a.type === AccountType.Reseller ? a.parentId : a.resellerId
    //           }))
    //     : [];
    // resellers = [{ id: resellerId, name: resellerName, parentId }, ...resellers];

    return (
        <RootBox>
            {
                <ButtonBox>
                    <MainButtonBox onClick={toggleAddCustomer}>{t('accounts.add.addButtonCustomer')}</MainButtonBox>
                    <ExpandBox isExpanded={expanded} onClick={toggleExpand}>
                        {!expanded ? (
                            <ChevronDownIcon small={true} color={Colors.white} />
                        ) : (
                            <ChevronUpIcon small={true} color={Colors.white} />
                        )}
                    </ExpandBox>
                </ButtonBox>
            }
            {expanded && (
                <DropdownOptionsBox>
                    <DropdownOptionBox onClick={toggleAddReseller}>
                        {t('accounts.add.addButtonReseller')}
                    </DropdownOptionBox>
                    <DropdownOptionBox onClick={toggleAssociateAccount}>
                        {t('accounts.add.addButtonMoveAccount')}
                    </DropdownOptionBox>
                </DropdownOptionsBox>
            )}
            <AddCustomerDialog open={isAddCustomerOpen} onClose={toggleAddCustomer} />
            <AddResellerDialog open={isAddResellerOpen} onClose={toggleAddReseller} />
            <MoveAccountDialog open={isAssociateAccountOpen} onClose={toggleAssociateAccount} />
        </RootBox>
    );
};

export default AccountAddButton;
