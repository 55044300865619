import { styled } from '@mui/system';

export const RootBox = styled('div')({
    height: '100vh',
    paddingTop: 10,
    paddingLeft: 0
});

export const ContentBox = styled('div')(({ theme }) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 24,
    width: 1080,
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        flexDirection: 'column'
    }
}));
