import { createContext, FC, useContext, PropsWithChildren, useState } from 'react';
import { useGetAccountDetailsQuery } from '../services/AccountsQueryService';
// import { EAccountStatus } from '../interfaces/enums/EAccountStatus';
// import { EAccountType } from '../interfaces/enums/EAccountType';
import { IAccountDetailsVM } from '../interfaces/views/IAccountDetailsVM';
import { IActivityVM } from '../interfaces/views/IActivityVM';
import { EActivityType } from '../interfaces/enums/EActivityType';

export interface IAccountDetailsContext {
    data?: IAccountDetailsVM[];
    activityData?: IActivityVM[];
    isAccountDetailsDataLoading: boolean;
    isAccountDetailsDataFetchingError: boolean;
    isActivityDataLoading: boolean;
    isActivityDataFetchingError: boolean;
}

export const AccountDetailsContext = createContext<IAccountDetailsContext>({} as IAccountDetailsContext);

interface IProps {}

export const AccountDetailsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [activityData] = useState([
        {
            id: '1',
            timestamp: 'Sep 24, 2024 6:02 PM',
            activityBy: 'John Doe',
            activityType: EActivityType.CHANGE_NAME,
            message: 'Name changed from "ACustomer Name" to "ACustomer Name"'
        },
        {
            id: '2',
            timestamp: 'Sep 14, 2024 6:12 PM',
            activityBy: 'Nick Pope',
            activityType: EActivityType.REMOVE_ADMIN,
            message: '"dusan.sancanin@nulia.com" Removed as a Nulia Admin'
        }
    ]);
    const {
        data: fetchedAccountsData,
        isLoading: isAccountDetailsDataLoading,
        isError: isAccountDetailsDataFetchingError
    } = useGetAccountDetailsQuery();

    const accountDetailsState: IAccountDetailsContext = {
        data: fetchedAccountsData || [],
        isAccountDetailsDataLoading,
        isAccountDetailsDataFetchingError,
        activityData: activityData,
        isActivityDataFetchingError: false,
        isActivityDataLoading: false
    };

    return <AccountDetailsContext.Provider value={accountDetailsState}>{children}</AccountDetailsContext.Provider>;
};

export const useAccountDetailsStateValue: () => IAccountDetailsContext = () => useContext(AccountDetailsContext);
