import React, { CSSProperties, FC } from 'react';
import { ButtonRoot } from './Style';

interface IProps {
    clickHandler?: (() => void) | ((e: React.MouseEvent) => void);
    title?: string;
    children?: React.ReactNode;
    style?: CSSProperties;
    disabled?: boolean;
    type?: string;
}

const PrimaryButton: FC<IProps> = ({ clickHandler, title, style, disabled, children }) => {
    return (
        <ButtonRoot style={{ ...style }} onClick={clickHandler} disabled={!!disabled}>
            {title}
            {children}
        </ButtonRoot>
    );
};

export default PrimaryButton;
