import { FC, useMemo } from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Page from '../../components/page/Page';
import PageContent from '../../components/page-content/PageContent';
import { createUrl, ICreateUrlResponse, NoDataCard, PrimaryButton, SomethingWentWrongCard } from 'nulia-ui';
import {
    ResourceActionBox,
    ResourceDescriptionTypography,
    ResourceItemBox,
    ResourceItemInfoBox,
    ResourceName,
    ResourcesInnerBox,
    RootBox
} from './Style';
import { useResourcesStateValue } from '../../contexts/ResourcesContext';
import { IResourceVM } from '../../interfaces/views/IResourceVM';
import { t } from 'i18next';

const Resources: FC = () => {
    const {
        data: resources,
        isResourcesDataFetchingError,
        isResourcesDataLoading,
        refetchResourcesCallback
    } = useResourcesStateValue();

    const LoadingResource: FC<{ inner?: boolean }> = ({ inner }) => (
        <Box marginBottom={inner ? 2 : undefined} style={{ maxWidth: '800px', width: '100%', minWidth: '500px' }}>
            <Skeleton variant='rect' height={118} width='100%' />
        </Box>
    );

    const onClick = (resource: IResourceVM) => (event: unknown) => {
        const formattedUrl: ICreateUrlResponse | undefined = createUrl(resource.url);
        if (formattedUrl && formattedUrl.generatedUrl) {
            window.open(formattedUrl?.generatedUrl, '_blank');
        }
    };

    const contentMemo = useMemo(() => {
        if (isResourcesDataFetchingError) {
            return (
                <SomethingWentWrongCard actionCallback={refetchResourcesCallback} boxStyle={{ boxShadow: 'none' }} />
            );
        }
        if (isResourcesDataLoading)
            return (
                <>
                    <LoadingResource inner={true} />
                    <LoadingResource inner={true} />
                    <LoadingResource inner={true} />
                    <LoadingResource inner={true} />
                    <LoadingResource />
                </>
            );
        if (resources?.length === 0) {
            return <NoDataCard boxStyle={{ boxShadow: 'none' }} />;
        }
        if (resources)
            return (
                <>
                    {resources.map((resource, index) => (
                        <ResourceItemBox key={`${resource.title}${index}`}>
                            <ResourceItemInfoBox>
                                <ResourceName>{resource.title}</ResourceName>
                                <ResourceDescriptionTypography>{resource.description}</ResourceDescriptionTypography>
                            </ResourceItemInfoBox>
                            <ResourceActionBox>
                                <PrimaryButton
                                    clickHandler={onClick(resource)}
                                    style={{ width: 120 }}
                                    title={t('resources.viewButton')}
                                />
                            </ResourceActionBox>
                        </ResourceItemBox>
                    ))}
                </>
            );
        return <></>;
    }, [resources, isResourcesDataFetchingError, isResourcesDataLoading]);

    return (
        <Page>
            <PageContent>
                <RootBox>
                    <ResourcesInnerBox>{contentMemo}</ResourcesInnerBox>
                </RootBox>
            </PageContent>
        </Page>
    );
};

export default Resources;
