import { styled } from '@mui/material/styles';
import { FormControl, MenuItem, Typography } from '@mui/material';
import Select from '@mui/material/Select';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    background: 'linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%), #FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08),0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    borderRadius: theme.borderRadius?.outside?.small
}));

export const TitleBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    position: 'relative'
});

export const TitleTypography = styled(Typography)(({ theme }) => ({
    padding: '16px 24px',
    color: theme.palette.gray.dark,
    flex: 1,
    [theme.breakpoints.down('lg')]: {
        padding: '12px 24px',
        fontWeight: 500
    },
    [theme.breakpoints.down('md')]: {
        padding: '4px 10px'
    }
}));

export const ContentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '448px',
    overflowY: 'auto',
    overlowX: 'auto',
    [theme.breakpoints.down('lg')]: {
        height: '400px'
    }
}));

export const StyledSelect = styled(Select<string[]>)(({ theme }) => ({
    '.MuiSvgIcon-root': {
        top: 3
    },
    '.MuiSelect-select': {
        outline: '0 !important',
        width: '210px',
        overflow: 'hidden',
        display: 'inline-block !important',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        position: 'relative',
        top: '2px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px !important'
        }
    },
    '.MuiSelect-icon': {
        transform: 'rotate(180deg)'
    },
    '.MuiSelect-iconOpen': {
        transform: 'rotate(0deg)'
    }
}));

export const MenuItemTitleSpan = styled('span')({
    paddingLeft: 6,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '28px',
    letterSpacing: '0.5px'
});

export const StyledMenuItem = styled(MenuItem)({});

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: 210,
    display: 'flex',
    margin: 1,
    marginRight: '25px',
    [theme.breakpoints.down('md')]: {
        marginRight: '5px',
        width: '150px'
    }
}));
