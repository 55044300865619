import { UseMutationResult, useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { ICurrentUserVM } from '../interfaces/views/ICurrentUserVM';
import { mapCurrentUser, mapUserRankings } from './helpers/mappers';
import { apiInstance } from './helpers/apiInstance';
import axios from 'axios';
import { ITenantPreferencesDTO } from '../interfaces/dtos/ITenantPreferencesDTO';
import { IUserPreferenceRequestDTO } from '../interfaces/dtos/IUserPreferenceRequestDTO';
import { ITenantPreferenceRequestDTO } from '../interfaces/dtos/ITenantPreferencesRequestDTO';
import { IUserRankingsVM } from '../interfaces/views/IUserRankingsVM';
import { IUserContentTrackingRequestDTO } from '../interfaces/dtos/IUserContentTrackingRequestDTO';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

export interface IUserImageResponse {
    contentType: string;
    data: string;
    id: string;
}

export interface IUserImageRequest {
    userIds: string[];
}

// POST FETCH USER IMAGES
export const postFetchUserImages = async (variables: IUserImageRequest & { authParams: IAuthParams }) => {
    const { userIds, authParams } = variables;
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await axios.post(
        `${process.env.REACT_APP_IMAGES_URL}images/list`,
        { userIds },
        {
            withCredentials: false,
            headers: {
                ...headers.headers
            }
        }
    );
    return data;
};

export const usePostUserImagesQuery: () => UseMutationResult<IUserImageResponse[], Error, IUserImageRequest> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: async (variables: IUserImageRequest) => {
            return postFetchUserImages({ ...variables, authParams });
        }
    });
};

// GET USER CURRENT
export const getCurrentUser = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`users/current`, {
        withCredentials: false,
        headers: {
            ...headers.headers
        }
    });
    return mapCurrentUser(data);
};

export const useGetCurrentUserQuery: () => UseQueryResult<ICurrentUserVM, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.USER_FETCH_CURRENT],
        queryFn: async () => {
            return getCurrentUser(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET TENANT PREFERENCES
export const getTenantPreferences = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`tenant/current`, headers);
    return data;
};

export const useGetTenantPreferencesQuery: () => UseQueryResult<ITenantPreferencesDTO, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.USER_FETCH_TENANT_PREFERENCES],
        queryFn: async () => {
            return getTenantPreferences(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// POST USERS CURRENT PREFERENCES
export const postUsersCurrentPreferences: (
    variables: IUserPreferenceRequestDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...userPreferenceRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`users/current/preferences`, userPreferenceRequestData, headers);
    return data;
};

export const usePostUsersCurrentPreferences: () => UseMutationResult<
    undefined,
    Error,
    IUserPreferenceRequestDTO
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IUserPreferenceRequestDTO) => {
            return postUsersCurrentPreferences({ ...variables, authParams });
        }
    });
};

// POST USERS TENANT PREFERENCES
export const postUsersTenantPreferences: (
    variables: ITenantPreferenceRequestDTO & { authParams: IAuthParams }
) => Promise<ITenantPreferencesDTO> = async (variables) => {
    const { authParams, ...tenantPreferenceRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`tenant/preferences`, tenantPreferenceRequestData, headers);
    return data;
};

export const usePostUsersTenantPreferences: () => UseMutationResult<
    ITenantPreferencesDTO,
    Error,
    ITenantPreferenceRequestDTO
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: ITenantPreferenceRequestDTO) => {
            return postUsersTenantPreferences({ ...variables, authParams });
        }
    });
};

// GET CURRENT USER IMAGE
export const getCurrentUserImage = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await axios.get(`${process.env.REACT_APP_IMAGES_URL}images/user`, {
        withCredentials: false,
        headers: {
            ...headers.headers
        }
    });

    return data;
};

export const useGetCurrentUserImageQuery: () => UseQueryResult<IUserImageResponse, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.USER_FETCH_IMAGE],
        queryFn: async () => {
            return getCurrentUserImage(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET USER RANKINGS
export const getUserRankingsQuery = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`userRankings`, headers);
    return mapUserRankings(data);
};

export const useGetUserRankingsQuery: () => UseQueryResult<IUserRankingsVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.USER_FETCH_RANKING],
        queryFn: async () => {
            return getUserRankingsQuery(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// POST USER CONTENT TRACKING
export const postUserContentTracking: (
    variables: IUserContentTrackingRequestDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...contentTracking } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`/user-content`, contentTracking, headers);
    return data;
};

export const usePostUserContentTrackingQuery: () => UseMutationResult<
    undefined,
    Error,
    IUserContentTrackingRequestDTO
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IUserContentTrackingRequestDTO) => {
            return postUserContentTracking({ ...variables, authParams });
        }
    });
};
