import { createContext, FC, useContext, useEffect, useState, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import ILanguages from '../interfaces/ILanguages';

interface TranslationContextProps {
    activeLanguage: ILanguages;
    setActiveLanguage: (code: ILanguages) => void;
}

const TranslationContext = createContext<TranslationContextProps>({} as TranslationContextProps);

interface IProps {}

export const TranslationProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [activeLanguage, setActiveLanguage] = useState<ILanguages>(
        (localStorage.getItem('lang') as ILanguages) || 'en'
    );
    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(activeLanguage);
        localStorage.setItem('lang', activeLanguage);
    }, [activeLanguage, i18n]);

    const contextState: TranslationContextProps = {
        activeLanguage,
        setActiveLanguage
    };

    return <TranslationContext.Provider value={contextState}>{children}</TranslationContext.Provider>;
};

export const useTranslaeStateValue: () => TranslationContextProps = () => useContext(TranslationContext);

export default TranslationContext;
