import { FC } from 'react';
import { ContentBox, RootBox } from './Style';

const PageContent: FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <RootBox>
            <ContentBox>{children}</ContentBox>
        </RootBox>
    );
};

export default PageContent;
