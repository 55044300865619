export const Colors = {
    purple: '#654ea3',
    lightPurple: '#f3f1f8',
    lighterPurple: 'rgba(101, 78, 163, 0.08)',
    darkPurple: '#45356f',
    purpleHover: '#c2beda',
    background: '#fafafa',
    white: '#ffffff',
    textPrimary: '#333333',
    textSecondary: '#666666',
    textTertiary: '#adadad',
    progressIncrease: '#b0cb3e',
    progressFlat: '#ffcc55',
    progressDecrease: '#FE6C6C',
    progressUnassigned: '#d6d6d6',
    disabled: '#d6d6d6',
    iconDefault: '#999999',
    iconHover: '#f3f1f8',
    unachievedSkill: '#d6d6d6',
    userSkill: '#ff955e',
    producerSkill: '#dd6862',
    masterSkill: '#349fee',
    star: '#DAA520',
    yellow: '#ffcc55',
    lightOrange: 'rgba(250, 208, 70, 0.32)',
    green: '#B0CB3E',
    lightGreen: 'rgba(142, 194, 11, 0.2)',
    verdunGreen: '#4E6B05',
    red: '#f15449',
    grey: 'rgba(0, 0, 0, 0.08)',
    darkGrey: 'rgba(0, 0 0, 0.64)',
    loginDarkGrey: '#c5c5c5',
    black: '#000',
    desert: '#AC5521',
    low: '#023BC6',
    moderate: '#0278E5',
    high: '#349FEE'
};
