import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { ILicenseSummaryVM } from '../interfaces/views/ILicenseSummaryVM';
import { apiInstance } from './util/apiInstance';
import { mapLicenseSummary } from './mappers';

// GET LICENSE SUMMARY
export const getLicenseSummary = async (authParams: IAuthParams, resellerId: string | undefined) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`licenseSummary${resellerId ? `/${resellerId}` : ''}`, headers);
    return mapLicenseSummary(data);
};

export const useGetLicenseSummaryQuery: (resellerId: string | undefined) => UseQueryResult<ILicenseSummaryVM, Error> = (
    resellerId
) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.PARTNER_FETCH_LICENSE_SUMMARY, resellerId],
        queryFn: async () => {
            return getLicenseSummary(authParams, resellerId);
        },
        refetchOnWindowFocus: false
    });
};
