import { FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: 16,
    flex: 1,
    marginLeft: '8px',
    '& label': {
        marginLeft: '0px !important',
        alignItems: 'flex-start'
    }
}));

export const StyledSwitchFormControlLabel = styled(FormControlLabel)(({}) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    gap: '10px',
    height: '40px',
    '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        display: 'grid',
        gridTemplateColumns: 'auto auto'
    },
    '& .MuiTypography-root': {
        minWidth: '170px',
        color: 'rgba(0, 0, 0, 0.8)'
    }
}));

export const StyledTextInputFormControlLabel = styled(FormControlLabel)(({}) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    gap: '20px',
    minHeight: '60px',
    '& .MuiFormControlLabel-label': {
        fontSize: '16px',
        display: 'grid',
        gridTemplateColumns: 'auto auto'
    },
    '& .MuiTypography-root': {
        minWidth: '170px',
        color: 'rgba(0, 0, 0, 0.8)'
    }
}));

export const TabPanelRootBox = styled('div')({
    padding: '16px 0'
});

export const ContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1
});

export const LicenseSyncLabel = styled('span')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    lineHeight: '20px'
});

export const NewLicenseNumberLabelTypography = styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px'
});

export const NewLicenseNumberValueTypography = styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '16px'
});

export const CountBox = styled('div')({
    display: 'flex',
    gap: '16px'
});

export const CurrentLicenseNumberLabelTypography = styled(Typography)({
    display: 'flex',
    fontSize: '16px'
});

export const CurrentLicenseNumberValueTypography = styled(Typography)({
    display: 'flex',
    fontWeight: 600,
    fontSize: '16px'
});
