import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PrevNextItem from '../../../ui/general/prev-next-item/PrevNextItem';
import { IRecommendationVM } from '../../../interfaces/views/IRecommendationVM';
import { RootBox, TitleBox, TitleTypography, ContentBox, RecommendationCardItemWrapper } from './Style';
import RecommendationSimpleCardItem from './recommendation-simple-card-item/RecommendationSimpleCardItem';
import NoDataCard from '../no-data-card/NoDataCard';
import usePrevNextVisible from '../../../hooks/usePrevNextVisible';

interface IProps {
    cardItems?: IRecommendationVM[];
    isLoading: boolean;
    successRecommendationCallback?: () => void;
}

const RecommendationSimpleCard: FC<IProps> = ({ cardItems, isLoading, successRecommendationCallback }) => {
    const { t } = useTranslation();
    const { changeHandler, isVisible, visibleIndex } = usePrevNextVisible({ step: 1 });

    const contentMemo = useMemo(() => {
        if (!isLoading) {
            if (cardItems && cardItems.length > 0) {
                return cardItems.map((cardItem, index) => {
                    return (
                        <RecommendationCardItemWrapper key={index} isVisible={isVisible(index) ?? false}>
                            <RecommendationSimpleCardItem
                                isVisible={isVisible(index)}
                                recommendation={cardItem}
                                successRecommendationCallback={successRecommendationCallback}
                            />
                        </RecommendationCardItemWrapper>
                    );
                });
            }
            return (
                <NoDataCard
                    textStyle={{ fontSize: 12 }}
                    message={t('recommendations.noRecommendationsAvailable')}
                    noBorderShadow
                />
            );
        }
        if (!cardItems && isLoading) {
            return (
                <RecommendationCardItemWrapper isVisible>
                    <RecommendationSimpleCardItem isLoading />
                </RecommendationCardItemWrapper>
            );
        }
        return <></>;
    }, [isLoading, cardItems, visibleIndex, successRecommendationCallback]);

    return (
        <RootBox id='recommendations-box'>
            <TitleBox>
                <TitleTypography variant='subtitle1'>{t('recommendations.discoverMore')}</TitleTypography>
                <PrevNextItem
                    isNextDisabled={!cardItems || visibleIndex + 1 >= cardItems.length}
                    isPreviousDisabled={visibleIndex <= 0}
                    changeHandler={changeHandler}
                    infoJsx={cardItems && cardItems?.length > 0 ? `${visibleIndex + 1} / ${cardItems?.length}` : ''}
                />
            </TitleBox>
            <ContentBox>{contentMemo}</ContentBox>
        </RootBox>
    );
};

export default RecommendationSimpleCard;
