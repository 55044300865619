import { EUserRoleImplementation } from '../interfaces/enums/EUserRoleImplementation';

export const convertRolesToEnumValues: (rolesValue: number) => EUserRoleImplementation[] = (rolesValue) => {
    const userRoles: EUserRoleImplementation[] = [];

    if (rolesValue === 0) {
        userRoles.push(EUserRoleImplementation.NONE);
        return userRoles;
    }

    if (rolesValue & (1 << 0)) userRoles.push(EUserRoleImplementation.USER);
    if (rolesValue & (1 << 1)) userRoles.push(EUserRoleImplementation.MANAGER);
    if (rolesValue & (1 << 2)) userRoles.push(EUserRoleImplementation.CHAMPION);
    if (rolesValue & (1 << 3)) userRoles.push(EUserRoleImplementation.ADMIN);
    if (rolesValue & (1 << 4)) userRoles.push(EUserRoleImplementation.PARTNER);
    if (rolesValue & (1 << 5)) userRoles.push(EUserRoleImplementation.INTERNAL);

    return userRoles;
};
