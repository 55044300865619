import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { apiInstance } from './util/apiInstance';
import { mapResources } from './mappers';
import { IResourceVM } from '../interfaces/views/IResourceVM';

// GET RESOURCES
export const getResources = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`resources`, headers);
    return mapResources(data);
};

export const useGetResourcesQuery: () => UseQueryResult<IResourceVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.RESOURCES_FETCH_ALL],
        queryFn: async () => {
            return getResources(authParams);
        },
        refetchOnWindowFocus: false
    });
};
