import {
    createContext,
    FC,
    useCallback,
    useContext,
    useState,
    ChangeEvent,
    useEffect,
    PropsWithChildren,
    useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import NuliaSmallIcon from '../assets/icons/NuliaSmallIcon';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import {
    roleCategories,
    roleDepartmentFilterCallback,
    roleCountryFilterCallback,
    roleConcreteRoleFilterCallback
} from '../contexts/util/filterCategories';
import useFilterSearch from '../hooks/useFilterSearch';
import ReportIcon from '../assets/icons/ReportIcon';
import LockIcon from '../assets/icons/LockIcon';
import { useTabsStateValue } from './TabsContext';
import { mapTenantUsersToRoles } from '../services/helpers/mappers';
import { useGetTenantAllUsersQuery } from '../services/TenantQueryService';
import { IRoleVM } from '../interfaces/views/IRoleVM';
import { usePostBulkAssignmentRole } from '../services/RoleQueryService';
import { EAssignmentRoles } from '../interfaces/enums/EAssignmentRoles';
import { EUserRoleLogical } from '../interfaces/enums/EUserRoleLogical';
import { convertLogicalRolesToStringValues } from '../utils/convertLogicalRolesToStringValues';

export interface IRolesContext {
    roles?: IRoleVM[];
    rolesStatusOverallInfo: IRoleStatusInfo[];
    isRolesLoading: boolean;
    changeRolesStatusOverallActiveFilters: (key: ERolesStatuses) => void;
    activeRolesStatusOverallFilters: ERolesStatuses[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    searchText: string;
    changeSearchText: (e: ChangeEvent<HTMLInputElement>) => void;
    filterCategories: IFilterCategories[];
    selectedRolesList: string[];
    setSelectedRolesList: (selected: string[]) => void;
    changeSelectedRolesList: (selected: string[]) => void;
    isErrorFetchingData: boolean;
    refetchData: () => void;
    isLoadingFetchingData: boolean;
    bulkAssignmentRoleCallback: (targetUserIds: string[], roleChange: EAssignmentRoles) => Promise<boolean | undefined>;
}

export const RolesContext = createContext<IRolesContext>({} as IRolesContext);

export enum ERolesStatuses {
    GLOBAL_ADMIN = 'globalAdmin',
    CHAMPION_ADMIN = 'championAdmin',
    LICENSE_ADMIN = 'licenseAdmin',
    REPORT_ADMIN = 'reportAdmin',
    CHAMPION = 'champion'
}

interface IRoleStatusInfo {
    key: ERolesStatuses;
    label: string;
    value?: number | string | null;
    icon?: React.ReactNode;
    circleColor?: string;
    isLoading?: boolean;
    isClickable: boolean;
}

interface IProps {}

export const RolesProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const { t } = useTranslation();
    const [roles, setRoles] = useState<IRoleVM[] | undefined>();
    const [filteredRoles, setFilteredRoles] = useState<IRoleVM[]>([]);
    const [rolesStatusOverallInfo, setRolesStatusOverallInfo] = useState<IRoleStatusInfo[]>([
        {
            key: ERolesStatuses.GLOBAL_ADMIN,
            label: t('roles.statuses.globalAdmin'),
            value: null,
            isLoading: true,
            icon: <LockIcon />,
            isClickable: true
        },
        {
            key: ERolesStatuses.LICENSE_ADMIN,
            label: t('roles.statuses.licenseAdmin'),
            value: null,
            isLoading: true,
            icon: <NuliaSmallIcon />,
            isClickable: true
        },
        {
            key: ERolesStatuses.REPORT_ADMIN,
            label: t('roles.statuses.reportAdmin'),
            value: null,
            isLoading: true,
            icon: <ReportIcon />,
            isClickable: true
        }
    ]);
    const [isRolesLoading] = useState<boolean>(false);
    const [activeRolesStatusOverallFilters, setActiveRolesStatusOverallFilters] = useState<ERolesStatuses[]>([]);
    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(roleCategories);
    const [selectedRolesList, setSelectedRolesList] = useState<string[]>([]);
    const activeFiltersRef = useRef<string[]>([]);
    const {
        data: fetchedUserRolesData,
        isError: isErrorGetTenantUsers,
        refetch: refetchGetTenantUsers,
        isLoading: isLoadingGetTenantUsers
    } = useGetTenantAllUsersQuery();
    const { mutateAsync: mutatePostAssignmentRoleAsync } = usePostBulkAssignmentRole();

    useEffect(() => {
        if (fetchedUserRolesData) {
            setRoles(mapTenantUsersToRoles(fetchedUserRolesData));
        }
    }, [fetchedUserRolesData]);

    const changeSelectedRolesList = useCallback((rolesList: string[]) => {
        setSelectedRolesList(rolesList);
    }, []);

    const onAnythingChange = (rolesNeedToBeFiltered: IRoleVM[], runSearchText?: boolean) => {
        let newFilteredRoles = [...rolesNeedToBeFiltered];
        newFilteredRoles = filterRolesByRoleStatusOverall(rolesNeedToBeFiltered);

        if (runSearchText) {
            newFilteredRoles = instantFilterByText(searchText, newFilteredRoles);
            newFilteredRoles = onFilterValueChange(filterCategories, undefined, true, newFilteredRoles);
            setFilteredRoles(newFilteredRoles);
        } else {
            newFilteredRoles = onFilterValueChange(filterCategories, undefined, true, newFilteredRoles);
            setFilteredRoles(newFilteredRoles);
        }
        return newFilteredRoles;
    };

    const filterRolesByRoleStatusOverall = (roles: IRoleVM[]) => {
        if (roles) {
            let newRoles = [...roles];
            activeRolesStatusOverallFilters.forEach((statusFilter) => {
                switch (statusFilter) {
                    case ERolesStatuses.GLOBAL_ADMIN:
                        newRoles = newRoles.filter((role) => role.roles.includes(EUserRoleLogical.ADMIN_GLOBAL));
                        break;
                    case ERolesStatuses.LICENSE_ADMIN:
                        newRoles = newRoles.filter((role) => role.roles.includes(EUserRoleLogical.ADMIN_LICENSES));
                        break;
                    case ERolesStatuses.REPORT_ADMIN:
                        newRoles = newRoles.filter((role) => role.roles.includes(EUserRoleLogical.ADMIN_REPORTS));
                        break;
                }
            });
            return newRoles;
        }
        return roles;
    };

    useEffect(() => {
        if (roles) {
            setFilteredRoles(roles);
            calculateRoleStatusOverallInfo(roles);
            const departments = new Set<string>();
            roles.forEach((role) => {
                if (role.department) departments.add(role.department);
            });
            const countries = new Set<string>();
            roles.forEach((role) => {
                countries.add(role.country);
            });
            const concreteRoles = new Set<EUserRoleLogical>();
            roles.forEach((role) => {
                role.roles.forEach((innerRole) => {
                    concreteRoles.add(innerRole);
                });
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'allDepartments') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: roleDepartmentFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'allRegions') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    countries.forEach((country) => {
                        categoryValues.push({
                            key: country,
                            name: country,
                            callback: roleCountryFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'allRoles') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    concreteRoles.forEach((concreteRole) => {
                        const role: string = convertLogicalRolesToStringValues([concreteRole]).join('');
                        categoryValues.push({
                            key: EUserRoleLogical[concreteRole],
                            name: role,
                            callback: roleConcreteRoleFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [roles]);

    const calculateRoleStatusOverallInfo = (filterRoles?: IRoleVM[]) => {
        const rolesToFilter = filterRoles || roles;
        if (rolesToFilter) {
            let numberOfGlobalAdmin: number = rolesToFilter.filter((role) =>
                role.roles.includes(EUserRoleLogical.ADMIN_GLOBAL)
            ).length;
            let numberOfLicenseAdmin: number = rolesToFilter.filter((role) =>
                role.roles.includes(EUserRoleLogical.ADMIN_LICENSES)
            ).length;
            let numberOfReportAdmin: number = rolesToFilter.filter((role) =>
                role.roles.includes(EUserRoleLogical.ADMIN_REPORTS)
            ).length;

            setRolesStatusOverallInfo((currentRolesStatusOverall) => {
                let newRolesStatusOverall = [...currentRolesStatusOverall];
                newRolesStatusOverall = newRolesStatusOverall.map((sso) => {
                    switch (sso.key) {
                        case ERolesStatuses.GLOBAL_ADMIN:
                            sso.value = numberOfGlobalAdmin;
                            break;
                        case ERolesStatuses.LICENSE_ADMIN:
                            sso.value = numberOfLicenseAdmin;
                            break;
                        case ERolesStatuses.REPORT_ADMIN:
                            sso.value = numberOfReportAdmin;
                            break;
                    }
                    return {
                        ...sso,
                        isLoading: false
                    };
                });
                return newRolesStatusOverall;
            });
        }
    };

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch({
        data: filteredRoles,
        dataSerachablePropertyName: 'name',
        onChangeCallback: onAnythingChange,
        setDataCallback: setFilteredRoles
    });
    const { searchText: headerInputSearchText } = useTabsStateValue();
    useEffect(() => {
        setSearchText(headerInputSearchText);
    }, [headerInputSearchText]);

    useEffect(() => {
        if (roles) onAnythingChange(roles, true);
    }, [activeRolesStatusOverallFilters, filterCategories, roles, searchText]);

    const changeSearchText = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value);
        },
        [setSearchText]
    );

    const changeRolesStatusOverallActiveFilters = useCallback(
        (key: ERolesStatuses) => {
            setActiveRolesStatusOverallFilters((activeStatusList) => {
                if (activeStatusList.includes(key)) {
                    return activeStatusList.filter((statusKey) => statusKey !== key);
                }
                return [...activeStatusList, key];
            });
        },
        [filteredRoles, activeRolesStatusOverallFilters]
    );

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        newActiveFilters?: string[],
        dontRunAnythingChange?: boolean,
        rolesToFilter?: IRoleVM[]
    ) => {
        let newFilteredRoles: IRoleVM[] = [];
        if (rolesToFilter) newFilteredRoles = rolesToFilter;
        else newFilteredRoles = roles ? [...roles] : [];
        let currentActiveFilters = activeFiltersRef.current;
        if (newActiveFilters) {
            currentActiveFilters = newActiveFilters;
            activeFiltersRef.current = newActiveFilters;
        }
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values!.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && currentActiveFilters.includes(filterCategoryValue.key)) {
                        newFilteredRoles = newFilteredRoles.filter((role) => {
                            if (filterCategoryValue.callback) {
                                const isValid = filterCategoryValue.callback(
                                    role,
                                    filterCategoryValue.name,
                                    filterCategoryValue.key
                                );
                                return isValid;
                            }
                            return false;
                        });
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredRoles, true);
        return newFilteredRoles;
    };

    const bulkAssignmentRoleCallback = useCallback(async (targetUserIds: string[], roleChange: EAssignmentRoles) => {
        try {
            await mutatePostAssignmentRoleAsync({
                targetUserIds,
                roleChange
            });
            return true;
        } catch (e) {
            return false;
        }
    }, []);

    const rolesContext: IRolesContext = {
        roles: filteredRoles,
        rolesStatusOverallInfo,
        isRolesLoading,
        changeRolesStatusOverallActiveFilters,
        activeRolesStatusOverallFilters,
        onFilterValueChange,
        searchText,
        changeSearchText,
        filterCategories,
        selectedRolesList,
        setSelectedRolesList,
        changeSelectedRolesList,
        isErrorFetchingData: isErrorGetTenantUsers,
        refetchData: refetchGetTenantUsers,
        isLoadingFetchingData: isLoadingGetTenantUsers,
        bulkAssignmentRoleCallback
    };

    return <RolesContext.Provider value={rolesContext}>{children}</RolesContext.Provider>;
};

export const useRolesStateValue: () => IRolesContext = () => useContext(RolesContext);
