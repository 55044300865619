import { ChangeEventHandler, CSSProperties, FC, MutableRefObject } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Colors } from './Colors';

const useStyles = makeStyles({
    input: {
        height: 40,
        width: 184
    },
    label: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0.42,
        lineHeight: '20px',
        height: 20
    },
    disabledLabel: {
        color: 'rgba(0,0,0,0.3)'
    },
    text: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        border: 0,
        margin: 0,
        padding: 0,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: 1.43,
        backgroundColor: 'transparent',
        color: Colors.textPrimary,
        caretColor: 'rgba(101,78,163,0.88)',
        paddingLeft: 4,
        paddingRight: 4,
        borderBottom: '1px solid rgba(0,0,0,0.4)',
        '&:hover': {
            borderBottom: '1px solid #654ea3'
        },
        '&:active': {
            borderBottom: '1px solid #654ea3'
        },
        '&:focus': {
            backgroundColor: 'rgba(101,78,163,0.04)',
            borderBottom: '1px solid #654ea3',
            outline: 'none'
        },
        '&:disabled': {
            borderBottom: '1px solid rgba(0,0,0,0.08)'
        },
        '&:disabled::placeholder': {
            color: 'rgba(0,0,0,0.3)'
        },
        '&::placeholder': {
            color: Colors.textTertiary
        }
    },
    error: {
        borderBottom: '1px solid #FE6C6C'
    },
    errorLabel: {
        color: '#FE6C6C',
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0.42,
        lineHeight: '20px',
        margin: 0
    }
});

interface Props {
    onChange?: ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
    style?: CSSProperties;
    inputStyle?: CSSProperties;
    disabled?: boolean;
    ref?: MutableRefObject<HTMLInputElement | null>;
    error?: boolean;
    textAlignRight?: boolean;
    readOnly?: boolean;
    value: string | number;
    autoFocus?: boolean;
    errorText?: string;
}

const Input: FC<Props> = ({
    placeholder,
    style,
    disabled,
    onChange,
    ref,
    error,
    textAlignRight,
    readOnly,
    value,
    autoFocus,
    inputStyle,
    errorText
}) => {
    const classes = useStyles();

    return (
        <div className={classes.input} style={style ? style : undefined}>
            {placeholder && (
                <div className={clsx(classes.label, disabled ? classes.disabledLabel : undefined)}>
                    {value !== '' ? placeholder : ''}
                </div>
            )}
            <input
                autoFocus={autoFocus}
                onChange={onChange}
                className={clsx(classes.text, error ? classes.error : undefined)}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                ref={ref}
                style={inputStyle ? inputStyle : textAlignRight ? { textAlign: 'right' } : undefined}
                readOnly={readOnly}
                aria-errormessage={errorText}
            />
            {error && errorText && <p className={classes.errorLabel}>{errorText}</p>}
        </div>
    );
};

export default Input;
