import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const CustomersBox = styled('div')(({ theme }) => ({
    minWidth: '100%'
}));

export const CardBox = styled('div')(({ theme }) => ({
    width: '100%',
    borderRadius: 4,
    boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
    paddingTop: 24
}));

export const HeaderActionBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end'
});

export const MainContentBox = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
    gap: '16px',
    marginTop: '16px',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        flexDirection: 'column-reverse'
    }
}));

export const FiltersBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
});

export const FilterByTypography = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.88)',
    letterSpacing: '2px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    paddingBottom: '8px',
    marginBottom: '20px',
    [theme.breakpoints.down('lg')]: {
        marginBottom: '4px'
    }
}));
