import { EUserPermission } from '../interfaces/enums/EUserPermission';

export const convertPermissionsToEnumValues: (permissionsValue: number) => EUserPermission[] = (rolesValue) => {
    const userPermissions: EUserPermission[] = [];

    if (rolesValue & (1 << 0)) userPermissions.push(EUserPermission.USER);
    if (rolesValue & (1 << 1)) userPermissions.push(EUserPermission.CHAMPION_ADMINISTRATION);
    if (rolesValue & (1 << 2)) userPermissions.push(EUserPermission.ASSESSMENT);
    if (rolesValue & (1 << 3)) userPermissions.push(EUserPermission.TENANT_INSIGHTS);
    if (rolesValue & (1 << 4)) userPermissions.push(EUserPermission.TENANT_OUTCOME_ASSIGNMENT);
    if (rolesValue & (1 << 5)) userPermissions.push(EUserPermission.LICENSE_ASSIGNMENT);
    if (rolesValue & (1 << 6)) userPermissions.push(EUserPermission.ROLE_ASSIGNMENT);
    if (rolesValue & (1 << 7)) userPermissions.push(EUserPermission.OUTCOME_FAVORITED_FOR_TENANT);
    if (rolesValue & (1 << 8)) userPermissions.push(EUserPermission.OUTCOME_MASTER_LEVEL_REQUESTED_FOR_TENANT);
    if (rolesValue & (1 << 9)) userPermissions.push(EUserPermission.TENANT_PREFERENCES);
    if (rolesValue & (1 << 16)) userPermissions.push(EUserPermission.PARTNER);
    if (rolesValue & (1 << 24)) userPermissions.push(EUserPermission.PLATFORM_ADMINISTRATION);
    if (rolesValue & (1 << 25)) userPermissions.push(EUserPermission.PARTNER_ADMINISTRATION);

    return userPermissions;
};
