import { FC } from 'react';
import { Colors } from '../Colors';

type Props = {
    selected?: boolean;
    mouseOver: boolean;
    mouseDown: boolean;
    disabled?: boolean;
};

const CloseIcon: FC<Props> = ({ disabled, selected, mouseOver, mouseDown }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='feather feather-x'
        color={
            disabled
                ? Colors.disabled
                : mouseOver && !mouseDown
                  ? Colors.textPrimary
                  : selected || mouseDown
                    ? Colors.purple
                    : Colors.iconDefault
        }
    >
        <line x1='18' y1='6' x2='6' y2='18' />
        <line x1='6' y1='6' x2='18' y2='18' />
    </svg>
);

export default CloseIcon;
