import { FC } from 'react';
import Dialog from '../dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { StyledForm } from './Style';
import { FormControl } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { EToastSeverity, NTextInput, useToastContextStateValue } from 'nulia-ui';
import { useGetPartnerResellersQuery, usePostAddReseller } from '../../../services/AccountsQueryService';

interface Props {
    open: boolean;
    onClose: () => void;
}

const AddResellerDialog: FC<Props> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        control,
        formState: { errors, isValid },
        reset,
        handleSubmit,
        getValues
    } = useForm();
    const { setToastMessage } = useToastContextStateValue();
    const { mutateAsync: mutatePostAddResellerAsync, isPending: isAddResellerRequestPending } = usePostAddReseller();
    const { refetch } = useGetPartnerResellersQuery();

    const handleAcceptDialog = async () => {
        handleSubmitForm();
    };

    const handleCloseDialog = () => {
        resetForm();
        onClose();
    };

    const resetForm = () => {
        reset();
    };

    const handleSubmitForm = async () => {
        try {
            await mutatePostAddResellerAsync({ name: getValues().resellerName });
            setToastMessage({
                isOpen: true,
                message: t('successToastMessages.accounts.addReseller'),
                severity: EToastSeverity.SUCCESS
            });
            refetch();
            handleCloseDialog();
        } catch (e) {
            setToastMessage({
                isOpen: true,
                message: t('errorToastMessages.accounts.addReseller'),
                severity: EToastSeverity.ERROR
            });
            handleCloseDialog();
        }
    };

    return (
        <Dialog
            open={open}
            onAccept={handleAcceptDialog}
            acceptText={t('accounts.addReseller.confirmButton')}
            onClose={handleCloseDialog}
            cancelText={t('accounts.addReseller.cancelButton')}
            title={t('accounts.addReseller.title')}
            minHeight='200px'
            isSubmitActionDisabled={!isValid || isAddResellerRequestPending}
            content={
                <StyledForm onSubmit={handleSubmit(handleSubmitForm)}>
                    <FormControl fullWidth>
                        <Controller
                            name={'resellerName'}
                            control={control}
                            rules={{
                                required: true,
                                maxLength: {
                                    message: 'Maximum length is 50',
                                    value: 50
                                }
                            }}
                            render={({ field }) => (
                                <NTextInput
                                    field={field}
                                    label={t('accounts.addReseller.resellerNameInput')}
                                    value={field.value}
                                    onChange={field.onChange}
                                    errorMessage={errors['resellerName']?.message as string}
                                />
                            )}
                        />
                    </FormControl>
                </StyledForm>
            }
        />
    );
};

export default AddResellerDialog;
