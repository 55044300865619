import { FC } from 'react';
import { Colors } from '../Colors';

interface Props {
    color?: string;
    large?: boolean;
}

const SearchIcon: FC<Props> = ({ color, large }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='feather feather-search'
        color={!color ? Colors.textSecondary : color}
        style={!large ? { height: 16, width: 16 } : { height: 24, width: 24 }}
    >
        <circle cx='11' cy='11' r='8' />
        <line x1='21' y1='21' x2='16.65' y2='16.65' />
    </svg>
);

export default SearchIcon;
