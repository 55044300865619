import { FC, PropsWithChildren } from 'react';
import { ChildrenBox } from './Style';
import { Tooltip as MuiTooltip } from '@mui/material';
import { useTheme } from '@mui/system';

interface IProps {
    title: string;
    children: React.ReactElement<any, any> | null | React.ReactNode;
}

const Tooltip: FC<PropsWithChildren<IProps>> = ({ title, children }) => {
    const theme = useTheme();
    return (
        <MuiTooltip
            title={title}
            slotProps={{
                tooltip: {
                    sx: {
                        boxShadow: '0 2.5px 8px 0.5px rgba(0,0,0,0.08), 0 1px 4px 0 rgba(0,0,0,0.08)',
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.common.black,
                        fontSize: 12,
                        fontFamily: 'Ubuntu',
                        fontStyle: 'normal',
                        fontWeight: 'normal'
                    }
                }
            }}
        >
            <ChildrenBox>{children}</ChildrenBox>
        </MuiTooltip>
    );
};

export default Tooltip;
