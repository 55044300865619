import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
        marginLeft: 'unset'
    }
}));

export const ImageBox = styled('div')({
    minHeight: 180,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 16,
    position: 'relative'
});

export const BadgeImg = styled('img')(({ theme }) => ({
    width: 200,
    opacity: 0,
    visibility: 'hidden',
    [theme.breakpoints.down(800)]: {
        width: '150px'
    }
}));

export const BadgeActionsBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    width: 'calc(100% - 26px)'
});

export const DownloadBadgeBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isLoading'
})<{ isLoading: boolean }>(({ theme, isLoading }) => ({
    cursor: isLoading ? 'not-allowed' : 'pointer',
    '&:hover svg path': {
        fill: isLoading ? 'initial' : `${theme.palette.primary.main} !important`
    }
}));

export const ShareBadgeBox = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    '&:hover svg path': {
        fill: `${theme.palette.primary.main} !important`
    }
}));

export const FooterBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    width: '100%'
});

export const FooterInfoRow = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
});

export const FooterInfoTypography = styled(Typography)({});

export const CircleWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    gap: '6px'
});

export const Circle = styled('div', {
    shouldForwardProp: (prop) => prop !== 'color'
})<{ color: string }>(({ color }) => ({
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: color
}));

export const ClaimBox = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
});
