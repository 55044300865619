import { FC, useEffect, useRef } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { Controller, useForm } from 'react-hook-form';
import NCheckbox from '../../../../ui/form/n-checkbox/NCheckbox';
import BasicCard from '../../../../ui/cards/basic-card/BasicCard';
import {
    BadgeForm,
    DescriptionBox,
    RootBox,
    SubmitBox,
    StyledForm,
    NotificationsWrapper,
    BadgesWrapper
} from './Style';
import NTextInput from '../../../../ui/form/n-text-input/NTextInput';
import { useUserAuthStateValue } from '../../../../contexts/UserAuthContext';
import PrimaryButton from '../../../../ui/buttons/primary-button/PrimaryButton';
import { useSettingsStateValue } from '../../../../contexts/SettingsContext';
import { IUserPreferenceRequestDTO } from '../../../../interfaces/dtos/IUserPreferenceRequestDTO';

const PersonalSettingsTab: FC = () => {
    const {
        control,
        setValue,
        handleSubmit,
        formState: { isDirty, errors, isValid },
        reset
    } = useForm({
        mode: 'onChange'
    });
    const { currentUserData, isCurrentUserDataLoading } = useUserAuthStateValue();
    const { updateUsersCurrentPreferences, isUpdateActionTriggered } = useSettingsStateValue();
    const formRef = useRef<HTMLFormElement>(null);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);

    useEffect(() => {
        if (currentUserData?.preferences) {
            reset();
            Object.entries(currentUserData.preferences).forEach(([key, value], index) => {
                setValue(key, value);
            });
            setValue('workEmail', account?.username);
        }
    }, [currentUserData]);

    const handleUpdateSettings = (data: any) => {
        const submitData = data as IUserPreferenceRequestDTO;
        updateUsersCurrentPreferences(submitData, () => reset({}, { keepValues: true }));
    };

    return (
        <StyledForm onSubmit={handleSubmit(handleUpdateSettings)} ref={formRef}>
            <RootBox>
                <SubmitBox>
                    <PrimaryButton
                        disabled={!isDirty || isUpdateActionTriggered || !isValid}
                        title='Submit'
                        clickHandler={() => {
                            formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                        }}
                    />
                </SubmitBox>
                <NotificationsWrapper>
                    <BasicCard
                        containerId='email-notifications'
                        isLoading={isCurrentUserDataLoading}
                        title='Email Notification Preferences'
                    >
                        <Controller
                            name={'enableWeeklyEmails'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label='Recieve Weekly Email'
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableAllEmails'}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <NCheckbox
                                        isChecked={field.value}
                                        label='Recieve Other Emails'
                                        field={field}
                                        setValue={setValue}
                                    />
                                );
                            }}
                        />
                    </BasicCard>
                    <BasicCard
                        containerId='teams-notifications'
                        isLoading={isCurrentUserDataLoading}
                        title='Teams Notification Preferences'
                    >
                        <Controller
                            name={'enableOutcomeAssignedNotification'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label='Outcome Assigned Notifications'
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableBadgeEligibiltyNotification'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label='Badge Eligibility Notifications'
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                        <Controller
                            name={'enableWeeklyUpdateAvailableNotification'}
                            control={control}
                            render={({ field }) => (
                                <NCheckbox
                                    isChecked={field.value}
                                    label='Weekly Update Available Notifications'
                                    field={field}
                                    setValue={setValue}
                                />
                            )}
                        />
                    </BasicCard>
                </NotificationsWrapper>
                <BadgesWrapper>
                    <BasicCard
                        containerId='badge-preferences'
                        isLoading={isCurrentUserDataLoading}
                        title='Badge Identification Preferences'
                    >
                        <BadgeForm>
                            <DescriptionBox>
                                When you show off your Skills on sites like LinkedIn by posting your badge, others can
                                verify the badge belongs to you by the name or emails you list below
                            </DescriptionBox>
                            <Controller
                                name={'workEmail'}
                                control={control}
                                render={({ field }) => (
                                    <NTextInput
                                        onChange={field.onChange}
                                        value={field.value}
                                        label={'Work Email (Not editable)'}
                                        field={field}
                                        disabled
                                    />
                                )}
                            />
                            <Controller
                                name={'preferredName'}
                                control={control}
                                render={({ field }) => (
                                    <NTextInput
                                        value={field.value || ''}
                                        onChange={field.onChange}
                                        field={field}
                                        label={'Name'}
                                        inputProps={{ maxLength: 255 }}
                                        errorMessage={errors['preferredName']?.message as string}
                                    />
                                )}
                                rules={{
                                    maxLength: 255,
                                    pattern: {
                                        value: /^[A-Za-z]+(?:[-' ][A-Za-z]+)*$/,
                                        message: 'Invalid name'
                                    }
                                }}
                            />
                            <Controller
                                name={'alternateEmail'}
                                control={control}
                                render={({ field }) => (
                                    <NTextInput
                                        value={field.value || ''}
                                        onChange={field.onChange}
                                        field={field}
                                        label={'Personal Email'}
                                        inputProps={{ maxLength: 255, type: 'email' }}
                                        errorMessage={errors['alternateEmail']?.message as string}
                                    />
                                )}
                                rules={{
                                    maxLength: 255,
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: 'Invalid email address'
                                    }
                                }}
                            />
                        </BadgeForm>
                    </BasicCard>
                </BadgesWrapper>
            </RootBox>
        </StyledForm>
    );
};

export default PersonalSettingsTab;
