import { AccountInfo, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';

export const getToken = async (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    ssoToken?: string
): Promise<string> => {
    if (ssoToken) {
        return ssoToken;
    }

    if (account) {
        try {
            const response = await instance.acquireTokenSilent({
                scopes: [process.env.REACT_APP_PLATFORM_APP_URI as string],
                account: account,
                forceRefresh: true
            });

            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                await instance
                    .acquireTokenPopup({
                        scopes: [process.env.REACT_APP_PLATFORM_APP_URI as string],
                        account: account
                    })
                    .catch((error: any) => {
                        console.error(error);
                    });
            }
        }
    }

    return '';
};
