import { FC, PropsWithChildren, useMemo } from 'react';
import { Container, Dot, TitleBox, TitleTypography } from './Style';
import Loading from '../../../ui/general/loading/Loading';
import SomethingWentWrongCard from '../something-went-wrong-card/SomethingWentWrongCard';

export enum EStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning'
}

interface IProps {
    title?: string;
    status?: EStatus;
    isLoading?: boolean;
    isError?: boolean;
    refetch?: () => void;
    containerId?: string;
}

const BasicCard: FC<PropsWithChildren<IProps>> = ({
    children,
    title,
    status,
    isLoading = false,
    isError = false,
    refetch,
    containerId
}) => {
    const color = useMemo(() => {
        switch (status) {
            case EStatus.SUCCESS:
                return '#B0CB3E';
            case EStatus.ERROR:
                return '#D63535';
            case EStatus.WARNING:
                return '#FC5';
            default:
                return undefined;
        }
    }, [status]);

    const contentMemo = useMemo(() => {
        if (isLoading) return <Loading />;
        if (isError) return <SomethingWentWrongCard boxStyle={{ boxShadow: 'none' }} actionCallback={refetch} />;
        return children;
    }, [isLoading, isError, refetch, children]);

    return (
        <Container id={containerId}>
            <TitleBox>
                {color && <Dot color={color} />}
                {title && <TitleTypography variant='overline'>{title}</TitleTypography>}
            </TitleBox>
            {contentMemo}
        </Container>
    );
};

export default BasicCard;
