import { Paper, Select, Typography, Tabs, Tab } from '@mui/material';
import styled from '../../../ui/theme/styled';

export const OutcomeCardWrapper = styled('div')({
    width: '100%'
});

export const OutcomesListWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 300ms',
    opacity: 1,
    minHeight: 140,
    paddingTop: 16,
    '&.preAnimation': {
        opacity: 0,
        maxHeight: 0
    }
});

export const TitleBox = styled(Typography)(({ theme }) => ({
    display: 'block',
    color: theme.palette.primary.main
}));

export const SkillCountBox = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

export const FilterLabel = styled('span')({
    letterSpacing: '2px',
    textTransform: 'uppercase',
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    fontSize: '12px'
});

export const FilterSelect = styled(Select)({
    width: 160,
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    }
});

export const FilterBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled'
})<{ isDisabled: boolean }>(({ isDisabled }) => ({
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    pointerEvents: isDisabled ? 'none' : 'unset',
    opacity: isDisabled ? 0.4 : 1,
    marginRight: 6
}));

export const OrderSpan = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 4,
    borderRadius: 20,
    '&:hover': {
        backgroundColor: theme.palette.background.default
    }
}));

export const StyledPaper = styled(Paper)({
    padding: 16,
    flex: 11,
    height: 'fit-content'
});

export const StyledMain = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        padding: '20px'
    }
}));

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 1080,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}));

export const MainContentBox = styled('div')({
    display: 'flex'
});

export const SideBox = styled('div')(({ theme }) => ({
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
    gap: 32,
    [theme.breakpoints.down('md')]: {
        padding: 0
    }
}));

export const HeaderActionWrapper = styled('div')({
    marginBottom: '13px'
});

export const SkillsStatusOverallWrapper = styled('div')<{
    isDisabled: boolean;
}>(({ isDisabled }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    paddingBottom: 24,
    flex: 1,
    pointerEvents: isDisabled ? 'none' : 'unset',
    opacity: isDisabled ? 0.4 : 1
}));

export const FiltersWrapper = styled('div')({
    flex: 100
});

export const HeaderWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    paddingRight: '20px',
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 0
    }
}));

export const AssignmentSpan = styled('span')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 12,
    lineHeight: '20px',
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.primary.main
    }
}));

export const StyledTabs = styled(Tabs)<{ tab: number }>(({ tab, theme }) => ({
    flex: 11,
    maxWidth: tab === 2 ? '810px' : 'unset',
    '&.MuiTabs-root': {
        [theme.breakpoints.down('md')]: {
            overflow: 'visible !important',
            maxWidth: '100%',
            width: '100%'
        }
    },
    '& .MuiTabs-flexContainer': {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            gap: '8px'
        }
    },
    '& .MuiTabs-indicator': {
        height: '4px !important',
        borderRadius: '2px'
    },
    '& .MuiTabs-scroller': {
        [theme.breakpoints.down('md')]: {
            overflowY: 'scroll !important'
        }
    }
}));

export const StyledTab = styled(Tab)<{ shadowed?: boolean }>(({ shadowed = false, theme }) => ({
    color: shadowed ? 'rgba(0, 0, 0, 0.16) !important' : '#616063',
    padding: 0,
    margin: '12px 16px',
    [theme.breakpoints.down('md')]: {
        minHeight: '24px',
        wordBreak: 'break-word',
        margin: '4px',
        textAlign: 'left'
    }
}));

export const HistoryWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 4,
    paddingLeft: '10px',
    [theme.breakpoints.down('lg')]: {
        flex: 'unset'
    },
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
}));
