import { FC, useEffect } from 'react';
import { Outlet } from 'react-router';
import { ITab, useTabsStateValue } from '../../contexts/TabsContext';
import { isUserAllowedToAccess } from '../../pages/routeRules';
import { ERoute } from '../../interfaces/enums/ERoute';
import { useUserAuthStateValue } from '../../contexts/UserAuthContext';
import { routes } from '../../pages/routes';

const OutcomesTabs: FC = () => {
    const { currentUserData } = useUserAuthStateValue();
    const { setTabs } = useTabsStateValue();

    useEffect(() => {
        if (!currentUserData) {
            setTabs([]);
            return;
        }
        const tabs: ITab[] = [
            {
                id: 1,
                title: 'My Own',
                fullTitle: 'My Own Outcomes',
                path: routes.USER_OUTCOMES,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.USER_OUTCOMES,
                    currentUserData?.roles,
                    currentUserData.permissions
                ),
                showLastUpdatedInfo: true
            },
            {
                id: 2,
                title: 'Organization',
                fullTitle: 'Organization Outcomes',
                path: routes.ADMIN_OUTCOMES,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.ADMIN_OUTCOMES,
                    currentUserData?.roles,
                    currentUserData.permissions
                ),
                showLastUpdatedInfo: false
            },
            {
                id: 3,
                title: 'History',
                fullTitle: 'Outcomes History',
                path: routes.HISTORY_OUTCOMES,
                isUserAllowedToAccess: isUserAllowedToAccess(
                    ERoute.OUTCOMES_HISTORY,
                    currentUserData?.roles,
                    currentUserData.permissions
                ),
                showLastUpdatedInfo: false
            }
        ];
        setTabs(tabs.filter((tab) => tab.isUserAllowedToAccess));
        return () => {
            setTabs([]);
        };
    }, [currentUserData]);

    return <Outlet />;
};

export default OutcomesTabs;
