import { FC, KeyboardEvent, useCallback, useMemo, useRef } from 'react';
import Tooltip from '../tooltip/Tooltip';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { formatDate, formatISOString } from '../../../utils/dateUtil';
import { EAssignerRole } from '../../../interfaces/enums/EAssignerRole';
import { useUserAuthStateValue } from '../../../contexts/UserAuthContext';
import {
    AddIconImg,
    AssignedBox,
    AssignedBySpan,
    AssignedInfoBox,
    AssignedInnerBox,
    InProgressSpan,
    IsAssignedTitleSpan,
    MaintainedSpan,
    RemoveIconImg,
    StyledIconButton,
    AssignedDateSpan,
    AssignControlWrapper,
    AssignedInfoInnerBox
} from './Style';
import { accessibilityEnterSpaceKeyCallback } from '../../../utils/accessibilityUtils';

interface IProps {
    assignment: {
        assignerName?: string;
        assignerRole?: EAssignerRole;
        date?: string | Date;
        maintainedForWeeks?: number;
        inProgressForWeeks?: number;
        assignerId?: string;
    };
    toggleAssign: (isAssigned: boolean) => void;
    isAssigned: boolean;
    translations?: {
        assignedTitle?: string;
        notAssignedTitle?: string;
        assignTooltipText?: string;
        unassignTooltipText?: string;
    };
    isActionDisabled?: boolean;
    wrap?: boolean;
    isCardMode?: boolean;
    index?: number;
}

const AssignedControl: FC<IProps> = ({
    assignment: { assignerName, assignerRole, date, maintainedForWeeks, inProgressForWeeks, assignerId },
    isAssigned,
    toggleAssign,
    translations,
    isActionDisabled,
    wrap = true,
    isCardMode = false,
    index
}) => {
    const { t } = useTranslation();
    const { currentUserData } = useUserAuthStateValue();
    const assignButtonRef = useRef<HTMLButtonElement>(null);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    let streak = undefined;
    if (maintainedForWeeks !== undefined) streak = maintainedForWeeks;
    if (inProgressForWeeks !== undefined) streak = inProgressForWeeks;

    const onAssignControlKeyDown = useCallback(
        (e: KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'Enter' && !isActionDisabled && assignButtonRef.current) {
                toggleAssign(!isAssigned);
                e.stopPropagation();
            } else if (e.key === 'ArrowLeft') {
                const currentElement = e.target as HTMLElement;
                const skillCardElement = document.getElementById(`skill-card-${index}`);
                const outcomeCardElement = document.getElementById(`outcome-card-${index}`);
                const heartControl = document.getElementById(`heart-control-${index}`);
                const descriptionControl = document.getElementById(`description-control-${index}`);
                if (skillCardElement) {
                    skillCardElement.setAttribute('tabindex', '0');
                    skillCardElement.focus();
                }
                if (outcomeCardElement) {
                    outcomeCardElement.setAttribute('tabindex', '0');
                    outcomeCardElement.focus();
                }
                if (currentElement) currentElement.setAttribute('tabindex', '-1');
                if (heartControl) heartControl.setAttribute('tabindex', '-1');
                if (descriptionControl) descriptionControl.setAttribute('tabindex', '-1');
            }
        },
        [isActionDisabled, isAssigned]
    );

    const assignControlMemo = useMemo(() => {
        return (
            <AssignControlWrapper wrap={!!wrap} isCardMode={isCardMode} isAssigned={isAssigned}>
                {isAssigned ? (
                    <Tooltip title={translations?.unassignTooltipText || 'Unassign'}>
                        <StyledIconButton
                            tabIndex={index === undefined ? 0 : -1}
                            id={`unassign-control-${index}`}
                            onKeyDown={(e: KeyboardEvent<any>) =>
                                accessibilityEnterSpaceKeyCallback(e, () => onAssignControlKeyDown(e))
                            }
                            ref={assignButtonRef}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!isActionDisabled) {
                                    toggleAssign(false);
                                }
                            }}
                            aria-label='Unassign'
                        >
                            <RemoveIconImg />
                        </StyledIconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title={translations?.assignTooltipText || 'Assign'}>
                        <StyledIconButton
                            tabIndex={index === undefined ? 0 : -1}
                            id={`assign-control-${index}`}
                            onKeyDown={(e: KeyboardEvent<any>) =>
                                accessibilityEnterSpaceKeyCallback(e, () => onAssignControlKeyDown(e))
                            }
                            ref={assignButtonRef}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!isActionDisabled) {
                                    toggleAssign(true);
                                }
                            }}
                        >
                            <AddIconImg />
                        </StyledIconButton>
                    </Tooltip>
                )}
            </AssignControlWrapper>
        );
    }, [isAssigned, translations, onAssignControlKeyDown, isActionDisabled, toggleAssign, wrap, isCardMode, index]);

    return (
        <AssignedBox>
            <AssignedInnerBox isassigned={isAssigned ? 1 : 0}>
                <IsAssignedTitleSpan isCardMode={isCardMode}>
                    {isAssigned
                        ? translations?.assignedTitle || t('components.assignedControl.assigned')
                        : translations?.notAssignedTitle || t('components.assignedControl.notAssigned')}
                </IsAssignedTitleSpan>
                {(!wrap || (!isLgDown && !isMdDown)) && assignControlMemo}
            </AssignedInnerBox>

            <AssignedInfoBox>
                {isAssigned && (
                    <AssignedInfoInnerBox wrap={wrap}>
                        {assignerName && (
                            <AssignedBySpan>
                                <Tooltip
                                    title={
                                        assignerRole &&
                                        currentUserData?.id !== assignerId &&
                                        assignerRole !== EAssignerRole.Self
                                            ? `This is your ${assignerRole?.toString()?.toLowerCase()}`
                                            : ''
                                    }
                                >
                                    Assigned by {currentUserData?.id === assignerId ? 'You' : assignerName}
                                </Tooltip>
                            </AssignedBySpan>
                        )}
                        {/* {assignerRole && isAssigned && <UserRoleSpan>{assignerRole}</UserRoleSpan>} */}
                        {date && (
                            <AssignedDateSpan>
                                {typeof date === 'string' ? formatISOString(date) : formatDate(date)}
                            </AssignedDateSpan>
                        )}
                        {maintainedForWeeks !== undefined && inProgressForWeeks === undefined && (
                            <MaintainedSpan>
                                <Trans i18nKey='components.assignedControl.maintainedForWeeks'>
                                    {/* @ts-ignore */}
                                    <span>{{ streak }}</span> weeks Maintained
                                </Trans>
                            </MaintainedSpan>
                        )}
                        {maintainedForWeeks === undefined && inProgressForWeeks !== undefined && (
                            <InProgressSpan>
                                <Trans i18nKey='components.assignedControl.inProgressForWeeks'>
                                    {/* @ts-ignore */}
                                    <span>{{ streak }}</span> weeks In Progress
                                </Trans>
                            </InProgressSpan>
                        )}
                    </AssignedInfoInnerBox>
                )}
                {(isLgDown || isMdDown) && wrap && assignControlMemo}
            </AssignedInfoBox>
        </AssignedBox>
    );
};

export default AssignedControl;
